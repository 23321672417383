import { useEffect, useState } from "react";
import { API_BASE } from "../../constants/global";
import { Register, InvitationToJoin, LearningCircleActivated, LearningCircleCreated, RequestApprovedToJoin, LearningCircleRecommended, UpgradedToAdmin, LearningCircleInActivated, LearningCircleClosed } from "./notifications/LearningCircle/index";
import NoNotifications from "./notifications/NoNotifications";
import { FeedBoxSkeleton } from "../../components/skeletons";

const LearningCircleTab = () => {
    const [notifications, setNotifications] = useState('');
    const [isLoader, setIsLoader] = useState(true);
    const _token = localStorage.getItem('lmstoken');
    const [page, setPage] = useState(0);
    const [total_page, setTotalPage] = useState(0);

    useEffect(() => {
        fetchLCNotification();
        // eslint-disable-next-line 
    }, [page]);

    const fetchLCNotification = async () => {
        setIsLoader(true)
        const response = await fetch(`${API_BASE}get-user-feeds-by-tab/Learning_Circle?page=${page}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        setIsLoader(false);
        if (data.status) {
            setTotalPage(data?.data?.count);
            setNotifications([...notifications, ...data?.data?.feeds]);
        }
    }

    const nextPage = () => {
        setPage(page + 1);
    }

    return (
        <>
            <div className="tab-pane fade show active" id="pills-circle" role="tabpanel" aria-labelledby="pills-circle-tab">
                <div className="course-tab-contents">
                    <div className="course-tabs-pane-block">
                        {
                            isLoader ?
                                <FeedBoxSkeleton count={3} />
                                :
                                <>
                                    {
                                        notifications !== ''
                                        &&
                                        notifications.map((ntfcn, index) =>
                                            <div key={index}>
                                                {
                                                    ntfcn.action === 'Learning_Circle_Created'
                                                    &&
                                                    <LearningCircleCreated ntfcn={ntfcn} />
                                                }

                                                {
                                                    ntfcn.action === 'Learning_Circle_Recommended'
                                                    &&
                                                    <LearningCircleRecommended ntfcn={ntfcn} />
                                                }

                                                {
                                                    ntfcn.action === 'Request_Approved_To_Join'
                                                    &&
                                                    <RequestApprovedToJoin ntfcn={ntfcn} />
                                                }

                                                {
                                                    ntfcn.action === 'Upgraded_To_Admin'
                                                    &&
                                                    <UpgradedToAdmin ntfcn={ntfcn} />
                                                }

                                                {
                                                    ntfcn.action === 'Invitation_To_Join'
                                                    &&
                                                    <InvitationToJoin ntfcn={ntfcn} />
                                                }

                                                {
                                                    ntfcn.action === 'Learning_Circle_Activated'
                                                    &&
                                                    <LearningCircleActivated ntfcn={ntfcn} />
                                                }

                                                {
                                                    ntfcn.action === 'Learning_Circle_InActivated'
                                                    &&
                                                    <LearningCircleInActivated ntfcn={ntfcn} />
                                                }

                                                {
                                                    ntfcn.action === 'Learning_Circle_Closed'
                                                    &&
                                                    <LearningCircleClosed ntfcn={ntfcn} />
                                                }

                                                {
                                                    ntfcn.action === 'New_Tutor_Registration'
                                                    &&
                                                    <Register ntfcn={ntfcn} />
                                                }
                                            </div>
                                        )
                                    }

                                    {
                                        notifications.length < total_page
                                        &&
                                        <div className="load_more_div">
                                            <a className="cursor-pointer" onClick={(e) => { nextPage(); }}>Load more</a>
                                        </div>
                                    }
                                </>
                        }

                        {
                            notifications === ''
                            &&
                            <NoNotifications
                                main_message={'No Learning Circle Updates yet'}
                                sub_message={<>All the learning circle related updates will be shown here.</>}
                            />
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default LearningCircleTab;