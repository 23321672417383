import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

const VacancyLayout = () => {
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('lmsuser'));
    const ek_manage_vacancies = localStorage.getItem('manage_vacancies');
    useEffect(() => {        
        if(ek_manage_vacancies != 'YES'){
            navigate('/dashboard/home');
        }
        //if(user?.is_corporate !== 1){
        //    navigate('/dashboard/home');
        //}
    }, []);
    return (
        <>
        <Outlet />
        </>
    );
}

export default VacancyLayout;