import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

const AnalyticsLayout = () => {
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('lmsuser'));
    useEffect(() => {        
        if(user?.is_corporate !== 1)
        {
           // navigate('/dashboard/home');
        }
    }, []);
    return (
        <>
        <Outlet />
        </>
    );
}

export default AnalyticsLayout;