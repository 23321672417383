import { io } from "socket.io-client";
// LIVE URL
const API_BASE          = "https://beta.edukula.com/backend/public/api/";
const FRONT_BASE        = "https://debetamo.edukula.com/";
const SOCKET_BASE       = "https://beta-chat.edukula.com/";
const LRS_ENDPOINT      = "https://beta.edukula.com/lrs/public/api/v1.0/";
const socket            = io.connect(SOCKET_BASE);
const GOOGLE_CLIENT_ID  = "55255271553-3rdfjvp8qkpgatm0a9clhen2msc3viet.apps.googleusercontent.com";
const FACEBOOK_APP_ID   = "1165717227343653";
const TINYMCE_KEY       =  "7ln7sqacw0leb6ler6mlccsy062q06d5vg5pzqbwhjzh43xj" //demo.edukula

const PDF_PREFIX                    = "https://cors-anywhere.herokuapp.com/";
const DEFAULT_UPLOAD_VIDEO_IMG      = "images/upload_video.jpg";
const DEFAULT_UPLOAD_FILE_IMG       = "images/add_file.png";
const DEFAULT_UPLOAD_FILE_IMG_NEW = "images/add_video.png";
const DEFAULT_UPLOAD_LOGO_IMG       = "images/add_logo_file.jpg";
const DEFAULT_UPLOAD_RESUME_IMG     = "images/resume_upload.jpg";
const DEFAULT_UPDATE_RESUME_IMG     = "images/resume_update.jpg";
const DEFAULT_UPLOAD_FILE_IMG_NEW2 = "images/images/add_video2.png";

const SMPLE_EMPLOYEE_CSV    = "sample/Corporate_Employees.csv";
const IMAGE_EXT             = /(\.jpg|\.jpeg|\.png|\.ico|\.webp|\.svg|\.jfif)$/i;
const IMAGE_TXT             = "Please upload JPG, JPEG, PNG, ICO, WEBP, SVG OR JFIF image only";

const VIDEO_EXT     = /(\.mp4|\.mkv)$/i;
const VIDEO_TXT     = "Please upload MP4, MKV video only";

const LRS_VERSION   =  "1.0";
export {
    API_BASE,
    FRONT_BASE,
    SOCKET_BASE,
    LRS_ENDPOINT,
    socket,
    GOOGLE_CLIENT_ID,
    FACEBOOK_APP_ID,
    TINYMCE_KEY,
    PDF_PREFIX,
    DEFAULT_UPLOAD_VIDEO_IMG,
    DEFAULT_UPLOAD_FILE_IMG,
    DEFAULT_UPLOAD_FILE_IMG_NEW,
    DEFAULT_UPLOAD_LOGO_IMG,
    DEFAULT_UPLOAD_RESUME_IMG,
    DEFAULT_UPDATE_RESUME_IMG,
    SMPLE_EMPLOYEE_CSV,
    DEFAULT_UPLOAD_FILE_IMG_NEW2,
    IMAGE_EXT,
    IMAGE_TXT,
    VIDEO_EXT,
    VIDEO_TXT,

};