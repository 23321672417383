import { API_BASE } from "../../constants/global";
import { useEffect, useLayoutEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import VerifiedBadge from "../../components/VerifiedBadge";
import { Helmet } from "react-helmet";

const TutorProfile = () => {

    const { slug } = useParams();
    const navigate = useNavigate();
    const [tutorProfile, setTutorProfile] = useState([]);
    const [ratingStar, setRatingStar] = useState([1, 2, 3, 4, 5]);
    useEffect(() => {
    }, []);
    const location = useLocation();

    useLayoutEffect(() => {
        fetchTutorProfile();
    }, [location.pathname]);
    const fetchTutorProfile = async () => {
        const response = await fetch(`${API_BASE}get-tutor-profile/${slug}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
        });

        const data = await response.json();
        if (data.status) {
            setTutorProfile(data.data);
        } else {
            setTutorProfile([]);
            navigate("/404");
        }
    }

    return (
        <>
            <Helmet>
                <title>{'EduKula - Tutor'}</title>
            </Helmet>
            {
                tutorProfile !== ''
                &&
                <>
                    <section className="custom-section">
                        <div className="profile-top-banner">
                            <img src={tutorProfile?.cover} className="img-fluid w-100" />
                        </div>
                        <div className="profile-top-desc">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-2">
                                        <div className="profile-top-block">
                                            <div className="proflie-user-img rounded-circle">
                                                <img src={tutorProfile?.photo} className="rounded-circle" alt={tutorProfile?.full_name} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="profile-top-block">
                                            <h3>{tutorProfile?.full_name}<VerifiedBadge _is_verified={tutorProfile?.is_verified} refname="dashboard"/></h3>
                                            <p>{tutorProfile?.headline}</p>
                                            {
                                                (tutorProfile?.tutor_average_rating > 0 && tutorProfile?.is_tutor == 1)
                                                &&
                                                <div className="review-section">
                                                    <a>
                                                        {
                                                            ratingStar.map((element, index) =>
                                                                (element <= tutorProfile.tutor_average_rating)
                                                                &&
                                                                <i key={index} className="fas fa-star"></i>
                                                            )
                                                        }
                                                    </a>
                                                </div>
                                            }
                                            {
                                                (tutorProfile?.is_tutor == 1)
                                                &&
                                                <div className="followers-section">
                                                    <div className="follower-block">
                                                        <h5>Total Students</h5>
                                                        <h4>{tutorProfile?.total_students}</h4>
                                                    </div>
                                                    <div className="follower-block">
                                                        <h5>Reviews</h5>
                                                        <h4>{tutorProfile?.total_reviews}</h4>
                                                    </div>
                                                    <div className="follower-block">
                                                        <h5>Courses</h5>
                                                        <h4>{tutorProfile?.active_courses_count}</h4>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="profile-top-block">
                                            <div className="profile-social-btn">
                                                <div className="social-icons">
                                                    {
                                                        tutorProfile?.facebook_link
                                                        &&
                                                        <a href={tutorProfile.facebook_link} target="_blank" className="btn btn-circle btn-success">
                                                            <i className="fab fa-facebook-f"></i>
                                                        </a>
                                                    }
                                                    {
                                                        tutorProfile?.twitter_link
                                                        &&
                                                        <a href={tutorProfile?.twitter_link} target="_blank" className="btn btn-circle btn-success">
                                                            <i className="fab fa-twitter"></i>
                                                        </a>
                                                    }
                                                    {
                                                        tutorProfile?.linkedin_link
                                                        &&
                                                        <a href={tutorProfile?.linkedin_link} target="_blank" className="btn btn-circle btn-success">
                                                            <i className="fab fa-linkedin-in"></i>
                                                        </a>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="custom-section prof_section">
                        <div className="explore-section">
                            <div className="container">
                                <div className="explore-block">
                                    <Tabs defaultActiveKey="about" className="nav-pills">
                                        <Tab eventKey="about" title="About">
                                            <div className="new-profile-tabs">
                                                {
                                                    tutorProfile?.about_me
                                                        ?
                                                        <div dangerouslySetInnerHTML={{ __html: tutorProfile?.about_me }} />
                                                        :
                                                        <p>No about information of {tutorProfile?.full_name}.</p>
                                                }
                                            </div>
                                            <div className="clearfix"></div>
                                        </Tab>
                                        {
                                            (typeof tutorProfile?.educations !== "undefined" && tutorProfile?.educations?.length > 0)
                                            &&
                                            <Tab eventKey="education" title="Education">
                                                <div className="new-profile-tabs">
                                                    <ul className="educat_list">
                                                        {
                                                            tutorProfile.educations.map((element, index) =>
                                                                <li key={index}>
                                                                    <div className="textbox">
                                                                        <h4>{element?.school_name}</h4>
                                                                        <p className="pb-2">{element?.degree} <span>{element?.start_date} - {element?.end_date}</span></p>
                                                                        <p>{element?.description}</p>
                                                                    </div>
                                                                </li>
                                                            )
                                                        }
                                                    </ul>
                                                    <div className="clearfix"></div>
                                                </div>
                                            </Tab>
                                        }
                                        {
                                            (typeof tutorProfile?.experiences !== "undefined" && tutorProfile?.experiences?.length > 0)
                                            &&
                                            <Tab eventKey="experience" title="Experience">
                                                <div className="new-profile-tabs">
                                                    <ul className="educat_list">
                                                        {
                                                            tutorProfile.experiences.map((element, index) =>
                                                                <li key={index}>
                                                                    <div className="textbox">
                                                                        <h4>{element?.title}</h4>
                                                                        <p className="pb-2"><strong>{element?.company}</strong> - {element?.type}<span>{element?.start_date} - {`${element?.currently_working == 1 ? 'Present' : element?.end_date ? element?.end_date : ""}`}</span><span>{element?.location}</span></p>
                                                                        <p>{element?.description}</p>
                                                                    </div>
                                                                </li>
                                                            )
                                                        }
                                                    </ul>
                                                    <div className="clearfix"></div>
                                                </div>
                                            </Tab>
                                        }
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </section>
                    {
                        (tutorProfile?.is_tutor == 1)
                        &&
                        <section className="custom-section">
                            <div className="explore-section">
                                <div className="container">
                                    <div className="explore-block">
                                        <Tabs defaultActiveKey="0" className="nav-pills">
                                            {
                                                (typeof tutorProfile?.category !== "undefined" && tutorProfile?.category?.length > 0)
                                                &&
                                                tutorProfile.category.map((element, index) =>
                                                    (element?.courses)
                                                    &&
                                                    <Tab key={index} eventKey={index} title={element.title}>
                                                        <div className="blog-card">
                                                            <div className="row">
                                                                {
                                                                    element.courses.map((child_element, child_index) =>
                                                                        <div className="col-md-6 col-lg-3" key={child_index}>
                                                                            <div className="blog-card-detail">
                                                                                <Link to={`/course-details/${child_element?.slug}`} className="ek-lrs-course-initialized">
                                                                                    <div className="custom-card-img">
                                                                                        {
                                                                                            child_element?.associated_vacancy > 0 && typeof child_element?.associated_vacancy !== 'undefined'
                                                                                            &&
                                                                                            <>
                                                                                                <div className="hiring">
                                                                                                    <span className="highest">Hiring</span>
                                                                                                </div>
                                                                                            </>
                                                                                        }
                                                                                        <img src={child_element?.image} className="card-img-top" alt={child_element?.title} />
                                                                                    </div>
                                                                                    <div className="blog-card-inner">
                                                                                        <h5>{child_element?.title}</h5>
                                                                                        <ul className="course_tabs_sub mt-3 mb-2">
                                                                                            {
                                                                                                child_element?.sub_category?.title
                                                                                                &&
                                                                                                <li><a><i className="fas fa-list"></i><span>{child_element?.sub_category?.title}</span></a></li>
                                                                                            }
                                                                                            {
                                                                                                child_element?.mode_of_learning
                                                                                                &&
                                                                                                <li><a><i className="far fa-user"></i><span>{child_element?.mode_of_learning}</span></a></li>
                                                                                            }
                                                                                            {
                                                                                                <li><a><i className="fas fa-star"></i><span>{child_element?.rating !== "" ? child_element?.rating : 'No'} Rating</span></a></li>
                                                                                            }
                                                                                        </ul>
                                                                                        <div className="clearfix"></div>
                                                                                    </div>
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </Tab>
                                                )
                                            }
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </section>
                    }
                </>
            }
        </>
    );
}

export default TutorProfile;