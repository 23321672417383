import { API_BASE } from "../constants/global";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import { Toaster } from "../entity/GlobalJS";
import { Helmet } from "react-helmet";

const NoAuthCertificates = () => {
    const [completed_courses, setCompletedCourses] = useState('');
    const [isLoader, setIsLoader] = useState(true);

    const { emp } = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        fetchCompletedCourses();
    }, []);

    const fetchCompletedCourses = async () => {
        if (typeof emp === 'undefined' || emp === '') {
            navigate('/');
        }
        setIsLoader(true);
        const response = await fetch(API_BASE + `user-completed-courses/${emp}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
        });

        const data = await response.json();
        if (data.status) {
            setIsLoader(false);
            var courses = data?.data?.courses;
            setCompletedCourses(courses);
        } else {
            Toaster.error(data.message);
            setIsLoader(false);
            navigate('/');
        }
    }

    return (
        <>
            <Helmet>
                <title>{'EduKula - Completed Courses'}</title>
            </Helmet>
            {/* Top Courses */}
            {
                (typeof completed_courses !== "undefined" && completed_courses?.length > 0)
                &&
                <section className="hiring_course top_course">
                    <div className="container">
                        <div className="explore-block">
                            <div className="title_with_btn">
                                <div>
                                    <h2><span>Completed</span> courses</h2>
                                    <p>You can download all your course completion certificates by clicking the download button below</p>
                                </div>
                                <Link to={"/courses"} className="btn">Explore more</Link>
                            </div>
                            {
                                (typeof completed_courses !== "undefined" && completed_courses?.length > 0)
                                &&
                                <div className="blog-card">
                                    <div className="row">
                                        {
                                            completed_courses.map((course, index) =>
                                                <div key={index} className="col-md-6 col-lg-3">
                                                    <div className="blog-card-detail m-0">
                                                        <div className="custom-card-img">
                                                            <img src={course?.image} alt={course?.title} />
                                                        </div>
                                                        <div className="blog-card-inner">
                                                            <h5><a>{course?.title}</a></h5>
                                                            <ul className="course_tabs_sub mt-3 mb-2">

                                                                <li className="">
                                                                    <div className="dropdown">
                                                                    &nbsp;&nbsp;<button title="Download Certificate" className="btn btn-info btn-circle py-2 px-3 download_btn" type="button" data-toggle="dropdown">&nbsp;&nbsp;Download Certificate &nbsp; <i className="fas fa-download"></i></button>
                                                                        <ul className="dropdown-menu">
                                                                            {course?.image_file !== '' ? <li><a href={course?.image_file} className="download pr-5" target="_blank">JPEG</a></li> : null}
                                                                            {course?.pdf_file !== '' ? <li><a href={course?.pdf_file} className="download pr-5" target="_blank">PDF</a></li> : null}
                                                                        </ul>
                                                                    </div>
                                                                </li>
                                                                <li>&nbsp;</li>
                                                                <li><a><i className="fas fa-list"></i><span>{course?.category}</span></a></li>
                                                                <li><a><i className="fas fa-user"></i><span>{course?.mode}  Learning</span></a></li>
                                                                <li><a><i className="fas fa-tasks"></i><span>{course?.progress}</span></a></li>
                                                                <li><a><i className="text-primary fa fa-award"></i><span>Certificate</span></a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </section>
            }
            <div className="clearfix"></div>
        </>
    );
}

export default NoAuthCertificates;