import { useForm } from "react-hook-form";
import { API_BASE } from "../../constants/global";
import { useContext, useEffect } from "react";
import { Toaster } from "./../../entity/GlobalJS";
import { Link, useNavigate } from "react-router-dom";
import authContext from "../../context/auth/authContext";
import { Helmet } from "react-helmet";


const ForgotPassword = () => {
    const navigate = useNavigate();
    const cntxAuth = useContext(authContext);

    useEffect(() => {
        if (cntxAuth.isLogged) {
            navigate("/");
        }
        //eslint-disable-next-line
    }, []);

    const { register, handleSubmit, formState: { errors }, setValue } = useForm();

    async function onSubmit(data) {
        let response = await fetch(API_BASE + "forgot-password", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(data)
        });

        response = await response.json();

        if (response.status) {
            setValue('email', '');
            Toaster.success(response.message);
        } else {
            Toaster.error(response.message);
        }
    }

    return (
        <>
            <Helmet>
                <title>{'EduKula - Forgot Password'}</title>
            </Helmet>
            <section>
                <div className="custom-login-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="custom-login-box">
                                    <div className="login-form">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <h2>Forgot Password</h2>
                                            <div className="form-group">
                                                <input {...register("email", {
                                                    required: "required",
                                                    pattern: {
                                                        value: /\S+@\S+\.\S+/,
                                                        message: "Entered value does not match email format"
                                                    }
                                                })} type="email" className="form-control" placeholder="Email Address"
                                                    autoComplete="off"
                                                />
                                                <span className="text-danger">
                                                    {errors.email && errors.email.type === "required" && 'Please enter email address'}
                                                    {errors.email && errors.email.type !== "required" && errors.email.message}
                                                </span>
                                            </div>
                                            <div className="form-group small clearfix">
                                                <Link to="/login" className="forgot-link">Already have an account ?</Link>
                                            </div>
                                            <button type="submit" className="btn btn-success">Submit</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="custom-login-box">
                                    <div className="login-img-box">
                                        <img src="images/login-img.png" alt="Login" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ForgotPassword;
