import { useEffect } from "react";
import { useState } from "react";
import { API_BASE } from "../../constants/global";
import CompletedCourses from "./listings/CompletedCourses";
import OnGoingCourses from "./listings/OnGoingCourses";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Joyride from 'react-joyride';
import { SwalBox, Toaster } from "../../entity/GlobalJS";

const MyCourses = () => {
    const _token = localStorage.getItem('lmstoken');
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('lmsuser'));
    const [enrolled, setEnrolled] = useState(false);
    const [modes, setModes] = useState('');
    const [progs, setProgs] = useState('');
    const [tour_steps, setTourSteps] = useState([]);
    useEffect(() => {
        if (user?.is_corporate === 1) {
            navigate('/dashboard/home');
        }

        fetchIsEnrolled();
        showCourseTour();
    }, []);
    const showCourseTour = () => {
        let show_tour = Cookies.get("show_student_tour");  
        if(show_tour != 'NO'){
            SwalBox.fire({
                allowOutsideClick: false,
                title: 'Would you like to have a quick tour?',
                text: 'This tour will take you through all the menu items in Student Dashbaord section and introduce you with the features.',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#30314c',
                cancelButtonColor: '#30314c',
                confirmButtonText: 'Yes, please.',
                cancelButtonText:'No, thank you.'
            }).then((result) => {
                if (result.isConfirmed) {
                    startCourseTour();
                }else{
                    Cookies.set('show_student_tour', 'NO');
                }
            });
        }
    }
    const startCourseTour = () => {
        let steps= [];
        steps= [
            {placementBeacon : 'right',disableBeacon : true,target: '.tour-student-step-01',title : 'Ongoing courses',content: 'In this tab, you can find all the courses you have enrolled in that are either not started or currently in progress.',},
            {placementBeacon : 'right',disableBeacon : true,target: '.tour-student-step-02',title : 'Filter courses',content: 'Use this feature to filter courses based on criteria such as category, course progress, and learning type. It helps you find the courses that best suit your preferences and needs.',},
            {placementBeacon : 'right',disableBeacon : true,target: '.tour-student-step-03',title : 'Completed courses',content: 'In this tab, you will find all the certificates for the courses you have successfully completed. It serves as a record of your achievements and acquired skills.',},
        ];
        setTourSteps(steps);
        Cookies.set('show_student_tour', 'NO');
    }
    const fetchIsEnrolled = async () => {
        const response = await fetch(`${API_BASE}get-student-count`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`

            },
        });

        const data = await response.json();
        if (data.status) {
            setEnrolled(data.data.count > 0 ? true : false);
            setModes(data.data.modes);
            setProgs(data.data.progs);
        } else {
            setEnrolled(false);
            setModes('');
            setProgs('');
        }
    }

    return (
        <>
            <div className="col-lg-9 home-section">
                <div className="custom-user-profile-content custom-learning-circle-content tutor_dashboard">
                    <div className="custom-learnig-circle-head">
                        <h4>Student Dashboard</h4>
                    </div>

                    <div className="learning-circle-courses">
                        <section>
                            <div className="explore-section">
                                <div className="container">
                                    {
                                        !enrolled
                                            ?
                                            <div className="empty_state">
                                                <h3>No courses enrolled</h3>
                                                <img alt="" src="images/no_courses.png" />
                                                <p>
                                                    Explore our course catalogue to enrol on a course and start your learning journey.
                                                    <br /><a href="/courses" className="link" >Explore Courses</a>
                                                </p>
                                            </div>
                                            :
                                            null
                                    }

                                    {
                                        enrolled
                                            ?
                                            <div className="explore-block">
                                                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                                    <li className="nav-item tour-student-step-01">
                                                        <NavLink to={`/dashboard/student/ongoing-courses`} className={`nav-link`} id="pills-course-tab" data-toggle="pill" href="#pills-course" role="tab" aria-controls="pills-course" aria-selected="true">Ongoing Courses</NavLink>
                                                    </li>
                                                    <li className="nav-item tour-student-step-03">
                                                        <NavLink to={`/dashboard/student/completed-courses`} className={`nav-link`} id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Completed Courses</NavLink>
                                                    </li>
                                                </ul>
                                                <div className="tab-content" id="pills-tabContent">
                                                    <Outlet context={[modes, progs]} />
                                                </div>
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            {
            tour_steps.length > 0 
            &&                 
            <Joyride
                steps={tour_steps}
                disableCloseOnEsc={true}
                disableOverlayClose={true}
                showSkipButton={true}
                continuous={true}
                hideCloseButton={true}

                styles={{options: {width: 500,zIndex: 5000,primaryColor: '#31314c',textColor: '#ef4a45',overlayColor: 'rgb(2 1 0 / 69%)',}}}
            />
        } 
        </>
    );
}

export default MyCourses;