import { useEffect, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { CircleBoxSkeleton } from "../../components/skeletons";
import { API_BASE } from "../../constants/global";
import CustomPagination from "../../entity/CustomPagination";
import CourseCard from "./course_tab/CourseCard";
import { Helmet } from "react-helmet";


const ExploreCourses = (props) => {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const currentPage = Number(searchParams.get('page')) || 0;
    const [reload, setReload] = useState(false);

    const _token = localStorage.getItem('lmstoken');
    const [search, setSearch] = useState('');
    const [total_count, setTotalCount] = useState(0);
    const [isLoader, setIsLoader] = useState(true);

    const [courses, setCourses] = useState('');
    const [categories, setCategories] = useState('');
    const [sub_categories, setSubCategories] = useState('');
    const [levels, setLevels] = useState('');
    const [languages, setLanguages] = useState('');
    const [jobs, setJobs] = useState('');
    const [modes, setModes] = useState('');
    const [rates, setRates] = useState('');

    const [selected_categories, setSelectedCategories] = useState('');
    const [selected_sub_categories, setSelectedSubCategories] = useState(props.type === 'sc' ? [props.category.slug] : '');
    const [selected_levels, setSelectedLevels] = useState('');
    const [selected_languages, setSelectedLanguages] = useState('');
    const [selected_jobs, setSelectedJobs] = useState('');
    const [selected_mode, setSelectedModes] = useState('');
    const [selected_rate, setSelectedRates] = useState('');
    const [clearAllBtnClass, setclearAllBtnClass] = useState('hide');
    const [currentItems, setCurrentItems] = useState([]);
    const [coursePerPage, setCoursePerPage] = useState(8);
    const [itemOffset, setItemOffset] = useState(0);
    // let itemsPerPage = 8;
    // const endOffset = itemOffset + coursePerPage;
    // const currentItems = courses.slice(itemOffset, endOffset);
    // const pageCount = Math.ceil(courses.length / itemsPerPage);
    // const [totalPageCount, setTotalPageCount] = useState(0);

    useEffect(() => {
        fetchCourses(currentPage);
        fetchCategories();
        fetchSubCategories();
        fetchLevels();
        fetchLanguage();
        fetchJobs()
        fetchModes();
        fetchRates();
        removeClearBtn();

        // eslint-disable-next-line

    }, [selected_categories, selected_sub_categories, selected_levels, selected_languages, selected_jobs, selected_mode, selected_rate, clearAllBtnClass, reload]);

    const funReload = (page) => {
        setSearchParams({ ...searchParams, page: page });
        setReload(!reload);
    }


    async function fetchCategories() {
        const response = await fetch(`${API_BASE}get-category-list`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        });

        const data = await response.json();
        if (data.status) {
            setCategories(data.data.course_categories);
        }
    }

    async function fetchSubCategories() {
        if (typeof props.type !== 'undefined') {
            var slug = '';
            if (typeof props.category !== 'undefined' && props.type === 'c') {
                slug = props.category.slug
            } else {
                slug = props.category.main_categories.slug;
            }

            const response = await fetch(`${API_BASE}get-sub-category-list/${slug}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
            });

            const data = await response.json();
            if (data.status) {
                setSubCategories(data.data.course_sub_categories);
            }
        }
    }

    async function fetchLevels() {
        const response = await fetch(`${API_BASE}get-course-levels`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        });

        const data = await response.json();
        if (data.status) {
            setLevels(data.data.levels);
        }
    }

    async function fetchLanguage() {
        const response = await fetch(`${API_BASE}get-course-languages`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        });

        const data = await response.json();
        if (data.status) {
            setLanguages(data.data.languages);
        }
    }

    async function fetchJobs() {
        setJobs([{ 'id': 1, 'name': 'Hiring' }, { 'id': 0, 'name': 'General' }]);
    }
    async function fetchModes() {
        const response = await fetch(`${API_BASE}get-course-learning-modes`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        });

        const data = await response.json();
        if (data.status) {
            setModes(data.data.modes);
        }
    }

    async function fetchRates() {
        const response = await fetch(`${API_BASE}get-course-rate-levels`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        });

        const data = await response.json();
        if (data.status) {
            setRates(data.data.rates);
        }
    }

    const fetchCourses = async (page = 0) => {
        var formData = new FormData();

        if (typeof props.type === 'undefined' && selected_categories !== '' && selected_categories.length > 0) {
            for (var i = 0; i < selected_categories.length; i++) {
                formData.append('c[]', selected_categories[i]);
            }
        }

        if (typeof props.type !== 'undefined') {
            if (typeof props.category !== 'undefined' && props.type === 'c') {
                formData.append('c[]', props.category.slug);
            } else {
                formData.append('c[]', props.category.main_categories.slug);
            }

            if (selected_sub_categories !== '' && selected_sub_categories.length > 0) {
                for (var i = 0; i < selected_sub_categories.length; i++) {
                    formData.append('sc[]', selected_sub_categories[i]);
                }
            }
        }

        if (selected_levels !== '' && selected_levels.length > 0) {
            for (var i = 0; i < selected_levels.length; i++) {
                formData.append('lvl[]', selected_levels[i]);
            }
        }

        if (selected_languages !== '' && selected_languages.length > 0) {
            for (var i = 0; i < selected_languages.length; i++) {
                formData.append('lng[]', selected_languages[i]);
            }
        }

        if (selected_mode !== '' && selected_mode.length > 0) {
            for (var i = 0; i < selected_mode.length; i++) {
                formData.append('lt[]', selected_mode[i]);
            }
        }

        if (selected_rate !== '' && selected_rate.length > 0) {
            for (var i = 0; i < selected_rate.length; i++) {
                formData.append('rt', selected_rate[i]);
            }
        }

        setIsLoader(true);

        const response = await fetch(`${API_BASE}search-course-list?page=${page}&s=${search}`, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + _token
            },
            body: formData
        });

        const data = await response.json();
        setIsLoader(false);
        if (data.status) {
            if (selected_jobs == 'General,Hiring' || selected_jobs == '') {
                setCourses(data.data.courses);
                setItemOffset(0);
                let endOffset = 0 + coursePerPage;
                let tempCurrentItems = data.data.courses.slice(0, endOffset);
                setCurrentItems(tempCurrentItems);
                setTotalCount(data.data.total_count);
            } else {
                var _course_data = data.data.courses;
                var _updated_course_data = [];
                _course_data.forEach(function (i) {
                    if (selected_jobs == 'General') {
                        if (i.vacancy.length == 0) { _updated_course_data.push(i); }
                    } else {
                        if (i.vacancy.length > 0) { _updated_course_data.push(i); }
                    }
                });
                if (_updated_course_data.length > 0) {
                    setCourses(_updated_course_data);
                    setItemOffset(0);
                    let endOffset = 0 + coursePerPage;
                    let tempCurrentItems = _updated_course_data.slice(0, endOffset);
                    setCurrentItems(tempCurrentItems);
                    setTotalCount(_updated_course_data.length);
                } else {
                    setCourses('');
                    setTotalCount(0);
                    window.scrollTo(0, 200);
                }
            }
            window.scrollTo(0, 0);
        } else {
            setCourses('');
            setTotalCount(0);
            window.scrollTo(0, 200);
        }
    }

    const clearSearch = () => {
        setSearch('');
        setSelectedCategories('');
        setSelectedSubCategories('');
        setSelectedLevels('');
        setSelectedLanguages('');
        setSelectedJobs('');
        setSelectedModes('');
        setSelectedRates('');
        setclearAllBtnClass('hide');
        fetchCourses();
    }

    const removeClearBtn = () => {
        if (selected_categories.length === 0 &&
            selected_sub_categories.length === 0 &&
            selected_levels.length === 0 &&
            selected_languages.length === 0 &&
            selected_mode.length === 0 &&
            selected_rate.length === 0 &&
            selected_jobs.length === 0
        ) {
            setclearAllBtnClass('hide');
        }
    }

    const handlePaginationPageClick = (event) => {
        const newOffset = (event.selected * coursePerPage) % courses.length;
        setItemOffset(newOffset);
       // setSearchParams({ ...searchParams, page: event.selected });
    }

    useEffect(() => {
        let endOffset = itemOffset + coursePerPage;
        setCurrentItems(courses.slice(itemOffset, endOffset));
    }, [itemOffset, coursePerPage]);

    return (
        <>
            <Helmet>
                <title>{'EduKula - courses'}</title>
            </Helmet>
            <section className="new_learning_circle_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9">
                            <div className="category-search-info">
                                <p>Search for courses</p>
                                <div className="footer-newslwtter1">
                                    <input onChange={(e) => { setSearch(e.target.value); }} onKeyUp={(e) => { if (e.key === 'Enter') { fetchCourses(); } }} type="text" name="search" placeholder="What do you want to learn ?" value={search} />
                                    <button onClick={(e) => { fetchCourses(); }} className="btn btn-success"><i className="fas fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="custom-section new_learning_circle_cate">
                <div className="category-search-section">
                    <div className="container">
                        <div className="category-search-block">
                            {/* <div className="category-search-info">
                                <p>Search Courses</p>
                                <div className="footer-newslwtter">
                                    <i className="fas fa-search"></i>
                                    <input onChange={(e) => { setSearch(e.target.value); }} onKeyUp={(e) => { if (e.key === 'Enter') { fetchCourses(); } }} type="text" name="search" placeholder="What do you want to learn ?" value={search} />
                                    <button onClick={(e) => { fetchCourses(); }} className="btn btn-info">search</button>
                                </div>
                            </div> */}
                            <div className="category-navigation">
                                <ul>
                                    {
                                        (typeof props.type === 'undefined' && categories !== '')
                                        &&
                                        <li>
                                            <div className="dropdown">
                                                <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Category</button>
                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    {
                                                        categories.map((category, index) => {
                                                            var flag = selected_categories.indexOf(category.slug) !== -1;
                                                            return (<a onClick={(e) => { if (!flag) { setSelectedCategories([...selected_categories, category.slug]); } setclearAllBtnClass(''); }} key={index} className={`dropdown-item ${flag ? ' active' : ''}`}>{category.title}</a>);
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </li>
                                    }

                                    {
                                        (typeof props.type !== 'undefined' && sub_categories !== '')
                                        &&
                                        <li>
                                            <div className="dropdown">
                                                <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Sub Category</button>
                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    {
                                                        sub_categories.map((category, index) => {
                                                            var flag = selected_sub_categories.indexOf(category.slug) !== -1;
                                                            return (<a onClick={(e) => { if (!flag) { setSelectedSubCategories([...selected_sub_categories, category.slug]); } setclearAllBtnClass(''); }} key={index} className={`dropdown-item ${flag ? ' active' : ''}`}>{category.title}</a>);
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </li>
                                    }

                                    {
                                        levels !== ''
                                        &&
                                        <li>
                                            <div className="dropdown">
                                                <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Level</button>
                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    {
                                                        levels.map((level, index) => {
                                                            var flag = selected_levels.indexOf(level.key) !== -1;
                                                            return (<a onClick={(e) => { if (!flag) { setSelectedLevels([...selected_levels, level.key]); } setclearAllBtnClass(''); }} key={index} className={`dropdown-item ${flag ? ' active' : ''}`}>{level.level}</a>);
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </li>
                                    }

                                    {/*
                                        languages !== ''
                                        &&
                                        <li>
                                            <div className="dropdown">
                                                <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Language</button>
                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    {
                                                        languages.map((language, index) => {
                                                            var flag = selected_languages.indexOf(language.name) !== -1;
                                                            return (<a onClick={(e) => { if (!flag) { setSelectedLanguages([...selected_languages, language.name]); } setclearAllBtnClass(''); }} key={index} className={`dropdown-item ${flag ? ' active' : ''}`}>{language.name}</a>);
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </li>
                                    */}
                                    {
                                        jobs !== ''
                                        &&
                                        <li>
                                            <div className="dropdown">
                                                <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Course Type</button>
                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    {
                                                        jobs.map((job, index) => {
                                                            {/* console.log('Hello', selected_jobs.indexOf(job.name) !== -1); */ }
                                                            var flagj = selected_jobs.indexOf(job.name) !== -1;
                                                            return (<a onClick={(e) => {
                                                                if (!flagj) {
                                                                    setSelectedJobs([...selected_jobs, job.name]);
                                                                }
                                                                setclearAllBtnClass('');
                                                            }} key={index} className={`dropdown-item ${flagj ? ' active' : ''}`}>{job.name}</a>);
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </li>
                                    }

                                    {
                                        modes !== ''
                                        &&
                                        <li>
                                            <div className="dropdown">
                                                <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Learning type</button>
                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    {
                                                        modes.map((mode, index) => {
                                                            var flag = selected_mode.indexOf(mode.mode) !== -1;
                                                            return (<a onClick={(e) => { if (!flag) { setSelectedModes([...selected_mode, mode.mode]); } setclearAllBtnClass(''); }} key={index} className={`dropdown-item ${flag ? ' active' : ''}`}>{mode.mode}</a>);
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </li>
                                    }

                                    {
                                        rates !== ''
                                        &&
                                        <li>
                                            <div className="dropdown">
                                                <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Rating</button>
                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    {
                                                        rates.map((rate, index) => {
                                                            var flag = selected_rate.indexOf(rate) !== -1;
                                                            return (<a onClick={(e) => { if (!flag) { setSelectedRates([rate]); } setclearAllBtnClass(''); }} key={index} className={`dropdown-item ${flag ? ' active' : ''}`}>{rate} Above</a>);
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </li>
                                    }
                                </ul>
                            </div>
                            <div className="selected-filter filter_display">
                                <ul>
                                    {
                                        selected_categories !== ''
                                        &&
                                        selected_categories.map((category, index) =>
                                            <li key={index}>
                                                <span className="btn btn-light btn-success select-filter"><span>{category}</span><i onClick={(e) => { setSelectedCategories(selected_categories.filter(item => item !== category)) }} className="fas fa-times"></i></span>
                                            </li>
                                        )
                                    }

                                    {
                                        selected_sub_categories !== ''
                                        &&
                                        selected_sub_categories.map((sub_category, index) =>
                                            <li key={index}>
                                                <span className="btn btn-light btn-success select-filter"><span>{sub_category}</span><i onClick={(e) => { setSelectedSubCategories(selected_sub_categories.filter(item => item !== sub_category)) }} className="fas fa-times"></i></span>
                                            </li>
                                        )
                                    }

                                    {
                                        selected_levels !== ''
                                        &&
                                        selected_levels.map((level, index) =>
                                            <li key={index}>
                                                <span className="btn btn-light btn-success select-filter"><span>{level}</span><i onClick={(e) => { setSelectedLevels(selected_levels.filter(item => item !== level)) }} className="fas fa-times"></i></span>
                                            </li>
                                        )
                                    }

                                    {/*
                                        selected_languages !== ''
                                        &&
                                        selected_languages.map((language, index) =>
                                            <li key={index}>
                                                <span className="btn btn-light btn-success select-filter"><span>{language}</span><i onClick={(e) => { setSelectedLanguages(selected_languages.filter(item => item !== language)) }} className="fas fa-times"></i></span>
                                            </li>
                                        )
                                    */}

                                    {
                                        selected_jobs !== ''
                                        &&
                                        selected_jobs.map((job, index) =>
                                            <li key={index}>
                                                <span className="btn btn-light btn-success select-filter"><span>{job}</span><i onClick={(e) => { setSelectedJobs(selected_jobs.filter(item => item !== job)) }} className="fas fa-times"></i></span>
                                            </li>
                                        )
                                    }

                                    {
                                        selected_mode !== ''
                                        &&
                                        selected_mode.map((mode, index) =>
                                            <li key={index}>
                                                <span className="btn btn-light btn-success select-filter"><span>{mode}</span><i onClick={(e) => { setSelectedModes(selected_mode.filter(item => item !== mode)) }} className="fas fa-times"></i></span>
                                            </li>
                                        )
                                    }

                                    {
                                        selected_rate !== ''
                                        &&
                                        selected_rate.map((rate, index) =>
                                            <li key={index}>
                                                <span className="btn btn-light btn-success select-filter"><span>{rate} Above</span><i onClick={(e) => { setSelectedRates(selected_rate.filter(item => item !== rate)) }} className="fas fa-times"></i></span>
                                            </li>
                                        )
                                    }
                                </ul>

                                {
                                    <a onClick={(e) => { clearSearch(); }} className={`remove-filter ${clearAllBtnClass}`}>Clear all</a>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </section>
            <div className="clearfix"></div>

            <section className="custom-section new_cources_list">
                <div className="course-list">
                    <div className="container">
                        <div className="title_box">
                            {
                                courses !== ''
                                &&
                                <>
                                    <h3><span>Courses</span> on EduKula ({total_count} Results)</h3>
                                    <p>Learn courses to upskill yourself and boost your job prospects</p>
                                </>
                            }
                        </div>
                        <div className="blog-card">
                            <div className="row">
                                {
                                    isLoader ?
                                        <>
                                            <CircleBoxSkeleton count={4} boxSize={'col-lg-3'} />
                                        </>
                                        :
                                        <>
                                            {
                                                courses !== ''
                                                &&
                                                currentItems.map((course, index) =>
                                                    <div key={index} className="col-md-6 col-lg-3 mb-4">
                                                        <CourseCard course={course} />
                                                    </div>
                                                )
                                            }

                                            {
                                                courses === ''
                                                &&
                                                <div className="col-12">
                                                    <div className="empty_state">
                                                        <h3>No courses found!</h3>
                                                        <img alt="" src="images/no_courses.png" />
                                                        <p>
                                                            Explore our course catalogue to enrol on a course and start your learning journey.
                                                            <br /><a className="link" href="/courses">Explore Courses</a>
                                                        </p>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                }
                            </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>

                {
                    total_count > coursePerPage
                    &&
                    <div className="custom-pagination">
                        <nav aria-label="Page navigation example">
                            <CustomPagination
                                pageCount={total_count}
                                perPageRecord={coursePerPage}
                                handlePageClick={handlePaginationPageClick}
                                currentPage={currentPage}
                            />
                        </nav>
                    </div>
                }

                <div className="clearfix"></div>
            </section >
            <div className="clearfix"></div>
        </>
    );
}

export default ExploreCourses;
