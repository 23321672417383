import { Link } from "react-router-dom";
import { API_BASE } from "../constants/global";
import { useEffect, useState } from "react";
import parse from 'html-react-parser';

const LearningCircleBreadcrumb = ({ category_slug, sub_category_slug }) => {
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [active_category, setActiveCategory] = useState([]);
    const [related_categories, setRelatedCategories] = useState([]);

    useEffect(() => {
        fetchBreadcrumbs();
    }, [category_slug, sub_category_slug]);

    async function fetchBreadcrumbs() {
        let type = 'learning_circle';
        const response = await fetch(`${API_BASE}get-breadcrumb-data?type=${type}&${(category_slug)?'&category_slug='+category_slug:''}${(sub_category_slug)?'&sub_category_slug='+sub_category_slug:''}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        });

        const data = await response.json();
        if (data.status) {
            setBreadcrumbs(data.data.breadcrumbs);
            setActiveCategory(data.data.active_category);
            setRelatedCategories(data.data.related_categories);
        } else {
            setBreadcrumbs([]);
            setActiveCategory([]);
            setRelatedCategories([]);
        }
    }

    return <>
        {
            (typeof category_slug === 'string')
            &&
            <>
            {
                breadcrumbs.length > 0
                &&
                <section className="custom-section hide">
                    <div className="custom-breadcrumb">
                        <div className="container">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    {
                                        breadcrumbs.map((item, index) => 
                                            <li key={index} className={`breadcrumb-item ${index == breadcrumbs.length-1 ?'active':''}`} aria-current={`${index == breadcrumbs.length-1 ?'page':''}`}>{(index == breadcrumbs.length-1)?(item.name):(<Link to={item.url}>{item.name}</Link>)}</li>
                                        )
                                    }
                                </ol>
                            </nav>
                        </div>
                    </div>
                </section>
            }

            {
                related_categories.length > 0
                &&
                <section className="custom-section hide">
                    <div className="category-description-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="category-description-info">
                                        {typeof active_category.description !== 'undefined' && active_category.description !== '' && active_category.description !== null ? parse(active_category.description) : ''}
                                        <br />
                                        <br />
                                        <div className="related-category">
                                            <h3>Related Category</h3>
                                            <div className="row">
                                            {
                                                related_categories.map((category, index) =>
                                                    <div key={index} className="col-lg-4 col-md-6 support-faq-info">
                                                        <div className="support-faq-block text-center">
                                                            <Link className={`${category.slug === active_category?.slug ? 'active' : ''}`} to={`/explore-learning-circles/${category.slug}`}>{category?.title}</Link>
                                                        </div>
                                                    </div>
                                                )    
                                            }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="category-description-info">
                                        <Link to={"/explore-learning-circles"} className="btn btn-info"> View All Categories</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
            </>
        }
    </>;
}

export default LearningCircleBreadcrumb;