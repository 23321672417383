import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const InvitationSkeleton = (props) => {
    const tempArray = [];
    for (let i = 0; i < props.count; i++) {
        tempArray[i] = i;
    }
    {
        return (
            tempArray.map((q, index) => (
                <div key={index} className="join-invite-list">
                    <div className="join-invite-user">
                        <div className="join-invite-avtar">
                            <Skeleton width={70} height={70} borderRadius={50} />
                        </div>
                        <div className="join-invite-content">
                            <Skeleton width={480} />
                            <Skeleton width={350} />
                        </div>
                    </div>
                    <div className="join-invite-action">
                        <Skeleton inline={true} count={2} width={100} height={40} borderRadius={50} className="mr-1" />
                    </div>
                </div>
            ))
        );
    }
}

export default InvitationSkeleton;