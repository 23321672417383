import { useContext, useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import logo from '../../assets/images/logo.svg';
import { API_BASE } from '../constants/global';
import authContext from '../context/auth/authContext';
import jwt_decode from "jwt-decode";
import { Toaster } from "../entity/GlobalJS";

const ComingSoonNavbar = () => {
    const ref = useRef(null);
    const navigate = useNavigate();
    const cntxAuth = useContext(authContext);
    const [coursesMenuData, setCoursesMenuData] = useState('');
    const [learningCircleMenuData, setLearningCircleMenuData] = useState('');

    useEffect(() => {
        const _token = localStorage.getItem('lmstoken');
        if (_token) {
            const decodedToken = jwt_decode(_token);
            const currentDate = new Date();

            if (decodedToken.exp * (60 * 60 * 24 * 7) < currentDate.getTime()) {
                Toaster.info('Your session is expired, please login again.');
                navigate('/logout');
                return;
            }
        }

        fetchHeaderMenuData();
        //eslint-disable-next-line
    }, []);

    function hideMenu(e) {
        if (ref.current.classList.contains('show')) {
            ref.current.classList.remove('show')
        }
    }

    async function fetchHeaderMenuData() {
        const response = await fetch(`${API_BASE}get-header-menu-data`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        });

        const data = await response.json();
        if (data.status) {
            setCoursesMenuData(data.data.course_categories);
            setLearningCircleMenuData(data.data.learning_circle_categories);
        } else {
            // Toaster.error(data.message);
        }
    }

    return (
        <section className="header-section">
            <div className="container">
                <nav className="navbar navbar-expand-lg navbar-light sticky-top">
                    <Link className="navbar-brand" to="/coming-soon">
                        <img src={`images/logo.svg`} alt="Logo" />
                    </Link>



                </nav>
            </div>
        </section>
    );
}

export default ComingSoonNavbar;