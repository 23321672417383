import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const CircleBoxSkeleton = (props) => {  
    const tempArray = [];
    for (let i = 0; i < props.count; i++) {
        tempArray[i] = i;
    }
    {
        return (
            tempArray.map((q, index) => (
                <div key={index} className={`col-md-6 ${(typeof props.boxSize !== 'undefined' && props.boxSize !== '') ? props.boxSize :"col-lg-6"}`}>
                    <div className="blog-card-detail">
                        <div className="custom-card-img">
                            <Skeleton height={200} borderRadius={10} />
                        </div>
                        <div className="blog-card-inner">
                            <h5><Skeleton count={1} /></h5>
                            <ul className='skeletonCss'>
                                <li><Skeleton /></li>
                                <li><Skeleton /></li>
                            </ul>
                        </div>
                    </div>
                </div>
            ))
        );
    }
}

export default CircleBoxSkeleton;
