import { useForm } from "react-hook-form";
import { API_BASE } from "../../constants/global";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { Toaster, SwalBox } from "./../../entity/GlobalJS";
import authContext from "../../context/auth/authContext";
import SocialMediaRegister from "./SocialMediaRegister";
import { Helmet } from "react-helmet";


const EmployeeInvitation = () => {
    const navigate = useNavigate();
    const cntxAuth = useContext(authContext);
    const [ip, setIp] = useState("");
    const [passwordType, setPasswordType] = useState("password");
    const [confirmPasswordType, setConfirmPasswordType] = useState("password");
    const [processing, setProcessing] = useState(false);
    const [is_strong, setStrong] = useState({
        eight_char: false,
        have_numub: false,
        have_lower: false,
        have_upper: false,
        have_spslc: false,
        same_confp: false,
    });
    const { invitation_id } = useParams();
    const queryStringToObject = (str) => {
        if (typeof str != "string" || str.length == 0) return {};
        var s = str.split("&");
        var s_length = s.length;
        var bit, query = {}, first, second;

        for (var i = 0; i < s_length; i++) {
            bit = s[i].split("=");
            first = decodeURIComponent(bit[0]);
            if (first.length == 0) continue;
            second = decodeURIComponent(bit[1]);
            if (typeof query[first] == "undefined") query[first] = second;
            else if (query[first] instanceof Array) query[first].push(second);
            else query[first] = [query[first], second];
        }

        return query;
    }

    useEffect(() => {
        if (cntxAuth.isLogged) {
            SwalBox.fire({
                allowOutsideClick: false,
                title: `Already login with another account`,
                text: `You are currently login with another account, first you need to log out from that account to continue with your employee  invitation link`,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#30314c',
                confirmButtonText: `Log out`,
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate('/logout', {
                        state: {
                            is_from: 'emp-invite',
                            empInviteID: invitation_id
                        }
                    });
                }
            });
            // navigate("/dashboard/home");
        }

        const pathArray = window.location.pathname.split('/');
        if (typeof (pathArray[2]) != undefined && pathArray[2] != '') {
            try {
                const invitation_params = atob(pathArray[2]);
                const _invitation_object = queryStringToObject(invitation_params);
                const _invited_user_email = _invitation_object["?email"];
                const _invited_by_id = _invitation_object["invitedby"];

                if (typeof (_invitation_object["firstname"]) != undefined && typeof (_invitation_object["firstname"]) != 'undefined') {
                    var _invited_firstname = _invitation_object["firstname"];
                    var _invited_lastname = _invitation_object["lastname"];
                    setValue('first_name', _invited_firstname);
                    setValue('last_name', _invited_lastname);
                }
                setValue('email', _invited_user_email);
                setValue('invitedByID', _invited_by_id);

            } catch (e) {
                SwalBox.fire({
                    title: "The Invitation link is invalid",
                    text: "It seems that the employee invitation link is not valid. Please try again.",
                    icon: "error",
                });
                setTimeout(() => {
                    SwalBox.close();
                }, 4000);

                navigate("/register");
            }
        } else {
            SwalBox.fire({
                title: "The Invitation link is invalid",
                text: "It seems that the employee invitation link is not valid. Please try again.",
                icon: "error",
            });
            setTimeout(() => {
                SwalBox.close();
            }, 4000);

            navigate("/register");
        }
    }, []);

    const { register, handleSubmit, formState: { errors }, setValue, watch, clearErrors } = useForm();
    const fetchIspIp = async () => {
        let response = await fetch("https://api.ipify.org/?format=json");
        response = await response.json();
        if (typeof response != 'undefined' && typeof response.ip != 'undefined') {
            setIp(response.ip);
        }
    }

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    const toggleConfirmPassword = () => {
        if (confirmPasswordType === "password") {
            setConfirmPasswordType("text")
            return;
        }
        setConfirmPasswordType("password")
    }

    const onSubmit = async (data) => {
        data['ip'] = ip;
        setProcessing(true);
        let response = await fetch(`${API_BASE}employee-invitation-accepted`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(data)
        });

        response = await response.json();
        if (response.status) {
            setValue('first_name', '');
            setValue('last_name', '');
            setValue('last_name', '');
            setValue('email', '');
            setValue('password', '');
            setValue('cpassword', '');
            setValue('is_tutor', '');

            setTimeout(() => {

                setProcessing(false);
                Toaster.success(response.message);
                navigate("/login");
            }, 2000);

        } else {
            setProcessing(false);
            Toaster.error(response.message);
        }
    }

    const checkPassword = (e) => {
        const pass_str = document.getElementById('password-field').value.trim();
        const cpass_str = document.getElementById('cpassword-field').value.trim();

        if (pass_str.length > 0) {
            clearErrors('password');
        }

        if (cpass_str.length > 0) {
            clearErrors('cpassword');
        }

        const eight_char = (pass_str.length >= 8) ? true : false;
        const have_numub = (/\d/.test(pass_str)) ? true : false;
        const have_lower = (/[a-z]/.test(pass_str)) ? true : false;
        const have_upper = (/[A-Z]/.test(pass_str)) ? true : false;
        const have_spslc = (/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(pass_str)) ? true : false;
        const same_confp = (pass_str.length > 0 && cpass_str === pass_str) ? true : false;

        setStrong({
            eight_char: eight_char,
            have_numub: have_numub,
            have_lower: have_lower,
            have_upper: have_upper,
            have_spslc: have_spslc,
            same_confp: same_confp
        });

        if (eight_char && have_numub && have_lower && have_upper && have_spslc && same_confp) {
            document.getElementById('password-checker').classList.add("d-none");
        } else {
            document.getElementById('password-checker').classList.remove("d-none");
        }
    }

    return (
        <>
            <Helmet>
                <title>{'EduKula - Accept Invitation & Sign-up'}</title>
            </Helmet>
            <section>
                <div className="custom-login-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="custom-login-box">
                                    <div className="login-form">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <h2>Accept Invitation & <span>Sign-up!</span></h2>
                                            <div className="form-group">
                                                <input id="first_name" {...register("first_name", {
                                                    required: "required",
                                                    pattern: {
                                                        value: /^[a-z ,.'-]+$/i,
                                                        message: "Please add first name appropriately."
                                                    },
                                                    minLength: {
                                                        value: 3,
                                                        message: "Please enter at least 3 characters"
                                                    },
                                                    maxLength: {
                                                        value: 80,
                                                        message: "First Name can not be more than 80 charaters."
                                                    }
                                                })} type="text" className="form-control" placeholder="First Name" />
                                                <span className="add-on max-chr-counter chrchk-first_name" data-ele="first_name" data-limit="80" data-showcounter="false"></span>
                                                <span className="text-danger">
                                                    {errors.first_name && errors.first_name.type === "required" && 'Please enter first name.'}
                                                    {errors.first_name && errors.first_name.type !== "required" && errors.first_name.message}
                                                </span>
                                            </div>
                                            <div className="form-group">
                                                <input id="last_name" {...register("last_name", {
                                                    required: "required",
                                                    pattern: {
                                                        value: /^[a-z ,.'-]+$/i,
                                                        message: "Please add last name appropriately."
                                                    },
                                                    minLength: {
                                                        value: 3,
                                                        message: "Please enter at least 3 characters"
                                                    },
                                                    maxLength: {
                                                        value: 80,
                                                        message: "Last Name can not be more than 80 charaters."
                                                    }
                                                })} type="text" className="form-control" placeholder="Last Name" />
                                                <span className="add-on max-chr-counter chrchk-last_name" data-ele="last_name" data-limit="80" data-showcounter="false"></span>
                                                <span className="text-danger">
                                                    {errors.last_name && errors.last_name.type === "required" && 'Please enter last name.'}
                                                    {errors.last_name && errors.last_name.type !== "required" && errors.last_name.message}
                                                </span>
                                            </div>
                                            <div className="form-group">
                                                <input {...register("email", {
                                                    required: "required",
                                                    pattern: {
                                                        value: /\S+@\S+\.\S+/,
                                                        message: "Entered value does not match email format"
                                                    }
                                                })} type="email" id="_invited_user_email" className="form-control" placeholder="Email Address" />
                                                <span className="text-danger">
                                                    {errors.email && errors.email.type === "required" && 'Please enter email address'}
                                                    {errors.email && errors.email.type !== "required" && errors.email.message}
                                                </span>
                                            </div>
                                            <div className="form-group">
                                                <input {...register("password", {
                                                    required: "required",
                                                    pattern: {
                                                        value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,50}$/,
                                                        message: "Password must contains at least a letter, number and a special character."
                                                    },
                                                    minLength: {
                                                        value: 8,
                                                        message: "Password can not be less than 8 characters."
                                                    },
                                                    maxLength: {
                                                        value: 50,
                                                        message: "Password can not be more than 50 characters."
                                                    }
                                                })}
                                                    type={passwordType}
                                                    id="password-field"
                                                    className="form-control"
                                                    placeholder="Password"
                                                    onChange={checkPassword}
                                                />
                                                <span toggle="#password-field" className={`${passwordType === "password" ? "fa fa-eye " : "fa fa-eye-slash"} field-icon`} onClick={togglePassword}></span>
                                                <span className="text-danger">
                                                    {errors.password && errors.password.type === "required" && 'Please enter password.'}
                                                </span>
                                            </div>
                                            <div className="form-group">
                                                <input {...register("cpassword", {
                                                    required: "required",
                                                    pattern: {
                                                        value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,50}$/,
                                                        message: "Password must contains at least a letter, number and a special character."
                                                    },
                                                    minLength: {
                                                        value: 8,
                                                        message: "Confirm password can not be less than 8 characters."
                                                    },
                                                    maxLength: {
                                                        value: 50,
                                                        message: "Confirm password can not be more than 50 characters."
                                                    },
                                                    validate: (val: string) => {
                                                        if (watch('password') !== val) {
                                                            return 'Confirm password do not match.'
                                                        }
                                                    }
                                                })}
                                                    type={confirmPasswordType}
                                                    id="cpassword-field"
                                                    className="form-control"
                                                    placeholder="Confirm Password"
                                                    onChange={checkPassword}
                                                />
                                                <span toggle="#cpassword-field" className={`${confirmPasswordType === "password" ? "fa fa-eye " : "fa fa-eye-slash"} field-icon`} onClick={toggleConfirmPassword}></span>
                                                <span className="text-danger">
                                                    {errors.cpassword && errors.cpassword.type === "required" && 'Please enter confirm password.'}
                                                </span>
                                            </div>
                                            <div id="password-checker" className="form-group d-none">
                                                <label className="w-100" htmlFor="">Password must contain:</label>
                                                <ul className="password_error">
                                                    <li>
                                                        {is_strong?.eight_char ? <i className="fa fa-check-circle text-success" aria-hidden="true"></i> : <i className="fa fa-times-circle text-danger" aria-hidden="true"></i>}
                                                        <label className="w-100">8 or more characters minimum</label>
                                                    </li>
                                                    <li>
                                                        {is_strong?.have_numub ? <i className="fa fa-check-circle text-success" aria-hidden="true"></i> : <i className="fa fa-times-circle text-danger" aria-hidden="true"></i>}
                                                        <label className="w-100">One or more than one characters with numbers</label>
                                                    </li>
                                                    <li>
                                                        {is_strong?.have_lower ? <i className="fa fa-check-circle text-success" aria-hidden="true"></i> : <i className="fa fa-times-circle text-danger" aria-hidden="true"></i>}
                                                        <label className="w-100">One or more lowercase characters</label>
                                                    </li>
                                                    <li>
                                                        {is_strong?.have_upper ? <i className="fa fa-check-circle text-success" aria-hidden="true"></i> : <i className="fa fa-times-circle text-danger" aria-hidden="true"></i>}
                                                        <label className="w-100">One or more capital characters</label>
                                                    </li>
                                                    <li>
                                                        {is_strong?.have_spslc ? <i className="fa fa-check-circle text-success" aria-hidden="true"></i> : <i className="fa fa-times-circle text-danger" aria-hidden="true"></i>}
                                                        <label className="w-100">One or more special characters</label>
                                                    </li>
                                                    <li>
                                                        {is_strong?.same_confp ? <i className="fa fa-check-circle text-success" aria-hidden="true"></i> : <i className="fa fa-times-circle text-danger" aria-hidden="true"></i>}
                                                        <label className="w-100">Match password with confirm password</label>
                                                    </li>
                                                </ul>
                                                {
                                                    (
                                                        (errors.password && errors.password.type !== "required")
                                                        ||
                                                        (errors.cpassword && errors.cpassword.type !== "required")
                                                    )
                                                        ?
                                                        <span className="text-danger">Password must follow the above rules.</span>
                                                        :
                                                        null
                                                }
                                            </div>
                                            {/*<div className="form-group">
                                                <input {...register("is_tutor")} type="checkbox" id="is_tutor" /> <label htmlFor="is_tutor"> Register as a Tutor</label>
                                            </div>*/}
                                            <div className="form-group small">
                                                <label htmlFor="terms_condition" className="register-links"> <p className="register-links">By clicking Agree &amp; Join, you agree to the EduKula <Link target="_blank" to="/pages/privacy-policy">Privacy Policy</Link> and <Link target="_blank" to="/pages/terms-of-use">Terms Of Service.</Link></p> </label>
                                            </div>
                                            <div className="clearfix"></div>
                                            <button disabled={processing} type="submit" className="btn btn-circle btn-success">
                                                {
                                                    processing ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : 'Agree & Join Now'
                                                }
                                            </button>
                                            <input {...register("invitedByID", {})} type="hidden" id="invitedByID" />
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="custom-login-box">
                                    <div className="login-img-box regi-area">
                                        <img src="images/register-img.png" alt="Login" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default EmployeeInvitation;