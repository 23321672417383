import { Link } from "react-router-dom";

const RequestApprovedToJoin = ({ ntfcn }) => {
    return (
        <>
            <div className="user-body">
                <div className="user-header">
                    <div className="user-profile-img">
                        <img src={ntfcn?.group?.owner?.image} className="rounded-circle" alt={ntfcn?.group?.owner?.name} />
                    </div>
                    <div className="user-details-title">
                        <h6><Link to={`/profile-details/${ntfcn?.group?.owner?.slug}`}>{ntfcn?.group?.owner?.name}</Link> <span>{ntfcn.date_time}</span></h6>
                        <p>Owner - {ntfcn?.group?.title}</p>
                    </div>
                </div>
                <div className="user-content-block">
                    <div className="user-course-desc">
                        <p>You have been successfully added as a member in the <strong>{ntfcn?.group?.title}</strong> circle. Welcome to the circle!</p>
                    </div>
                    <div className="user-course-block">
                        <Link className="link" to={`/view-learning-circle/${ntfcn?.group?.uid}/feeds`} target="_blank">
                            <img src={ntfcn?.group?.image} alt={ntfcn?.group?.title} />
                            <span>Manage this circle</span>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RequestApprovedToJoin;