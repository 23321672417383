import { useEffect, useState } from "react";
import { API_BASE } from "../../../constants/global";
import Comment from "./Comment";

const FeedComments = (props) => {
    // const comments = props.comments.comments;
    const _token = localStorage.getItem('lmstoken');
    const [comments, setComments] = useState(null);
    const [all, setAll] = useState(false);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [comments_count, setCommentsCount] = useState(0);
    const [fetching, setFetching] = useState(false);

    useEffect(() => {
        setPage(1 + 1);
        fetchComments();
        //eslint-disable-next-line
    }, []);

    const fetchComments = async () => {
        setFetching(true);
        const response = await fetch(`${API_BASE}get-comment-list/${props.feed_id}?page=${page}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            setFetching(false);
            setComments(data.data.comments.comments);
            setTotal(data.data.comments.total_count);
            setCommentsCount(data.data.comments.comments_count);
            props.setCommCount(data.data.comments.total_count);

            if (data.data.comments.total === 1) {
                setAll(true);
            }
        } else {
            setFetching(false);
            setComments(null);
            setAll(false);
        }
    }

    const showMore = async () => {
        setPage(page + 1);
        fetchComments();
    }

    return (
        <>
            {
                fetching
                    ?
                    <p className='text-left'><i className="fas fa-spinner fa-spin"></i> Loading....</p>
                    :
                    comments
                        ?
                        comments.map((comment, index) =>
                            <Comment
                                key={index}
                                commentX={comment}
                                user={props.user}
                                profile={props.profile}
                                feed_id={props.feed_id}
                                likes={comment.likes}
                                my_like_status={comment.my_like_status}
                                is_inner={false}
                                fetchComments={fetchComments}
                                setCommCount={props.setCommCount}
                                parent_id={comment.id}
                                comments_countP={comments_count}
                                setCommentsCountP={setCommentsCount}
                                totalP={total}
                                setTotalP={setTotal}
                                pageP={page}
                                setPageP={setPage}
                            />
                        )
                        :
                        null
            }

            {
                (!all && total > 0)
                    ?
                    <div className="like_cmnt noborder">
                        <p className="left"><a className="link" onClick={showMore}>Load more comments</a></p>
                        <p className="right">{comments_count} of {total} comments</p>
                    </div>
                    :
                    null
            }
        </>
    );
}

export default FeedComments;