import React from 'react'
import { Link } from 'react-router-dom'

const CourseCard = ({ course }) => {
    return (
        <div  className="user-right-body-block">
        <Link target="_blank" to={'/course-details/' + course.slug} className="ca_card_link">
            <div className="user-info-head">
                {
                course.associated_vacancy > 0
                &&
                <>
                <div className="ca-hiring"><span className="ca-highest">Hiring</span></div>
                </>
                }
                <img className="ca-image" src={course.image} alt={course.title} />
            </div>
            <div className="user-info-desc">
                <h6>{course.title}</h6>
            </div>
        </Link>
    </div>
    )
}

export default CourseCard