import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";


const CoursePref = () => {
    const { _token } = useParams();
    const navigate = useNavigate();
    const [processing, setProcessing] = useState(false);
    const new_registration = localStorage.getItem('new_registration');

    const { register, handleSubmit, formState: { errors } } = useForm();

    useEffect(() => {
        if (typeof new_registration === 'undefined' || !new_registration) {
            navigate(`/login`);
        }
        //eslint-disable-next-line
    }, []);

    const onSubmit = async (data) => {
        localStorage.removeItem('new_registration');

        if (data.create_course === 'yes') {
            navigate(`/dashboard/tutor/published-courses`);
        } else {
            navigate(`/dashboard/home`);
        }
    }

    return (
        <>
            <Helmet>
                <title>{'EduKula - Course Preference'}</title>
            </Helmet>
            <section>
                <div className="step_2_box">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <div className="custom-login-box">
                                    <div className="login-form">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <h2>Are you ready to <br />create a course now?</h2>
                                            <div className="form-group pb-0">
                                                <label>Thank you for inviting employees to the platform! would you like to go ahead and create a course now?</label>
                                                <div className="check_box_select">
                                                    <label className="form-check-label" for="inlineRadio1">
                                                        <input {...register('create_course', { required: "required" })} className="form-check-input" type="radio" id="inlineRadio1" value="yes" />
                                                        <span>Yes</span>
                                                    </label>
                                                </div>
                                                <div className="check_box_select">
                                                    <label className="form-check-label" for="inlineRadio2">
                                                        <input {...register('create_course', { required: "required" })} className="form-check-input" type="radio" id="inlineRadio2" value="no" />
                                                        <span>Skip for now</span>
                                                    </label>
                                                </div>
                                                <div className="clearfix"></div>
                                                <span className="text-danger">
                                                    {errors.create_course && errors.create_course.type === "required" && 'Please select any one option.'}
                                                    {errors.create_course && errors.create_course.type !== "required" && errors.create_course.message}
                                                </span>
                                            </div>
                                            <button disabled={processing} type="submit" className="btn btn-circle btn-success">
                                                {processing ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : 'Finish'}
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CoursePref