import { useForm } from "react-hook-form";
import { API_BASE } from "../../constants/global";
import { Toaster } from "../../entity/GlobalJS";

const ReportCircle = ({ report_types, unique_id, for_course, course_id }) => {
    const _token = localStorage.getItem('lmstoken');
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();

    const onReportDetails = async (form) => {
        const formData = new FormData();
        formData.append('type', form.type);
        formData.append('title', form.title);

        const callUrl = for_course ? `report-course/${course_id}` : `report-group/${unique_id}`;

        const response = await fetch(`${API_BASE}${callUrl}`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const rdata = await response.json();
        if (rdata.status) {
            document.getElementById('reportModalCenterClose').click();
            Toaster.success(rdata.message);
            setValue('title', '');
            setValue('type', '');
        } else {
            Toaster.error(rdata.message);
        }
    }

    return (
        <>
            <div className="modal fade reportpopup" id="reportModalCenter" tabIndex="-1" role="dialog" aria-labelledby="reportModalCenterTitle" aria-hidden="true"  data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="reportModalLongTitle">Report abuse</h4>
                            <button type="button" id="reportModalCenterClose" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit(onReportDetails)}>
                                <p>Flagged content is reviewed by EduKula to determine whether it violates Terms of Service or Community Guidelines. If you have a question or technical issue, please contact our <a className="cursor-pointer">Support team here</a>.</p>
                                <div className="form-group">
                                    <label>Issue type</label>
                                    <select {...register("type", { required: "required" })} >
                                        <option value=''>Select an issue</option>
                                        {
                                            report_types !== ''
                                            &&
                                            report_types.map((report_type, index) =>
                                                <option key={index} value={report_type.id}>{report_type.value}</option>
                                            )
                                        }
                                    </select>
                                    <span className="text-danger">
                                        {errors.type && errors.type.type === "required" && 'Please select issue type.'}
                                    </span>
                                </div>
                                <div className="form-group">
                                    <label>Issue details</label>
                                    <textarea id="title" {...register("title", {
                                        required: "required",
                                        maxLength: {
                                            value: 1000,
                                            message: "Issue details can not be more than 1000 charaters."
                                        }
                                    })} rows="10" cols="10"></textarea>
                                    <span className="add-on max-chr-counter chrchk-title" data-ele="title" data-limit="1000" data-showcounter="true"></span>
                                    <span className="text-danger">
                                        {errors.title && errors.title.type === "required" && 'Please enter issue details.'}
                                        {errors.title && errors.title.type !== "required" && errors.title.message}
                                    </span>
                                </div>
                                <div className="clear"></div>
                                <div className="btn_right">
                                    <input type="submit" value="Report" />
                                    <button type="button" data-dismiss="modal">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ReportCircle;