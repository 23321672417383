import { useEffect, useState } from "react";
import { Link, useLocation, useOutletContext, useSearchParams } from "react-router-dom";
import { API_BASE } from "../../../constants/global";
import CustomPagination from "../../../entity/CustomPagination";
import CardPublished from "./CardPublished";
import CircleBoxSkeleton from "../../../components/skeletons/CircleBoxSkeleton";
import { Helmet } from "react-helmet";

const PublishedCourses = () => {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const currentPage = Number(searchParams.get('page')) || 0;
    const [reload, setReload] = useState(false);

    const [published, setPublished, drafted, setDrafted] = useOutletContext();

    const _token = localStorage.getItem('lmstoken');
    const [courses, setCourses] = useState('');
    const [total_count_view, setTotalCountView] = useState(0);
    const [total_count, setTotalCount] = useState(0);
    const [isLoader, setIsLoader] = useState(true);

    useEffect(() => {
        fetchCourses(currentPage);
        // eslint-disable-next-line 
    }, [location, reload]);

    const funReload = (page) => {
        setSearchParams({ ...searchParams, page: page });
        setReload(!reload);
    }

    const fetchCourses = async (page) => {
        setIsLoader(true);
        const response = await fetch(`${API_BASE}get-tutor-published-course-list?page=${page}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        setIsLoader(false);
        if (data.status) {
            setCourses(data.data.courses);
            setTotalCount(data.data.total_count);
            setTotalCountView(data.data.total_count);
            setPublished(data.data.total_count);
        }
    }

    return (
        <>
            <Helmet>
                <title>{'EduKula - Published Courses'}</title>
            </Helmet>
            <div className="tab-pane fade show active" id="pills-course" role="tabpanel" aria-labelledby="pills-course-tab">
                <div className="blog-card">
                    <div className="row">
                        {
                            isLoader ?
                                <>
                                    <CircleBoxSkeleton count={4} />
                                </>
                                :
                                <>
                                    {
                                        courses !== ''
                                        &&
                                        courses.map((course, index) =>
                                            <CardPublished key={index} course={course} total_count_view={total_count_view} setTotalCountView={setTotalCountView} published={published} setPublished={setPublished} drafted={drafted} setDrafted={setDrafted} />
                                        )
                                    }

                                    {
                                        (total_count_view <= 0 || courses === '')
                                            ?
                                            <div className="col-md-12 col-lg-12 text-center">
                                                <div className="empty_state">
                                                    <h3>No courses published</h3>
                                                    <img alt="" src="images/no_courses.png" />
                                                </div>
                                            </div>
                                            :
                                            null
                                    }
                                </>
                        }
                    </div>
                </div>
                {
                    total_count > 4
                    &&
                    <div className="custom-pagination">
                        <nav aria-label="Page navigation example">
                            <CustomPagination pageCount={total_count} perPageRecord={4} handlePageClick={(data) => { funReload(data.selected) }} currentPage={currentPage} />
                        </nav>
                    </div>
                }
            </div>
        </>
    );
}

export default PublishedCourses;