import { Link } from "react-router-dom";
import VerifiedBadge from "../../../../components/VerifiedBadge";
const InvitedToCourse = ({ntfcn}) => {

    return (
        <>
            <div className="user-body">
                <div className="user-header">
                    <div className="user-profile-img">
                        <img src={ntfcn?.course?.creator?.image} className="rounded-circle" alt={ntfcn?.course?.creator?.name} />
                    </div>
                    <div className="user-details-title">
                        <h6><Link to={`/profile-details/${ntfcn?.course?.creator?.slug}`}>{ntfcn?.course?.creator?.name}</Link><VerifiedBadge _is_verified={ntfcn?.course?.creator?.is_verified}  refname="notification"/><span>{ntfcn.date_time}</span></h6>
                        <p>Tutor - {ntfcn?.course?.title}</p>
                    </div>
                </div>
                <div className="user-content-block">
                    <div className="user-course-desc">
                        <p>Hey, come join my <Link to={`/course-details/${ntfcn?.course?.slug}`} className="ek-lrs-course-initialized" data-ek-lrs-url={`/course-details/${ntfcn?.course?.slug}`} data-ek-lrs-title={ntfcn?.course?.title}><strong>{ntfcn?.course?.title}</strong></Link> course to enhance your career.</p>
                        {
                            ntfcn?.course_invite_code !== '' && ntfcn?.course_invite_code !== null
                            &&
                            <p>You can enroll using code : <strong>{ntfcn?.course_invite_code}</strong></p>
                        }
                    </div>
                    <div className="user-course-block">
                        <Link className="link ek-lrs-course-initialized" to={'/course-details/' + ntfcn?.course?.slug} data-ek-lrs-url={`/course-details/${ntfcn?.course?.slug}`} data-ek-lrs-title={ntfcn?.course?.title}>
                            <img src={ntfcn?.course?.image} alt={ntfcn?.course?.title} />
                            <span>View This Course</span>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default InvitedToCourse;