import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const SidebarCourseSkeleton = (props) => {
    const tempArray = [];
    for (let i = 0; i < props.count; i++) {
        tempArray[i] = i;
    }
    {
        return (
            tempArray.map((q, index) => (
                <div key={index} className="user-right-body-block">
                    <a href="#">
                        <div className="user-info-head">
                            <Skeleton width={50} height={50} />
                        </div>
                        <div className="user-info-desc pl-2">
                            <Skeleton width={200} />
                            <Skeleton />
                        </div>
                    </a>
                </div>
            ))
        );
    }
}

export default SidebarCourseSkeleton;