import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { useState } from "react";
import { API_BASE } from "../../../../../constants/global";
import { Toaster } from "../../../../../entity/GlobalJS";
import Comment from "./Comment";

const Comments = ({ course_id, query, comment_count }) => {
    const _token = localStorage.getItem('lmstoken');
    const user = JSON.parse(localStorage.getItem('lmsuser'));
    const profile = user.image;
    const [total_count, setTotalCount] = useState(comment_count);
    const [comment_text, setCommentText] = useState('');
    const [new_comments, setNewComments] = useState('');
    const [edit_id, setEditId] = useState('');
    const [show_comments, setShowComments] = useState(false);
    const [fetching, setFetching] = useState(false);

    const saveComment = async () => {
        if (comment_text.trim() !== '') {
            const formData = new FormData();
            formData.append('course_id', course_id);
            formData.append('parent_id', query.id);
            formData.append('comment', comment_text);

            setFetching(true);
            const response = await fetch(`${API_BASE}post-course-query${edit_id !== '' ? `/${edit_id}` : ''}`, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${_token}`
                },
                body: formData
            });

            const data = await response.json();
            if (data.status) {
                setNewComments('');
                setNewComments([data.data.comment, ...new_comments]);
                setEditId('');
                setCommentText('');
                setShowComments(true);
                setTotalCount(total_count + 1);
                setFetching(false);
            } else {
                setFetching(false);
                Toaster.error(data.message);
            }
        }
    }

    const addEmoji = (e) => {
        let sym = e.unified.split("-");
        let codesArray = [];
        sym.forEach((el) => codesArray.push("0x" + el));
        let emoji = String.fromCodePoint(...codesArray);
        setCommentText(comment_text + emoji);
    };

    return (
        <>
            <div className="feed_comment">
                <div className="cmnt_count cursor-pointer" onClick={(e) => { setShowComments(!show_comments); }}>
                    {total_count} Comments
                </div>
                <div className="clearfix"></div>
                <div className="like_cmnt">
                    <p className="left"><a onClick={(e) => { document.getElementById(`post_comment${query.id}`).focus(); }} className="link"><i className="far fa-comment"></i> Comment</a></p>
                </div>
                <div className="own_coment">
                    <div className="prof_img">
                        <img src={profile} alt={user.first_name} />
                    </div>
                    <div className="form-group">
                        <input
                            id={`post_comment${query.id}`}
                            onChange={(e) => { setCommentText(e.target.value); }}
                            onKeyUp={(e) => { if (e.key === 'Enter') { saveComment(); } }}
                            type="text"
                            className="form-control"
                            placeholder="Write a public comment..."
                            value={comment_text}
                        />
                        <i id={`${course_id}_MenuButtonEmoji`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="far fa-smile"></i>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="MenuButtonEmoji">
                            <div className='emojibox'>
                                <Picker
                                    data={data}
                                    onEmojiSelect={addEmoji}
                                    style={{
                                        position: "absolute",
                                        marginTop: "465px",
                                        marginLeft: -40,
                                        maxWidth: "320px",
                                        borderRadius: "20px",
                                    }}
                                    theme="dark"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`commentlstarea ${show_comments ? '' : 'd-none'}`}>
                    {
                        fetching
                            ?
                            <p className='text-center'><i className="fas fa-spinner fa-spin"></i> Loading....</p>
                            :
                            new_comments !== ''
                                ?
                                new_comments.map((comment, index) =>
                                    <Comment key={index} index={index} course_id={course_id} comment={comment} is_reply={false} new_added={true} total_count={total_count} setTotalCount={setTotalCount} />
                                )
                                :
                                null
                    }

                    {
                        (query.comments !== '' && query.comments !== null)
                        &&
                        query.comments.map((comment, index) =>
                            <Comment key={index} index={index} course_id={course_id} comment={comment} is_reply={false} new_added={false} total_count={total_count} setTotalCount={setTotalCount} />
                        )
                    }

                    {/* <div className="like_cmnt noborder">
                    <p className="left"><a className="link">view 8 more comments</a></p>
                    <p className="right">02 of 264</p>
                </div> */}
                </div>
            </div>
            <div className="clearfix"></div>
        </>
    );
}

export default Comments;