import React from 'react'
import { Link } from 'react-router-dom'
const UniversityCard = ({ university }) => {
    //console.info(university);
    if(university != undefined){
        //console.info(university);
        if(university.title != undefined){
            return (
                <div  className="user-right-body-block">
                    <div className="user-info-desc">
                    <p>{university.title}</p>
                    </div>
            </div>
            )
        }
    }
}

export default UniversityCard
