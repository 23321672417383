import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const QueryBoxSkeleton = (props) => {
    const tempArray = [];
    for (let i = 0; i < props.count; i++) {
        tempArray[i] = i;
    }
    {
        return (
            tempArray.map((q, index) => (
                <div key={index} className="feed_post">
                    <div className="custom-learnig-circle-head">
                        <div className="feed_prof_icon">
                            <Skeleton width={50} height={50} />
                            <h3></h3>
                            <p><Skeleton width={200} /></p>
                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="MaterialsMenuButton">
                                <div className="buttons_action">

                                </div>
                            </div>
                        </div>
                        <div className="caption">
                            <p><Skeleton width={200} /></p>
                            <p><Skeleton width={250} /></p>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                    <div className="clearfix"></div>
                </div>
            ))
        );
    }
}

export default QueryBoxSkeleton;