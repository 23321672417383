import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const FeedBoxSkeleton = (props) => {
    const tempArray = [];
    for (let i = 0; i < props.count; i++) {
        tempArray[i] = i;
    }
    {
        return (
            tempArray.map((q, index) => (
                <div key={index} className="user-body">
                    <div className="user-header">
                        <div className="user-profile-img">
                            <Skeleton width={50} height={50} />
                        </div>
                        <div className="user-details-title">
                            <p><Skeleton width={200} /></p>
                            <p><Skeleton width={250} /></p>
                        </div>
                    </div>
                    <div className="user-content-block">
                        <div className="user-course-desc">
                            <Skeleton />
                            <Skeleton width={200} />
                        </div>
                        <div className="user-course-block">
                            <Skeleton borderRadius={10} height={444} />
                            <Skeleton />
                        </div>
                    </div>
                </div>
            ))
        );
    }
}

export default FeedBoxSkeleton;