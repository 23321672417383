import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const DetailListSkeleton = (props) => {
    const tempArray = [];
    for (let i = 0; i < props.count; i++) {
        tempArray[i] = i;
    }
    {
        return (
            tempArray.map((q, index) => (
                <li key={index}><Skeleton width={200} /></li>
            ))
        );
    }
}

export default DetailListSkeleton;