import { useEffect, useState } from "react";
import { API_BASE } from "../../constants/global";
import Slider from "react-slick";
import CircleCard from "../../components/CircleCard";
import CircleBoxSkeleton from "../../components/skeletons/CircleBoxSkeleton";
import LearningCircleCard from "../../pages/front/learning_circle_tab/LearningCircleCard";

const TrendingCircleI = () => {
    var settings = {
        className: "slick_slider_custom_cls",
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        }, {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
    };

    const _token = localStorage.getItem('lmstoken');
    const [groups, setGroups] = useState([]);
    const [isLoader, setIsLoader] = useState(true);

    useEffect(() => {
        fetchGroups();
        // eslint-disable-next-line 
    }, []);

    const fetchGroups = async () => {
        setIsLoader(true);
        const response = await fetch(`${API_BASE}get-trending-circles`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();
        setIsLoader(false);
        if (data.status) {
            setGroups(data.data.groups);
        }
    }

    return (
        <>
            <div className="custom-user-profile-content custom-learning-circle-content learnig-circle-slider">
                <div className="custom-learnig-circle-head">
                    <h4>Trending Learning Circles</h4>
                    <div className="learning-controls">
                        <span className="slide-left slide-btn" onClick={(e) => { document.getElementsByClassName('slick-prev')[0].click(); }}><i className="fas fa-chevron-left"></i></span>
                        <span className="slide-right slide-btn" onClick={(e) => { document.getElementsByClassName('slick-next')[0].click(); }}><i className="fas fa-chevron-right"></i></span>
                    </div>
                </div>
                <div className="custom-learning-slide">
                    <div className="blog-card">
                        <div>
                            {
                                isLoader ?
                                    <>
                                        <div style={{display:"flex"}}>
                                            <CircleBoxSkeleton count={2} />
                                        </div>
                                    </>
                                    :
                                    <>
                                        {
                                            typeof groups !== 'undefined' && groups.length > 0
                                            &&
                                            <Slider {...settings}>
                                                {
                                                    groups.map((group, index) =>
                                                        <div key={index} className="p-1">
                                                            {/* <CircleCard group={group} /> */}
                                                            <LearningCircleCard group={group} rating={group?.average_rating} />
                                                        </div>
                                                    )
                                                }
                                            </Slider>
                                        }

                                        {
                                            (typeof groups === 'undefined' || groups.length <= 0)
                                            &&
                                            <><div className="col-md-12 col-lg-12 text-center"><p>No Circles Found.</p></div></>
                                        }
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TrendingCircleI;