import React from 'react'
import { Outlet } from 'react-router-dom';
import Navbar from '../header/Navbar';
import Footer from '../footer/Footer';
import { Chat } from '../chat';

const MasterLayout = () => {
    return (
        <>
            <Navbar />
            <Outlet />
            <Chat />
            <Footer />
        </>
    )
}

export default MasterLayout