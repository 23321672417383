import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API_BASE } from "../../constants/global";
import SidebarCourseSkeleton from "../../components/skeletons/SidebarCourseSkeleton";

const NewReleaseCourses = () => {
    const [newCourses, setNewCourses] = useState('');
    const [isLoader, setIsLoader] = useState(true);
    const _token = localStorage.getItem('lmstoken');

    useEffect(() => {
        fetchNewCourses();
        // eslint-disable-next-line 
    }, []);

    const fetchNewCourses = async () => {
        setIsLoader(true);
        const response = await fetch(`${API_BASE}get-new-released-courses`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        setIsLoader(false);
        if (data.status) {
            setNewCourses(data.data.courses);
        }
    }

    return (
        <>
            <div className="user-right-section">
                <div className="user-right-header">
                    <h5>New Release Courses</h5>
                </div>
                {
                    isLoader
                        ?
                        <SidebarCourseSkeleton count={3} />
                        :
                        <div className="user-right-body">
                            {
                                newCourses !== ''
                                    ?
                                    newCourses.map((course, index) =>
                                        <div key={index} className="user-right-body-block">
                                            <Link to={'/course-details/' + course.slug} className="ek-lrs-course-initialized" data-ek-lrs-url={`/course-details/${course?.slug}`} data-ek-lrs-title={course?.title}>
                                                <div className="user-info-head">
                                                    <img src={course.image} alt={course.title} />
                                                </div>
                                                <div className="user-info-desc">
                                                    <h6>{course.title}</h6>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                    :
                                    null
                            }

                            {
                                newCourses === ''
                                    ?
                                    <div className="user-right-body-block">
                                        <div className="user-group-block">
                                            <h6>No New Courses</h6>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                        </div>
                }

            </div>
        </>
    );
}

export default NewReleaseCourses;