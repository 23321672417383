import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import CircleCard from "../../components/CircleCard";
import { API_BASE } from "../../constants/global";
import parse from 'html-react-parser';
import { Toaster } from "../../entity/GlobalJS";
import LearningCircleCard from "./learning_circle_tab/LearningCircleCard";
import authContext from "../../context/auth/authContext";
import ReportCircle from "../../user_dashboards/learning_circle/ReportCircle";
import VerifiedBadge from "../../components/VerifiedBadge";
import { SwalBox } from "../../entity/GlobalJS";
import { Helmet } from "react-helmet";


const LearningCircleDetails = () => {
    const cntxAuth = useContext(authContext);
    const navigate = useNavigate();
    const location = useLocation();
    const _token = localStorage.getItem('lmstoken');
    const { unique_id } = useParams();
    const [group, setGroup] = useState('');
    const [text_join, setJoinText] = useState('Join Now');
    const [join_processed, setJoinProcessed] = useState(false);
    const [report_types, setReportTypes] = useState('');

    useEffect(() => {
        fetchGroup();
        fetchReportTypes();
        window.scrollTo(0, 0);
        localStorage.removeItem('enrollLC');
        localStorage.removeItem('LCid');
        // eslint-disable-next-line
    }, []);

    useLayoutEffect(() => {
        fetchGroup();
        window.scrollTo(0, 0);
    }, [location.pathname]);

    const fetchGroup = async () => {
        const response = await fetch(`${API_BASE}get-group-details/${unique_id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();
        if (data.status) {
            if (data.message === "No data found.") {
                navigate('/404');
            }

            setGroup(data.data.circle);
            setJoinText(data.data.circle.join_txt);
            setJoinProcessed(data.data.circle.join_flg);
        }
    }

    const ActionJoinButton = async () => {
        if (join_processed) {
            return false;
        }

        if (_token == null) {
            SwalBox.fire({
                allowOutsideClick: false,
                title: 'Enrollment Alert',
                text: 'To join the circle, you must be logged in to the platform. If you do not have an account, don\'t worry! You can easily create one by clicking the "Sign Up" button below.',
                icon: 'info',
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: '#30314c',
                cancelButtonColor: '#30314c',
                confirmButtonText: 'Sign In',
                cancelButtonText: 'Sign Up'
            }).then((result) => {
                if (result.isConfirmed) {
                    localStorage.setItem('enrollLC', true);
                    localStorage.setItem('LCid', unique_id);
                    navigate('/login');
                    return false;
                } else {
                    if (result.dismiss == 'cancel') {
                        localStorage.setItem('enrollLC', true);
                        localStorage.setItem('LCid', unique_id);
                        navigate('/register');
                        return false;
                    } else {
                        SwalBox.close();
                    }
                }
            });
            return false;
        }

        const response = await fetch(`${API_BASE}join-group/${unique_id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            Toaster.success(data.message);
            if (data.data.is_private === 0) {
                window.location.href = `/view-learning-circle/${unique_id}/feeds`;
                // navigate(`/view-learning-circle/${unique_id}/feeds`);
            }
            setJoinText(data.data.text);
            setJoinProcessed(true);
        } else {
            Toaster.error(data.message);
        }
    }

    const fetchReportTypes = async () => {
        if (cntxAuth.isLogged) {
            const response = await fetch(`${API_BASE}get-report-types`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + _token
                },
            });

            const data = await response.json();
            if (data.status) {
                setReportTypes(data.data.types);
            }
        }
    }

    return (
        <>
            <Helmet>
                <title>{'EduKula - Learning Circle'}</title>
            </Helmet>
            {
                group !== ''
                &&
                <>
                    {/* <section className="custom-bg pt-5 hide">
                        <div className="course-top-banner">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="course-detail-info pb-4">
                                            <nav aria-label="breadcrumb">
                                                <ol className="breadcrumb">
                                                    <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                                                    <li className="breadcrumb-item"><Link to="/explore-learning-circles">Learning Circle</Link></li>
                                                    <li className="breadcrumb-item"><Link to={"/explore-learning-circles/" + group.category_url}>{group.category}</Link></li>
                                                    <li className="breadcrumb-item active" aria-current="page">{group.title}</li>
                                                </ol>
                                            </nav>
                                            <h3></h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="course-video-section nobg">
                                    <div className="course-video-block">
                                        <img src={group.image} alt={group.title} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}

                    <section className="custom-bg new_detail_pg_top mb-0">
                        <div className="course-top-banner">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-7">
                                        <div className="course-detail-info">
                                            <h3>
                                                {group.title}
                                            </h3>
                                            <ul className="top_listing">
                                                <li><i className="fa-solid fa-check"></i><strong>Category: </strong> {group.category}</li>
                                                <li><i className="fa-solid fa-check"></i><strong>Sub category: </strong> {group.sub_category_title}</li>
                                                <li><i className="fa-solid fa-check"></i><strong>Circle profile: </strong> {group.private}</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <div className="course-video-section">
                                            <div className="img_box">
                                                <img src={group.image} alt={group.title} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </section>
                    <div className="clearfix"></div>

                    <section className="custom-section learning_detail new_course_detal">
                        <div className="course-details-tab-section">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <div className="course-details-tab-block explore-block">
                                            <ul className="nav nav-pills custom-section" id="pills-tab" role="tablist">
                                                <li className="nav-item">
                                                    <a className="nav-link active" id="pills-about-tab" data-toggle="pill" href="#pills-about" role="tab" aria-controls="pills-about" aria-selected="true">About</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" id="pills-learn-tab" data-toggle="pill" href="#pills-learn" role="tab" aria-controls="pills-learn" aria-selected="false">Why should you join</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" id="pills-instructors-tab" data-toggle="pill" href="#pills-instructors" role="tab" aria-controls="pills-instructors" aria-selected="false">Admin</a>
                                                </li>
                                            </ul>
                                            <div className="tab-content" id="pills-tabContent">
                                                <div className="tab-pane fade show active" id="pills-about" role="tabpanel" aria-labelledby="pills-about-tab">
                                                    <div className="course-tab-contents">
                                                        <div className="course-tabs-pane-block">
                                                            <h3>About this learning circle</h3>
                                                            <div className="course-tabs-box">
                                                                <div className="course-tabs-body">
                                                                    {(group.about.length > 0) ? parse(group.about) : ''}
                                                                    {/*
                                                                    <div className="course-accordion-section">
                                                                        <ul className="about-course-list">
                                                                            <li><div><strong>Category:</strong><span>{group.category}</span></div></li>
                                                                            <li><div><strong>Circle profile:</strong><span>{group.private}</span></div></li>
                                                                            <li><div><strong>Learning circle language:</strong><span>{group.language}</span></div></li>
                                                                        </ul>
                                                                    </div>
                                                                    */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="pills-learn" role="tabpanel" aria-labelledby="pills-learn-tab">
                                                    <div className="course-tab-contents">
                                                        <div className="course-tabs-pane-block">
                                                            <h3>What you'll learn</h3>
                                                            <div className="course-tabs-box">
                                                                <div className="course-tabs-body">
                                                                    {(group.why_join.length > 0) ? parse(group.why_join) : ''}
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="pills-instructors" role="tabpanel" aria-labelledby="pills-instructors-tab">
                                                    <div className="course-tab-contents">
                                                        <div className="course-tabs-pane-block">
                                                            <h3>Admin</h3>
                                                            <div className="course-tabs-box">
                                                                <div className="course-tabs-box-header">
                                                                    <div className="row justify-content-center">
                                                                        <div className="col-md-4">
                                                                            <div className="course-tabs-profile">
                                                                                <img src={group.admins.profile} className="rounded-circle" alt={group.admins.name} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-8">
                                                                            <div className="course-tabs-profile course-tabs-description mb-0">
                                                                                <h4><Link to={`/profile-details/${group?.admins?.slug}`}>{group?.admins?.name}</Link><VerifiedBadge _is_verified={group?.admins?.is_verified} refname="dashboard" /></h4>
                                                                                <p>{(group.admins.headline.length > 0) ? parse(group.admins.headline) : ''}</p>
                                                                                {
                                                                                    group?.admins?.is_tutor == 1
                                                                                    &&
                                                                                    <ul className="course-tabs-details">
                                                                                        <li><i className="fas fa-users"></i><span>{group?.ownerInfo?.total_students} Students</span></li>
                                                                                        <li><i className="fas fa-play-circle"></i><span>{group?.ownerInfo?.active_courses_count} Course</span></li>
                                                                                    </ul>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="course-tabs-body">
                                                                    {(group.admins.about.length > 0) ? parse(group.admins.about) : ''}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="course-details-tab-block right_box">
                                            <div className="course-right-description">
                                                <h4>This learning circle includes:</h4>
                                                <ul className="course-tabs-details my-4">
                                                    <li><p><i className="fas fa-user-friends"></i><span>{group.members}+ Group members</span></p></li>
                                                    <li><p><i className="fas fa-handshake"></i><span>Attend meetings</span></p></li>
                                                    <li><p><i className="fas fa-book"></i><span>Share materials</span></p></li>
                                                    <li><p><i className="fa fa-comments"></i><span>Conduct discussion</span></p></li>
                                                </ul>
                                                {
                                                    group.isMember === false
                                                    &&
                                                    <button onClick={(e) => { ActionJoinButton(); }} className="btn btn-success ek-lrs-module-registered" data-ek-lrs-url={`/view-learning-circle/${unique_id}/feeds`} data-ek-lrs-title={group.title}>{text_join}</button>
                                                }

                                                {/* {
                                                    group.isMember !== false
                                                    &&
                                                    <Link to={`/view-learning-circle/${unique_id}/feeds`} className="btn btn-success ek-lrs-module-launched" data-ek-lrs-url={`/view-learning-circle/${unique_id}/feeds`} data-ek-lrs-title={`${group.title}`}>Go to circle</Link>
                                                }
                                                {
                                                    <a className="rating_text"><i className="far fa-star"></i> {group?.average_rating ? group?.average_rating : 'No'} Rating</a>
                                                }
                                                {
                                                    cntxAuth.isLogged
                                                    &&
                                                    <>
                                                        <span className="newreport cursor-pointer"><span className="dropdown-item" data-toggle="modal" data-target="#reportModalCenter"><i className="fas fa-flag"></i>Report this learning circle</span></span>
                                                        <ReportCircle report_types={report_types} unique_id={unique_id} for_course={false} course_id={''} />
                                                    </>
                                                } */}

                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </section>
                    <div className="clearfix"></div>

                    <section className="custom-section related_course new_arrow">
                        <div className="container">
                            <TrendingCircleI unique_id={unique_id} />
                        </div>
                    </section>
                    <div className="clearfix"></div>
                </>
            }
        </>
    );
}

const TrendingCircleI = ({ unique_id }) => {
    var settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        }, {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        }, {
            breakpoint: 680,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
    };

    const _token = localStorage.getItem('lmstoken');
    const [groups, setGroups] = useState([]);

    useEffect(() => {
        fetchGroups();
        // eslint-disable-next-line
    }, []);

    const fetchGroups = async () => {
        const response = await fetch(`${API_BASE}get-trending-circles/${unique_id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();

        if (data.status) {
            setGroups(data.data.groups);
        }
    }

    return (
        <>
            <div className="releted-course course_slider_arrow">
                <h3>Learning circles recommended for you</h3>
                <div className="blog-card learning-circle-list-section">
                    {
                        typeof groups !== 'undefined' && groups.length > 0
                        &&
                        <Slider {...settings}>
                            {
                                groups.map((group, index) =>
                                    <LearningCircleCard key={index} group={group} rating={group.average_rating} />
                                )
                            }
                        </Slider>
                    }

                    {
                        (typeof groups === 'undefined' || groups.length <= 0)
                        &&
                        <div className="row "><div className="col-md-12 col-lg-12 text-center"><p>No Circles Found.</p></div></div>
                    }
                </div>
            </div>
        </>
    );
}

export default LearningCircleDetails;
