import { useForm } from "react-hook-form";
import { API_BASE } from "../constants/global";
import { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import { CircleBoxSkeleton } from "../components/skeletons";
import { Helmet } from "react-helmet";

const Home = () => {
    const [homePage, setHomePageContent] = useState('');
    const [search, setSearch] = useState('');
    const [isLoader, setIsLoader] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        fetchHomePage();
        //eslint-disable-next-line
    }, []);

    const fetchHomePage = async () => {
        setIsLoader(true);
        const response = await fetch(API_BASE + 'pages/home', {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
        });

        const data = await response.json();
        if (data.status) {
            setIsLoader(false);
            var homeData = data.data;
            setHomePageContent(homeData);
        } else {
            setIsLoader(false);
        }
    }

    const homeSearch = () => {
        if (search != "") {
            navigate('/search/' + search);
        }
    }

    return (
        <>
            <Helmet>
                <title>{'EduKula - Homepage'}</title>
            </Helmet>
            {/* Banner */}
            <section className="custom-section m-0">
                <div className="banner-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="banner-info">
                                    <h2>Enhance Your Skills<span> & Advance in Your Career</span></h2>
                                    <div className="banner-form">
                                        <input type="text" onChange={(e) => { setSearch(e.target.value); }} onKeyUp={(e) => { if (e.key === 'Enter') { homeSearch(); } }} placeholder="What do you want to learn ?" value={search} />
                                        <a onClick={(e) => { homeSearch() }} type="button" className="btn btn-success"><i className="fas fa-search"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* top image */}
                <div className="content" dangerouslySetInnerHTML={{ __html: homePage?.logo_section }}></div>
            </section>

            {/* Our Course */}
            {
                (typeof homePage?.our_courses_section?.data !== "undefined" && homePage?.our_courses_section?.data?.length > 0)
                &&
                <section className="custom-section whitebg pt-5 pb-5 m-0">
                    <div className="explore-section">
                        <div className="container">
                            <div className="explore-block">
                                <h1>{homePage?.our_courses_section?.title}</h1>
                                <p>{homePage?.our_courses_section?.description}</p>
                                <Tabs defaultActiveKey="0" className="nav-pills">
                                    {
                                        (typeof homePage?.our_courses_section?.data !== "undefined" && homePage?.our_courses_section?.data?.length > 0)
                                        &&
                                        homePage?.our_courses_section?.data.map((element, index) =>
                                            (element?.courses)
                                            &&
                                            <Tab key={index} eventKey={index} title={element.title}>
                                                <div className="blog-card">
                                                    <div className="row">
                                                        {
                                                            element.courses.map((child_element, child_index) =>
                                                                <div className="col-md-6 col-lg-3" key={child_index}>
                                                                    <Link to={`/course-details/${child_element?.slug}`} className="ek-lrs-course-initialized" data-ek-lrs-url={`/course-details/${child_element?.slug}`} data-ek-lrs-title={child_element?.title}>
                                                                        <div className="blog-card-detail">
                                                                            <div className="custom-card-img">
                                                                                <img src={child_element?.image} className="card-img-top" alt={child_element?.title} />
                                                                            </div>
                                                                            <div className="blog-card-inner">
                                                                                <h5>{child_element?.title}</h5>
                                                                                <ul className="course_tabs_sub mt-3 mb-2">
                                                                                    {
                                                                                        child_element?.sub_category?.title
                                                                                        &&
                                                                                        <li><a><i className="fas fa-list"></i><span>{child_element?.sub_category?.title}</span></a></li>
                                                                                    }
                                                                                    {
                                                                                        child_element?.mode_of_learning
                                                                                        &&
                                                                                        <li><a><i className="far fa-user"></i><span>{child_element?.mode_of_learning}</span></a></li>
                                                                                    }
                                                                                    {
                                                                                        <li><a><i className="fas fa-star"></i><span>{child_element?.rating !== "" ? child_element?.rating : 'No'} Rating</span></a></li>
                                                                                    }
                                                                                </ul>
                                                                                <div className="clearfix"></div>
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </Tab>
                                        )
                                    }
                                </Tabs>
                                <div className="explore-footer text-center">
                                    <Link to="/courses" className="btn btn-success btn-circle">Explore more</Link>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </div>
                </section>
            }

            {/* power of peer to peer */}
            <div className="content" dangerouslySetInnerHTML={{ __html: homePage?.peer_learning_section }}></div>

            {/* explore learning circle */}
            {
                (typeof homePage?.learning_circles_section?.data !== "undefined" && homePage?.learning_circles_section?.data?.length > 0)
                &&
                <section className="custom-section graybg pt-5 pb-1 mb-0">
                    <div className="explore-section">
                        <div className="container">
                            <div className="explore-block">
                                <h1>{homePage?.learning_circles_section?.title}</h1>
                                <p>{homePage?.learning_circles_section?.description}</p>
                                <div className="explore-block learning-circle-list-section pt-3">
                                    <Tabs defaultActiveKey="0" className="nav-pills pb-5">
                                        {
                                            (typeof homePage?.learning_circles_section?.data !== "undefined" && homePage?.learning_circles_section?.data?.length > 0)
                                            &&
                                            homePage?.learning_circles_section?.data.map((element, index) =>
                                                (element?.learning_circle)
                                                &&
                                                <Tab key={index} eventKey={index} title={element.title}>
                                                    <div className="row">
                                                        {
                                                            element?.learning_circle
                                                            &&
                                                            element?.learning_circle.map((group, index) =>
                                                                <div key={index} className="col-md-6 col-lg-3 mb-5">
                                                                    <div className="blog-card-detail">
                                                                        <div className="custom-card-img">
                                                                            <Link to={'/learning-circle-details/' + group.unique_id} className="ek-lrs-module-initialized" data-ek-lrs-url={`/learning-circle-details/${group.id}`} data-ek-lrs-title={group.title}>
                                                                                <img src={group.image} className="card-img-top" alt="Blog image" />
                                                                            </Link>
                                                                        </div>
                                                                        <div className="blog-card-inner">
                                                                            <span>Started {group.started}</span>
                                                                            <Link to={'/learning-circle-details/' + group.unique_id} className="ek-lrs-module-initialized" data-ek-lrs-url={`/learning-circle-details/${group.id}`} data-ek-lrs-title={group.title}>
                                                                                <h5>{group.title}</h5>
                                                                            </Link>

                                                                            <ul className="course-tabs-details">
                                                                                <li><a><i className="fas fa-list"></i><span>{group.category}</span></a></li>
                                                                                {/* <li><Link to={`/profile-details/${group?.owner_slug}`}><i className="far fa-user"></i><span>{group?.owner}</span></Link></li> */}
                                                                                <li><i className="far fa-user"></i><span>{group?.owner}</span></li>
                                                                                <li><a><i className="fas fa-users"></i><span>{group.membersCount}+ Group members</span></a></li>
                                                                                {/* <li><a><i className="fas fa-language"></i><span>{group.language}</span></a></li> */}
                                                                                <li><a><i className="fas fa-star"></i><span>{group?.average_rating !== null ? group?.average_rating : 'No'} Rating</span></a></li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </Tab>
                                            )
                                        }
                                    </Tabs>
                                    <div className="explore-footer text-center m-0 mb-5">
                                        <Link to="/explore-learning-circles" className="btn btn-success btn-circle">Explore more</Link>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }

            {/* become tutor */}
            <div className="content whitebg pt-5 pb-1" dangerouslySetInnerHTML={{ __html: homePage?.become_a_tutor_section }}></div>
        </>
    );
}

export default Home;
