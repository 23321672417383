import {
  React,
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { API_BASE, LRS_ENDPOINT } from "../../constants/global";
import { SwalBox, Toaster } from "../../entity/GlobalJS";
import CustomPagination from "../../entity/CustomPagination";
import { event } from "jquery";
import Select from "react-select";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useReactToPrint } from "react-to-print";
// import Autosuggest from "react-autosuggest";
import Cookies from "js-cookie";
import Joyride from 'react-joyride';

function HiringReport() {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    setFocus,
    clearErrors,
  } = useForm();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [course_options, setCourseOptions] = useState([]);
  const _token = localStorage.getItem("lmstoken");
  const [selected_course, setSelectedCourse] = useState("");
  const [loading, setLoading] = useState(false);
  const [employee_options, setEmployeeOption] = useState("");
  const [selected_employee, setSelectedEmployee] = useState("");
  const currentPage = Number(searchParams.get("page")) || 0;
  const [reload, setReload] = useState(false);
  const { vacancy_id } = useParams();
  const [employee, setEmployee] = useState("");
  const [vacancy_info, setVacancyInfo] = useState("");
  const [report_file_url, setReportFileUrl] = useState("");
  const [total_count, setTotalCount] = useState(0);
  const [material_ids, setMaterialids] = useState("");
  const [sharingReport, setSharingReport] = useState(false);
  const [emails, setEmails] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [ref_vacancy, setRefVacancy] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [isBlank, setIsBlank] = useState(false);
  const [selectedCourseName, setSelectedCourseName] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [tour_steps, setTourSteps] = useState([]);

  const user = JSON.parse(localStorage.getItem('lmsuser'));

  const [_course_comp_avg, setCourseCompAvg] = useState(0);
  const defaultColDef = {
    resizable: true,
    editable: false,
    filter: true,
    sortable: true,
    floatingFilter: true,
    suppressNavigable: true,
  };

  var filterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var dateParts = dateAsString.split("/");
      var cellDate = new Date(
        Number(dateParts[2]),
        Number(dateParts[1]) - 1,
        Number(dateParts[0])
      );
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
  };

  const columnDefs = [
    {
      headerName: "Sr.",
      valueGetter: "node.rowIndex + 1",
      filter: false,
      sortable: false,
      floatingFilter: false,
      width: 70,
      minWidth: 70,
      colId: "No",
    },
    {
      headerName: "Name",
      field: "name",
      cellRenderer: (params) => {
        return (
          <>
            <img
              src={params.data.image}
              alt="Profile Photo"
              width={45}
              height={45}
            ></img>
            <span>&nbsp;&nbsp;{params.data.name}</span>
          </>
        );
      },
      width: 250,
      minWidth: 250,
    },
    {
      headerName: "Employee Name",
      field: "name",
      hide: true,
      colId: "EName",
    },
    {
      headerName: "Email",
      field: "email",
      width: 250,
      minWidth: 250,
      colId: "Email",
      filter: "agTextColumnFilter",
      filterParams: {
        // pass in additional parameters to the Text Filter
      },
    },
    /*
    {
      headerName: "Date of Enrollment",
      field: '',
      valueGetter: (params) => {
        return '-';
      },
      colId: "DOE"
    },
    {
      headerName: "Date  of Completion",
      field: '',
      valueGetter: (params) => {
        return '-';
      },
      colId: "DOC"
    },
    */
    {
      headerName: "Course Status",
      field: "course_status",
      colId: "Status",
      // filter: "agSetColumnFilter",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Spent Time",
      field: "total_hours",
      cellRenderer: (params) => {
        console.log(typeof params.data.duration.total_hours);
        console.log(params.data.duration.total_hours);
        return params.data.duration.total_hours;
      },
      colId: "CourseComplete",
      filterValueGetter: (params) => {
        return params.data.duration.total_hours;
      }
    },
    {
      headerName: "Quiz Score",
      field: "score",
      cellRenderer: (params) => {
        return <>{params.data.score_2}</>;
      },
      filter: "agNumberColumnFilter",
      colId: "ScoreObtained",
    },
    {
      headerName: "Assignment Score",
      field: "score",
      cellRenderer: (params) => {
        return <>{params.data.lrs.assignment_right_count+'/'+params.data.lrs.assignment_total_count} </>;
      },
      filter: "agNumberColumnFilter",
      colId: "ScoreObtained",
    },

    {
      headerName: "Hiring Status",
      field: "vacancy_status",
      cellRenderer: (params) => {
        let _hstatus = "N/A";
        if (params.data.lrs.vacancy_status != null) {
          _hstatus = params.data.lrs.vacancy_status;
        }
        return _hstatus;
      },
      filterValueGetter: (params) => {
        let _hstatus = "N/A";
        if (params.data.lrs.vacancy_status != null) {
          _hstatus = params.data.lrs.vacancy_status;
        }
        return _hstatus;
      },
      colId: "HiringStatus",
    },
  ];

  const gridRef = useRef();

  const toggleLeftMenu = async () => {
    try {
      let sidebar = document.querySelector(".sidebar");
      sidebar.classList.add("close");
    } catch (e) {}
  };

  useEffect(() => {
    showHiringReportTour();
    fetchCourses();
    fetchEmployees();
    toggleLeftMenu();
  }, []);

  const fetchCourses = async () => {
    const response = await fetch(
      `${API_BASE}get-hiring-report-course-list?page=0`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${_token}`,
        },
      }
    );
    const data = await response.json();
    if (data.status) {
      let defaultValue = "";
      var stringCourse = [];
      data.data.courses.forEach(function (value, i) {
        stringCourse.push({ value: value.id, label: value.title });
      });
      setCourseOptions(stringCourse);
      if (defaultValue !== "") {
        setSelectedCourse(stringCourse.find((c) => c.value === defaultValue));
      }
    }
  };

  const fetchEmployees = async (page = 0, search = "") => {
    setLoading(true);
    const response = await fetch(
      `${API_BASE}employee/get?page=${page}&search=${search}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${_token}`,
        },
      }
    );

    const data = await response.json();

    if (data.status) {
      var stringCourse = [];
      data.data.employees.forEach(function (value, i) {
        stringCourse.push({ value: value.uid, label: value.full_name });
      });
      setEmployeeOption(stringCourse);
    } else {
    }
    setLoading(false);
  };
  const s2t = function (t) {
    return (
      parseInt(t / 86400) +
      "d " +
      new Date((t % 86400) * 1000)
        .toUTCString()
        .replace(/.*(\d{2}):(\d{2}):(\d{2}).*/, "$1h $2m $3s")
    );
  };

  function sortByKey(array, key1, key2, sortOrder = "asc") {
    const sortedArray = array.slice().sort((a, b) => {
      if (a[key1] === 0) {
        // If spent_time is 0, move to the end of the array
        return 1;
      } else if (b[key1] === 0) {
        // If spent_time is 0, move to the end of the array
        return -1;
      } else if (a[key1] < b[key1]) {
        return sortOrder === "asc" ? -1 : 1;
      } else if (a[key1] > b[key1]) {
        return sortOrder === "asc" ? 1 : -1;
      } else if (a["score"] > 60 && b["score"] > 60) {
        // If spent_time is equal, compare scores if both scores are greater than 60
        if (a[key2] < b[key2]) {
          return sortOrder === "asc" ? -1 : 1;
        } else if (a[key2] > b[key2]) {
          return sortOrder === "asc" ? 1 : -1;
        }
      }
      return 0;
    });
    return sortedArray;
  }

  function sortByMultipleKeys(array, sortKeys, sortOrders) {
    return array.sort((a, b) => {
      for (let i = 0; i < sortKeys.length; i++) {
        const key = sortKeys[i];
        const sortOrder = sortOrders[i] || "asc";

        const valueA = a[key];
        const valueB = b[key];

        if (valueA < valueB) return sortOrder === "asc" ? -1 : 1;
        if (valueA > valueB) return sortOrder === "asc" ? 1 : -1;
      }
      return 0;
    });
  }

  const fetchStudentEmployees = async (cid) => {
    SwalBox.fire({
      title: "Processing...",
      html: "Please wait...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        SwalBox.showLoading();
      },
    });
    let page = 1;
    let search = "";
    const response = await fetch(
      `${API_BASE}course-students/${cid}?page=${page}&search=${search}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + _token,
        },
      }
    );
    let _course_comp_avg = 0;
    const data = await response.json();
    if (data.status) {
      if (data.data.all_counts !== 0) {
        //setRowData(data.data.students);
        //console.info(data.data);return false;
        let _updated_leaderboard_rows = [];
        let _updated_sorted_leaderboard_rows = [];
        let _avg_total_time = 0;
        let _avg_total_emp  = 0;
        let _total_time = 0;
        if (data.data.student_count !== 0) {
          let _existing_leaderboard_rows = data.data.students;

          if (_existing_leaderboard_rows.length > 0) {
            for (let [
              i,
              _existing_leaderboard_row,
            ] of _existing_leaderboard_rows.entries()) {
              var _req_slug = data.data.course_slug;
              var _req_agent = _existing_leaderboard_row.email;
              var _rep_1_request_json = { slug: _req_slug, agent: _req_agent };
              const response_n = await fetch(
                `${LRS_ENDPOINT}statements/get-course-duration?slug=` +
                  _req_slug +
                  "&agent=" +
                  _req_agent,
                {
                  method: "POST",
                  body: JSON.stringify(_rep_1_request_json),
                }
              );
              let data_n = await response_n.json();
              _existing_leaderboard_row.duration = {};
              let _duration = data_n.duration;
              if (_duration != undefined && typeof _duration != "undefined") {
                _existing_leaderboard_row.duration.total_hours = s2t(_duration);
                _existing_leaderboard_row.spent_time = parseInt(_duration);
                _total_time += parseInt(_duration);
                if (_existing_leaderboard_row.lrs.completed != null) {
                  _existing_leaderboard_row.course_status = "Completed";
                } else {
                  _existing_leaderboard_row.course_status = "Not Completed";
                }
              } else {
                _existing_leaderboard_row.duration.total_hours = "0 Hr";
                _existing_leaderboard_row.spent_time = 0;
                _existing_leaderboard_row.duration.total_hours = "0 Hr";
                _existing_leaderboard_row.spent_time = 0;
                _existing_leaderboard_row.course_status = "Not Started";
              }
              if (
                typeof _existing_leaderboard_row.lrs.quiz.right_count !=
                  "undefined" &&
                _existing_leaderboard_row.lrs.quiz.right_count != null
              ) {
                _existing_leaderboard_row.score = parseFloat(
                  parseInt(_existing_leaderboard_row.lrs.quiz.right_count) +
                    " / " +
                    parseInt(_existing_leaderboard_row.lrs.quiz.total_count) *
                      100
                );
                _existing_leaderboard_row.score_2 =
                  parseInt(_existing_leaderboard_row.lrs.quiz.right_count) +
                  " / " +
                  parseInt(_existing_leaderboard_row.lrs.quiz.total_count);
              } else {
                _existing_leaderboard_row.score = 0;
                _existing_leaderboard_row.score_2 = 0;
              }
              if (_existing_leaderboard_row.lrs.completed != null) {
                _avg_total_time += _existing_leaderboard_row.spent_time;
                _avg_total_emp++;
              }
              _updated_leaderboard_rows.push(_existing_leaderboard_row);
            }

            //let sortedByTime = sortByKey(_existing_leaderboard_rows, 'spent_time', 'desc');
            let sortByScorec = sortByMultipleKeys(
              _updated_leaderboard_rows,
              ["score_2", "spent_time"],
              ["desc", "desc"]
            );
            setEmployee("");
            //setEmployee(sortByScorec);
            setTotalCount(
              data.data.student_count ? data.data.student_count : 0
            );

            _course_comp_avg = parseInt(_avg_total_time / _avg_total_emp);
            setCourseCompAvg(_course_comp_avg); 


             console.info(sortByScorec); 


             let _updated_leaderboard_rows2 = [];
             for (let [i, _existing_leaderboard_row2] of sortByScorec.entries()) {

                     if (_existing_leaderboard_row2.lrs.completed === null) {
                      _existing_leaderboard_row2.system_note =  "This candidate has not completed the course.";
                     } else if (_existing_leaderboard_row2.spent_time < _course_comp_avg) {
                      _existing_leaderboard_row2.system_note =  "This candidate has completed the course in less than the average time.";
                     } else if (_existing_leaderboard_row2.spent_time > _course_comp_avg) {
                      _existing_leaderboard_row2.system_note =  "This candidate has spent more than average time & having good quiz score";
                     }else{
                      _existing_leaderboard_row2.system_note =  "This candidate has spent more than average time & having good quiz score";
                     }

                     _updated_leaderboard_rows2.push(_existing_leaderboard_row2);
     
             }
             setEmployee(_updated_leaderboard_rows2);
            //setRowData(sortByScorec);
            setRowData(_updated_leaderboard_rows2);
            setIsBlank(false);
            setIsDisabled(false);
          }
        }
      } else {
        setRowData([]);
        setIsBlank(true);
      }
    } else {
    }
    //setTimeout(() => {
    SwalBox.close();
    //}, 1000);
  };

  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv({
      columnKeys: [
        "No",
        "EName",
        "Email",
        "DOE",
        "DOC",
        "Status",
        "CourseComplete",
        "Score",
      ],
    });
  }, []);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    const allColumnIds = [];
    params.columnApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });

    params.columnApi.autoSizeColumns(allColumnIds);
  };

  const exportResults = async (download) => {
    if (
      selected_course == null ||
      selected_course == "" ||
      selected_course == undefined
    ) {
      SwalBox.fire({
        title: "Select a Course",
        text: "Please select a course from the dropdown list.",
        icon: "error",
      });
      setTimeout(() => {
        SwalBox.close();
      }, 3000);
    } else {
      SwalBox.fire({
        title: "Processing...",
        html: "Please wait...",
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          SwalBox.showLoading();
        },
      });

      
      const gridApi = gridRef.current.api;
      const visibleData = [];
      gridApi.forEachNodeAfterFilterAndSort((node) => {
        visibleData.push(node.data);
      });
      //const emails = employee.map(obj => obj.email);
      const emails = visibleData.map((obj) => obj.email);
      var _email_string = emails.join(",");

      let reportscore = [];
      const filteredArray = visibleData.filter(function (item) {
        var cDuration = "Not Started";
        if (item.duration == null) {
          var cDuration = "Not Started";
        } else {
          var cDuration = item.duration.total_hours;
        }

        reportscore.push(
            item.student_id +
            "/" +
            item.email +
            "/" +
            item.lrs.quiz.right_count +
            "/" +
            item.lrs.quiz.total_count +
            "/" +
            ref_vacancy +
            "/" +
            cDuration +
            "/" +
            item.system_note +
            "/" +
            item.lrs.assignment_right_count +
            "/" +
            item.lrs.assignment_total_count +
            "/" +
            item.lrs.percentage +
            "/" +
            item.lrs.assignment_w +
            "/" +
            item.lrs.assignment_percentage +
            "/" +
            item.lrs.quiz_w +
            "/" +
            item.lrs.quiz_percentage
        );
      });
      const _course_details_response = await fetch(
        `${API_BASE}get-course-detail-by-id/${selected_course}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + _token,
          },
        }
      );
      var _slug = "";
      const _course_details_data = await _course_details_response.json();
      if (_course_details_data.status) {
        _slug = _course_details_data.data.course.slug;
        var ref_vacancy = "";
        try {
          var formData = new FormData();
          formData.append("slug", _slug);
          formData.append("ref_vacancy", ref_vacancy);
          formData.append("slides", btoa(_course_details_data.data.slides));
          formData.append("agents", btoa(_email_string));
          formData.append("emails", download);
          formData.append("details", btoa(reportscore.join("*")));
          const _download_response = await fetch(
            `${LRS_ENDPOINT}report/download-hiring-report`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${_token}`,
              },
              body: formData,
            }
          );
          const _download_data = await _download_response.json();

          if (_download_data.status) {
            SwalBox.close();
            setReportFileUrl(_download_data.furl);
            if (download === true) {
              window.location.href = _download_data.furl;
            } else {
              SwalBox.fire({
                title: "Report has been shared.",
                text: "An email has been sent to the email address(es) you have provided.",
                icon: "success",
              });
              setTimeout(() => {
                document.getElementById("ShareReportClose").click();
              }, 2000);
              setTimeout(() => {
                SwalBox.close();
              }, 3000);
            }
          }
        } catch (e) {}
        SwalBox.close();
      }
    }
  };
  const ShareResults = async () => {
    document.getElementById("ShareReportButton").click();
    return false;
  };
  const gridRefDiv = useRef();

  const printAgGrid = useReactToPrint({
    content: () => gridRefDiv.current,
    documentTitle: "Hiring Report",
  });

  const onSubmit = async (data) => {
    if (emails.length >= 30) {
      setError(
        "email",
        { type: "focus", message: "Maximum 30 emails can be sent at a time." },
        { shouldFocus: false }
      );
      return false;
    }
    setProcessing(true);
    var _email_string = data.email;
    var _remove_all_spaces = _email_string.replace(/\s\s+/g, " ");
    var _convert_space_in_coma = _remove_all_spaces.split(/[ ,]+/).join(",");
    var iEmail = _convert_space_in_coma.trim();
    var comEmails = iEmail.split(",");
    if (typeof comEmails !== "undefined" && comEmails.length > 1) {
      var duplicate = false;
      var checkEmails = [];
      for (let index = 0; index < comEmails.length; index++) {
        const sepEmail = comEmails[index].trim();
        if (emails.indexOf(sepEmail) === -1) {
          checkEmails.push(sepEmail);
        } else {
          duplicate = true;
        }
      }
      const callAsync = async () => {
        const newList = [...emails, ...checkEmails];
        setEmails(newList.splice(30));
        setEmails(newList);
      };
      callAsync();
      if (duplicate) {
        Toaster.info(
          "Some of the email(s) are duplicate and not added to the list."
        );
      }
      setValue("email", "");
      setFocus("email");
      setProcessing(false);
    } else {
      if (emails.indexOf(iEmail) !== -1) {
        setError(
          "email",
          { type: "focus", message: "This email already in the list." },
          { shouldFocus: true }
        );
        setProcessing(false);
        return false;
      }
      setEmails([...emails, iEmail]);
      setValue("email", "");
      setFocus("email");
      setProcessing(false);
    }
  };
  const removeItem = async (e, item) => {
    const newEmails = [];
    emails.forEach((email, index) => {
      if (item !== email) {
        newEmails.push(email);
      }
    });
    setEmails(newEmails);
  };
  const processShareReport = async () => {
    var formData = new FormData();
    for (var i = 0; i < emails.length; i++) {
      formData.append("members[]", emails[i]);
    }
    //alert(emails.toString());
    var _email_string = emails.toString();
    exportResults(_email_string);
  };

  // Mock database schema for demonstration
  const databaseSchema = {
    users: ["email", "first_name"],
  };

  // List of SQL reserved words
  const sqlReservedWords = [
    "SELECT",
    "FROM",
    "WHERE",
    "JOIN",
    "INNER JOIN",
    "LEFT JOIN",
    "RIGHT JOIN",
    "ON",
    "AND",
    "OR",
    "ORDER BY",
    "GROUP BY",
    "HAVING",
    "LIMIT",
    "Course Name",
    "Name",
    "Email",
    "Course Status",
    "Spent Time",
    "Score Obtained",
    "Hiring Status",
    // ...other SQL reserved words
  ];

  const [query, setQuery] = useState("");
  const [currentWord, setCurrentWord] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const onInputChange = (event, { newValue }) => {
    // Split the current input into words
    const words = newValue.split(" ");
    // Get the last word (current word)
    const currentInputWord = words[words.length - 1];
    setQuery(newValue);
    setCurrentWord(currentInputWord);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    // Combine database schema and SQL reserved words for suggestions
    const allSuggestions = [
      ...Object.keys(databaseSchema),
      ...sqlReservedWords,
    ];

    // Filter suggestions based on the current word (last word in the input)
    const filteredSuggestions = allSuggestions
      .filter((item) => item.toLowerCase().includes(currentWord.toLowerCase()))
      .sort(); // Sort the suggestions in ascending order

    setSuggestions(filteredSuggestions);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const getSuggestionValue = (suggestion) => {
    // Replace only the last word in the input with the suggestion
    const words = query.split(" ");
    words[words.length - 1] = suggestion;
    return words.join(" ");
  };

  const renderSuggestion = (suggestion) => <div>{suggestion}</div>;

  const inputProps = {
    placeholder: "Enter your SQL query...",
    value: query,
    onChange: onInputChange,
  };

  const generateQuery = () => {
    // You can customize this function to generate more complex queries based on user input.
    return query;
  };

  function getCurrentDateFormatted() {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = months[currentDate.getMonth()];
    const day = currentDate.getDate();

    const formattedDate = `${month}-${day}-${year}`;
    return formattedDate;
  }

  const formattedCreationDate = getCurrentDateFormatted();

  const setPrinterFriendly = (api) => {
    const eGridDiv = document.querySelector("#myGrid");
    eGridDiv.style.width = "";
    // eGridDiv.style.height = '';
    api.setDomLayout("print");
  };

  const setNormal = (api) => {
    const eGridDiv = document.querySelector("#myGrid");
    eGridDiv.style.width = "100%";
    // eGridDiv.style.height = '664px';
    api.setDomLayout();
    // const gridApi = gridRef.current.api;
    // gridApi.setDomLayout();
    var Griddivlist = document.querySelectorAll(
      ".ag-root-wrapper-body.ag-focus-managed"
    );
    Griddivlist[0].style.height = "fit-content";
  };

  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById('page-size').value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  const showHiringReportTour = () => {
    let show_tour = Cookies.get("show_hiringreport_tour");
    if (show_tour != 'NO') {
      SwalBox.fire({
        allowOutsideClick: false,
        title: 'Would you like to have a quick tour?',
        text: 'This tour will take you through all the menu items in Hiring report and introduce you with the features.',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#30314c',
        cancelButtonColor: '#30314c',
        confirmButtonText: 'Yes, please.',
        cancelButtonText: 'No, thank you.'
      }).then((result) => {
        if (result.isConfirmed) {
          startHiringReportTour();
        } else {
          Cookies.set('show_hiringreport_tour', 'NO');
        }
      });
    }
  }

  const startHiringReportTour = () => {
    let steps = [];
    const ek_manage_reports = localStorage.getItem('manage_reports');
    if (user?.is_corporate === 1 || ek_manage_reports == 'YES') {
      steps = [
        { placementBeacon: 'right', disableBeacon: true, target: '.tour-employee-step-01', title: 'Course', content: "To select the course to show course wise", },
        { placementBeacon: 'right', disableBeacon: true, target: '.tour-employee-step-02', title: 'Export', content: "To export students list to CSV file and download it" },
        { placementBeacon: 'right', disableBeacon: true, target: '.tour-employee-step-03', title: 'Share', content: "To share report file to email addresses" },
        { placementBeacon: 'right', disableBeacon: true, target: '.tour-employee-step-04', title: 'Print', content: "To print or download students list with details in pdf format" },
      ];
    } else {
      steps = [
      ];
    }
    setTourSteps(steps);
    Cookies.set('show_hiringreport_tour', 'NO');
  }

  const setAgGridForPrint = () => {
    const api = gridRef.current.api;
    setPrinterFriendly(api);
    setTimeout(function () {
      printAgGrid();
    }, 1000);
    setTimeout(function () {
      setNormal(api);
    }, 2000);
  };

  return (
    <>
      {/* <div>
        <Autosuggest
          className="form-control"
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
        /> 
        <button
          className="btn btn-circle btn-info"
          onClick={() => alert(generateQuery())}
        >
          Show Result
        </button>
      </div>
      */}
      <a
        className="btn ml-2 mb-2 d-none"
        data-toggle="modal"
        id="ShareReportButton"
        data-target="#ShareReportModal"
      >
        &nbsp
      </a>
      <div
        className="modal header_red fade"
        id="ShareReportModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="ShareReportModalTitle"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 id="ShareReportTitle">Share Report</h4>
              <button
                type="button"
                id="ShareReportClose"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body invitepop">
              <div className="form-group">
                <label>Enter Email Addresses to Share Report File</label>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="email_box">
                  <input
                    {...register("email", {
                      required: "required",
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "Entered value does not match email format",
                      },
                    })}
                    type="text"
                    placeholder="Enter email address"
                  />
                  <button
                    disabled={processing}
                    className="btn btn-circle btn-info ml-2 add_btn"
                  >
                    {processing && (
                      <i className="fa fa-refresh fa-spin fa-fw"></i>
                    )}
                    {!processing && "Add"}
                  </button>
                </div>
                <span className="text-danger">
                  {errors.email &&
                    errors.email.type === "required" &&
                    "Please enter email address"}
                  {errors.email &&
                    errors.email.type !== "required" &&
                    errors.email.message}
                </span>
              </form>
              {emails.length > 0 && (
                <>
                  <div className="emailList_box">
                    <ul className="mt-3">
                      {emails.map((email, index) => (
                        <li
                          key={index}
                          className="border px-2 py-1 mt-1 bg-white"
                        >
                          <span>{email}</span>
                          <i
                            onClick={(e) => {
                              removeItem(e, email);
                            }}
                            className="fa fa-times text-danger float-right mt-1"
                            title="Close"
                            aria-hidden="true"
                          ></i>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-circle btn-md cancel-btn"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                onClick={(e) => {
                  processShareReport();
                }}
                disabled={sharingReport}
                type="button"
                className="btn btn-circle btn-md submit-pink-btn"
              >
                {" "}
                {sharingReport ? (
                  <>
                    <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                  </>
                ) : (
                  <>Share Report</>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-2">
        <div className="row m-2 justify-content-between ek-align-items-cent">
          <div className="col-md-5  ek-mb-0 px-0 py-0 materials_section">
          <div className="form-group tour-employee-step-01">
              <label className="m-0" htmlFor={"course_id"}>
                <strong>Select Course</strong>
              </label>
              <>
                <Controller
                  control={control}
                  name="course_id"
                  id="course_id"
                  rules={{ required: false, message: "" }}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Select
                      inputRef={ref}
                      classNamePrefix="addl-class"
                      options={course_options}
                      onChange={(val) => {
                        setSelectedCourse(val.value);
                        setValue("course_id", val.value);
                        fetchStudentEmployees(val.value);
                        setSelectedCourseName(val.label);
                      }}
                      searchable={true}
                    />
                  )}
                />
              </>
            </div>
          </div>
          <div className="ek-inline-flex col-md-5  ek-mb-0 px-0 py-0 materials_section">
            <button
              disabled={isDisabled}
              onClick={(e) => {
                exportResults(true);
              }}
              type="button"
              className="btn btn-circle btn-info tour-employee-step-02"
            >
              <i className="fa-solid fa-file-excel">&nbsp;</i>Export
            </button>
            &nbsp;
            <button
              disabled={isDisabled}
              onClick={(e) => {
                ShareResults();
              }}
              type="button"
              className="btn btn-circle btn-info tour-employee-step-03"
            >
              <i className="fa-solid fa-share">&nbsp;</i>Share
            </button>
            &nbsp;
            <button
              disabled={isDisabled}
              onClick={() => {
                setAgGridForPrint();
              }}
              type="button"
              className="btn btn-circle btn-info tour-employee-step-04"
            >
              <i className="fa-solid fa-print">&nbsp;</i>Print
            </button>
          </div>
        </div>

        {selected_course !== "" &&
          selected_course !== null &&
          selected_course !== undefined &&
          (rowData.length !== 0 ? (
            <>
              <div className="Pageselectparent hiringperpage">
                Page Size:-
                <select onChange={onPageSizeChanged} id="page-size" className="Pageselect">
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                  <option value="200">200</option>
                  <option value="500">500</option>
                  <option value="1000">1000</option>
                </select>
              </div>
              <div ref={gridRefDiv} className="gridprint">
                <div className="hiringPrint">
                  <p>
                    <span className="PrintColumn">Report Type :- </span> Hiring
                    Report
                  </p>
                  <p>
                    <span className="PrintColumn">Creation Date :- </span>{" "}
                    {formattedCreationDate}
                  </p>
                  <p>
                    <span className="PrintColumn">Course Name :- </span>
                    {selectedCourseName}
                  </p>
                  <p>
                    <span className="PrintColumn">Description :- </span>{" "}
                    {`Course wise employee report`}
                  </p>
                </div>
                <div
                  id="myGrid"
                  className="ag-theme-alpine"
                  style={{ height: "100%", width: "100%" }}
                >
                  <AgGridReact
                    onGridReady={onGridReady}
                    columnDefs={columnDefs}
                    ref={gridRef}
                    defaultColDef={defaultColDef}
                    rowData={rowData}
                    domLayout="autoHeight"
                    enableFilter={true}
                    enableSorting={true}
                    enableColResize={true}
                    pagination={true}
                    paginationPageSize={20}
                    rowHeight={50}
                    suppressRowClickSelection={true}
                  />
                </div>
              </div>
              {
                tour_steps.length > 0
                &&
                <Joyride
                  steps={tour_steps}
                  disableCloseOnEsc={true}
                  disableOverlayClose={true}
                  showSkipButton={true}
                  continuous={true}
                  hideCloseButton={true}

                  styles={{ options: { width: 500, zIndex: 5000, primaryColor: '#31314c', textColor: '#ef4a45', overlayColor: 'rgb(2 1 0 / 69%)', } }}
                />
              }   
            </>
          ) : (
            <>
              <div className="col-12">
                <div className="empty_state">
                  <h3>No Record Found</h3>
                  <img alt="" src="images/no_courses.png" />
                </div>
              </div>
            </>
          ))}

        {isBlank == true && (
          <>
            <br></br>
            <h3 className="d-flex justify-content-center">No Records Found</h3>
          </>
        )}
      </div>
    </>
  );
}

export default HiringReport;
