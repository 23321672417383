import parse from 'html-react-parser';

const AboutCard = ({group}) => {
    return (
        <div className="about_circle sticky-top">
            <h4>About This Circle</h4>
            <p className="pad0"><strong>{group.title}</strong></p>
            {parse(group.about)}

            {
                group.private === 'Public'
                &&
                <>
                    <p className="pad0 opacity"><i className="fas fa-globe"></i> <strong>Public Circle</strong></p>
                    <p>Any EduKula User can join this circle.</p>
                    <p className="pad0 opacity"><i className="fas fa-eye"></i> <strong>Visible</strong></p>
                    <p>Anyone can find this circle.</p>
                </>
            }
            <p className="pad0 opacity"><i className="fas fa-clock"></i> <strong>History</strong></p>
            <p>Circle created on {group.created_at}</p>
        </div>
    );
}

export default AboutCard;