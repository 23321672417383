import { useEffect, useState } from "react";
import { API_BASE } from "../../constants/global";
import { FeedBoxSkeleton } from "../../components/skeletons";
import { Register, BecameTutor, CoursePublished, CourseNotPublished } from './notifications/Tutor/index';
import NoNotifications from "./notifications/NoNotifications";

const TutorTab = () => {
    const [notifications, setNotifications] = useState('');
    const [isLoader, setLoader] = useState(true);
    const _token = localStorage.getItem('lmstoken');
    const [page, setPage] = useState(0);
    const [total_page, setTotalPage] = useState(0);

    useEffect(() => {
        fetchTutorNotification();
        // eslint-disable-next-line 
    }, [page]);

    const fetchTutorNotification = async () => {
        setLoader(true);
        const response = await fetch(`${API_BASE}get-user-feeds-by-tab/Tutor?page=${page}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        setLoader(false);
        if (data.status) {
            try{
                setTotalPage(data?.data?.count);
                setNotifications([...notifications, ...data?.data?.feeds]);
            }catch(e){}
        }
    }

    const nextPage = () => {
        setPage(page + 1);
    }

    return (
        <>
            <div className="tab-pane fade show active" id="pills-tutor" role="tabpanel" aria-labelledby="pills-tutor-tab">
                <div className="course-tab-contents">
                    <div className="course-tabs-pane-block">
                        {
                            isLoader ?
                                <FeedBoxSkeleton count={3} />
                                :
                                notifications == '' ?
                                    <NoNotifications
                                        main_message={'No notifications yet'}
                                        sub_message={<></>}
                                    />
                                    :
                                    <>
                                        {
                                            notifications !== ""
                                            &&
                                            notifications.map((ntfcn, index) =>
                                                <div key={index}>
                                                    {
                                                        ntfcn.action === 'Tutor_Registration'
                                                        &&
                                                        <Register ntfcn={ntfcn} />
                                                    }

                                                    {
                                                        ntfcn.action === 'Became_Tutor'
                                                        &&
                                                        <BecameTutor ntfcn={ntfcn} />
                                                    }

                                                    {
                                                        ntfcn.action === 'Course_Published'
                                                        &&
                                                        <CoursePublished ntfcn={ntfcn} />
                                                    }

                                                    {
                                                        ntfcn.action === 'Course_Not_Published'
                                                        &&
                                                        <CourseNotPublished ntfcn={ntfcn} />
                                                    }
                                                </div>
                                            )
                                        }

                                        {
                                            notifications.length < total_page
                                            &&
                                            <div className="load_more_div">
                                                <a className="cursor-pointer" onClick={(e) => { nextPage(); }}>Load more</a>
                                            </div>
                                        }
                                    </>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default TutorTab;