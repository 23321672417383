import { Editor } from '@tinymce/tinymce-react'
import { useRef } from 'react';
import { TINYMCE_KEY } from '../constants/global';

const TinyMCEEditor = (props) => {
    const editorRef = useRef();

    return (
        <>
            <Editor
                apiKey={TINYMCE_KEY}
                onInit={(e, editor) => { editorRef.current = editor }}
                onChange={(e) => { props.onChange(editorRef.current.getContent()); }}
                onEditorChange={(newValue, editor) => props.onChange(newValue)}
                init={{
                    menubar: false,
                    placeholder: props.placeholder,
                    height: "200"
                }}
                value={props.value}
                // plugins={['wordcount']}
            />
        </>
    );
}

export default TinyMCEEditor;