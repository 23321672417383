import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const CmsPageSkeleton = (props) => { 
    {
        return (
            <>
            <section>
                <Skeleton height={400} borderRadius={10} />
                <div className='cms_pages'>
                    <div className='container pt-5 pb-5'>
                        <Skeleton height={20} count={15} />
                    </div>
                </div>
            </section>
            </>
        );
    }
}

export default CmsPageSkeleton;
