import { useEffect, useState } from "react";
import { Link, useLocation, useParams, useSearchParams } from "react-router-dom";
import { API_BASE } from "../../../constants/global";
import CustomPagination from "../../../entity/CustomPagination";
import { SwalBox, Toaster } from "../../../entity/GlobalJS";
import { Helmet } from "react-helmet";

const CircleMeetings = (props) => {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const currentPage = Number(searchParams.get('page')) || 0;
    const [reload, setReload] = useState(false);

    const _token = localStorage.getItem('lmstoken');
    const { unique_id } = useParams();
    const [meetings, setMeetings] = useState([]);
    const [total_count, setTotalCount] = useState(0);

    useEffect(() => {
        fetchMeetings(currentPage);
        // eslint-disable-next-line 
    }, [location, reload]);

    const funReload = (page) => {
        setSearchParams({ ...searchParams, page: page });
        setReload(!reload);
    }

    const fetchMeetings = async (page, search = '') => {
        const response = await fetch(`${API_BASE}get-group-meetings/${unique_id}?page=${page}&search=${search}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();
        if (data.status) {
            setMeetings(data.data.meetings);
            setTotalCount(data.data.total_count);
        } else {
            setMeetings([]);
            setTotalCount(0);
        }
    }

    const ConfirmDelete = (meeting_id) => {
        SwalBox.fire({
            title: 'Are you sure?',
            text: 'Sure to delete meeting?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                DeleteMeeting(meeting_id);
            }
        });
    }

    const DeleteMeeting = async (meeting_id) => {
        const response = await fetch(`${API_BASE}meeting/delete/${meeting_id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();
        if (data.status) {
            Toaster.success(data.message);
            fetchMeetings(0);
        } else {
            Toaster.error(data.message);
        }
    }

    return (
        <>
            <Helmet>
                <title>{'EduKula - Learning Circle'}</title>
            </Helmet>
            <section className={`custom-section member_section`}>
                <div className="profile_user_middle_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <section className="custom-section meeting_section">
                                    <div className="profile_user_middle_section">
                                        <div className="custom-user-profile-content custom-learning-circle-content">
                                            <div className="custom-learnig-circle-head">
                                                <h4>Meetings List</h4>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <i className="fas fa-search"></i><input onChange={(e) => { fetchMeetings(0, e.target.value); }} type="text" className="form-control" placeholder="Search meetings" />
                                                        </div>
                                                    </div>
                                                    {
                                                        // (props.isowner || props.isadmin)
                                                        // &&
                                                        <div className="col-md-6 text-right">
                                                            <Link to={`/view-learning-circle/${unique_id}/create-meeting`} className="btn btn-circle btn-info ml-1 mb-1"><i className="fa fa-plus" aria-hidden="true"></i> Add Meeting</Link>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="meetingdata">
                                                <table>
                                                    {
                                                        typeof meetings !== 'undefined' && meetings.length > 0
                                                        &&
                                                        <thead>
                                                            <tr>
                                                                <th width="100">Start Time</th>
                                                                <th width="250">Topic</th>
                                                                <th width="90">Created by</th>
                                                                <th width="90">Duration</th>
                                                                <th width="90">Status</th>
                                                                <th width="30">Action</th>
                                                            </tr>
                                                        </thead>
                                                    }
                                                    <tbody>
                                                        {
                                                            typeof meetings !== 'undefined' && meetings.length > 0
                                                            &&
                                                            meetings.map((meeting, index) =>
                                                                <tr key={index}>
                                                                    <td>{meeting.date},<br />{meeting.time}</td>
                                                                    <td><p><strong>{meeting.title}</strong>{meeting.agenda}</p></td>
                                                                    <td><Link to={`/profile-details/${meeting.creator_slug}`}>{meeting.creator}</Link></td>
                                                                    <td>{meeting.duration}</td>
                                                                    <td className={ meeting.status !== 'Completed' ? "orange_color" : ''}>
                                                                        <MeetingButton meeting={meeting} />
                                                                        {/* {
                                                                            meeting.mtype === true
                                                                                ?
                                                                                <button onClick={(e) => { StartMeeting(meeting.id); }} className="btn btn-circle btn-info ml-1 mb-1"> START <i className="fa fa-video" aria-hidden="true"></i> </button>
                                                                                :
                                                                                <button onClick={(e) => { JoinMeeting(meeting.id, meeting.user); }} className="btn btn-circle btn-info ml-1 mb-1"> JOIN <i className="fa fa-video" aria-hidden="true"></i> </button>
                                                                        } */}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            meeting.mtype === true && meeting.status !== 'Completed'
                                                                            &&
                                                                            <button onClick={(e) => { ConfirmDelete(meeting.id); }} className="dlt_btn" href=""><i className="fas fa-trash-alt"></i></button>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }

                                                        {
                                                            (typeof meetings === 'undefined' || meetings.length <= 0)
                                                            &&
                                                            <tr key={Math.random()}>
                                                                <th className="text-center" scope="row"><strong>No meetings are created.</strong></th>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                                <div className="clearfix"></div>

                                                {
                                                    total_count > 10
                                                    &&
                                                    <div className="custom-pagination">
                                                        <nav aria-label="Page navigation example">
                                                            <CustomPagination pageCount={total_count} perPageRecord={10} handlePageClick={(data) => { funReload(data.selected) }} currentPage={currentPage} />
                                                        </nav>
                                                    </div>
                                                }
                                            </div>
                                            <div className="clearfix"></div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CircleMeetings;

const MeetingButton = ({ meeting }) => {
    const _token = localStorage.getItem('lmstoken');
    const [join, setJoin] = useState(false);

    setTimeout(() => {
        checkMeeting();
    }, 2000);

    const checkMeeting = async () => {
        const response = await fetch(`${API_BASE}check-circle-meeting/${meeting.id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            setJoin(true);
        }
    }

    const StartMeeting = async (meeting_id) => {
        const response = await fetch(`${API_BASE}meeting/start/${meeting_id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            //window.location.href = data.data;
            var newTab = window.open(data.data, '_blank');
        } else {
            Toaster.error(data.message);
        }
    }

    const JoinMeeting = async (meeting_id, user_id) => {
        const response = await fetch(`${API_BASE}meeting/join/${meeting_id}/${user_id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            //window.location.href = data.data;
            var newTab = window.open(data.data, '_blank');
        } else {
            Toaster.error(data.message);
        }
    }

    return (
        <>
            {
                (meeting.mtype === true && meeting.status !== 'Completed')
                &&
                <button onClick={(e) => { StartMeeting(meeting.id); }} className="btn btn-circle btn-info ml-1 mb-1 ek-lrs-meeting-launched"> START <i className="fa fa-video" aria-hidden="true"></i> </button>
            }

            {
                (join && meeting.mtype !== true)
                &&
                <button onClick={(e) => { JoinMeeting(meeting.id, meeting.user); }} className="btn btn-circle btn-info ml-1 mb-1 ek-lrs-meeting-attended"> JOIN <i className="fa fa-video" aria-hidden="true"></i> </button>
            }

            {
                !join && !(meeting.mtype === true && meeting.status === 'Not Started')
                &&
                meeting.status
            }
        </>
    );
}