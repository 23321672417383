import { Link } from "react-router-dom";
const LearningCircleClosed = ({ ntfcn }) => {
    return (
        <>
            <div className="user-body">
                <div className="user-header">
                    <div className="user-profile-img">
                        <img src={ntfcn?.group?.owner?.image} className="rounded-circle" alt={ntfcn?.group?.owner?.name} />
                    </div>
                    <div className="user-details-title">
                        <h6><Link to={`/profile-details/${ntfcn?.group?.owner?.slug}`}>{ntfcn?.group?.owner?.name} </Link> <span>{ntfcn.date_time}</span></h6>
                        <p>Owner - {ntfcn?.group?.title}</p>
                    </div>
                </div>
                <div className="user-content-block">
                    <div className="user-course-desc">
                        <p>The <strong>{ntfcn?.group?.title}</strong> circle has been closed. Thank you for joining us and supporting us in this journey of learning.</p>
                    </div>
                    <div className="user-course-block">
                        <Link className="link" to={'/dashboard/my-circles'} target="_blank">
                            <img src={ntfcn?.group?.image} alt={ntfcn?.group?.title} />
                            <span>View this circle</span>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LearningCircleClosed;