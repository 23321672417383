import { Link } from "react-router-dom";
import { useContext } from "react";
import authContext from '../../../../context/auth/authContext';
import VerifiedBadge from "../../../../components/VerifiedBadge";

const Register = ({ntfcn}) => {
    const cntxAuth = useContext(authContext);

    return (
        <>
            <div className="user-body">
                <div className="user-header">
                    <div className="user-profile-img">
                        <img src="images/logo_icon.png" className="rounded-circle" alt="Profile Image" />
                    </div>
                    <div className="user-details-title">
                        <h6>EduKula<VerifiedBadge _is_verified={true}  refname="notification"/><span>{ntfcn.date_time}</span></h6>
                    </div>
                </div>
                <div className="user-content-block">
                    <div className="user-course-desc">
                        <p>Congratulations on becoming a Tutor on EduKula. Start your journey to upskill others by creating a course!</p>
                    </div>
                    <div className="user-course-block">
                        <Link className="link" to="/dashboard/tutor/create-course" target="_blank">
                        {
                        cntxAuth.userInfo.is_corporate == 1
                        &&
                        <img src="images/corporate-to-create-courses.jpg" alt="Course Image" />
                        }
                        {
                        cntxAuth.userInfo.is_corporate == 0
                        &&
                        <img src="images/tutor-to-create-courses.jpg" alt="Course Image" /> 
                        }
                            <span>Create a Course</span>
                        </Link>
                    </div>
                    <div className="like-main-area">
                        <div className=""></div>
                        <div className=""></div>
                        <div className=""></div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Register;