import { Link } from "react-router-dom";
import VerifiedBadge from "../../../../components/VerifiedBadge";
const Announcement = ({feed}) => {
    return (
        <>
            <div className="user-body">
                <div className="user-header">
                    <div className="user-profile-img">
                        <img src="images/logo_icon.png" className="rounded-circle" alt="Profile Image" />
                    </div>
                    <div className="user-details-title">
                        <h6>EduKula<VerifiedBadge _is_verified={true}  refname="notification"/><span>{feed?.date_time}</span></h6>
                    </div>
                </div>
                <div className="user-content-block">
                    <div className="user-course-desc mb-3">
                        <h5><Link to={'/dashboard/announcement-details/' + feed?.id}>{feed?.title}</Link></h5>
                    </div>
                    <div className="user-course-block">
                        <Link className="link" to={'/dashboard/announcement-details/' + feed?.id}>
                            <img src={feed?.image} alt="Course Image" />
                                <span>View Announcements</span>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Announcement;