import { useContext, useState } from "react";
import { Outlet } from "react-router-dom";
import { API_BASE } from "../constants/global";
import authContext from "../context/auth/authContext";
import { SwalBox, Toaster } from "../entity/GlobalJS";
import Links from "./Links";

const LayoutProfile = () => {
    const _token = localStorage.getItem('lmstoken');
    const cntxAuth = useContext(authContext);
    const user = JSON.parse(localStorage.getItem('lmsuser'));
    const [is_tutor, setIsTutor] = useState(user?.is_tutor);
    const confirmIsTutor = (e) => {
        var IsTutor = 1;
        var _IsTutor = 1;
        var textT = IsTutor ? 'Sure to become tutor?' : 'Sure to become tutor?';
        SwalBox.fire({
            title: 'Are you sure?',
            text: textT,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                saveIsTutor(_IsTutor, IsTutor);
            } else {
                // e.target.checked = _IsTutor;
            }
        });
    }
    const saveIsTutor = async (isTrue, IsTutor) => {
        const response = await fetch(API_BASE + 'post-set-tutor/' + isTrue, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            }
        });

        const data = await response.json();
        if (data.status) {
            setIsTutor(IsTutor);
            localStorage.setItem('lmsuser', JSON.stringify(data.data.user));
            cntxAuth.updateUser(data.data.user);
            Toaster.success(data.message);
        } else {
            Toaster.error(data.message);
        }
    }
    return (
        <>
            <section>
                <div className="profile-dashboard">
                    <div className="container">
                        <div className="profile-info">
                            <div className="row">
                                <div className="col-lg-3">
                                    <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                        <Links is_tutor={is_tutor} />
                                    </div>
                                </div>

                                <div className="col-lg-9">
                                    <div className="col-lg-12">
                                        {
                                            user?.is_tutor !== 1
                                            &&
                                            <a className="btn become_tutor_btn" onClick={(e) => { confirmIsTutor(e) }}>Become a Tutor</a>
                                        }
                                    </div>
                                    <div className="tab-content profile-tab-content" id="v-pills-tabContent">
                                        <Outlet />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default LayoutProfile;