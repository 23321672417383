import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const TutorReport = () =>{
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('lmsuser'));
    useEffect(() => {        
        if(user?.is_tutor !== 1)
        {
            navigate('/dashboard/home');
        }
    }, []);
    return (
        <>
            <Helmet>
                <title>{'EduKula - Tutor Report'}</title>
            </Helmet>
            <p className="text-center">Comming soon ..</p>
        </>
    )
}
export default TutorReport;
