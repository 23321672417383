import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import { API_BASE } from "../constants/global";
import { Doughnut } from 'react-chartjs-2';
import { Helmet } from "react-helmet";
import ReactSpeedometer from "react-d3-speedometer";


function CourseProgress() {
  const { _slug } = useParams();
  const [course, setCourse] = useState();
  const _token = localStorage.getItem('lmstoken');
  const [outer_slides, setOuterSlides] = useState([]);
  const [fullsections, setFullsections] = useState([]);

  const fetchCourseDetails = async () => {
    console.log("1");
    if (_slug !== '' && typeof _slug !== 'undefined') {
      console.log("2");

      const response = await fetch(`${API_BASE}get-course-about/${_slug}`, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Bearer " + _token
        }
      });

      const data = await response.json();
      if (data.status) {
        console.log(data.data.course.title);
        setCourse(data.data.course);
      }

      if (data.message === "No data found.") {

      }
    }
  }

  const fetchMaterials = async () => {
    const response = await fetch(`${API_BASE}get-learning-materials/${_slug}`, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": "Bearer " + _token
      },
    });

    const data = await response.json();
    if (data.status) {
      setOuterSlides(data.data.outer_slides);
      setFullsections(data.data.sections);
    } else {
      setOuterSlides([]);
      setFullsections([]);
    }
  }

  useEffect(() => {
    fetchCourseDetails();
    fetchMaterials();
    console.log("0");
  }, []);

  let totalSlides = outer_slides.length;
  let slideCompleted = 0;
  outer_slides.forEach((sl) => {
    if (sl.completed) {
      ++slideCompleted;
    }
  })

  if (fullsections.length > 0) {
    let Slidewithsection = 0;

    fullsections.forEach((section) => {
      Slidewithsection += section.slides.length;
      section.slides.forEach((slide) => {
        if (slide.completed) {
          ++slideCompleted;
        }
      })
    });

    totalSlides += Slidewithsection;
  }

  const remainingSlides = totalSlides - slideCompleted;

  const data = {
    labels: ['Completed Slides', 'Remaining Slides'],
    datasets: [
      {
        data: [slideCompleted, remainingSlides],
        backgroundColor: ['#36A2EB', '#FF6384'],
        hoverBackgroundColor: ['#36A2EB', '#FF6384'],
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    aspectRatio: 1,
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const currentValue = dataset.data[tooltipItem.index];
          const totalValue = dataset.data.reduce((accumulator, currentValue) => accumulator + currentValue);
          const percentage = ((currentValue / totalValue) * 100).toFixed(2);
          return `${data.labels[tooltipItem.index]}: ${currentValue} (${percentage}%)`;
        },
      },
    },
  };

  return (
    <>
      <Helmet>
        <title>{'EduKula - Course Progress'}</title>
      </Helmet>
      <div className="col-lg-9 home-section">
        <div className="custom-user-profile-content custom-learning-circle-content tutor_dashboard">
          <div className="custom-learnig-circle-head">
            <h4 className="generate-report">Course Progress</h4>
            <Link className="btn btn-circle btn-md submit-pink-btn back-button-course float-right pl-3 pr-3" to={`/${'learn-course'}/${_slug}`}><i className="fa fa-arrow-left" aria-hidden="true"></i> Back to Course</Link>
          </div>
          <section className="enroll_detail">

            <div className="container pl-0">
              <h3 className="top_title w-100 clearfix">{course?.title}
              </h3>

            </div>
            <div className="container">
              <div style={{ width: '300px', height: '300px' }}>
                <Doughnut data={data} options={options} />
              </div>
              <div className=''>
                {/* <ReactSpeedometer
                  segments={3}
                  needleColor={'#ef403d'}
                  forceRender={true}
                  segmentColors={['#EA425C', '#F5CD19', '#5BE12C']}
                  customSegmentLabels={[
                    {
                      text: "",
                    },
                    {
                      text: "",
                    },
                    {
                      text: "",
                    },
                  ]}
                  minValue={1}
                  maxValue={10}
                  value={6}
                  currentValueText={''}
                /> */}
              </div>
            </div>

            <div className='container'>
              {/* <h5>Course Details</h5> */}
              <h6>Total slides:- {totalSlides}</h6>
              <h6>Completed slides:- {slideCompleted}</h6>
              {/* <p>
                Total Sections :- {course?.course_material_sections?.total_count}
              </p> */}

              {/* {
                outer_slides && outer_slides.length > 0 &&
                outer_slides.map((outerslide, index) => {
                  return (
                    <>
                      <p key={index}>
                        <b>  {outerslide.title}</b>
                        {
                          outerslide.completed && <i className='ml-2'>Completed</i>
                        }
                      </p>
                    </>
                  )
                })
              }
              {
                fullsections && fullsections.length > 0 &&
                fullsections.map((section, index) => {
                  return (
                    <>
                      <h4> {section.title}</h4>
                      <p>
                        Duraction:- {section.duration} </p>
                      <p> Total Slide in Section:- {section.slide_count}</p>
                      <p> Slides Completed:- {section.completed}</p>
                      {
                        section.slides && section.slides !== '' &&
                        section.slides.map((slide, index) => {

                          return (
                            <>
                              <p key={index}>
                                <b>{slide.title}</b>
                                {
                                  slide.completed && <i className='ml-2'>Completed</i>
                                }
                              </p>
                              <p>
                                {slide.duration}
                              </p>
                            </>
                          )
                        })
                      }
                    </>
                  )
                })
              } */}
            </div>
          </section>
        </div>
      </div>
    </>
  )
}

export default CourseProgress;
