import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { API_BASE, DEFAULT_UPLOAD_VIDEO_IMG, VIDEO_EXT, VIDEO_TXT } from "../../../constants/global";
import { Toaster } from "../../../entity/GlobalJS";
import TextareaAutosize from 'react-textarea-autosize';

const PostVideoPopup = (props) => {
    const defaultImg = DEFAULT_UPLOAD_VIDEO_IMG;
    const [video, setVideo] = useState(defaultImg);
    const [file, setFile] = useState('');
    const [videoValidationMsg, setVideoValidationMsg] = useState(0);
    const [upFileClass, setUpFileClass] = useState('');
    const _token = localStorage.getItem('lmstoken');
    const { unique_id } = useParams();
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [submitting, setSubmitting] = useState(false);

    const refreshCaption = () => {
        document.getElementById('capti').focus();
    }

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'video/*': []
        },
        maxFiles: 1,
        onDrop: (acceptFile) => {
            const fileObj = acceptFile[0];

            if (!VIDEO_EXT.exec(fileObj.path)) {
                Toaster.error(VIDEO_TXT);
                removeVideo();
                return;
            }

            setVideo(URL.createObjectURL(fileObj));
            setFile(fileObj);
            setVideoValidationMsg(0);
            setUpFileClass('upfile_img_vdo');
        },
    });

    const removeVideo = () => {
        setVideo(defaultImg);
        setFile('');
        setVideoValidationMsg(1);
        setUpFileClass('');
    }

    const savePost = async (form) => {

        setVideoValidationMsg(0);
        if (typeof file === 'string') {
            setVideoValidationMsg(1);
            return false;
        }
        const formData = new FormData();
        formData.append('group_id', unique_id);
        formData.append('post_type', 2);
        formData.append('caption', form.caption);

        if (file !== '') {
            formData.append('image_video', file);
        }

        setSubmitting(true);
        const response = await fetch(`${API_BASE}post-create-feed`, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + _token
            },
            body: formData
        });

        const data = await response.json();
        if (data.status) {
            setSubmitting(false);
            props.feedPosted(data.data.feed);
            Toaster.success(data.message);
            document.getElementById('close_popup_video').click();
            props.setCaption('');
            setValue('caption', '');
            removeVideo();
            setVideoValidationMsg(0);
            setUpFileClass('');
        } else {
            setSubmitting(false);
            Toaster.error(data.message);
        }
    }

    return (
        <>
            <div className="modal fade" id="videoModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <form onSubmit={handleSubmit(savePost)}>
                            <div className="modal-header">
                                <h4 className="modal-title">Create Post</h4>
                                <button type="button" className="close" id="close_popup_video" data-dismiss="modal" aria-label="Close"
                                    onClick={() => { props.setCaption(''); setValue('caption', ''); removeVideo(); setVideoValidationMsg(0); setUpFileClass(''); }}>
                                    <span aria-hidden="true">X</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="feed_prof_icon">
                                    <div className="img">
                                        <img src={props.profile} alt={props.user.first_name} />
                                    </div>
                                    <h3><a href={`/profile-details/${props.user.slug}`}>{props.user.first_name} {(props.user.last_name !== 'null' && props.user.last_name !== null) ? ' ' + props.user.last_name : ''}</a></h3>
                                </div>
                                <div className="form_box">
                                    <div className="form-group">
                                        <TextareaAutosize {...register("caption", {
                                            //required: "required",
                                            maxLength: {
                                                value: 1000,
                                                message: "Caption can not be more than 1000 charaters."
                                            }
                                        })} minRows={3} maxRows={5} id="capti" onChange={(e) => { props.setCaption(e.target.value); }} type="text" className="form-control" placeholder={"What's on your mind, " + props.user.first_name} value={props.caption ? props.caption : ''} />
                                        <span className="add-on max-chr-counter chrchk-capti" data-ele="capti" data-limit="1000" data-showcounter="true"></span>
                                        <span className="text-danger">
                                            {errors.caption && errors.caption.type === "required" && 'Please enter caption'}
                                            {errors.caption && errors.caption.type !== "required" && errors.caption.message}
                                        </span>
                                    </div>
                                    <div className="clearfix"></div>
                                    <div className="prev_imgbox">
                                        {
                                            defaultImg !== video
                                            &&
                                            <div onClick={(e) => { removeVideo(); }} className="btnGrp">
                                                <i className="fa fa-trash" aria-hidden="true"></i>
                                            </div>
                                        }
                                        <div className="form-group">
                                            {
                                                defaultImg !== video
                                                &&
                                                <div className={upFileClass}>
                                                    <video controls>
                                                        <source src={video} type="video/mp4" />
                                                        Sorry, your browser doesn't support embedded videos.
                                                    </video>
                                                </div>
                                            }

                                            {
                                                defaultImg === video
                                                &&
                                                <div {...getRootProps()} className={upFileClass}>
                                                    <img src={video} alt='preview' onClick={() => refreshCaption()} />
                                                    <input {...getInputProps()} />
                                                </div>
                                            }
                                            <span className="text-danger">{videoValidationMsg === 1 && 'Please select a video for the post'}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button disabled={submitting} type="submit" name="submit" id="submit" className="btn submit-pink-btn">
                                    {submitting ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : 'Submit'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PostVideoPopup;
