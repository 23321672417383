import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { API_BASE } from "../constants/global";

const SocialLinks = (props) => {
    const [links, setLinks] = useState('');

    useEffect(() => {
        fetchLinks();
        // eslint-disable-next-line 
    }, []);

    const fetchLinks = async () => {
        const response = await fetch(`${API_BASE}get-social-links`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        });

        const data = await response.json();
        if (data.status) {
            setLinks(data.data.social_links);
        } else {
            setLinks('');
        }
    }
    
    return (
        <>
            {
                props.isFooter !== "yes"
                &&
                <div className="login-footer-icons">
                    <ul>
                        {
                            links !== ''
                            &&
                            links.map((link, index) => 
                                <a key={index} href={link.url} className="btn btn-circle btn-login-icon"><i className={link.icon}></i></a>
                            )
                        }
                    </ul>
                </div>
            }

            {
                props.isFooter === "yes"
                &&
                <div className="social-icons">
                    {
                        links !== ''
                        &&
                        links.map((link, index) => 
                            <a key={index} href={link.url} target="_blank" className="btn btn-circle btn-success"><i className={link.icon}></i></a>
                        )
                    }
                </div>
            }

        </>
    );
}

export default SocialLinks;