import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { API_BASE } from "../../constants/global";
import { Toaster } from "../../entity/GlobalJS";
import { Helmet } from "react-helmet";

const StudentReport = () => {
    const _token = localStorage.getItem('lmstoken');
    const userData = JSON.parse(localStorage.getItem('lmsuser'));
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [submitting, setSubmitting] = useState(false);
    const [file, setFile] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if(userData?.is_corporate === 1)
        {
            navigate('/dashboard/home');
        }
    }, [])
    const clearForm = () => {
        setValue('timezone', userData?.timezone);
        setValue('title');
        setValue('agenda');
        setValue('date');
        setValue('time');
        setValue('duration');
    }

    const setEndDate = (e) => {
        document.getElementById(`end_date`).setAttribute('min', e.target.value);
    }

    const exportData = async (form) => {
        const response = await fetch(`${API_BASE}reports/student-course`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
            body: JSON.stringify(form)
        });

        const data = await response.json();
        if (data.status) {
            let download = data.data?.file_path ? data.data?.file_path : '';
            setFile(download)
            Toaster.success(data.message);
        } else {
            setFile('')
            Toaster.error(data.message);
        }
    }

    return (
        <>
            <Helmet>
                <title>{'EduKula - Student Report'}</title>
            </Helmet>
        <div className="tab-pane fade show active" id="pills-stReport" role="tabpanel" aria-labelledby="pills-stReport-tab">
            <div className="blog-card">
                <div className="row">
                    <div className="col-md-12 pb-5">
                        <form onSubmit={handleSubmit(exportData)}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="start_date"><b>Enrolment date from</b></label>
                                        <input {...register("start_date")} id="start_date" type="date" className="form-control" onChange={(e) => { setEndDate(e) }} placeholder="Date*" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="end_date"><b>Enrolment date to</b></label>
                                        <input {...register("end_date")} id="end_date" type="date" className="form-control" placeholder="Date*" />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor=""><b>Export Included :</b> </label><br />
                                        <label htmlFor="not_started">
                                            <input {...register("not_started")} id="not_started" type="checkbox" name="not_started" className="" />
                                            &nbsp;Not Started
                                        </label>&nbsp;&nbsp;
                                        <label htmlFor="in_progress">
                                            <input {...register("in_progress")} id="in_progress" type="checkbox" name="in_progress" className="" />
                                            &nbsp;In Progress
                                        </label>&nbsp;&nbsp;
                                        <label htmlFor="completed">
                                            <input {...register("completed")} id="completed" type="checkbox" name="completed" className="" />
                                            &nbsp;Completed
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="send_email"><b>Share CSV file via email ? :</b> </label>&nbsp;&nbsp;
                                        <input {...register("send_email")} id="send_email" type="checkbox" name="send_email" className="" />
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-circle btn-md submit-pink-btn">Export CSV</button>
                        </form><br />
                        {
                            file !== ''
                            &&
                            <p><a href={file} className="dlt_btn" download>Click to download</a></p>
                        }
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default StudentReport;
