import { useState } from "react";
import { Link } from "react-router-dom";
import { API_BASE } from "../../../../../constants/global";
import { SwalBox, Toaster } from "../../../../../entity/GlobalJS";
import Comments from "./Comments";

const QueryCard = ({ index, query, course_id }) => {
    const _token = localStorage.getItem('lmstoken');
    const [queryX, setQueryX] = useState(query);

    const ConfirmDeleteQuery = (id) => {
        SwalBox.fire({
            title: 'Are you sure?',
            text: 'Sure to delete query?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteQuery(id);
            }
        });
    }

    const deleteQuery = async (id) => {
        const response = await fetch(`${API_BASE}delete-course-query/${id}`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            setQueryX('');
        } else {
            Toaster.error(data.message);
        }
    }

    return (
        <>
            {
                queryX !== ''
                &&
                <div className="feed_post">
                    <div className="custom-learnig-circle-head">
                        <div className="feed_prof_icon">
                            <div className="img">
                            <Link to={`/profile-details/${query.user.slug}`}><img src={query.user.image} alt="" /></Link>
                            </div>
                            <h3><Link to={`/profile-details/${query.user.slug}`}>{query.user.name}</Link> <span>{query.date_time}</span></h3>
                            {
                                query.is_my_comment === 1
                                &&
                                <>
                                    <a className="ellipsbtn" id={`${query.id}_MaterialsMenuButton`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fas fa-ellipsis-v"></i></a>
                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="MaterialsMenuButton">
                                        <div className="buttons_action">
                                            <a onClick={(e) => { ConfirmDeleteQuery(query.id); }} className="delete_comment dropdown-item cursor-pointer">Delete</a>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                        <div className="caption">
                            <p>{query.query}</p>
                        </div>
                        <div className="clearfix"></div>

                        <Comments course_id={course_id} query={query} comment_count={query.comment_count} />
                    </div>
                    <div className="clearfix"></div>
                </div>
            }
        </>
    );
}

export default QueryCard;