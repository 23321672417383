import React from "react";
import { useState, useEffect, useCallback, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { API_BASE } from "../../../constants/global";
import { useLocation, Link, useNavigate } from "react-router-dom";
import ReactTags from "react-tag-autocomplete";
import { Helmet } from "react-helmet";
import { SwalBox, Toaster } from "../../../entity/GlobalJS";
import EduKulaAi from "../../../entity/EdukulaAi";


function QuizBank() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
    unregister,
  } = useForm();
  const [draftCourse, setDraftCourse] = useState("");
  const [courseCategories, setCourseCategories] = useState("");
  const [loadingdata, setLoadingdata] = useState(true);
  const [quizfrom, setQuizfrom] = useState(1);
  const [tags, setTags] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [aiquiz, setAiquiz] = useState(false);
  const [result_heading_title_text, setResultHeadingTitleText] = useState('');
  const [result_points_text, setResultPointsText] = useState('');
  const [assesement_result_text, setAssesementResultText] = useState('');
  const [no_questions, setNumberofquestions] = useState('1');
  const navigate = useNavigate();





  const location = useLocation();
  let raw_courses = [];
  const _token = localStorage.getItem("lmstoken");

  const reactTags = useRef();
  const onDelete = useCallback(
    (tagIndex) => {
      setTags(tags.filter((_, i) => i !== tagIndex));
    },
    [tags]
  );

  const onAddition = useCallback(
    (newTag) => {
      setTags([...tags, newTag]);
    },
    [tags]
  );





  const handleAiQuiz = async (question,op1,op2,op3,op4,right_answer, tags) => {
    const formData = new FormData();
    formData.append('question', question);
    formData.append('question_image', null);
    formData.append('answer1_image', null);
    formData.append('answer2_image', null);
    formData.append('answer3_image', null);
    formData.append('answer4_image', null);
    formData.append('answer_type', 'text');
    formData.append('question_type', 'text');
    formData.append('question_level', 'Easy');
    formData.append('question_tags', tags);
    formData.append('answer1', op1);
    formData.append('answer1_description', '');
    formData.append('answer2', op2);
    formData.append('answer2_description', '');
    formData.append('answer3', op3);
    formData.append('answer3_description', '');
    formData.append('answer4', op4);
    formData.append('answer4_description', '');
    formData.append('is_true', right_answer);
    formData.append('question_video', null);

    var sURL = `${API_BASE}save-quiz-bank-question`;
      const response = await fetch(sURL, {
      method: 'POST',
      headers: {
      "Authorization": "Bearer " + _token
      },
      body: formData
    });

    const data = await response.json();
    if (data.status) {
      

    }


  }



























  const saveQuizBank = async (form) => {
    let _ref_keyword = '';
    if(quizfrom == '1'){
      _ref_keyword = form.course_id;
    }else{
      _ref_keyword = form.prompts;
    }
    
    SwalBox.fire({
      title: 'Please wait...',
      text:'Your questions are being generated... please wait',
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        SwalBox.showLoading();
      }
    });
    let no_questions = 10;
    let area_assessment = _ref_keyword;

    let _generate_prompt = '';
    _generate_prompt += 'I want you to generate the exact prompt for me using given input. The info might be a sentence or just a single word.';
    _generate_prompt += 'You will have to figure out the primary goal or the subject area and then generate below prompt for me. You must follow the exact pattern.';
    _generate_prompt += 'Input : AWS';
    _generate_prompt += 'Output : ';
    _generate_prompt += 'I want you to generate '+ no_questions +' multi-choice question(s) to test my knowledge in AWS and Cloud Computing.';
    _generate_prompt += 'Generate a multiple-choice question with the following pattern:';
    _generate_prompt += 'Question: [Insert your question here] \nA. [Option A] \nB. [Option B] \nC. [Option C] \nD. [Option D] \nAnswer: [Correct option]';
    _generate_prompt += '\nPlease follow the regex pattern given above and give me straigt forward output no initial messages. The output must start with generated questions. You must add "***" at the end of each generated entry';
    
    _generate_prompt += 'Input : Selling products and improving business';
    _generate_prompt += 'Output : ';
    _generate_prompt += 'I want you to generate '+ no_questions +' multi-choice question(s) to test my knowledge in Sales and Marketing.';
    _generate_prompt += 'Generate a multiple-choice question with the following pattern:';
    _generate_prompt += 'Question: [Insert your question here] \nA. [Option A] \nB. [Option B] \nC. [Option C] \nD. [Option D] \nAnswer: [Correct option]';
    _generate_prompt += '\nPlease follow the regex pattern given above and give me straigt forward output no initial messages. The output must start with generated questions. You must add "***" at the end of each generated entry';
    
    _generate_prompt += 'Input: Algebra';
    _generate_prompt += 'Output : ';
    _generate_prompt += 'I want you to generate '+ no_questions +' multi-choice question(s) to test my knowledge in Algebra.';
    _generate_prompt += 'Generate a multiple-choice question with the following pattern:';
    _generate_prompt += 'Question: [Insert your question here] \nA. [Option A] \nB. [Option B] \nC. [Option C] \nD. [Option D] \nAnswer: [Correct option]';
    _generate_prompt += '\nPlease follow the regex pattern given above and give me straigt forward output no initial messages. The output must start with generated questions. You must add "***" at the end of each generated entry';
    
    _generate_prompt += 'Input : ';
    _generate_prompt += area_assessment;


      EduKulaAi.createCompletion({
          model: "text-davinci-003",
          prompt: _generate_prompt,
          temperature: 0.1,
          max_tokens: 4097-(_generate_prompt.length),
      }).then((pcompletion) => {
          const responsePrompt = pcompletion.data.choices[0].text;
          let _prompt_string = '';
          _prompt_string = responsePrompt;
          setNumberofquestions(no_questions);
              EduKulaAi.createCompletion({
                  model: "text-davinci-003",
                  prompt: _prompt_string,
                  temperature: 0.1,
                  max_tokens: 4097-(_prompt_string.length),
              }).then((completion) => {
                  const responseText = completion.data.choices[0].text;

                  const _questions = responseText.split("***");
                  var _questionbank = [];
                  var _aiquestionbank = [];
                  var _counter = 1;
                  _questions.forEach(function(qtemp) { 
                      let q = qtemp.trim();
                      
                      const _answers = q.split("\n");
                          if(_answers){
                            console.info(_answers);
                            if(_counter > 9){
                              Toaster.success('Questions are added successfully');
                              navigate("/dashboard/tutor/quizbank");
                            }
                              if(typeof(_answers[5]) != 'undefined'){
                                  var _temp_q       = _answers[0];
                                  var _temp_op1     = _answers[1];
                                  var _temp_op2     = _answers[2];
                                  var _temp_op3     = _answers[3];
                                  var _temp_op4     = _answers[4];
                                  var _temp_a_text  = _answers[5];

                                  var is_true = 1;
                                  if(_temp_a_text.includes("Answer: A") == true){ var _temp_a     = 'A'; is_true = 1;}
                                  if(_temp_a_text.includes("Answer: B") == true){ var _temp_a     = 'B'; is_true = 2;}
                                  if(_temp_a_text.includes("Answer: C") == true){ var _temp_a     = 'C'; is_true = 3}
                                  if(_temp_a_text.includes("Answer: D") == true){ var _temp_a     = 'D'; is_true = 4}
        
                                  handleAiQuiz(_temp_q, _temp_op1, _temp_op2, _temp_op3, _temp_op4,is_true, form.tags);

                                  _counter++;

                                }
                          }
                  });
                  setAiquiz({
                      "quizSynopsis":"Your Quiz for - "+area_assessment+ ' is ready',
                      "nrOfQuestions": no_questions,
                      "questions": _aiquestionbank,
                       "appLocale": {"resultPageHeaderText":result_heading_title_text, "resultPagePoint": result_points_text}
                  });
                  SwalBox.close();
              });

      }).catch((error) => {

      });




      const handleSendQuizWithImage = async (form) => {


      }









    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    // setLoadingdata(true);
    // setLoadingdata(false);
    const newTags = tags.map((tag, inx) => tag.name);
    let newForm = form;
    if (newTags.length > 0) {
      newForm = { ...form, tags: newTags };
    }
    console.log(newForm);
    //alert("Generate Quiz");
  };

  const fetchCategories = async () => {
    setLoadingdata(true);
    const response = await fetch(`${API_BASE}get-category-list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + _token,
      },
    });

    const data = await response.json();
    if (data.status) {
      let Categories = [];
      let Cate = data.data.course_categories;
      Cate.forEach(function (q) {
        let tempCate = {
          categories_id: q.id,
          categories_title: q.title,
        };

        Categories.push(tempCate);
      });
      setCourseCategories(Categories);
      setLoadingdata(false);
    } else {
    }
  };

  const fetchPublishedCourses = async (page) => {
    const response = await fetch(
      `${API_BASE}get-tutor-published-course-list?limit=50&page=${page}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${_token}`,
        },
      }
    );

    const data = await response.json();
    if (data.status) {
      let publishedCourse = data.data.courses;
      publishedCourse.forEach(function (q) {
        let tempDraft = {
          course_id: q.id,
          course_title: q.title,
        };

        raw_courses.push(tempDraft);
      });
      setDraftCourse(raw_courses);
      setLoadingdata(false);
    }
  };

  const fetchDraftCourses = async (page) => {
    setLoadingdata(true);
    const response = await fetch(
      `${API_BASE}get-tutor-drafted-course-list?limit=50&page=${page}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${_token}`,
        },
      }
    );

    const data = await response.json();
    if (data.status) {
      let draftcourse = data.data.courses;
      draftcourse.forEach(function (q) {
        let temp_draft = {
          course_id: q.id,
          course_title: q.title,
        };

        raw_courses.push(temp_draft);
      });
      fetchPublishedCourses(0);
    }
  };

  

  const generateQuestions = async () => {
    SwalBox.fire({
      title: 'Please wait...',
      text:'Your questions are being generated... please wait',
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
          SwalBox.showLoading();
      }
     });
    
    
    
    
    
    
    
    //alert('Generate Questions');
    
    
    
    
    
    
    
    /*
    const response = await fetch(`${API_BASE}get-course-form-data-stepOne/${course_id}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": `Bearer ${_token}`
        },
    });

    const data = await response.json();

    if (data.status) {
        let _current_lmsuser = JSON.parse(localStorage.getItem('lrsuser'));
        setCurrentUserType(_current_lmsuser.is_corporate);
        setCourse(data.data);
        setGradingEnable(data?.data?.is_gradable);
    } else {
        navigate('/dashboard/tutor/published-courses');
        Toaster.error(data.message);
    }
    */
}


  useEffect(() => {
    fetchCategories();
    fetchDraftCourses(0);
  }, [location]);

  const handleChange = (event) => {
    var radioSelect = event.target.value == 1 ? 1 : 0;
    setQuizfrom(radioSelect);
    if (radioSelect === 0) {
      unregister("course_id");
    }
    if (radioSelect === 1) {
      unregister("tags");
      unregister("prompts");
    }
  };

  return (
    <>
      <Helmet>
        <title>{"EduKula - Add Questions"}</title>
      </Helmet>
      <div className="col-lg-9 home-section">
        <div className="custom-user-profile-content custom-learning-circle-content chatPage vacancypage">
          <div className="custom-learnig-circle-head">
            <h4>Add Questions</h4>
            <Link to={"/dashboard/tutor/quizbank"}>
              <button type="button" className="close">
                <i className="fa fa-arrow-left" aria-hidden="true"></i>
              </button>
            </Link>
          </div>

          <div className="modal-body">
            <p className="QBlable QBlablemain" htmlFor="Quizfrom">
              Generate quiz :-{" "}
            </p>
            <div className="form-check-inline">
              <label className="form-check-label">
                <input
                  type="radio"
                  onChange={(event) => {
                    handleChange(event);
                  }}
                  className="form-check-input"
                  name="Quizfrom"
                  defaultValue={1}
                  checked={quizfrom === 1 ? "checked" : ""}
                />
                Course
              </label>
            </div>

            <div className="form-check-inline">
              <label className="form-check-label">
                <input
                  type="radio"
                  onChange={(event) => {
                    handleChange(event);
                  }}
                  className="form-check-input"
                  name="learning_email"
                  defaultValue={0}
                  checked={quizfrom === 0 ? "checked" : ""}
                />
                Keyword
              </label>
            </div>

            <form onSubmit={handleSubmit(saveQuizBank)}>
              <div className="form-group adq tagdiv">
                <label className="QBtags">Add tags :- </label>
                <input
                      id="tags"
                      type="text"
                      {...register("tags", {
                      })}
                      className="form-control"
                      placeholder="Please add tags"
                      autoFocus
                    />
              </div>

              {quizfrom === 1 ? (
                <>
                  <div className="form-group adq">
                    <label className="QBlable">Select Course :- </label>
                    <select
                      {...register("course_id", {
                        required: "required",
                      })}
                      className="QBSelect"
                    >
                      <option value="">Select Course</option>
                      {draftCourse !== "" &&
                        draftCourse.map((section, index) => (
                          <option key={index} value={section.course_title}>
                            {section.course_title}
                          </option>
                        ))}
                    </select>
                    <span className="text-danger">
                      {errors.course_id &&
                        errors.course_id.type === "required" &&
                        "Please select the course"}
                    </span>
                  </div>
                </>
              ) : (
                <>
                  <div className="form-group adq tagdiv">
                    <label className="QBtags">Custom Prompt :- </label>
                    {/* <input type='text' */}
                    <input
                      id="prompts"
                      type="text"
                      {...register("prompts", {
                        required: "required",
                      })}
                      className="form-control"
                      placeholder="Example. : Generate  multi-choice question(s) to test my students' knowledge in Sales and Marketing"
                      autoFocus
                    />
                  </div>
                </>
              )}

              <div className="QuizBankSave">
                <button
                  type="submit"
                  className="btn btn-circle btn-md submit-pink-btn"
                  disabled={loadingdata}
                >
                  {loadingdata ? (
                    <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                  ) : (
                    "Generate Questions"
                  )}
                </button>
              </div>
            </form>
          </div>
          <div
            className="modal fade"
            id="GenerateQuestionDia"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="GenerateQuestionDia"
            aria-hidden="true"
            data-backdrop="static"
            data-keyboard="false"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-ek-lg-1200"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h4 id="GenerateQuestionDiaTitle">Generate Questions</h4>
                  <button
                    type="button"
                    id="GenerateQuestionDiaClose"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="form-group" style={{ textAlign: "center" }}>
                    <h5>Generate Questions</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default QuizBank;
