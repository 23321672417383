import React from 'react';
import HiringReport from './HiringReport';
import TrainingReport from './TrainingReport';
import { Helmet } from "react-helmet";

function CorporateReport() {



  return (
    <>
      <Helmet>
                <title>{'EduKula - Corporate Report'}</title>
            </Helmet>
      <div className="col-lg-9 home-section">
        <div className="custom-user-profile-content custom-learning-circle-content chatPage vacancypage">
          <div className="custom-learnig-circle-head">
            <h4>Corporate Report</h4>
          </div>
          <div className="chatbox">
            <div className="learning-circle-courses">
              <section>
                <div className="explore-section">
                  <div className="container">
                    <div className="explore-block">
                      <ul className="nav nav-pills" id="pills-tab" role="tablist">
                        <li className="nav-item">
                          <a className="nav-link active" id="pills-hiring-tab" data-toggle="pill" href="#pills-hiring" role="tab" aria-controls="pills-hiring" aria-selected="true">Hiring Report</a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" id="pills-training-tab" data-toggle="pill" href="#pills-training" role="tab" aria-controls="pills-training" aria-selected="true">Training Report</a>
                        </li>
                      </ul>
                      <div className="tab-content" id="pills-tabContent">

                        <div className="tab-pane fade show active" id="pills-hiring" role="tabpanel" aria-labelledby="pills-hiring-tab">
                          <div className="course-tab-contents">
                            <div className="course-tabs-pane-block px-2 py-3">
                              <div className="course-tabs-body">
                                <HiringReport />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="tab-pane fade" id="pills-training" role="tabpanel" aria-labelledby="pills-training-tab">
                          <div className="course-tab-contents">
                            <div className="course-tabs-pane-block px-2 py-3">
                              <div className="course-tabs-body">
                                <TrainingReport />
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CorporateReport;
