import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { RolesSchema } from "../schemas";
import { API_BASE } from "../../../constants/global";
import { Toaster, SwalBox } from "../../../entity/GlobalJS";
import { useState, useEffect } from "react";
import { event } from "jquery";
import { Helmet } from "react-helmet";

const initialValues = {
    'role_title': "",
    'manage_employees' : false,
    'manage_roles' : false,
    'manage_reports' : false,
    'manage_courses' : false,
    'manage_vacancies' : false,
    'manage_circles' : false,
}





const RolesDetail = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { role_id } = useParams();
    const urlState = location.state;
    const _token = localStorage.getItem('lmstoken');
    const [role, setRole] = useState('');
    const [processing, setProcessing] = useState(false);
    const [formValues, setFormValues] = useState({
        'role_title': "",
        'manage_employees' : false,
        'manage_roles' : false,
        'manage_reports' : false,
        'manage_courses' : false,
        'manage_vacancies' : false,
        'manage_circles' : false,
    });



    useEffect(() => {
        fetchRole();
        //alert(role_id);
        /*
        fetchRole();
        if (vacancy_id !== '' && vacancy_id !== null && typeof vacancy_id !== 'undefined') {
            fetchVacancy(vacancy_id);
            setEdit(vacancy_id)
        } else {
            setEdit('')
            if (urlState !== null) {
                setFormValues({ ...formValues, course_id: urlState?.course_slug });
            }
        }
        */
    }, []);

    const fetchRole = async (page = 0, search = "") => {
        const response = await fetch(`${API_BASE}role/detail/${role_id}?page=${page}&search=${search}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });
        const data = await response.json();
        if (data.status) {
            let curr_role = data.data.role;
            setFormValues({
                'role_title': curr_role.role_name,
                'manage_employees' : (curr_role.manage_employees == '1') ? true : false,
                'manage_roles' : (curr_role.manage_roles  == '1') ? true : false,
                'manage_reports' : (curr_role.manage_reports  == '1') ? true : false,
                'manage_courses' : (curr_role.manage_courses  == '1') ? true : false,
                'manage_vacancies' : (curr_role.manage_vacancies == '1') ? true : false,
                'manage_circles' : (curr_role.manage_circles == '1') ? true : false
            });
        } else if (data.message === "Server Error") {
            SwalBox.fire({
                title: "Link is invalid",
                text: "It seems that the link is not valid. Please try again.",
                icon: "error",
            });
            setTimeout(() => {
                SwalBox.close();
            }, 3000);
            navigate('/dashboard/roles');
            setRole('');
        } else {
            setRole('');
        }
    }



    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        enableReinitialize: true,
        initialValues: formValues ? formValues : initialValues,
        validationSchema: RolesSchema,
        onSubmit: async (values, action) => {
            setProcessing(true);
            //var formData = new FormData();
            //formData.append('values', values.role_title);
            var roleData        = {};
            roleData            = values;
            const response = await fetch( `${API_BASE}role/save/${role_id}`, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${_token}`
                },
                body: JSON.stringify(roleData)
            });
            const data = await response.json();
            if (data.status) {
                Toaster.success(data.message);
                action.resetForm();
                setProcessing(false);
                navigate('/dashboard/roles');
            }
        }
    });


    return (
        <>
            <Helmet>
                <title>{'EduKula - Roles'}</title>
            </Helmet>
            <div className="col-lg-9 home-section">
                <div className="custom-user-profile-content custom-learning-circle-content tutor_dashboard vacancypage">
                    <div className="custom-learnig-circle-head">
                        <h4>Edit Role</h4>
                        <Link className="btn btn-circle btn-info" to={"/dashboard/roles"}>Back to Listing</Link>
                    </div>
                    <div className="learning-circle-courses">
                <section>
                    <div className="explore-section">
                        <div className="container">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label><b>Role Name</b></label>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                name="role_title"
                                                id="role_title"
                                                placeholder="e.g. HR"
                                                className="form-control"
                                                autoFocus
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.role_title}
                                            />
                                            {
                                                (errors.role_title && touched.role_title) &&
                                                <span className="text-danger">{errors.role_title}</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12"><div className="form-group"><label><b>Allowed Modules</b></label></div></div>
                                    <div className="col-4"><div className="form-group"><input onChange={handleChange} name="manage_employees" id="cb-manage-employees" type="checkbox" value={values.manage_employees} checked={values.manage_employees} />&nbsp;Manage Employees</div></div>
                                    <div className="col-4"><div className="form-group"><input onChange={handleChange} name="manage_roles" id="cb-manage-roles" type="checkbox" value={values.manage_roles} checked={values.manage_roles} />&nbsp;Manage Roles</div></div>
                                    <div className="col-4"><div className="form-group"><input onChange={handleChange} name="manage_reports" id="cb-manage-reports" type="checkbox" value={values.manage_reports} checked={values.manage_reports} />&nbsp;Download Reports</div></div>
                                    <div className="col-4"><div className="form-group"><input onChange={handleChange} name="manage_courses" id="cb-manage-courses" type="checkbox" value={values.manage_courses} checked={values.manage_courses} />&nbsp;Manage Courses</div></div>
                                    <div className="col-4"><div className="form-group"><input onChange={handleChange} name="manage_vacancies" id="cb-manage-vacancies" type="checkbox" value={values.manage_vacancies} checked={values.manage_vacancies} />&nbsp;Manage Vacancies</div></div>
                                    <div className="col-4"><div className="form-group"><input onChange={handleChange} name="manage_circles" id="cb-manage-circles" type="checkbox" value={values.manage_circles} checked={values.manage_circles} />&nbsp;Manage Learning Circles</div></div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <Link className="btn btn-circle btn-md cancel-btn" to={"/dashboard/roles"}>Cancel</Link>
                                        <button type="submit" disabled={processing} className="btn btn-circle btn-md submit-pink-btn">
                                        {
                                            processing
                                            ?
                                            <i className="fa fa-refresh fa-spin fa-fw"></i>
                                            :
                                            'Submit'
                                        }
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>

                </div>
            </div>
        </>
    )
}

export default RolesDetail;