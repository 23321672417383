import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API_BASE } from "../constants/global";
import { SwalBox, Toaster } from "../entity/GlobalJS";
import RateStart from "../components/RateStart";


const SlideList = ({ course, handleCertified }) => {
    const { _slug } = useParams();
    const _token = localStorage.getItem('lmstoken');
    const [outer_slides, setOuterSlides] = useState('');
    const [sections, setSections] = useState('');
    const [active_lession, setActiveLession] = useState('');
    const [refresh, setRefresh] = useState(0);
    const [showFinish, setShowFinish] = useState(false);
    const [certified, setCertified] = useState(false);
    const rateArrays = [1, 2, 3, 4, 5];
    const [my_rating, setMyRating] = useState(0);
    const [feedbackMessage, setFeedbackMessage] = useState('');


    useEffect(() => {
        fetchMaterials();
        handleCertified(certified);
    }, [refresh, certified]);

    const fetchMaterials = async () => {
        const response = await fetch(`${API_BASE}get-learning-materials/${_slug}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();
        if (data.status) {
            setOuterSlides(data.data.outer_slides);
            setSections(data.data.sections);
            setShowFinish(data.data.showFinish);
            setCertified(data.data.certified);
        } else {
            setOuterSlides('');
            setSections('');
        }
    }

    const updateProgress = async (e, id) => {
        var forData = new FormData();
        forData.append('course_id', course.id);
        forData.append('slide_id', id);

        const response = await fetch(`${API_BASE}update-progress/${e.target.checked}`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: forData
        });

        const data = await response.json();
        if (data.status) {
            if (data.data.completed) {
                Toaster.success(data.message);
                setRefresh(!refresh);
            } else {
                setRefresh(!refresh);
            }
        } else {
            Toaster.error(data.message);
        }
    }

    const finishCourse = () => {
        SwalBox.fire({
            title: 'Are you sure?',
            text: `This will complete the course and you won't receive any future updates or attend future sessions.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                finishCourseProcess();
            }
        });
    }

    const finishCourseProcess = async () => {

        var forData = new FormData();
        forData.append('course_id', course.id);

        const response = await fetch(`${API_BASE}finish-course`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: forData
        });

        const data = await response.json();
        if (data.status) {
            if (data.data.completed) {
                Toaster.success(data.message);
                setRefresh(!refresh);
                document.getElementById('ratingModalFinishCourseclick').click();
            } else {
                setRefresh(!refresh);
            }
        } else {
            Toaster.error(data.message);
        }
    }

    const postRate = async (rate) => {
        setMyRating(rate);
    }

    async function sendExperienceData() {
        let formData = {};

        const _lmsuser = JSON.parse(localStorage.getItem('lmsuser'));
        const _lms_learner_id = _lmsuser['id'];
        formData.user_id = _lms_learner_id;
        formData.course_id = course?.id;
        formData.message = feedbackMessage;
        formData.rating = my_rating; // 1 to 5
        formData.feedback_type = 'student'; // tutor or student

        const response = await fetch(API_BASE + 'course/feedback', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(formData)
        });

        const data = await response.json();
        if (data.status) {
            document.getElementById('ratingModalFinishCourseclick').click();
            Toaster.success(data.message);
        } else {
            document.getElementById('ratingModalFinishCourseclick').click();
        }
    }

    return (
        <>
            {
                (course !== '' && typeof course !== 'undefined')
                &&
                <div className="accordion tour-course-step-05" id="accordionExample">
                    <div className="side_bar_count">
                        <ul>
                            {
                                outer_slides !== ''
                                &&
                                outer_slides.map((slide, indexx) =>
                                    <SlideBox key={indexx} index={indexx} slide={slide} updateProgress={updateProgress} showFinish={showFinish} certified={certified} />
                                )
                            }
                        </ul>
                    </div>

                    {
                        sections !== ''
                        &&
                        sections.map((section, index) => {
                            return (
                                (typeof section.slides !== 'undefined' && section.slides.length > 0)
                                &&
                                <div key={index} className="card">
                                    <div className="card-header" id={`heading${index}`}>
                                        <button className={`accordion-button ${index > 0 ? 'collapsed' : ''}`} type="button" data-toggle="collapse" data-target={`#collapseOne${index}`} aria-expanded={`${index > 0 ? false : true}`} aria-controls={`collapseOne${index}`}>
                                            Section {index + 1}: {section.title}<span>{section.completed}/{section.slide_count} | {section.duration}</span>
                                        </button>
                                    </div>
                                    <div id={`collapseOne${index}`} className={`collapse ${index > 0 ? '' : 'show'}`} aria-labelledby={`heading${index}`} data-parent="#accordionExample">
                                        <div className="card-body">
                                            <ul>
                                                {
                                                    section.slides.map((slide, indexx) =>
                                                        <SlideBox key={indexx} index={indexx} slide={slide} updateProgress={updateProgress} showFinish={showFinish} certified={certified} />
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                        )
                    }
                    {
                        (showFinish && certified == false) ? <button onClick={() => { finishCourse() }} className="btn btn-circle btn-md submit-pink-btn back-button-course float-right pl-3 pr-3 pt-1 pb-1 mt-2 ek-lrs-course-completed" data-ek-lrs-title={course.title} data-ek-lrs-url={`/learn-course/` + course.slug}> Finish Course</button> : null
                    }
                </div>
            }
            <div className="modal fade ratingpopup" id="ratingModalFinishCourse" tabIndex="-1" role="dialog" aria-labelledby="ratingModalFinishCourseTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="ratingModalLongTitle">How was your experience ?</h4>
                            <button type="button" id="ratingModalFinishCourseClose" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body p-2">
                            <h5 className="m-0">Rate us</h5>
                            {
                                rateArrays.map((rate_val, indexv) =>
                                    <RateStart key={indexv} postRate={postRate} value={rate_val} my_rating={my_rating} />
                                )
                            }
                            <div className="form-group m-2">
                                <textarea name="feedbackMessage" placeholder="Please Share your experience here..." className="form-control" onChange={(e) => setFeedbackMessage(e.target.value)} rows={3}></textarea>
                            </div>
                            <div className="float-right m-2">
                                <button className="btn btn-success btn-circle btn-md" onClick={sendExperienceData}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <a className="d-none" data-toggle="modal" data-target="#ratingModalFinishCourse" id="ratingModalFinishCourseclick">View Slide</a>
        </>
    );
}

export default SlideList;


const SlideBox = ({ index, slide, updateProgress, showFinish, certified }) => {
    const { _slug } = useParams();
    const { _slide } = useParams();
    const navigate = useNavigate();
    const [checked, setChecked] = useState(slide.completed);

    const loadSlide = (slide) => {
        if (_slide !== slide.toString()) {
            navigate(`/learn-course/${_slug}/${slide}`);
        }
    }

    return (
        <>
            <li className={_slide == slide.uid ? 'active ek-lrs-lesson-initialized' : 'ek-lrs-lesson-initialized'} data-ek-lrs-material-title={slide.title} data-ek-lrs-material-url={btoa(unescape(encodeURIComponent(slide.material.material)))} data-ek-lrs-material-duration={slide.duration} data-ek-lrs-material-type={slide.material.material_type} data-ek-lrs-material-parent="">
                <input id={`slide_${slide.uid}`} type="checkbox" disabled={certified} checked={checked} onChange={(e) => { updateProgress(e, slide.id); setChecked(e.target.checked); }} className="ek-lrs-lesson-completed" data-ek-lrs-material-title={slide.title} data-ek-lrs-material-url={btoa(unescape(encodeURIComponent(slide.material.material)))} data-ek-lrs-material-duration={slide.duration} data-ek-lrs-material-type={slide.material.material_type} data-ek-lrs-material-parent="" />
                <label onClick={(e) => { loadSlide(slide.uid); }}>
                    {index + 1}. {slide.title}
                    <span>
                        {
                            slide.material.material_type === 'Video'
                            &&
                            <i className="fas fa-play-circle"> </i>
                        }
                        {
                            slide.material.material_type === "Quiz"
                            &&
                            <i className="fa-solid fa-circle-question"></i>
                        }
                        {
                            slide.material.material_type !== 'Video' && slide.material.material_type !== "Quiz"
                            &&
                            <i className="fas fa-file-alt"> </i>
                        }
                        &nbsp;
                        {slide.duration}
                    </span>
                </label>
            </li>
        </>
    );
}
