import { useEffect, useContext, useState, useLayoutEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { API_BASE, LRS_ENDPOINT } from "../constants/global";
import { SwalBox, Toaster } from "../entity/GlobalJS";
import CoursePlay from "./CoursePlay";
import Details from "./Details";
import SlideList from "./SlideList";
import authContext from '../context/auth/authContext';
import { Widget, addResponseMessage, deleteMessages, setBadgeCount, toggleMsgLoader } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';
import chatbotlogo from '../assets/images/chatbotlogo.png';
import EduKulaAi from "../entity/EdukulaAi";
import Cookies from "js-cookie";
import Joyride from 'react-joyride';
import { Helmet } from "react-helmet";

const RedirectHOC = (WrappedComponent) => {
    const MainComponent = (props) => {
        const { _slug } = useParams();
        const { _slide } = useParams();
        const navigate = useNavigate();
        const _token = localStorage.getItem('lmstoken');
        let ek_location = useLocation();

        const queryParams = new URLSearchParams(ek_location.search);
        const currTabName = queryParams.get('tab');

        const fetchCourseDetails = async () => {
            const response = await fetch(`${API_BASE}get-course-about/${_slug}${(typeof _slide !== 'undefined' && _slide.trim() !== '') ? '/' + `${_slide.trim()}` : ''}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + _token
                },
            });
            const data = await response.json();
            if (data.status) {
                if (typeof _slide === 'undefined' || _slide.trim() === '') {
                    if (currTabName == "QA") {
                        navigate(`/learn-course/${_slug}/${data.data.slide_id}?tab=QA`);
                    }
                    else {
                        navigate(`/learn-course/${_slug}/${data.data.slide_id}`);
                    }
                }
            }
            if (data.message === "No data found.") {
            }
        }

        useEffect(() => {
            fetchCourseDetails();
        }, []);

        return <WrappedComponent {...props} />;
    };

    return MainComponent;
};


const LearnCourseLayout = () => {
    const { _slug } = useParams();
    const { _slide } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const _token = localStorage.getItem('lmstoken');
    const _lrs_user_info = JSON.parse(localStorage.getItem('lrsuser'));
    const [course, setCourse] = useState();
    const [expier, setExpier] = useState(0);
    const [certified, setCertified] = useState(false);
    const [not_available, setNotAvailable] = useState(false);
    const cntxAuth = useContext(authContext);
    const [showVirtualTutor, setShowVirtualTutor] = useState(false);
    const [tour_steps, setTourSteps] = useState([]);
    const [slide, setSlide] = useState('');
    const [refresh2, setRefresh2] = useState(0);
    const [previous_slide, setPreviouSlide] = useState('1');
    const [next_slide, setNextSlide] = useState('1');
    const [processing, setProcessing] = useState(false);
    const [current_slide, setCurrentSlide] = useState('');

    let ek_location = useLocation();
    const queryParams = new URLSearchParams(ek_location.search);
    const currTabName = queryParams.get('tab');

    useEffect(() => {
        fetchMaterials();
        fetchSlide();
    }, [location])

    try {
        // eslint-disable-next-line
        $(document).ready(function () {
            if (currTabName === "QA") {
                document.getElementById('pills-home-tab').click();
                document.getElementById('pills-home-tab').scrollIntoView();
            }
        });
    } catch (e) {

    }

    function findPreviousAndNext(array, value) {
        let _previous_id = '';
        let _next_id = '';
        for (let i = 0; i < array.length; i++) {
            if (array[i] == value) {
                _previous_id = array[(i - 1)];
                _next_id = array[(i + 1)];
                if (_previous_id != 'undefined' && _previous_id != undefined) {
                    setPreviouSlide(_previous_id);
                } else {
                    setPreviouSlide('');
                }
                if (_next_id != 'undefined' && _next_id != undefined) {
                    setNextSlide(_next_id);
                } else {
                    setNextSlide('');
                }
            }
        }
        return { _previous_id, _next_id }
    }

    function extractIds(array45) {
        if (Array.isArray(array45) === true) {
            const idsArray = array45.map((entry) => entry.uid);
            return idsArray;
        } else {
            return [];
        }
    }

    const fetchMaterials = async () => {
        const response = await fetch(`${API_BASE}get-learning-materials/${_slug}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });
        const data = await response.json();
        if (data.status) {

            if (data.data.sections === "") {
                let IDtoSend = extractIds(data.data.outer_slides);
                // setIdsSlide(IDtoSend);
                findPreviousAndNext(IDtoSend, _slide);
            }
            if (data.data.sections.length > 0) {
                var _material_string = '';
                let _materials = data.data;
                if (_materials.outer_slides.length > 0) {
                    var s_mat = _materials.outer_slides.map(obj => obj.uid);
                    _material_string += s_mat.join(',');
                }
                if (_materials.sections.length > 0) {
                    _materials.sections.forEach(function (s) {
                        var se_mat = s.slides.map(obj => obj.uid);
                        if (se_mat.length > 0) {
                            _material_string += ',';
                            _material_string += se_mat.join(',');
                        }
                    });
                }
                const resultArray = _material_string.split(",");
                // setIdsSlide(resultArray);
                findPreviousAndNext(resultArray, _slide);
            }
        }
    }

    const gotoPreviousSlide = async () => {
        navigate(`/learn-course/${_slug}/${previous_slide}`);
    }

    const gotoNextSlide = (current_slideID) => {
        updateProgress(current_slideID);
    }

    const showACourseTour = () => {
        SwalBox.fire({
            allowOutsideClick: false,
            title: 'Get Started Right',
            text: 'Before starting your learning journey, we recommend taking a quick tour of this page. It will familiarize you with the resources and features available, optimizing your experience. Don\'t miss out on this opportunity!',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#30314c',
            cancelButtonColor: '#30314c',
            confirmButtonText: 'Yes, please.',
            cancelButtonText: 'No, thank you.'
        }).then((result) => {
            if (result.isConfirmed) {
                startCourseTour();
            } else {
                Cookies.set('show_course_tour', 'NO');
                localStorage.removeItem('enrolled');
            }
        });
    }
    const startCourseTour = () => {
        let steps = [];
        steps = [
            { placementBeacon: 'right', disableBeacon: true, target: '.tour-course-step-01', title: 'Course Overview', content: "Here, you'll find a concise overview of the course you're about to embark on.", },
            { placementBeacon: 'right', disableBeacon: true, target: '.tour-course-step-02', title: 'Q&A Section', content: "Seek clarity and deepen your understanding! In this dedicated Q&A section, you have the opportunity to ask any questions related to the course. Engage with fellow learners and instructors to gain insights, resolve doubts, and enhance your learning experience.", },
            { placementBeacon: 'right', disableBeacon: true, target: '.tour-course-step-03', title: 'Notebook', content: "Unleash your inner curator and stay organized! Make note of any doubts or questions that arise along the way. Keep your thoughts in one place and personalize your learning experience to make it truly yours.", },
            { placementBeacon: 'right', disableBeacon: true, target: '.tour-course-step-04', title: 'Live Lessons', content: "Join the excitement of live sessions! In this section, you'll find information about any upcoming live sessions associated with your course", },
            { placementBeacon: 'right', disableBeacon: true, target: '.tour-course-step-05', title: 'Course Materials', content: "Get a quick glimpse of what lies ahead in your learning journey. ", },
            { placementBeacon: 'right', disableBeacon: true, target: '.tour-course-step-06', title: 'Current Material ', content: "Here you can easily view and engage with a range of educational materials such as videos, documents, and uploaded files from the instructor.", },
            { placementBeacon: 'right', disableBeacon: true, target: '.rcw-close-widget-container', title: 'Virtual Tutor', content: "Your virtual tutor is here to guide you every step of the way! Feel free to ask any questions related to the current course, and you'll receive immediate answers. This interactive feature ensures that you have a supportive learning environment where doubts are resolved promptly, allowing you to progress smoothly and confidently. ", },
        ];
        setTourSteps(steps);
        Cookies.set('show_course_tour', 'NO');
        localStorage.removeItem('enrolled');
    }

    useEffect(() => {
        fetchCourseDetails();
        handleCertified(certified);
        deleteMessages();
        addResponseMessage('Hi, ' + cntxAuth.userInfo.first_name + '. How may I help you ?');
        setBadgeCount(0);
        isVirtualTutorEnabled();
        fetchSlide();
        checkForLrsEntry();
    }, [certified]);

    useLayoutEffect(() => {
        fetchSlide();
    }, [location.pathname]);


    const checkForLrsEntry = async () => {
        try {
            let _course_slug = _slug;
            var formData = new FormData();
            formData.append('slug', _course_slug);
            formData.append('agent', _lrs_user_info.email);
            const response = await fetch(`${LRS_ENDPOINT}statements/check-lrs-entry`, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${_token}`
                },
                body: formData
            });
            const data = await response.json();
            if (data.status && data.data.lrs == false) {
                setTimeout(() => {
                    document.getElementById('ek-enroll-lrs-user').click();
                }, 2000);
            }
        } catch (e) { }
    }

    const handleCertified = (flag) => {
        setCertified(flag);
    }
    const isVirtualTutorEnabled = async () => {
        let formData = {};
        const _lmsuser = JSON.parse(localStorage.getItem('lmsuser'));
        const _lms_learner_id = _lmsuser['id'];
        formData.user_id = _lms_learner_id;
        formData.action = "fetch";
        const response = await fetch(`${API_BASE}get-responsible-ai`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
            body: JSON.stringify(formData)
        });
        const data = await response.json();
        if (data.status) {
            if (data.data.virtual_tutor == "TRUE") { setShowVirtualTutor(true); }
            //console.info(showVirtualTutor);
        } else {
        }
    }
    const fetchCourseDetails = async () => {
        if (_slug !== '' && typeof _slug !== 'undefined') {
            const response = await fetch(`${API_BASE}get-course-about/${_slug}${(typeof _slide !== 'undefined' && _slide.trim() !== '') ? '/' + `${_slide.trim()}` : ''}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + _token
                },
            });

            const data = await response.json();
            if (data.status) {
                // if (typeof _slide === 'undefined' || _slide.trim() === '') {
                //navigate(`/learn-course/${_slug}/${data.data.slide_id}`);
                // window.location = `/learn-course/${_slug}/${data.data.slide_id}`;
                // }
                showWelcomeMessage();
                setCourse(data.data.course);
                setExpier(data.data.expier);

            }
            if (data.message === "No data found.") {
                // navigate('/404');
                setNotAvailable(true);
                setExpier(0);
                navigate(`/dashboard/student/ongoing-courses`);
                SwalBox.fire({
                    title: "Resource Not Found",
                    text: "Either this course is removed or not available.",
                    icon: "error",
                });
                setTimeout(() => {
                    SwalBox.close();
                }, 3000);
            }
        }
    }

    const fetchSlide = async () => {
        if (_slug !== '' && typeof _slug !== 'undefined' && _slide != undefined) {
            const response = await fetch(`${API_BASE}get-single-material/${_slug}/${_slide}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${_token}`
                },
            });

            const data = await response.json();
            if (data.status) {
                setSlide(data.data.slide);
                document.getElementById("ek-course-breadcrumb").scrollIntoView();
                setCurrentSlide(data.data.slide.id);
            }
        }
    }
    const handleNewUserMessage = (newMessage) => {
        toggleMsgLoader();
        let _default_prompt = 'I want you to act as my virtual tutor. I will initiate chat with you. I may ask you queries related to different topics and you will explain or answer me based on my queries. You will need to analise my reply/statement first and answer me according to the type of my statement i.e whether it is a question or just a sentense. Please keep your reply as short as possible.';
        let _default_course = 'Currently I am learning '+course.title+ '\n Here is my query :';
        let _request_prompt = _default_prompt +'\n'+_default_course+'\n'+newMessage+'.';
        try{
            EduKulaAi.createCompletion({
                model: "text-davinci-003",
                prompt: _request_prompt,
                stop: "Stop",
                max_tokens: 4097-(_request_prompt.length),
                temperature: 0.1,
            }).then((completion) => {
                toggleMsgLoader();
                if(completion.status != 200){
                    addResponseMessage("There's some technical error handling your request. Please try after some time");
                }else{
                    const responseText = completion.data.choices[0].text;
                    addResponseMessage(responseText);
                }
            }).catch((error) => {
                SwalBox.fire({
                    title: 'Something went wrong',
                    text: 'Please try again after some time.',
                    icon: 'error',
                });
                addResponseMessage("There's some technical error handling your request. Please try after some time");
            });
        }catch(e){ 
            addResponseMessage("There's some technical error handling your request. Please try after some time");
        }
    };
    const showWelcomeMessage = async () => {
        var enrolled = localStorage.getItem('enrolled');
        if (typeof enrolled !== 'undefined' && enrolled !== null) {
            showACourseTour();
        }
    };

    const needtoRefresh = () => {
        setRefresh2(!refresh2);

    };

    const updateProgress = async (current_slideID) => {
        setProcessing(true);
        Toaster.dismiss();

        var forData = new FormData();
        forData.append('course_id', course.id);
        forData.append('slide_id', current_slideID);
        const response = await fetch(`${API_BASE}update-progress/true`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: forData
        });
        const data = await response.json();
        if (data.status) {
            if (data.data.completed) {
                //Toaster.success(data.message);
                var checkbox = document.getElementById('slide_' + _slide);
                if (!checkbox.checked) {
                    document.getElementById('slide_' + _slide).click();
                    checkbox.checked = true;
                }
                needtoRefresh();
                setProcessing(false);
                navigate(`/learn-course/${_slug}/${next_slide}`);
            } else {
                setProcessing(false);
            }
        } else {
            Toaster.error(data.message);
            setProcessing(false);
        }
    }

    return (
        <>
            <Helmet>
                <title>{'EduKula - Learn Course'}</title>
            </Helmet>
            {
                not_available &&
                <div className="alert alert-danger bottom_pos alert-dismissible fade show" role="alert">
                    <strong>Important! </strong> Either this course is removed or not available.
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            }
            {
                course !== ''
                &&
                <section className="enroll_detail">
                    <div className="custom-breadcrumb course-player-custom-breadcrumb">
                        <div className="container" id="ek-course-breadcrumb">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to={'/dashboard'}><i className="fas fa-home"></i>&nbsp;Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to={'/dashboard/student/ongoing-courses'}>Courses I am Learning</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">{course?.title}</li>
                                </ol>
                            </nav>
                            <a id="ek-enroll-lrs-user" className="ek-enroll-lrs-user ek-lrs-course-registered ek_hideele" data-ek-lrs-title={course?.title} data-ek-lrs-url={`/learn-course/` + course?.slug}>&nbsp;</a>
                        </div>
                    </div>
                    <div className="container ek-course-player">
                        <h3 className="pt-3 pb-3 top_title w-100 clearfix"><i className="fas fa-bookmark"></i>&nbsp;{slide.title} <Link className="btn btn-circle btn-md submit-pink-btn back-button-course float-right pl-3 pr-3 pt-1 pb-1 mt-2" to={'/dashboard/student/ongoing-courses'}><i className="fa fa-arrow-left" aria-hidden="true"></i> Back to Student Dashboard</Link>
                            <Link className="btn btn-circle btn-md submit-pink-btn back-button-course float-right pl-3 pr-3 pt-1 pb-1 mt-2" to={`/${'dashboard/course-progress'}/${_slug}`}><i className="fa-solid fa-chart-pie mr-1" aria-hidden="true"></i>View Course Progress</Link>
                        </h3>
                    </div>
                    <div className="col-sm-12 col-lg-8 mb-3">
                        <div className="row">
                            {previous_slide != '' && <div className="col-6"><button onClick={(e) => { gotoPreviousSlide(); }} id="ek-previous-float-left" className="btn btn-circle btn-md submit-pink-btn"><i className="fa fa-chevron-left" aria-hidden="true"></i>&nbsp;Previous</button></div>}
                            {next_slide != '' && <div className={`${previous_slide == '' ? 'col-12' : 'col-6'}`}><button onClick={(e) => { gotoNextSlide(current_slide); }} id="ek-next-float-right" className="btn btn-circle btn-md submit-pink-btn" disabled={processing} >

                                {processing ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></> : <>Next&nbsp;</>}

                                <i className="fa fa-chevron-right" aria-hidden="true"></i></button></div>}
                        </div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="row m-0">
                        <div className="col-sm-12 col-lg-8 tour-course-step-06">
                        <CoursePlay course={course} expier={expier} certified={certified} gotoNextSlide={gotoNextSlide} />
                        </div>
                        <div className="col-sm-12 col-lg-4">
                            {
                                expier !== 0 && !certified
                                &&
                                <>
                                    <div className="clearfix"></div>
                                    <div className="alert alert-danger bottom_pos alert-dismissible fade show" role="alert">
                                        <strong>Important! </strong> Please complete this course within {expier} days, because this course is no longer available.
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                </>
                            }

                            <SlideList course={course} handleCertified={handleCertified} refresh2={refresh2} />
                        </div>
                        <div className="col-sm-12 col-lg-8">
                            <Details course={course} certified={certified} expier={expier} needtoRefresh={needtoRefresh} refresh2={refresh2} />
                        </div>
                        {
                            showVirtualTutor == true
                            &&
                            <Widget
                                handleNewUserMessage={handleNewUserMessage}
                                profileAvatar={chatbotlogo}
                                title="I am your Virtual Tutor"
                                subtitle="Your can ask your queries..."
                                senderPlaceHolder="Ask me anything!"
                                profileClientAvatar={cntxAuth.userInfo.image}
                                autofocus={true}
                                showTimeStamp={true}
                                resizable={false}
                                emojis={true}
                                showBadge={true}

                            />
                        }
                    </div>
                </section>
            }
            {
                tour_steps.length > 0
                &&
                <Joyride
                    steps={tour_steps}
                    disableCloseOnEsc={true}
                    disableOverlayClose={true}
                    showSkipButton={true}
                    continuous={true}
                    hideCloseButton={true}
                    disableScrolling={false}

                    styles={{ options: { width: 500, zIndex: 5000, primaryColor: '#31314c', textColor: '#ef4a45', overlayColor: 'rgb(2 1 0 / 69%)', } }}
                />
            }
        </>
    );
}

export default RedirectHOC(LearnCourseLayout);
