import { default as CustRouter } from './Router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthState from './context/auth/authState';



function App() {


  return (
    <div>
      <AuthState>
        <CustRouter />
        <ToastContainer limit={1}/>
      </AuthState>
    </div>
  );
}

export default App;
