import NoNotifications from "./notifications/NoNotifications";
import { API_BASE } from "../../constants/global";
import { FeedBoxSkeleton } from "../../components/skeletons";
import { useEffect, useState } from "react";
import { Announcement } from "./notifications/Announcement/index";
import VerifiedBadge from "../../components/VerifiedBadge";


const AnnouncementTab = () => {
    const [notifications, setNotifications] = useState('');
    const [isLoader, setLoader] = useState(false);
    const _token = localStorage.getItem('lmstoken');
    const [page, setPage] = useState(0);
    const [total_page, setTotalPage] = useState(0);

    useEffect(() => {
        fetchAnnouncementNotification();
    }, [page]);

    const fetchAnnouncementNotification = async () => {
        setLoader(true);
        const response = await fetch(`${API_BASE}get-user-feeds-by-tab/Announcements?page=${page}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        setLoader(false);
        if (data.status) {
            setTotalPage(data?.data?.count);
            setNotifications([...notifications, ...data?.data?.feeds]);
        }
    }

    const nextPage = () => {
        setPage(page + 1);
    }

    return (
        <>
            <div className="tab-pane fade show active" id="pills-announcements" role="tabpanel" aria-labelledby="pills-announcements-tab">
                <div className="course-tab-contents">
                    <div className="course-tabs-pane-block">
                        {
                            isLoader ?
                                <FeedBoxSkeleton count={3} />
                                :
                                notifications == '' ?
                                    <NoNotifications
                                        main_message={'No announcements yet'}
                                        sub_message={<></>}
                                    />
                                    :
                                    <>
                                        {
                                            notifications.map((feed, index) =>
                                                <div key={index}>
                                                    <Announcement feed={feed} />
                                                </div>
                                            )
                                        }

                                        {
                                            notifications.length < total_page
                                            &&
                                            <div className="load_more_div">
                                                <a className="cursor-pointer" onClick={(e) => { nextPage(); }}>Load more</a>
                                            </div>
                                        }
                                    </>

                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default AnnouncementTab;