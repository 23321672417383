import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { API_BASE, IMAGE_EXT, IMAGE_TXT } from "../../constants/global";
import DropdownSkeleton from "../../components/skeletons/DropdownSkeleton";
import { SwalBox, Toaster } from "../../entity/GlobalJS";
import Select from 'react-select';
import TinyMCEEditor from "./../../entity/TinyMCEEditor";
import ImageCropper from "../../personal_profile/Tabs/ImageCropper";
import { Helmet } from "react-helmet";

const CreateLearningCircle = () => {
    const _token = localStorage.getItem('lmstoken');
    const navigate = useNavigate();
    const { register, control, handleSubmit, formState: { errors }, setValue, clearErrors } = useForm();

    const [languages, setLanguages] = useState('');
    const [categories, setCategories] = useState('');
    const [sub_categories, setSubCategories] = useState('');
    const [courses, setCourses] = useState('');
    const [image, setImage] = useState('');
    const [options, setOptions] = useState([]);
    const [course_options, setCourseOptions] = useState([]);
    const [processing, setProcessing] = useState(false);
    const [showCatErr, setShowCatErr] = useState(false);
    const [showSubCatErr, setShowSubCatErr] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [prev, setPrev] = useState(false);
    const [new_photo, setNewPhoto] = useState(null);
    const [fetching_sub, setFetchingSub] = useState(false);
    const [editing, setEditing] = useState(false);
    const [circleId, setCircleId] = useState(null);
    const [selected_course, setSelectedCourse] = useState('');
    const [cropbtnstatus, setCropbtnstatus] = useState('');
    const { unique_id } = useParams();

    useEffect(() => {
        localStorage.removeItem('createLC');
        fetchCircle();
        fetchLanguages();
        fetchCategories();
        fetchUsers();
        // eslint-disable-next-line
    }, []);

    const fetchCircle = async () => {
        if (typeof unique_id !== 'undefined' && unique_id !== '') {
            const response = await fetch(`${API_BASE}get-group-details/${unique_id}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + _token
                },
            });

            const data = await response.json();
            if (data.status) {
                setEditing(true);
                const circle = data.data.circle;
                setCircleId(circle?.id);
                fetchSubCategories(circle?.category_id);
                setValue('title', circle?.title);
                setValue('about', circle?.about);
                setValue('category_id', circle?.category_id);
                setValue('why_join', circle?.why_join);
                setValue('private', circle?.private_key);
                setImage(circle?.image ? circle?.image : '');
                setFetchingSub(true);
                fetchCourses(circle?.sub_category_id, circle?.course_slug);
                setTimeout(() => {
                    setFetchingSub(false);
                    setValue('sub_category_id', circle?.sub_category_id);
                }, 3000);

            } else {
                Toaster.error(data.message);
            }
        } else {

            const response = await fetch(`${API_BASE}circles-i-have-create?page=0`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${_token}`
                },
            });

            const data = await response.json();
            if (data.status) {
                if (data.data.total_count > 0) {
                    if (parseInt(data.data.total_count) <= 1) {
                    } else {
                        SwalBox.fire({
                            allowOutsideClick: false,
                            text: "You've reached maximum number of Learning Circle you can create. In order to create a new learning circle you'll either be required to leave or close a circle earlier created.",
                            icon: 'info',
                            showCancelButton: false,
                            confirmButtonColor: '#30314c',
                            cancelButtonColor: '#30314c',
                        });

                        setTimeout(() => {
                            SwalBox.close();
                            navigate('/dashboard/my-circles/i-have-created');
                        }, 2000);
                    }
                }
            }

        }
    }

    const categoryHandler = (e) => {
        var cat_id = e.target.value;
        setCourses('');
        setSubCategories('');
        if (cat_id.length !== 0) {
            fetchSubCategories(cat_id);
            setShowCatErr(true);
        } else {
            setSubCategories('');
            setShowCatErr(false);
        }
    }

    const subCategoryHandler = (e) => {
        var sub_cat_id = e.target.value;
        setCourses('');
        if (sub_cat_id.length !== 0) {
            fetchCourses(sub_cat_id);
            setShowSubCatErr(true);
        } else {
            setCourses('');
            setShowSubCatErr(false);
        }
    }

    const prevImage = (e) => {
        var filePath = e.target.value;
        if (!IMAGE_EXT.exec(filePath)) {
            Toaster.error(IMAGE_TXT);
            setImage('');
            setValue('image');
            return false;
        }
        setPrev(true);
        setImage(URL.createObjectURL(e.target.files[0]));
        moderateUploadedMedia(e.target.files[0]);
    }

    const fetchLanguages = async () => {
        const response = await fetch(`${API_BASE}get-course-languages`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        });

        const data = await response.json();

        if (data.status) {
            setLanguages(data.data.languages);
        } else {
            Toaster.error(data.message);
        }
    }

    const fetchCategories = async () => {
        const response = await fetch(`${API_BASE}get-course-categories`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        });

        const data = await response.json();

        if (data.status) {
            setCategories(data.data.course_categories);
        } else {
            Toaster.error(data.message);
        }
    }

    const fetchUsers = async () => {
        const response = await fetch(`${API_BASE}get-all-users`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();

        if (data.status) {
            var stringJ = [];
            data.data.users.forEach(function (value, i) {
                // stringJ.push({ value: value.id, label: <><img className="profile-dropdown-image" src={value.profile} alt='learning circle' />{value.name}</> });
                stringJ.push({ id: value.id, value: value.name, label: value.name, image: value.profile });
            });
            setOptions(stringJ);
        } else {
            Toaster.error(data.message);
        }
    }

    const fetchSubCategories = async (cat_id) => {
        const response = await fetch(`${API_BASE}get-course-sub-categories/${cat_id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        });

        const data = await response.json();

        if (data.status) {
            setSubCategories(data.data.course_sub_categories);
        } else {
            Toaster.error(data.message);
        }
    }

    const fetchCourses = async (sub_cat_id, defaultValue = '') => {
        setCourses("");
        const response = await fetch(`${API_BASE}get-course-from-sub-category/${sub_cat_id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        });

        const data = await response.json();
        if (data.status) {
            setCourses(data.data.courses);
            var stringCourse = [];
            data.data.courses.forEach(function (value, i) {
                stringCourse.push({ value: value.slug, label: value.title });
            });
            setCourseOptions(stringCourse);
            if (defaultValue !== '') {
                setSelectedCourse(stringCourse.find((c) => c.value === defaultValue))
            }
        }
    }

    const removePhoto = () => {
        setImage('');
    }

    const saveCircle = async (form) => {
        const formData = new FormData();
        var fileField = document.getElementById('circle_image');
        var imageF = fileField.files[0];
        formData.append('image', new_photo ? new_photo : "");
        formData.append('title', form.title);
        formData.append('about', form.about);
        formData.append('language_id', form.language_id);
        formData.append('category_id', form.category_id);
        formData.append('sub_category_id', form.sub_category_id);
        formData.append('why_join', form.why_join);
        if (typeof form.course_id !== 'undefined' && form.course_id.value !== '') {
            formData.append('course_id', form.course_id.value);
        }
        formData.append('private', form.private);
        formData.append('existing_users', selectedOptions);
        formData.append('email_users', form.email_users);

        setProcessing(true);

        var sURL = `${API_BASE}create-group`;
        if (typeof unique_id !== 'undefined' && unique_id !== '') {
            sURL = `${API_BASE}create-group/${circleId}`;
        }
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const data = await response.json();
        if (data.status) {
            Toaster.success(data.message);
            navigate('/dashboard/my-circles/i-have-created');
        } else {
            Toaster.error(data.message);
        }

        setProcessing(false);
    }
    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };


    const moderateUploadedMedia = async (image) => {
        setCropbtnstatus('disabled')
        const formData = new FormData();
        var fileField = image;
        const base64 = await convertBase64(fileField);
        formData.append("image", fileField);
        formData.append("base64", base64);
        formData.append("mediatype", fileField.type);
        const response = await fetch(API_BASE + 'validate-uploaded-image', {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + _token
            },
            body: formData
        });
        const data = await response.json();
        if (data.status) {
            var _is_valid = data.data;
            if (_is_valid != "") {
                fetchCircle();
                SwalBox.fire({
                    title: 'Inappropriate image',
                    text: 'Your image has been removed as it contains inappropriate content (' + _is_valid + ') , please upload a valid image.',
                    icon: 'warning',
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ok'
                });
                setPrev(false);
                setImage('');
                setValue('image');
                setTimeout(function () { removePhoto(); }, 1000)
            } else {
                setCropbtnstatus('');
            }
        } else {
            setCropbtnstatus('');
        }
    }

    return (
        <>
            <Helmet>
                <title>{'EduKula - Learning Circle'}</title>
            </Helmet>
            <div className="col-lg-9 home-section">
                <div className="custom-user-profile-content custom-learning-circle-content">
                    <div className="custom-learnig-circle-head">
                        <h4>Learning Circle Form</h4>
                    </div>
                    <div className="learning-circle-form learning-circle-form1">
                        <form onSubmit={handleSubmit(saveCircle)}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label className="m-0" htmlFor={'circle_title'}>Learning Circle Title</label>

                                        <input id="circle_title" {...register("title", {
                                            required: "required",
                                            maxLength: {
                                                value: 80,
                                                message: "Circle name can not be more than 80 charaters."
                                            }
                                        })} type="text" className="form-control" placeholder="Eg: Fundamentals of Sales and Marketing" autoFocus />
                                        <span className="add-on max-chr-counter chrchk-circle_title" data-ele="circle_title" data-limit="80" data-showcounter="true"></span>
                                        <span className="text-danger">
                                            {errors.title?.type === "required" && 'Please enter title.'}
                                            {errors.title?.type !== "required" && errors.title?.message}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label className="m-0" htmlFor={'circle_title'}>Learning Circle Description</label>
                                        <Controller
                                            control={control}
                                            name="about"
                                            rules={{ required: true, message: "asfsf" }}
                                            render={({ field: { onChange, value, name, ref } }) => (
                                                <TinyMCEEditor onChange={onChange} value={value} placeholder='Circle description' />
                                            )}
                                        />
                                        <span className="text-danger">
                                            {errors.about?.type === "required" && 'Please enter about.'}
                                            {errors.about?.type !== "required" && errors.about?.message}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-lg-12" style={{ display: "none" }}>
                                    <div className="form-group">
                                        <label className="m-0" htmlFor={'language_id'}>Language</label>
                                        <p>What is the primary language that will be used by members to communicate.</p>
                                        {/*
                                        <select id="language_id" {...register("language_id", { required: "required" })} className="form-control">
                                            <option value="">Choose Language</option>
                                            {
                                                languages
                                                &&
                                                languages.map((language, index) =>
                                                    <option key={index} value={language.id}>{language.name}</option>
                                                )
                                            }
                                        </select>
                                        */}
                                        <select {...register("language_id", { required: "required" })} className="form-control">
                                            <option value="1">English</option>
                                        </select>
                                        <span className="text-danger">
                                            {errors.language_id?.type === "required" && 'Please select language.'}
                                            {errors.language_id?.type !== "required" && errors.language_id?.message}
                                        </span>
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label className="m-0" htmlFor={'category_id'}>Category</label>
                                        <p>Choose relevant category for the learning circle.</p>
                                        <select id="category_id" {...register("category_id", { required: "required" })} className="form-control" onChange={(e) => { categoryHandler(e); }}>
                                            <option value="">Choose Category</option>
                                            {
                                                categories
                                                &&
                                                categories.map((category, index) =>
                                                    <option key={index} value={category.id}>{category.title}</option>
                                                )
                                            }
                                        </select>
                                        <span className="text-danger">
                                            {showCatErr || errors.category_id?.type === "required" && 'Please select category.'}
                                            {errors.category_id?.type !== "required" && errors.category_id?.message}
                                        </span>
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label className="m-0" htmlFor={'sub_category_id'}>Sub Category</label>
                                        <p>Choose relevant sub category for the learning circle.</p>
                                        {
                                            fetching_sub
                                                ?
                                                <DropdownSkeleton />
                                                :
                                                <select id="sub_category_id" {...register("sub_category_id", { required: "required" })} className="form-control" onChange={(e) => { subCategoryHandler(e); }}>
                                                    <option value="">Choose Sub Category</option>
                                                    {
                                                        sub_categories
                                                        &&
                                                        sub_categories.map((sub_category, index) =>
                                                            <option key={index} value={sub_category.id}>{sub_category.title}</option>
                                                        )
                                                    }
                                                </select>
                                        }
                                        <span className="text-danger">
                                            {showSubCatErr || errors.sub_category_id?.type === "required" && 'Please select sub category.'}
                                            {errors.sub_category_id?.type !== "required" && errors.sub_category_id?.message}
                                        </span>
                                    </div>
                                </div>

                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label className="m-0" htmlFor={'why_join'}>Why Learners should join the Learning Circle?</label>
                                        <p>This section will help you explain how is your learning circle different from other similar learning circles and attract more members.</p>
                                        <Controller
                                            id="why_join"
                                            control={control}
                                            name="why_join"
                                            rules={{ required: true }}
                                            render={({ field: { onChange, value, name, ref } }) => (
                                                <TinyMCEEditor onChange={onChange} value={value} placeholder='Elaborate on what the members achieve by joining your learning circle.' />
                                            )}
                                        />
                                        <span className="text-danger">
                                            {errors.why_join?.type === "required" && 'Please elaborate why user should join the circle.'}
                                            {errors.why_join?.type !== "required" && errors.why_join?.message}
                                        </span>
                                    </div>
                                </div>

                                <div className="col-sm-12">
                                    <div className="form-group image-learing-circle">
                                        <label className="m-0" htmlFor={'circle_image'}>Upload Cover Image for the Learning Circle</label>
                                        <input className="d-none" {...register("image")} type="file" id="circle_image" onChange={(e) => { prevImage(e); }} />
                                        {
                                            image === ''
                                            &&
                                            <>
                                                <div onClick={(e) => { document.getElementById('circle_image').click(); }} className="update-profile-section cover-page-image">
                                                    <div className="update-profile-block">
                                                        <span className="update-profile-img">
                                                            <span><i className="fas fa-camera"></i></span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="mb-1">
                                                    *Recommended Size (1266*400)
                                                    <br />
                                                    <span className="text-danger">
                                                        {errors.image?.type === "required" && 'Please upload circle image.'}
                                                        {errors.image?.type !== "required" && errors.image?.message}
                                                    </span>
                                                </div>
                                            </>
                                        }
                                        {
                                            image !== ''
                                            &&
                                            <>
                                                <div className="update-profile-section cover-page-image">
                                                    <img className="w-100 h-100" src={image} alt='learning circle' />
                                                </div>
                                                {
                                                    (typeof unique_id !== 'undefined' && unique_id !== '')
                                                        ?
                                                        <span onClick={(e) => { removePhoto(); }} className="cancel_change btn btn-success btn-sm ml-1"><i className="fa fa-trash" aria-hidden="true"></i></span>
                                                        :
                                                        <>
                                                            <span onClick={(e) => { document.getElementById('circle_image').click(); }} className="cancel_change edit btn btn-success btn-sm ml-1" style={{ right: "65px" }}><i className="fa fa-edit" aria-hidden="true"></i></span>
                                                            <span onClick={(e) => { removePhoto(); }} className="cancel_change btn btn-success btn-sm ml-1"><i className="fa fa-trash" aria-hidden="true"></i></span>
                                                        </>
                                                }
                                                <ImageCropper cropbtnstatus={cropbtnstatus} photo={image} setPhotoToShow={setImage} show_modal={prev} setPhotoToUpload={setNewPhoto} src={'Circle'} />
                                            </>
                                        }
                                    </div>
                                </div>

                                <div className="mt-5">
                                    {
                                        courses
                                        &&
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <label className="m-0" htmlFor={'course_id'}>Choose parent course</label>
                                                <p>Select parent course related to the this learning circle</p>
                                                {
                                                    fetching_sub
                                                        ?
                                                        <DropdownSkeleton />
                                                        :
                                                        <>
                                                            <Controller
                                                                control={control}
                                                                name="course_id"
                                                                id="course_id"
                                                                rules={{ required: false, message: "asfsf" }}
                                                                render={({ field: { onChange, value, name, ref } }) => (
                                                                    <Select
                                                                        inputRef={ref}
                                                                        classNamePrefix="addl-class"
                                                                        options={course_options}
                                                                        value={selected_course}
                                                                        onChange={(val) => {
                                                                            setSelectedCourse(val);
                                                                            setValue('course_id', val);
                                                                        }}
                                                                        searchable={true}
                                                                    />
                                                                )}
                                                            />

                                                            <span className="text-danger">
                                                                {errors.course_id?.type === "required" && 'Please select parent course.'}
                                                                {errors.course_id?.type !== "required" && errors.course_id?.message}
                                                            </span>

                                                        </>
                                                }

                                            </div>
                                        </div>
                                    }

                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label htmlFor="private" className="m-0">Choose Privacy</label>
                                            <br />
                                            <p>Public Learning Circles are listed on EduKula portal and any user can join them. Private Learning circles are not listed on EduKula and only users approved or invited by the admin can join.</p>
                                            <select {...register("private", { required: "required" })} className="form-control" id="private">
                                                <option value=''>Choose privacy</option>
                                                <option value={0}>Public</option>
                                                <option value={1}>Private</option>
                                            </select>
                                            {/* <div className="form-check-inline">
                                                <label className="form-check-label">
                                                    <input {...register("private", { required: "required" })} type="radio" className="form-check-input" value={0} />Public
                                                </label>
                                            </div>
                                            <div className="form-check-inline">
                                                <label className="form-check-label">
                                                    <input {...register("private", { required: "required" })} type="radio" className="form-check-input" value={1} />Private
                                                </label>
                                            </div> */}
                                            <span className="text-danger">
                                                {errors.private?.type === "required" && 'Please select whether circle is public or private.'}
                                                {errors.private?.type !== "required" && errors.private?.message}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                {!editing
                                    ?
                                    <>
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <label className="m-0">Select EduKula users you wish to invite to the Learning Circle</label>
                                                <p>Select EduKula Users</p>
                                                <Controller
                                                    control={control}
                                                    name="existing_users"
                                                    render={({ field: { onChange, value, name, ref } }) => (
                                                        <Select
                                                            inputRef={ref}
                                                            classNamePrefix="addl-class"
                                                            options={options}
                                                            value={options.find((c) => c.value === value)}
                                                            onChange={(val) => {
                                                                onChange(val.map((c) => c.value));
                                                                setSelectedOptions((val.map((c) => c.id)).join());
                                                            }}
                                                            isMulti
                                                            getOptionLabel={option => (
                                                                <>
                                                                    <img src={option.image} alt={option.value} style={{ width: '20px', marginRight: '10px' }} />
                                                                    {option.label}
                                                                </>
                                                            )}
                                                        />
                                                    )}
                                                />
                                                <span className="text-danger">
                                                    {errors.existing_users?.type === "required" && 'Please select alteast one member to invite.'}
                                                    {errors.existing_users?.type !== "required" && errors.existing_users?.message}
                                                </span>
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <label className="m-0">Enter the email addresses of people you wish to invite to the Learning Circle</label>
                                                <p>Enter the email addresses (comma-separated) of the people you wish to invite to the learning circle</p>
                                                <input {...register("email_users")} type="text" className="form-control" placeholder="Enter email addresses(comma-separated) of the peoples you wish to invite to this learning circle." />
                                            </div>
                                        </div>
                                    </>
                                    :
                                    null}

                            </div>
                            <a onClick={() => navigate(-1)} className="btn btn-circle btn-success">Cancel</a>
                            {
                                processing
                                &&
                                <span className="btn btn-circle btn-info ml-2"><i className="fa fa-refresh fa-spin fa-fw"></i></span>
                            }

                            {
                                !processing
                                &&
                                <button className="btn btn-circle btn-info ml-2">Submit</button>
                            }
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CreateLearningCircle;
