import React from 'react'
import { Outlet } from 'react-router-dom';
import ComingSoonNavbar from '../header/ComingSoonNavbar';
const ComingsoonLayout = () => {
    return (
        <>
            <ComingSoonNavbar />
            <Outlet />
        </>
    )
}
export default ComingsoonLayout