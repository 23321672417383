const NoNotifications = (props) => {
    return (
        <>
            <div className="user-body">
                <div className="empty_state">
                    <img alt="" src="images/empty-mailbox-v2.jpg" />
                    <h3>{props.main_message}</h3>
                    <p>{props.sub_message}</p>
                </div>
            </div>
        </>
    );
}

export default NoNotifications;