const About = ({course}) => {
    return (
        <>
            <div className="tab-pane fade show active" id="pills-about" role="tabpanel" aria-labelledby="pills-about-tab">
                <div className="course-tab-contents">
                    <div className="course-tabs-pane-block">
                        <h3>About this course</h3>
                        <div className="course-tabs-box">
                            <div className="course-tabs-body">
                                <p>{course?.description}</p>
                                {/* <h4>At a glance</h4> */}
                                <div className="course-accordion-section">
                                    <ul className="about-course-list">
                                        <li><div><strong>Category:</strong><span>{course?.category?.title}</span></div></li>
                                        <li><div><strong>Subcategory:</strong><span>{course?.sub_category?.title}</span></div></li>
                                        <li><div><strong>Level:</strong><span>{course?.study_level}</span></div></li>
                                        {/*<li><div><strong>Course Language:</strong><span>{course?.language}</span></div></li>*/}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default About;