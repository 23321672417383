import { Link } from "react-router-dom";

const BecameTutor = ({ntfcn}) => {
    return (
        <>
            <div className="user-body">
                <div className="user-header">
                    <div className="user-profile-img">
                        <img src="images/logo_icon.png" className="rounded-circle" alt="Profile Image" />
                    </div>
                    <div className="user-details-title">
                        <h6>EduKula <span>{ntfcn.date_time}</span></h6>
                    </div>
                </div>
                <div className="user-content-block">
                    <div className="user-course-desc">
                        <p>Congratulations on successfully registering as a Tutor on EduKula</p>
                    </div>
                    <div className="user-course-block">
                        <Link className="link" to={'/dashboard/tutor/create-course'} target="_blank">
                            <img src="images/course-img-1.png" alt="Course Image" />
                            <span>Create course</span>
                        </Link>
                    </div>
                    <div className="like-main-area">
                        <div className=""></div>
                        <div className=""></div>
                        <div className=""></div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BecameTutor;