const P2PBenifits = () => {
    return (
        <>
            <h5>Benefits @EduKula</h5>
            <ul className="community-resources">
                <li>5 Days Working</li>
                <li>Flexible Timings</li>
                <li>Celebrations & Events</li>
                <li>Fun Activities</li>
                <li>Cleanliness and Hygienic environment</li>
                <li>Music at Workstation</li>
                <li>Learning & Development Opportunities</li>
                <li>Work-life Balance</li>
            </ul>
        </>
    );
}

export default P2PBenifits;