import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import { Toaster } from "./../../entity/GlobalJS";
import authContext from "../../context/auth/authContext";
import { API_BASE } from "../../constants/global";
import useEffectOnce from "../../chat/useEffectOnce";

const Verify = () => {
    const { _token } = useParams();
    const navigate = useNavigate();
    const cntxAuth = useContext(authContext);

    useEffectOnce(() => {
        if (cntxAuth.isLogged) {
            navigate('/dashboard/home');
        }
        onSubmit();
        //eslint-disable-next-line
    }, []);

    async function onSubmit() {
        let response = await fetch(`${API_BASE}verify/${_token}`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        });

        response = await response.json();

        if (response.status) {
            Toaster.success(response.message);
        } else {
            Toaster.error(response.message);
        }

        navigate('/login');
    }

    return (
        <></>
    );
}

export default Verify;