import { Link } from "react-router-dom";

const CourseCard = ({ course }) => {
    return (
        <>
            <div className="new_cardcourse">
                <div className="blog-card-detail">
                    <a className="pos_link ek-lrs-course-initialized" data-ek-lrs-url={`/course-details/${course?.slug}`} data-ek-lrs-title={course?.title} href={`/course-details/${course?.slug}`}></a>
                    <div className="custom-card-img">
                        {
                            course?.badge
                            &&
                            <>
                                <div className="badges">
                                    {course?.badge === 'Highest Rated' ? <span className="highest">Highest Rated</span> : ''}
                                    {course?.badge === 'Bestseller' ? <span className="selling">Best Selling</span> : ''}
                                    {course?.badge === 'Trending' ? <span className="tranding">Trending</span> : ''}
                                </div>
                            </>
                        }
                        {
                            course?.associated_vacancy > 0 && typeof course?.associated_vacancy !== 'undefined'
                            &&
                            <>
                                <div className="hiring">
                                    <span className="highest">Hiring</span>
                                </div>
                            </>
                        }
                        <img src={course?.image} className="card-img-top" alt={course?.title} />
                    </div>
                    <div className="blog-card-inner">
                        <div className="compny_logo">
                            {
                            course.creator.is_corporate == '1'
                            ?
                            <img src={course.creator.image} alt={course.creator.name} />
                            :
                            <img src="./images/course_logo_img.jpg" alt={course.creator.name} />
                            }
                        </div>
                        <h5>{course?.title}</h5>
                        <p className="membr_line"><span>{course?.mode_of_learning}</span> &nbsp; 
                        {
                            course?.associated_vacancy > 0 && typeof course?.associated_vacancy !== 'undefined'
                            &&
                            <>
                                | &nbsp; <span>{course.creator.name}</span>
                            </>
                        }
                        </p>
                        <ul className="course_tabs_sub">
                            <li><a><i className="fas fa-list"></i><span>{course?.category?.title}</span></a></li>
                            {/* <li><a><i className="far fa-user"></i><span>{course?.mode_of_learning}</span></a></li> */}
                            {
                                course?.associated_vacancy > 0 && typeof course?.associated_vacancy !== 'undefined'
                                &&
                                <>
                                    <li><a><i className="fas fa-chair"></i><span>{course.vacancy[0].job_position}</span></a></li>
                                </>
                            }
                            {
                            course?.associated_vacancy == 0 
                            &&
                            <>
                            <li><a><i className="fas fa-star"></i><span>{course?.average_rating !== null ? course?.average_rating[0]?.average : 'No'} Rating</span></a></li>
                            </>
                            }

                        </ul>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CourseCard;