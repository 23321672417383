import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { RolesSchema } from "./schemas";
import { API_BASE } from "../../constants/global";
import { Toaster } from "../../entity/GlobalJS";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";


const initialValues = {
    'role_title': "",
    'manage_employees' : false,
    'manage_roles' : false,
    'manage_reports' : false,
    'manage_courses' : false,
    'manage_vacancies' : false,
    'manage_circles' : false,
}
const RolesForm = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const urlState = location.state;
    const _token = localStorage.getItem('lmstoken');
    const [processing, setProcessing] = useState(false);
    const [formValues, setFormValues] = useState({
        'role_title': "",
        'manage_employees' : false,
        'manage_roles' : false,
        'manage_reports' : false,
        'manage_courses' : false,
        'manage_vacancies' : false,
        'manage_circles' : false,
    });
    useEffect(() => {
        /*
        fetchCourses();
        if (vacancy_id !== '' && vacancy_id !== null && typeof vacancy_id !== 'undefined') {
            fetchVacancy(vacancy_id);
            setEdit(vacancy_id)
        } else {
            setEdit('')
            if (urlState !== null) {
                setFormValues({ ...formValues, course_id: urlState?.course_slug });
            }
        }
        */
    }, []);
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        enableReinitialize: true,
        initialValues: formValues ? formValues : initialValues,
        validationSchema: RolesSchema,
        onSubmit: async (values, action) => {
            setProcessing(true);
            //var formData = new FormData();
            //formData.append('values', values.role_title);
            var roleData        = {};
            roleData            = values;
            const response = await fetch( `${API_BASE}role/save`, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${_token}`
                },
                body: JSON.stringify(roleData)
            });
            const data = await response.json();
            if (data.status) {
                Toaster.success(data.message);
                action.resetForm();
                setProcessing(false);
                navigate('/dashboard/roles');
            }
        }
    });

    return (
    <>
            <Helmet>
                <title>{'EduKula - Roles'}</title>
            </Helmet>
    <div className="col-lg-9 home-section">
        <div className="custom-user-profile-content custom-learning-circle-content tutor_dashboard">
            <div className="custom-learnig-circle-head">
                <h4>Add New Role</h4>
                <Link className="btn btn-circle btn-info" to={"/dashboard/roles"}>Back to Listing</Link>
            </div>
            <div className="learning-circle-courses">
                <section>
                    <div className="explore-section">
                        <div className="container">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label><b>Role Name</b></label>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                name="role_title"
                                                id="role_title"
                                                placeholder="e.g. HR"
                                                className="form-control"
                                                autoFocus
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.role_title}
                                            />
                                            {
                                                (errors.role_title && touched.role_title) &&
                                                <span className="text-danger">{errors.role_title}</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12"><div className="form-group"><label><b>Allowed Modules</b></label></div></div>
                                    <div className="col-4"><div className="form-group"><input onChange={handleChange} name="manage_employees" id="cb-manage-employees" type="checkbox" value={values.manage_employees} checked={values.manage_employees} />&nbsp;Manage Employees</div></div>
                                    <div className="col-4"><div className="form-group"><input onChange={handleChange} name="manage_roles" id="cb-manage-roles" type="checkbox" value={values.manage_roles} checked={values.manage_roles} />&nbsp;Manage Roles</div></div>
                                    <div className="col-4"><div className="form-group"><input onChange={handleChange} name="manage_reports" id="cb-manage-reports" type="checkbox" value={values.manage_reports} checked={values.manage_reports} />&nbsp;Download Reports</div></div>
                                    <div className="col-4"><div className="form-group"><input onChange={handleChange} name="manage_courses" id="cb-manage-courses" type="checkbox" value={values.manage_courses} checked={values.manage_courses} />&nbsp;Manage Courses</div></div>
                                    <div className="col-4"><div className="form-group"><input onChange={handleChange} name="manage_vacancies" id="cb-manage-vacancies" type="checkbox" value={values.manage_vacancies} checked={values.manage_vacancies} />&nbsp;Manage Vacancies</div></div>
                                    <div className="col-4"><div className="form-group"><input onChange={handleChange} name="manage_circles" id="cb-manage-circles" type="checkbox" value={values.manage_circles} checked={values.manage_circles} />&nbsp;Manage Learning Circles</div></div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <Link className="btn btn-circle btn-md cancel-btn" to={"/dashboard/roles"}>Cancel</Link>
                                        <button type="submit" disabled={processing} className="btn btn-circle btn-md submit-pink-btn">
                                        {
                                            processing
                                            ?
                                            <i className="fa fa-refresh fa-spin fa-fw"></i>
                                            :
                                            'Submit'
                                        }
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
    </>
    )
}
export default RolesForm;