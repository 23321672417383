import React from 'react'
import { Link } from 'react-router-dom'

const CircleCard = ({ circle }) => {
    return (
        <div className="user-right-body-block">
            <Link target="_blank" to={'/learning-circle-details/' + circle.unique_id} className="ca_card_link">
                <div className="user-info-head">
                <img src={circle.image} alt={circle.title} />
                </div>
                <div className="user-info-desc">
                <h6>{circle.title}</h6>
                </div>
            </Link>
        </div>
    )
}

export default CircleCard