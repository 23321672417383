import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import RolesListSkeleton from "../../components/skeletons/RolesListSkeleton";
import { SwalBox, Toaster } from "../../entity/GlobalJS";
import { API_BASE } from "../../constants/global";
import CustomPagination from "../../entity/CustomPagination";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import Joyride from 'react-joyride';
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";

const RolesListing = () => {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const currentPage = Number(searchParams.get('page')) || 0;
    const [reload, setReload] = useState(false);

    const navigate = useNavigate();
    const urlState = location.state;

    const _token = localStorage.getItem('lmstoken');

    const [roles, setRoles] = useState('');
    const [loading, setLoading] = useState(false);
    const [total_count, setTotalCount] = useState(0);
    const [existingShow, setExistingShow] = useState(false);

    const [course_id, setCourseId] = useState('');
    const [role_id, setRoleId] = useState('');
    const [course_title, setCourseTitle] = useState('');
    const [role_title, setRoleTitle] = useState('');
    const [tour_steps, setTourSteps] = useState([]);
    const user = JSON.parse(localStorage.getItem('lmsuser'));
    useEffect(() => {
        fetchRoles(currentPage);
        //showRolesTour();
    }, [location, reload]);
    const showRolesTour = () => {
        let show_tour = Cookies.get("show_roles_tour");  
        if(show_tour != 'NO'){
            SwalBox.fire({
                allowOutsideClick: false,
                title: 'Would you like to have a quick tour?',
                text: 'This tour will take you through all the menu items in Roles & Responsibility section and introduce you with the features.',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#30314c',
                cancelButtonColor: '#30314c',
                confirmButtonText: 'Yes, please.',
                cancelButtonText:'No, thank you.'
            }).then((result) => {
                if (result.isConfirmed) {
                    startRolesTour();
                }else{
                    Cookies.set('show_roles_tour', 'NO');
                }
            });
        }
    }
    const startRolesTour = () => {
        let steps= [];
        if (user?.is_corporate === 1) {
            steps= [
                {placementBeacon : 'right',disableBeacon : true,target: '.tour-roles-step-01',title : '',content: "",},
                {placementBeacon : 'right',disableBeacon : true,target: '.tour-roles-step-02',title : '',content: "",},
                {placementBeacon : 'right',disableBeacon : true,target: '.tour-roles-step-03',title : '',content: "",},
                {placementBeacon : 'right',disableBeacon : true,target: '.tour-roles-step-04',title : '',content: "",},
                {placementBeacon : 'right',disableBeacon : true,target: '.tour-roles-step-05',title : '',content: "",},
           ];
        }else{
            steps= [];
        }
        setTourSteps(steps);
        Cookies.set('show_roles_tour', 'NO');
    }

    const funReload = (page) => {
        setSearchParams({ ...searchParams, page: page });
        setReload(!reload);
    }
    const fetchRoles = async (page = 0) => {
        setLoading(true);
        const response = await fetch(`${API_BASE}role/get?page=${page}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();

        if (data.status) {
            setRoles(data.data.roles);
            setTotalCount(data.data.total_count);
        } else {
            Toaster.error(data.message);
            setTotalCount(0);
        }

        setLoading(false);
    }


    const ConfirmDeleteRole = (role_id) => {
        SwalBox.fire({
            title: 'Are you sure?',
            text: 'All the current employees in this role will be transitioned to regular employee status? Are you sure you wish to proceed with deleting this role?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteRole(role_id);
            }
        });
    }

    const deleteRole = async (id) => {
        const response = await fetch(`${API_BASE}role/delete/${id}`, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();
        if (data.status) {
            Toaster.success("Deleted successfully");
            fetchRoles();
        } else {
            Toaster.error(data.message);
        }
    }











    return (
        <>
            <Helmet>
                <title>{'EduKula - Roles & Responsibilities'}</title>
            </Helmet>
            <div className="col-lg-9 home-section">
                <div className="custom-user-profile-content custom-learning-circle-content chatPage vacancypage">
                    <div className="custom-learnig-circle-head">
                        <h4>Roles & Responsibilities</h4>
                        <Link to={'/dashboard/roles/form'} className="btn btn-circle btn-info tour-vacancy-step-01">Create a New Role</Link>
                    </div>
                    <div className="chatbox">
                        <div className="vacancy_list">
                            {
                                loading
                                    ?
                                    <RolesListSkeleton count={4} />
                                    :
                                    <>
                                        {
                                            (roles !== '' && roles !== null && typeof roles !== 'undefined')
                                                ?
                                                roles.map((role, index) =>
                                                    <div key={index} className="join-invite-list">
                                                       <div className="join-invite-user">
                                                            <div className="join-invite-content pl-2 rounded-left" style={{ borderLeft: "3px solid #ff3a43" }}>
                                                                <p className="mb-0"><strong>Role Name: </strong>{role.name}</p>
                                                                <p className="mb-0"><strong>Responsibilities : </strong><br></br>
                                                                {role.manage_employees == 1 ? <>- Can manage Employees<br></br></>:''}
                                                                {role.manage_roles == 1 ? <>- Can manage Roles & Responsibilities<br></br></>:''}
                                                                {role.manage_reports == 1 ? <>- Can download Reports<br></br></>:''}
                                                                {role.manage_courses == 1 ? <>- Can manage Courses<br></br></>:''}
                                                                {role.manage_vacancies == 1 ? <>- Can manage Vacancies<br></br></>:''}
                                                                {role.manage_circles == 1 ? <>- Can manage Learning Circles<br></br></>:''}
                                                                
                                                                
                                                                
                                                                
                                                                <span></span>
                                                                
                                                                
                                                                </p>
                                                            </div>

                                                        </div>
                                                        <div className="join-invite-action">
                                                            <Link to={`/dashboard/roles/detail/${role.role_id}`} className="btn btn-circle btn-warning mr-2 ml-2 tour-vacancy-step-04">Edit</Link>
                                                            <button onClick={(e) => { ConfirmDeleteRole(role.role_id) }} className="btn btn-circle btn-info tour-vacancy-step-05">Remove</button>
                                                        </div>
                                                    </div>
                                                )
                                                :
                                                <div className="col-12">
                                                    <div className="empty_state">
                                                        <h3>No role listed!</h3>
                                                        <img alt="" src="images/no_courses.png" />
                                                        <p>
                                                            You Haven't Created Any Roles Yet.
                                                        </p>
                                                    </div>
                                                </div>
                                        }
                                    </>
                            }
                        </div>

                        {
                            total_count > 5
                            &&
                            <div className="custom-pagination">
                                <nav aria-label="Page navigation example">
                                    <CustomPagination pageCount={total_count} perPageRecord={5} handlePageClick={(data) => { funReload(data.selected) }} currentPage={currentPage} />
                                </nav>
                            </div>
                        }
                    </div>
                </div>
            </div>




{
            tour_steps.length > 0 
            &&                 
            <Joyride
                steps={tour_steps}
                disableCloseOnEsc={true}
                disableOverlayClose={true}
                showSkipButton={true}
                continuous={true}
                hideCloseButton={true}

                styles={{options: {width: 500,zIndex: 5000,primaryColor: '#31314c',textColor: '#ef4a45',overlayColor: 'rgb(2 1 0 / 69%)',}}}
            />
            }  


        </>
    )
}

export default RolesListing;

