import { useEffect } from "react";
import { useState } from "react";
import { API_BASE } from "../../constants/global";
import ListItem from "./ListItem";

const StudentTab = (props) => {
    const [notification, setNotification] = useState('');
    const _token = localStorage.getItem('lmstoken');
    const [page, setPage] = useState(0);
    const [total_page, setTotalPage] = useState(0);

    useEffect(() => {
        fetchStudentNotifications();
    }, [page]);

    const fetchStudentNotifications = async () => {
        const response = await fetch(`${API_BASE}get-notification-feeds-by-tab/Student?page=${page}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            }
        });

        const data = await response.json();
        if (data.status) {
            setTotalPage(data?.data?.count);
            if (data?.data?.feeds) {
                let alldataArray = data?.data?.feeds;

                if (props.userNotiPreference.course_material === "FALSE") {
                    function check(obj) {
                        if (obj.action !== "Course_Material_Added" && obj.action !== "Course_Assignment_Added" && obj.action !== "Course_Quiz_Added" && obj.action !== "Course_Material_Updated" && obj.action !== "Course_Assignment_Updated" && obj.action !== "Course_Quiz_Updated" && obj.action !== "Course_Material_Deleted" && obj.action !== "Course_Assignment_Deleted" && obj.action !== "Course_Quiz_Deleted") {
                            return obj;
                        }
                    }
                    alldataArray = alldataArray.filter(check);
                }

                if (props.userNotiPreference.course_meeting === "FALSE") {
                    function check(obj) {
                        if (obj.action !== "OTM_Meeting_Created" && obj.action !== "OTM_Meeting_Deleted") {
                            return obj;
                        }
                    }
                    alldataArray = alldataArray.filter(check);
                }

                if (props.userNotiPreference.course_onemeeting === "FALSE") {
                    function check(obj) {
                        if (obj.action !== "OTO_Meeting_Accepted" && obj.action !== "OTO_Meeting_Rejected") {
                            return obj;
                        }
                    }
                    alldataArray = alldataArray.filter(check);
                }
                if (props.userNotiPreference.course_qa === "FALSE") {
                    function check(obj) {
                        if (obj.action !== "Reply_On_Query") {
                            return obj;
                        }
                    }
                    alldataArray = alldataArray.filter(check);
                }
                setNotification([...notification, ...alldataArray]);
                setTotalPage(alldataArray.length);
            }
            props.setStudentCount(0);
            setTimeout(() => {
                readLearningCircleNotifications();
                props.fetchUnreadNotificationCount();
            }, 3000);
            setTimeout(() => {
            }, 200);
        }
    }

    const readLearningCircleNotifications = async () => {
        const response = await fetch(`${API_BASE}set-read-notification-feeds-by-tab/Student`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            }
        });

        const data = await response.json();
        if (data.status) {
            // fetchStudentNotifications();
        }
    }

    const nextPage = () => {
        setPage(page + 1);
    }


    return (
        <>
            <div className="tab-pane fade show active" id="pill-student" role="tabpanel" aria-labelledby="pill-student-tab">
                <div className="course-tab-contents">
                    <div className="course-tabs-pane-block">
                        <div className="user-body">
                            <ul className="noti_box">
                                {
                                    (notification !== '' && notification !== 'undefined' && notification?.length !== 0)
                                        ?
                                        <>
                                            {
                                                notification.map((ntfcn, index) =>
                                                    <ListItem key={index} data={ntfcn} />
                                                )
                                            }

                                            {
                                                notification.length < total_page
                                                &&
                                                <a className="cursor-pointer" onClick={(e) => { nextPage(); }}>Load more</a>
                                            }
                                        </>
                                        :
                                        <li>
                                            <div className="col-12">
                                                <div className="empty_state">
                                                    <h3>No notifications!</h3>
                                                    <img alt="" src="images/no_courses.png" />
                                                </div>
                                            </div>
                                        </li>
                                }
                            </ul>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}
export default StudentTab;
