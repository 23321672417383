import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { API_BASE, LRS_ENDPOINT } from "../../constants/global";
import { Toaster } from "../../entity/GlobalJS";
import moment from 'moment';

//import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
//import { Bar } from 'react-chartjs-2';
//ChartJS.register(ArcElement, Tooltip, Legend);

import {Chart as ChartJS,CategoryScale,LinearScale,BarElement,Title,Tooltip,Legend,} from 'chart.js';
import { Pie , Doughnut } from 'react-chartjs-2';
import { Helmet } from "react-helmet";
ChartJS.register(CategoryScale,LinearScale,BarElement,Title,Tooltip,Legend);
  

const LoginReport = () => {
    const _token = localStorage.getItem('lmstoken');
    const userData = JSON.parse(localStorage.getItem('lmsuser'));
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [submitting, setSubmitting] = useState(false);
    const [graphdata, setGraphdata] = useState(false);
    
    const [file, setFile] = useState('');
    const navigate = useNavigate();
    const [employees, setEmployees] = useState('');
    
    useEffect(() => {
        initialiseGraph();
        if(userData?.is_corporate === 1){
            fetchEmployees();
        }else{

        }
    }, [])
    const initialiseGraph = async () => {
        var _request_json               = {};
            _request_json.agent         = userData.email;
            _request_json.start_date    = null;
            _request_json.end_date      = null;

        const login_report_response = await fetch(`${LRS_ENDPOINT}report/login`, {
            method: 'POST',
            body: JSON.stringify(_request_json)
        });
    
        const login_report_data = await login_report_response.json();
        if (login_report_data.status) {
            let download = login_report_data.data?.file_path ? login_report_data.data?.file_path : '';
            setFile(download);
            //console.info(login_report_data.data);
            Toaster.success(login_report_data.message);

            let csv_r = login_report_data.data?.csv_temp_row;
            const graph_labels      = [];
            const _login_dataset    = [];
            const _logout_dataset   = [];

            for (const [i, grp_activity] of csv_r.entries()) {
                try {
                    var _activity_date = grp_activity['1'];
                    var _activity_time = grp_activity['2'];
                    var _activity_type = grp_activity['3'];
                    var converted_date = new Date(_activity_date+' 00'+_activity_time).toISOString().split('.')[0];   //console.info(converted_date);
                    var converted_date_time = new Date(converted_date).getTime();
                    moment.globalTimezone = 'Asia/Kolkata';
                    var converted_date_m = moment(converted_date);
                    //console.info(converted_date_m.format('MM-DD-YYYY h:m'));
                    if(_activity_type == 'login'){
                        _login_dataset.push(converted_date);
                    }else{
                        _logout_dataset.push(converted_date);
                    }
                    graph_labels.push(_activity_date);
                }catch(err) { }
            }
            var _final_labels = graph_labels.filter(onlyUnique);
            //console.info(_login_dataset);    
            //console.info(_logout_dataset); 
            //console.info(_final_labels);
            //setGraphdata(gdata);
            //console.info(graphdata);
        
        



            const data_1 = {
                labels: [
                    'Login',
                    'Logout',
                  ],
                datasets: [
                  {
                    label: 'Total in a Day ',
                    data: [_login_dataset.length, _logout_dataset.length],
                    backgroundColor: [
                      'rgb(255, 99, 132)',
                      'rgb(54, 162, 235)',
                    ],
                    pointStyle: 'circle',
                    pointRadius: 10,
                    pointHoverRadius: 15
                  }
                ]
            };
            const config = {
                type: 'doughnut',
                data: data_1,
              };
            setGraphdata(data_1);
            //console.info(data_1);
        
        
        
        
        
        } else {
            setFile('')
            Toaster.error(login_report_data.message);
        }
        //alert(_request_json.agent);
    }
    
    
    const clearForm = () => {
        /*
        setValue('timezone', userData?.timezone);
        setValue('title');
        setValue('agenda');
        setValue('date');
        setValue('time');
        setValue('duration');
        */
    }
    const setEndDate = (e) => {
        document.getElementById(`end_date`).setAttribute('min', e.target.value);
    }
    const fetchEmployees = async (page = 0, search = "") => {
        const response = await fetch(`${API_BASE}employee/get?page=${page}&search=${search}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });
        const data = await response.json();
        if (data.status) {
            setEmployees(data.data.employees);
        } else {

        }
    }
    const exportData = async (form) => {
        var _form_data      = form;
        if(userData?.is_corporate != 1){
            _form_data.agent    = userData.email;
        }
        if(_form_data.agent == 'all'){
            _form_data.agent_list = [];
            employees && employees.map((employee, index) =>
            _form_data.agent_list.push(employee.email)
            )
        }
    }
    
    function onlyUnique(value, index, array) {
        return array.indexOf(value) === index;
    }

    return(
        <>
            <Helmet>
                <title>{'EduKula - Login Report'}</title>
            </Helmet>
            <div className="col-lg-9">
                <div className="custom-user-profile-content custom-learning-circle-content tutor_dashboard">
                    <div className="custom-learnig-circle-head">
                        <h4 className="generate-report">Login Report</h4>
                        <NavLink to={`/dashboard/reports`} className="btn btn-circle btn-md submit-pink-btn back-button-course float-right pl-3 pr-3 pt-1 pb-1 mt-2"><i className="fa fa-arrow-left" aria-hidden="true"></i> Back to Reports Dashboard</NavLink>
                    </div>
                    <div className="learning-circle-courses">
                        <section>
                            <div className="explore-section">
                                <div className="container">
                                    <div className="explore-block" style={{'border': 'none'}}>
                                        <div className="blog-card">
                                            {
                                            file !== ''
                                            &&
                                            <>
                                            <div className="row">
                                                <div className="col-lg-4">                                          
                                                <Doughnut data={graphdata} />
                                                <p><a href={file} className="dlt_btn" download>Click to download</a></p>
                                                {                                             
                                                /*
                                                <Bar options={options} data={data} />
                                                <Line options={options} data={data} />
                                                <Pie data={data} />
                                                <Doughnut data={data} />
                                                */
                                                }

                                                </div>
                                            </div>
                                            </>
                                            }
                                            
                                            <form onSubmit={handleSubmit(exportData)}>
                                            { /*}
                                            <div className="row">
                                                {
                                                userData?.is_corporate === 1
                                                &&
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <label htmlFor={'uid'}><b>Employee</b></label>
                                                        <select {...register("agent")} id={'uid'} className="form-control" required>
                                                            {
                                                                employees && employees.map((employee, index) =>
                                                                    <option key={index + 1} value={employee.email}>{employee.first_name} {employee.last_name} </option>
                                                                )
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                }
                                                <div className="col-md-12 pb-5">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="start_date"><b>From</b></label>
                                                                <input {...register("start_date")} id="start_date" type="date" className="form-control" onChange={(e) => { setEndDate(e) }} placeholder="Date*" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="end_date"><b>To</b></label>
                                                                <input {...register("end_date")} id="end_date" type="date" className="form-control" placeholder="Date*" />
                                                            </div>
                                                        </div>
                                                        </div>
                                                    <button type="submit" className="btn btn-circle btn-md submit-pink-btn">Download Report</button>
                                                </div>
                                            </div>
                                            */ }
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    )
}
export default LoginReport;
