import { useEffect, useState } from "react";
import { API_BASE } from "../../../constants/global";
import parse from 'html-react-parser';

const Vacancy = ({ course }) => {
    const _token = localStorage.getItem('lmstoken');
    const [vacancy, setVacancy] = useState('');

    useEffect(() => {
        var vacancy = course?.vacancy ? course?.vacancy[0] : '';
        setVacancy(vacancy);
    }, []);

    return (
        <>
            <div className="tab-pane fade show active" id="pills-prerequisites" role="tabpanel" aria-labelledby="pills-prerequisites-tab">
                <div className="course-tab-contents">
                    <div className="course-tabs-pane-block">
                        <h3>Vacancy</h3>
                        <div className="course-tabs-box">
                            <div className="course-tabs-body">
                                <h4>{vacancy?.job_position}</h4>
                                <div className="course-accordion-section">
                                    <ul className="about-course-list d-block mb-0">
                                        <li><div><strong>Experience :</strong><span>{vacancy?.experience}</span></div></li>
                                    </ul>
                                    {
                                        vacancy?.description !== ''
                                        &&
                                        <p className="mt-0">
                                            <strong>Description : </strong>
                                            <span className="vacancy-details-li-set">{parse(vacancy?.description ? vacancy?.description : '')}</span>
                                        </p>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Vacancy;