import { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import { API_BASE } from "../../constants/global";
import { Toaster } from "../../entity/GlobalJS";
import ReCAPTCHA from 'react-google-recaptcha';
import { Helmet } from "react-helmet";


const ContactUs = () => {
    const { register, handleSubmit, formState: { errors }, setValue, clearErrors, control, setError } = useForm();
    const recaptchaRef = useRef();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [is_agree, setIsAgree] = useState(0);
    const [is_submitting, setSubmitting] = useState(false);
    const [recaptchaValue, setRecaptchaValue] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    async function saveContactUs(form) {
        if (recaptchaValue === null) {
            setError('recaptcha', {
                type: 'manual',
                message: 'Please complete the reCAPTCHA verification.'
            });
            return;
        }
        setSubmitting(true);
        const response = await fetch(API_BASE + 'post-contact-us', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(form)
        });
        clearErrors('recaptcha');
        const data = await response.json();
        if (data.status) {
            Toaster.success(data.message);
            if (recaptchaRef.current) {
                recaptchaRef.current.reset();
            }
            setValue('name', '');
            setValue('email', '');
            setValue('message', '');
            setIsAgree(0);
            setSubmitting(false);

        } else {
            if (recaptchaRef.current) {
                recaptchaRef.current.reset();
            }
            Toaster.error(data.message);
            setSubmitting(false);
        }
    }

    return (
        <>
            <Helmet>
                <title>{'EduKula - Contact Us'}</title>
            </Helmet>
            {/* BREADCRUMB */}
            <section className="custom-section">
                <div className="custom-breadcrumb">
                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </section>
            {/* BREADCRUMB */}

            {/* CONTACT DETAILS */}
            <section className="custom-section">
                <div className="conatct-box-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="contact-detail-box phone-details">

                                    <div className="contact-box-info">
                                        <div className="contact-box-header red-box">
                                            <i className="fas fa-phone-volume"></i>
                                        </div>
                                        <div className="contact-box-body">
                                            <h3>Phone Number</h3>
                                            <p><a href="tel:+44 20 8144 6541">+44 20 8144 6541</a></p>
                                            <p><a href="tel:+1 586 840 6316">+1 586 840 6316</a></p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="contact-detail-box mail-details">

                                    <div className="contact-box-info">
                                        <div className="contact-box-header blue-box">
                                            <i className="far fa-envelope"></i>
                                        </div>
                                        <div className="contact-box-body">
                                            <h3>Email</h3>
                                            <p><a href="mailto:support@smartvisibility.com">support@smartvisibility.com</a></p>
                                            <p><a href="mailto:info@smartvisibility.com">info@smartvisibility.com</a></p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="contact-detail-box address-details">

                                    <div className="contact-box-info">
                                        <div className="contact-box-header black-box">
                                            <i className="fas fa-map-signs"></i>
                                        </div>
                                        <div className="contact-box-body">
                                            <h3>Address & Hours</h3>
                                            <p>FF-107, Abhishree Adroit, Mansi Circle, Vastrapur, Ahmedabad - 380015. Gujarat, India.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* CONTACT DETAILS */}

            {/* CONTACT FORM */}
            <section className="custom-section">
                <div className="contact-form-section">
                    <div className="container">
                        <h3 className="text-center">Send a message</h3>
                        <p className="text-center custom-section">YOUR EMAIL ADDRESS WILL NOT BE PUBLISHED. REQUIRED FIELDS ARE MARKED</p>
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="contact-form-details">
                                    <div className="contact-form-img">
                                        <img src="images/contact-form.png" alt="Contact" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="contact-form-details">
                                    <div className="contact-form-desc">
                                        <form onSubmit={handleSubmit(saveContactUs)}>
                                            <div className="form-group">
                                                <input {...register("name", {
                                                    required: "required",
                                                    pattern: {
                                                        value: /^[a-z A-Z]+$/,
                                                        message: "Please enter valid name."
                                                    },
                                                    maxLength: {
                                                        value: 30,
                                                        message: "Name can not be more than 30 charaters."
                                                    }
                                                })} onChange={(event) => { setName(event.target.value); clearErrors('name'); }} type="text" className="form-control" placeholder="Your Name" defaultValue={name ? name : ''} />
                                                <span className="text-danger">
                                                    {errors?.name && errors?.name?.type === "required" && 'Please enter your name.'}
                                                    {errors?.name && errors?.name?.type !== "required" && errors?.name?.message}
                                                </span>
                                            </div>
                                            <div className="form-group">
                                                <input {...register("email", {
                                                    required: "required",
                                                    pattern: {
                                                        value: /\S+@\S+\.\S+/,
                                                        message: "Entered value does not match email format"
                                                    }
                                                })} onChange={(event) => { setEmail(event.target.value); clearErrors('email'); }} type="text" className="form-control" placeholder="Your Email" defaultValue={email ? email : ''} />
                                                <span className="text-danger">
                                                    {errors.email && errors.email.type === "required" && 'Please enter your email.'}
                                                    {errors.email && errors.email.type !== "required" && errors.email.message}
                                                </span>
                                            </div>
                                            <div className="form-group">
                                                <textarea {...register("message", {
                                                    required: "required",
                                                    maxLength: {
                                                        value: 500,
                                                        message: "Message can not be more than 500 charaters."
                                                    }
                                                })} onChange={(event) => { setMessage(event.target.value); clearErrors('message'); }} className="form-control" name="message" placeholder="Message" rows="7" data-form-field="Message" defaultValue={message ? message : ''}></textarea>
                                                <span className="text-danger">
                                                    {errors.message && errors.message.type === "required" && 'Please enter your message.'}
                                                    {errors.message && errors.message.type !== "required" && errors.message.message}
                                                </span>
                                            </div>
                                            <div className="form-group">
                                                <Controller
                                                    name="recaptcha"
                                                    control={control}
                                                    render={({ onChange, onBlur }) => (
                                                        <ReCAPTCHA
                                                            ref={recaptchaRef}
                                                            sitekey="6Lc7XD4lAAAAAA5YHYwBCSEJA9Aj3sGE90EXLNml"
                                                            onChange={(value) => { setRecaptchaValue(value); clearErrors('recaptcha') }}
                                                            onExpired={() => { setRecaptchaValue(null) }}
                                                        />
                                                    )}
                                                />
                                                {errors.recaptcha && <p className='text-theme_red font-WorkSans_Regular'>
                                                    <span className="text-danger">{errors.recaptcha.message}</span></p>}
                                            </div>
                                            <div className="form-group">
                                                <div className="form-desc">
                                                    <input type="checkbox" {...register("agree", { required: "required" })} onChange={(e) => { setIsAgree(e.target.checked ? 1 : 0); }} id="contactform_terms" checked={is_agree === 1 ? 'checked' : ''} defaultValue={1} />
                                                    <label htmlFor="contactform_terms" className="mb-0"><p>I agree to the <Link to="/pages/terms-of-use"> Terms of Use </Link> and have read the <Link to="/pages/privacy-policy"> Privacy Policy. </Link></p></label>
                                                </div>
                                                <span className="text-danger">
                                                    {errors.agree && errors.agree.type === "required" && 'Please agree to Terms of Use & Privacy Policy.'}
                                                </span>
                                            </div>
                                            <button disabled={is_submitting ? true : false} type="submit" className="btn btn-circle btn-success">{is_submitting ? <i className="fas fa-spinner fa-spin"></i> : 'Submit'}</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* CONTACT FORM */}
        </>
    );
}

export default ContactUs;
