import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

const ReportLayout = () => {
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('lmsuser'));
    useEffect(() => {        
        
    }, []);
    return (
        <>
        <Outlet />
        </>
    );
}

export default ReportLayout;