import { Link } from "react-router-dom";

const CourseNotPublished = ({ ntfcn }) => {
    return (
        <>
            <div className="user-body">
                <div className="user-header">
                    <div className="user-profile-img">
                        <img src="images/logo_icon.png" className="rounded-circle" alt="Profile Image" />
                    </div>
                    <div className="user-details-title">
                        <h6>EduKula <span>{ntfcn.date_time}</span></h6>
                        <p>Course - {ntfcn?.course?.title}</p>
                    </div>
                </div>
                <div className="user-content-block">
                    <div className="user-course-desc">
                        <p>Your <strong>{ntfcn?.course?.title}</strong> course requires some fixes in order to published on the EduKula.</p>
                    </div>
                    <div className="user-course-block">
                        <Link className="link" to={'/dashboard/tutor/edit-course/' + ntfcn?.course?.id} target="_blank">
                            <img src={ntfcn?.course?.image} alt={ntfcn?.course?.title} />
                            <span>View This Course</span>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CourseNotPublished;