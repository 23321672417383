import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { PDF_PREFIX } from "../../../constants/global";
const url = "https://cors-anywhere.herokuapp.com/http://www.pdf995.com/samples/pdf.pdf";

const SinglePage = (props) => {
      
    pdfjs.GlobalWorkerOptions.workerSrc = 
    `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [loadSucces, setloadSucces] = useState(0);
    const { pdf, title } = props;
    // const pdf = "http://www.pdf995.com/samples/pdf.pdf";
    /*To Prevent right click on screen*/
    document.addEventListener("contextmenu", (event) => {
      event.preventDefault();
    });
      
    /*When document gets loaded successfully*/
    function onDocumentLoadSuccess({ numPages }) {
      setloadSucces(1);
      setNumPages(numPages);
      setPageNumber(1);
    }
    
    function changePage(offset) {
      setPageNumber(prevPageNumber => prevPageNumber + offset);
    }
    
    function previousPage() {
      changePage(-1);
    }
    
    function nextPage() {
      changePage(1);
    }
    
    return (
      <>
      <div className="main">
        {
          loadSucces != 2
          &&
          <Document file={PDF_PREFIX+pdf} onLoadSuccess={onDocumentLoadSuccess} onLoadError={(error) => /*console.log("Inside Error", error)*/ setloadSucces(2) }>
            <Page pageNumber={pageNumber} />
          </Document>
        }
        {
          loadSucces == 1
          &&
          <div>
            <div className="pagec">
              Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
            </div>
            <div className="buttonc">
              <button type="button" disabled={pageNumber <= 1} onClick={previousPage} className="Pre">Previous</button>
              <button type="button" disabled={pageNumber >= numPages} onClick={nextPage}>Next</button>
            </div>
          </div>
        }
        {
          loadSucces == 2
          &&
          <p>Download: <a className="btn btn-circle btn-success coman_btn m-0" href={pdf} target="_blank" download>{title} <i className="fas fa-download"></i></a></p>
        }
      </div>
      </>
    );
  }
export default SinglePage;