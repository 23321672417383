import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { API_BASE } from "../../constants/global";
import NewReleaseCourses from "../dashboard/NewReleaseCourses";
import TrendingLearningCircle from "../dashboard/TrendingLearningCircle";
import LearningCircleTab from "./LearningCircleTab";
import StudentTab from "./StudentTab";
import TutorTab from "./TutorTab";
import { stickyArea } from '../dashboard/scroll';
import Joyride from 'react-joyride';
import Cookies from "js-cookie";
import { SwalBox, Toaster } from "../../entity/GlobalJS";
import { Helmet } from "react-helmet";

const NotificationListing = () => {
    const [fetchUnreadNotificationCount] = useOutletContext();
    const [active_tab, setActiveTab] = useState(1);
    const [studentCount, setStudentCount] = useState(0);
    const [tutorCount, setTutorCount] = useState(0);
    const [learningCircleCount, setLearningCircle] = useState(0);
    const user = JSON.parse(localStorage.getItem('lmsuser'));
    const _token = localStorage.getItem('lmstoken');
    const [tour_steps, setTourSteps] = useState([]);
    const [userNotiPreference, setUserNotiPreference] = useState('');

    useEffect(() => {
        fetchNotification();
        fetchStudentNotifications();
        fetchTutorNotifications();
        fetchLearningCircleNotifications();
        if (user?.is_corporate === 1) { setActiveTab(2) }

        stickyArea();
        showNotificationTour();
    }, [])
    const showNotificationTour = () => {
        let show_tour = Cookies.get("show_notification_tour");
        if (show_tour != 'NO') {
            SwalBox.fire({
                allowOutsideClick: false,
                title: 'Would you like to have a quick tour?',
                text: 'This tour will take you through all the menu items in Notification section and introduce you with the features.',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#30314c',
                cancelButtonColor: '#30314c',
                confirmButtonText: 'Yes, please.',
                cancelButtonText: 'No, thank you.'
            }).then((result) => {
                if (result.isConfirmed) {
                    startNotificationTour();
                } else {
                    Cookies.set('show_notification_tour', 'NO');
                }
            });
        }
    }
    const startNotificationTour = () => {
        let steps = [];
        if (user?.is_corporate === 1) {
            steps = [
                { placementBeacon: 'right', disableBeacon: true, target: '.tour-notification-step-01', title: 'Course Notifications', content: "This tab shows all the notifications related to the courses you have created for employee training and hiring. You can view the details of the notifications and take necessary actions", },
                { placementBeacon: 'right', disableBeacon: true, target: '.tour-notification-step-03', title: 'Learning Circle Notifications', content: "This tab shows all the notifications related to the learning circles you have joined or created. You can view the details of the notifications and take necessary actions.", },
            ];
        } else {
            if (user?.is_tutor === 1) {
                steps = [
                    { placementBeacon: 'right', disableBeacon: true, target: '.tour-notification-step-01', title: 'Student notifications', content: "This tab shows all the notifications related to the courses you have enrolled in for learning. You can view the details of the notifications and take necessary actions.", },
                    { placementBeacon: 'right', disableBeacon: true, target: '.tour-notification-step-02', title: 'Tutor notifications', content: "This tab shows all the notifications related to the courses you have created. You can view the details of the notifications and take necessary actions.", },
                    { placementBeacon: 'right', disableBeacon: true, target: '.tour-notification-step-03', title: 'Learning circle notifications', content: "This tab shows all the notifications related to the learning circles you have joined or created. You can view the details of the notifications and take necessary actions.", },
                ];
            } else {
                steps = [
                    { placementBeacon: 'right', disableBeacon: true, target: '.tour-notification-step-02', title: 'Student notifications', content: "This tab shows all the notifications related to the courses you have enrolled in for learning. You can view the details of the notifications and take necessary actions.", },
                    { placementBeacon: 'right', disableBeacon: true, target: '.tour-notification-step-03', title: 'Learning circle notifications', content: "This tab shows all the notifications related to the learning circles you have joined or created. You can view the details of the notifications and take necessary actions.", },
                ];
            }
        }
        setTourSteps(steps);
        Cookies.set('show_notification_tour', 'NO');
    }

    const fetchStudentNotifications = async () => {
        const response = await fetch(`${API_BASE}get-notification-feeds-by-tab/Student`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            }
        });

        const data = await response.json();
        if (data.status && data?.data?.unread_count) {
            setStudentCount(data?.data?.unread_count);
        }
    }

    const fetchTutorNotifications = async () => {
        const response = await fetch(`${API_BASE}get-notification-feeds-by-tab/Tutor`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            }
        });

        const data = await response.json();
        if (data.status && data?.data?.unread_count) {
            setTutorCount(data?.data?.unread_count);
        }
    }

    const fetchLearningCircleNotifications = async () => {
        const response = await fetch(`${API_BASE}get-notification-feeds-by-tab/Learning_Circle`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            }
        });

        const data = await response.json();
        if (data.status && data?.data?.unread_count) {
            setLearningCircle(data?.data?.unread_count);
        }
    }

    const fetchNotification = async () => {
        let formData = {};
        const _lmsuser = JSON.parse(localStorage.getItem('lmsuser'));
        const _lms_learner_id = _lmsuser['id'];
        formData.user_id = _lms_learner_id;


        const response = await fetch(`${API_BASE}get-notification-preferences`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
            body: JSON.stringify(formData)

        });

        const data = await response.json();
        if (data.status) {
            setUserNotiPreference(data.data);
        } else {

        }
    }

    return (
        <>
            <Helmet>
                <title>{'EduKula - Notification'}</title>
            </Helmet>
            <div className="col-lg-9 home-section">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="custom-user-profile-content notification_dashboard">
                            <div className="course-details-tab-block explore-block">
                                <ul className="nav nav-pills custom-section" id="pills-tab" role="tablist">
                                    {
                                        user?.is_corporate !== 1
                                        &&
                                        <li className="nav-item tour-notification-step-01">
                                            <a onClick={(e) => { setActiveTab(1); }} className={`nav-link ${active_tab === 1 ? 'active' : ''}`} id="pills-student-tab" data-toggle="pill" href="#pill-student" role="tab" aria-controls="pill-student" aria-selected="true">Student <span>{studentCount}</span></a>
                                        </li>
                                    }
                                    {
                                        user?.is_tutor === 1
                                        &&
                                        <li className="nav-item tour-notification-step-02">
                                            <a onClick={(e) => { setActiveTab(2); }} className={`nav-link ${active_tab === 2 ? 'active' : ''}`} id="pills-tutor-tab" data-toggle="pill" href="#pills-tutor" role="tab" aria-controls="pills-tutor" aria-selected="false">{user?.is_corporate === 1 ? 'Course' : 'Tutor'} <span>{tutorCount}</span></a>
                                        </li>
                                    }
                                    <li className="nav-item tour-notification-step-03">
                                        <a onClick={(e) => { setActiveTab(3); }} className={`nav-link ${active_tab === 3 ? 'active' : ''}`} id="pills-circle-tab" data-toggle="pill" href="#pills-circle" role="tab" aria-controls="pills-circle" aria-selected="false">Learning Circle <span>{learningCircleCount}</span></a>
                                    </li>
                                </ul>
                                <div className="tab-content" id="pills-tabContent">
                                    {userNotiPreference !== '' && (<>
                                        {
                                            (active_tab === 1)
                                            &&
                                            <StudentTab setStudentCount={setStudentCount} fetchUnreadNotificationCount={fetchUnreadNotificationCount} userNotiPreference={userNotiPreference} />
                                        }
                                        {
                                            (active_tab === 2 && user?.is_tutor === 1)
                                            &&
                                            <TutorTab setTutorCount={setTutorCount} fetchUnreadNotificationCount={fetchUnreadNotificationCount}
                                                userNotiPreference={userNotiPreference}
                                            />
                                        }
                                        {
                                            (active_tab === 3)
                                            &&
                                            <LearningCircleTab setLearningCircle={setLearningCircle} fetchUnreadNotificationCount={fetchUnreadNotificationCount} userNotiPreference={userNotiPreference} />
                                        }
                                    </>)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div data-sticky="true" data-top-gap="10" data-bottom-gap="10" className="custom-user-profile-content sticky-top">
                            {
                                user?.is_corporate !== 1
                                &&
                                <NewReleaseCourses />
                            }
                            <TrendingLearningCircle />
                        </div>
                    </div>
                </div>
            </div>

            {
                tour_steps.length > 0
                &&
                <Joyride
                    steps={tour_steps}
                    disableCloseOnEsc={true}
                    disableOverlayClose={true}
                    showSkipButton={true}
                    continuous={true}
                    hideCloseButton={true}

                    styles={{ options: { width: 500, zIndex: 5000, primaryColor: '#31314c', textColor: '#ef4a45', overlayColor: 'rgb(2 1 0 / 69%)', } }}
                />
            }

        </>
    );
}

export default NotificationListing;
