import { useEffect, useLayoutEffect, useState, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { API_BASE, LRS_ENDPOINT } from "../constants/global";
import parse from 'html-react-parser';
import { Toaster,SwalBox } from "../entity/GlobalJS";
import { useForm } from "react-hook-form";
import loadingGif from "../assets/images/load.gif";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Quiz from 'react-quiz-component';
import VideoRecorder from 'react-video-recorder';
import { async } from "q";
import ProgressBar from 'react-bootstrap/ProgressBar';


const _token = localStorage.getItem('lmstoken');
const _lrs_user_info = JSON.parse(localStorage.getItem('lrsuser'));


const CoursePlay = ({ course, expier, certified, gotoNextSlide }) => {
    const { _slug } = useParams();
    const { _slide } = useParams();
    const location = useLocation();
    const [slide, setSlide] = useState('');
    const [processing, setProcessing] = useState(false);
    const handle = useFullScreenHandle();
    const [isPlaying, setIsPlaying] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [verified, setVerified] = useState(false);
    const [isKYCComplete, setisKYCComplete] = useState(false);
    const [retryKYC, setRetryKYC] = useState(false);
    const [hideRekycMessage, setHideRekycMessage] = useState(false);
    const [messageToShow, setMessageToShow] = useState('');
    const [isLoader, setIsLoader] = useState(false);
    const [messageContent, setMessageContent] = useState('');
    const [dynamicStatusMessage, setDynamicStatusMessage] = useState("Preparing to upload video...");
    const [progressBar, setProgressBar] = useState(10);
    const [showClose, setShowClose] = useState(true);
    const [notMoreAttempt, setNotMoreAttempt] = useState(false);
    const [previousAttemptScore, setPreviousAttemptScore] = useState(null);
    const [key, setKey] = useState(1);
    const [currentSlideIDEnc, setCurrentSlideIDEnc] = useState('');
    let initialAttempt = null;

    useEffect(() => {
        // fetchSlide();
    }, []);

useLayoutEffect(() => {
        getProctoringData();
        fetchQuiz();
        if(_slide != undefined){
            fetchSlide();
        }else{
            setTimeout(() => {
                fetchSlide();
            }, 2000);
        }

        const Urlsegments = location.pathname.split('/');
        const lastUrlsegment = Urlsegments[Urlsegments.length - 1];
        const UpdateTime = setInterval(() => { sendCourseDuration(lastUrlsegment) }, 15000);
        return () => {
            clearInterval(UpdateTime);
        };
    }, [location.pathname]);

    function getExtension(filename) {
        return filename.split('.').pop()
    }
    const sendCourseDuration = async (lastUrl) => {
        try{
            if (document.visibilityState === 'visible') {
                let _course_slug = _slug;
                var formData = new FormData();
                formData.append('slug', _course_slug);
                formData.append('slide', lastUrl);
                formData.append('agent', _lrs_user_info.email);
                const response = await fetch(`${LRS_ENDPOINT}statements/update-course-duration`, {
                    method: 'POST',
                    headers: {
                        "Authorization": `Bearer ${_token}`
                    },
                    body: formData
                });
                const data = await response.json();
                if (data.status && data.data.submitted) {
    
                }
               }
        }catch(e){ }
    }
    const fetchSlide = async () => {
        try {
            if (_slug !== '' && typeof _slug !== 'undefined' && _slide != undefined) {
                setProcessing(true);
                const response = await fetch(`${API_BASE}get-single-material/${_slug}/${_slide}`, {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": `Bearer ${_token}`
                    },
                });
    
                const data = await response.json();
                if (data.status) {
                    setProcessing(false);
                    setSlide(data.data.slide);
                    setCurrentSlideIDEnc(data.data.slide.id);
                }
            }
        }catch(err) {  window.location.reload(); }
    }

    const clickOnNextSlide = async () => {
        document.getElementById('ek-next-float-right').click();
    }
    const clickOnPreviousSlide = async () => {
        document.getElementById('ek-previous-float-left').click();
    }
    //const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = useRef(null);
    /*
    const togglePlay = () => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
            } else {
                videoRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };
    */
    const CouVideoRef = useRef(null);
    const CourseVideo = document.querySelector('.CourseVideo');

    const handleCouPlay = () => {
        if (CourseVideo.paused) {
            CouVideoRef.current.play();
            setIsPlaying(true);
        } else {
            CouVideoRef.current.pause();
            setIsPlaying(false);
        }
    }

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const VerifyPopup = () => {
        document.getElementById('ratingModalCenterclick').click();

    };

    const handleratingModalClose = () => {
        setKey(prevKey => prevKey + 1);
    }

    const GivePermission = () => (
        <div>
            In order to complete your Verification process, you need to give permission to your camera and microphone.
            <br />
            <br />
            Please restart it and try again 👍
        </div>
    )


    const sendVerifyVideo = async (videoBlob) => {
        setIsLoader(true);
        setTimeout(() => {
            setProgressBar(30);
            setDynamicStatusMessage('We\'re uploading your video securely...');
        }, 5000);

        setTimeout(() => {
            setProgressBar(60);
            setDynamicStatusMessage('Analyzing your video for verification...');
        }, 11000);

        setTimeout(() => {
            setProgressBar(90);
            setDynamicStatusMessage('Verifying the status. Please wait a moment...');
        }, 18000);




        const formData = new FormData();
        formData.append('videoKYC', videoBlob);

        const response = await fetch(`${API_BASE}validate-kyc-video`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const data = await response.json();
        console.log(data);
        if (data.data.kyc.verification_status) {
            setMessageToShow(data.data.kyc.message_header_text);
            setMessageContent(data.data.kyc.message_content_text);
            setisKYCComplete(true);
            setRetryKYC(false);
            setIsLoader(false);
        } else if (data.data.kyc.verification_status === false) {
            setMessageToShow(data.data.kyc.message_header_text);
            setMessageContent(data.data.kyc.message_content_text);
            setShowClose(true);
            setisKYCComplete(true);
            setRetryKYC(true);
            setIsLoader(false);
            setDynamicStatusMessage("Preparing");
            setProgressBar(10);
        }

    }

    const StartQuiz = () => {
        document.getElementById('ratingModalCenterClose').click();
        setVerified(true);
        // document.getElementById('quizName').click();
    }
    const handleReKYCMessage = () => {
        setHideRekycMessage(true);
        setShowClose(false);
    }

    const handleVideoKYCStart = () => {
        setShowClose(false);
    }

    const getProctoringData = async () => {
        const formData = new FormData();
        formData.append('slide_id', _slide);

        const response = await fetch(`${API_BASE}get-proctoring`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const data = await response.json();
        if (data.status) {
            try {
                initialAttempt = data.data.attempt_id;
                if (data.data.attempt_id > 1) {
                    setNotMoreAttempt(true);
                } else if (data.data.attempt_id <= 1) {
                    setNotMoreAttempt(false);
                }
            } catch (e) {}
        } else {
        }
    }
    const fetchQuiz = async () => {
        if (_slide !== '' && typeof _slide !== 'undefined') {
            const response = await fetch(`${API_BASE}get-submitted-quiz/${_slide}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + _token
                },
            });
            const data = await response.json();
            if (data.status) {
                console.info(data, "This is fetch quiz");
                let tCount = data.data.quiz.total_count;
                let rCount = data.data.quiz.right_count;
                let _percentage_f = (100 * rCount) / tCount;
                let _percentage = _percentage_f.toFixed(2);
                setPreviousAttemptScore(_percentage);
            } else {
                // setSubmitted('');
            }
        }
    }

    const handleVideoComplete = () => {
        gotoNextSlide(currentSlideIDEnc)
    }
    return (
        <FullScreen handle={handle}>
            <div className="course-video-block border p-3">
                <>
                    {
                        slide?.material?.material_type !== 'Video'
                        &&
                        <>
                            {
                                handle.active
                                    ?
                                    <button className="btn btn-default btn-l" title="Default Screen" id="smallScreenClick" onClick={handle.exit}>
                                        <i className="fa fa-compress"></i>
                                    </button>
                                    :
                                    <button className="btn btn-default" title="Full Screen" id="fullScreenClick" onClick={handle.enter}>
                                        <i className="fa fa-expand"></i>
                                    </button>
                            }
                        </>
                    }


                    {
                        slide !== '' && processing == false
                            ?
                            <>
                                {
                                    ((slide.material.material_type === 'Text' || slide.material.material_type === 'Quiz') && slide.material.material !== '')
                                    &&
                                    <>
                                        <div className="txt_content_box">
                                            <span className="ek-modal-content-text-material" id="quizName">{parse(slide.material.material)}</span>
                                            {
                                                slide.material.material_type === 'Quiz'
                                                &&
                                                (verified ? (notMoreAttempt ? (<>
                                                    {previousAttemptScore !== null && <p>Your Previous attempt score is {previousAttemptScore} percentage</p>}
                                                    <p className="QuizVerifyMessage textColorRed">
                                                        you have exhausted the maximum allowable attempts for this quiz.
                                                    </p>
                                                </>) : (<><QuizBox slide={slide} expier={expier} certified={certified} /></>)) : (
                                                    notMoreAttempt ? (<>
                                                        {previousAttemptScore !== null && <p>Your Previous attempt score is {previousAttemptScore} percentage</p>}
                                                        <p className="QuizVerifyMessage textColorRed">
                                                            you have exhausted the maximum allowable attempts for this quiz.
                                                        </p>
                                                    </>) : (<>
                                                        <div className="QuizVerify">
                                                            <h4>Verification Required</h4>
                                                            <p className="QuizVerifyMessage">
                                                                Hello, we want to ensure the integrity of the exam process. To continue, please complete a quick verification to confirm you are a real human. This helps us maintain a fair testing environment. Thank you for your cooperation!
                                                            </p>
                                                            <div>
                                                                <button className="btn btn-circle btn-info" type="button" onClick={VerifyPopup}>Verify Now</button>
                                                            </div>
                                                        </div>

                                                        <div className="modal fade ratingpopup" id="ratingModalCenter" tabIndex="-1" role="dialog" aria-labelledby="ratingModalCenterTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                                                            <div className="modal-dialog modal-dialog-centered" role="document">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h4 className="modal-title" id="ratingModalLongTitle">Verification Required</h4>
                                                                        <button type="button" id="ratingModalCenterClose" className={`${showClose ? "" : "d-none"} close`} data-dismiss="modal" aria-label="Close" 
                                                                        // eslint-disable-next-line
                                                                        onClick={() => { handleratingModalClose }}>
                                                                            <span aria-hidden="true">&times;</span>
                                                                        </button>
                                                                    </div>
                                                                    <div className="modal-body pt-2 pb-2">
                                                                        {isKYCComplete === false ?
                                                                            (isLoader ? (<>
                                                                                {/* <i className="fa fa-refresh fa-spin"></i> */}
                                                                                <ProgressBar animated now={progressBar} variant="success" />
                                                                                <h4>Processing...</h4>
                                                                                <h4 className="dynamicMes">{dynamicStatusMessage}</h4>
                                                                            </>) : (<>
                                                                                <div className="videoParent">
                                                                                    <VideoRecorder
                                                                                        key={key}
                                                                                        onRecordingComplete={(videoBlob) => {
                                                                                            sendVerifyVideo(videoBlob);
                                                                                        }}
                                                                                        renderErrorView={GivePermission}
                                                                                        isFlipped={true}
                                                                                        timeLimit={5000}
                                                                                        showReplayControls={true}
                                                                                        replayVideoAutoplayAndLoopOff={true}
                                                                                        disablePictureInPicture
                                                                                        videoControlsList="nodownload"
                                                                                        onStartRecording={handleVideoKYCStart}
                                                                                    />
                                                                                </div>
                                                                            </>)) : (retryKYC === false ?
                                                                                (<>
                                                                                    <h3>{messageToShow}</h3>
                                                                                    <h5>{messageContent}</h5>
                                                                                    <div>
                                                                                        <button className="btn btn-circle btn-info" onClick={StartQuiz}>Start Quiz</button>
                                                                                    </div>
                                                                                </>) : (isLoader ?
                                                                                    (<>
                                                                                        <ProgressBar animated now={progressBar} variant="success" />
                                                                                        <h4>Processing...</h4>
                                                                                        <h4 className="dynamicMes">{dynamicStatusMessage}</h4>
                                                                                    </>) : (<>
                                                                                        <h4 className={`errorMessage ${hideRekycMessage ? 'd-none' : ''}`}>{messageToShow}</h4>
                                                                                        <h5 className={`errorMessage ${hideRekycMessage ? 'd-none' : ''}`}>{messageContent}</h5>
                                                                                        <div className="videoParent">
                                                                                            <VideoRecorder
                                                                                                key={key}
                                                                                                onRecordingComplete={(videoBlob) => {
                                                                                                    sendVerifyVideo(videoBlob);
                                                                                                }}
                                                                                                renderErrorView={GivePermission}
                                                                                                isFlipped={true}
                                                                                                timeLimit={5000}
                                                                                                showReplayControls={true}
                                                                                                replayVideoAutoplayAndLoopOff={true}
                                                                                                disablePictureInPicture
                                                                                                videoControlsList="nodownload"
                                                                                                onStartRecording={handleReKYCMessage}
                                                                                            />
                                                                                        </div>
                                                                                    </>)
                                                                                )
                                                                            )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <a className="d-none" data-toggle="modal" data-target="#ratingModalCenter" id="ratingModalCenterclick"></a>
                                                    </>)
                                                ))
                                            }
                                        </div>
                                    </>
                                }

                                {
                                    (slide.material.material_type === 'Assignment' && slide.material.material !== '')
                                    &&
                                    <AssignmentBox slide={slide} />
                                }

                                {
                                    (slide.material.material_type === 'Photo' && slide.material.material !== '')
                                    &&
                                    <img src={slide.material.material} alt={slide.material.title} data-ek-lrs-title={slide.title} data-ek-lrs-url={btoa(slide.material.material)} />
                                }

                                {
                                    (slide.material.material_type === 'Video' && slide.material.material !== '')
                                    &&
                                    <>
                                        <div className="VidContainer" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                        <video
                                            className="CourseVideo"
                                            id="couVideo"
                                            controlsList="nodownload"
                                            crossOrigin="anonymous"
                                            preload="metadata"
                                            controls
                                            autoPlay
                                            allowFullScreen={false}
                                            data-ek-lrs-title={slide.title}
                                            data-ek-lrs-url=""
                                            ref={CouVideoRef}
                                            onPlay={() => setIsPlaying(true)}
                                            onPause={() => setIsPlaying(false)}
                                            onEnded={handleVideoComplete}
                                        >
                                        <source src={slide.material.material} type="video/mp4" />
                                        {slide.material.material.includes('.mp4') && (
                                            <track
                                                src={slide.material.material.replace('.mp4', '.vtt')}
                                                kind="subtitles"
                                                srcLang="en"
                                                label="English"
                                                default
                                            />
                                        )}
                                        Sorry, your browser doesn't support embedded videos.
                                        </video>
                                            {isHovered && (
                                                <button className="CustVidButton" title="Pause" onClick={handleCouPlay}>
                                                    <i className={`${isPlaying && !CourseVideo.paused ? "fa-solid fa-circle-pause" : "fa-solid fa-circle-play"}`}></i>
                                                </button>
                                            )}
                                        </div>
                                    </>
                                }

                                {
                                    slide.material.material_type === 'Document'
                                        ?
                                        <>
                                            {
                                                ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'].indexOf(getExtension(slide.material.material).toLowerCase()) !== -1
                                                    ?
                                                    getExtension(slide.material.material).toLowerCase() === 'pdf'
                                                        ?
                                                        <iframe className="doc_iframe" id="pdf-js-viewer" src={`${API_BASE.replace('public/api/', '')}pdfjs/web/viewer.html?file=${slide.material.material}`} title="webviewer" frameBorder="0" width="500" height="600"></iframe>
                                                        :
                                                        <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${slide.material.material}`} style={{ width: 100 + '%' }} frameBorder="0"></iframe>
                                                    :
                                                    <p>Download: <a className="btn btn-circle btn-success coman_btn m-0" href={slide.material.material} target="_blank" download>{slide.title} <i className="fas fa-download"></i></a></p>
                                            }
                                        </>
                                        :
                                        ""
                                }

                                {
                                    (slide.material.material_type === 'Youtube')
                                    &&
                                    <div className="iframe_box">
                                        <iframe width="560" height="315" src={`https://www.youtube.com/embed/${slide.material.material}?start=4`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"></iframe>
                                    </div>
                                }

                                {
                                    (slide.material.material_type === 'TinCan')
                                    &&
                                    <div className="iframe_box">
                                        <iframe frameBorder="0" marginHeight="0" marginWidth="0" width="100%" height="500" scrolling="auto" src={`${slide.material.material}#toolbar=0`}>
                                            Your browser doesn't support iframes
                                        </iframe>
                                    </div>
                                }

                                {
                                    (slide.material.material_type === 'Scorm')
                                    &&
                                    <div className="iframe_box">
                                        <iframe frameBorder="0" marginHeight="0" marginWidth="0" width="100%" height="500" scrolling="auto" src={`${slide.material.material}#toolbar=0`}>
                                            Your browser doesn't support iframes
                                        </iframe>
                                    </div>
                                }
                            </>
                            :
                            <><img src={loadingGif} alt="loading" style={{ width: 'auto' }} /></>
                    }
                </>
            </div>
        </FullScreen >
    );
}

export default CoursePlay;

const AssignmentBox = ({ slide }) => {
    const { _slide } = useParams();
    const [submitted, setSubmitted] = useState('');
    const [resubmit, setResubmit] = useState(true);
    const [file, setFile] = useState('');
    const [showresubmitcon, setShowResubmitcon] = useState(false);
    const [assigngrade, setassigngrade] = useState('');
    const [assignscore, setassignscore] = useState('');
    const [assignnote, setassignnote] = useState('');
    useEffect(() => {
        fetchAssignment();
        fetchSubmittedAssignment();
    }, []);

    const fetchAssignment = async () => {
        if (_slide !== '' && typeof _slide !== 'undefined') {
            const response = await fetch(`${API_BASE}get-submitted-assignment/${_slide}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${_token}`
                },
            });

            const data = await response.json();
            if (data.status) {
                setSubmitted(data.data.assignment);
                // setResubmit(false);
            } else {
                setSubmitted('');
                // setResubmit(true);
            }

            setFile('');
        }
    }

    const fetchSubmittedAssignment = async () => {
        const _lmsuser = JSON.parse(localStorage.getItem('lmsuser'));
        const userID = _lmsuser['id'];

        const formData = new FormData();
        formData.append('user_id', userID);

        const response = await fetch(`${API_BASE}check-submitted-assignment/${slide.id}`, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + _token
            },
            body: formData
        });
        const data = await response.json();
        console.log(data);

        if (data.status) {
            if ((data.data.score !== null && data.data.score !== "" && data.data.grade !== null && data.data.grade !== "")) {
                setResubmit(false);
                setassignscore(data.data.score);
                setassigngrade(data.data.grade);
                setassignnote(data.data.notes);
            }
        } else {

        }
    }

    const saveAssignment = async () => {
        if (_slide !== '' && typeof _slide !== 'undefined' && file !== '') {
            var formData = new FormData();
            formData.append('assignment', file);
            const response = await fetch(`${API_BASE}submit-assignment/${_slide}`, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${_token}`
                },
                body: formData
            });

            const data = await response.json();
            if (data.status && data.data.submitted) {
                Toaster.success(data.message);
                fetchAssignment();
                setFile('');
                setShowResubmitcon(false);
            } else {
                Toaster.error(data.message);
            }

        }
    }

    return (
        <>
            <div className="asignmet_box">
            { /*slide?.material?.course_mode === 'Self Paced' &&
                    (<div className="nogradingmsgdiv"><p className="nogradingmsg">In this self-paced course, there's no formal assignment submission. However, kindly complete the assignment before marking it as completed to maximize your learning experience.</p></div>) */}

                <div className="long_assignment"> {parse(slide.material.material)}</div>

                {slide?.material?.course_mode !== 'Self Paceddd' && (
                    <>
                        <hr />
                        {
                            submitted !== ''
                            &&
                            <>
                                <p className="mb-0">You've Already submitted this assignment</p>
                                {showresubmitcon === false && (
                                    <div className="mt-1 text-center">
                                        <a className="text-danger1" href={submitted} target="_blank" download><i className="fas fa-download"></i>View Submitted Assignment</a>
                                    </div>
                                )}
                                {resubmit ? (
                                    <>
                                    <p>
                                        <a onClick={() => { setShowResubmitcon(true); setFile(''); }} className="link_box"> Click here to resubmit.</a>
                                    </p>
                                </>
                                ) : (
                                    <>
                                        <div className="submittedAssigntableDiv">
                                            <table border="1" className="submittedAssigntable">
                                                <tr className="assigntabletr">
                                                    <td className="assigntablehea">Score</td>
                                                    <td className="assigntablecont">{assignscore}</td>
                                                </tr>
                                                <tr className="assigntabletr">
                                                    <td className="assigntablehea">Grade</td>
                                                    <td className="assigntablecont">{assigngrade}</td>
                                                </tr>
                                                <tr className="assigntabletr">
                                                    <td className="assigntablehea">Note</td>
                                                    <td className="assigntablecont">{assignnote}</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </>
                                )}
                            </>
                        }
                        {
                            (showresubmitcon || submitted === '')
                            &&
                            <>
                                <div className="form-group">
                                    <input type="file" className="form-control" onChange={(e) => { setFile(e.target.files[0]); }} />
                                    <br />
                                    <button onClick={(e) => { saveAssignment(); }} type="button" className="btn btn-circle btn-md submit-pink-btn ek-lrs-assessment-shared" data-ek-lrs-title={slide.title} data-ek-lrs-url="">Submit</button>
                                    {
                                        submitted !== ''
                                        &&
                                        <button onClick={(e) => { setShowResubmitcon(false); setFile(''); }} className="btn btn-circle btn-md cancel-btn ml-1">Cancel</button>
                                    }
                                </div>
                            </>
                        }
                    </>)}
            </div>
        </>
    );
}

const QuizBox = ({ slide, expier, certified }) => {
    const navigate      = useNavigate();
    const { _slug }     = useParams();
    const { _slide }    = useParams();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [submitted, setSubmitted] = useState('');
    const [processing, setProcessing] = useState(false);
    const [aiquiz, setAiquiz] = useState(false);
    const [result_heading_title_text, setResultHeadingTitleText] = useState('');
    const [result_points_text, setResultPointsText] = useState('');
    const [assesement_result_text, setAssesementResultText] = useState('');
    const [userAnswers, setUserAnswers] = useState({});
    let _course_slug = _slug;
    const [attemptNo, setAttemptNo] = useState('');

    const [idle, setIdle] = useState(false);
    const [idleTimeout, setIdleTimeout] = useState(300000);
    const [noOfWarning, setNoOfWarning] = useState(0);


    const [terminateQuiz, setTerminateQuiz] = useState(false);
    const [isSaveAPICall, setIsSaveAPICall] = useState(true);

    let initialAttempt;
    let timeout;
    let activityType = "";

    const resetTimeout = () => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            // setIdle(true);
            activityType = "Inactive";
            saveProctoringData(activityType);

            SwalBox.close();
            SwalBox.fire({
                title: 'You are inactive',
                text: `Stay engaged and interact with the exam interface regularly to avoid any potential issues. Failure to do so may result in consequences as outlined by the exam guidelines`,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonClass: 'button_confirm_class',
                confirmButtonText: 'Okay',
                target: document.getElementById('sweetalertParent')
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        SwalBox.close();
                    } else {

                    }
                });
            setTimeout(() => {
                SwalBox.close();
            }, 2500);

        }, idleTimeout);
    };

    useEffect(() => {
        const events = ['mousedown', 'keydown', 'mousemove', 'scroll', 'load'];
        const handleActivity = () => {
            setIdle(false);
            resetTimeout();
        };

        for (const event of events) {
            window.addEventListener(event, handleActivity);
        }

        resetTimeout(); // Start the initial timeout

        return () => {
            // Remove event listeners when the component unmounts
            for (const event of events) {
                window.removeEventListener(event, handleActivity);
            }
            clearTimeout(timeout); // Clear the timeout on unmount
        };
    }, []);

    useEffect(() => {
        const handleCopy = () => {
            activityType = "Copy";
            CopyPasteAlert(activityType);
            saveProctoringData(activityType);
        };

        const handlePaste = () => {
            activityType = "Paste";
            CopyPasteAlert(activityType);
            saveProctoringData(activityType);
        }
        document.addEventListener('copy', handleCopy);
        document.addEventListener('paste', handlePaste);
        return () => {
            document.removeEventListener('copy', handleCopy);
            document.removeEventListener('paste', handlePaste);
        };
    }, []);
    /*
    useEffect(() => {
        const handleBeforeUnload = (e) => {
            activityType = "Page Reload";
            CopyPasteAlert(activityType);
            saveProctoringData(activityType);
            e.preventDefault();
            e.returnValue = '';
            return;
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };

    }, []);
    */
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === "hidden") {
                activityType = "New tab or window";
                saveProctoringData(activityType);
                VisibilityAlert();
            }
            if (document.visibilityState === "unloaded") {
                VisibilityAlert();
            }
        };

        const pageHideListener = () => {
            VisibilityAlert();
        }
        const handleKeyPress = (e) => {
            if ((e.ctrlKey || e.metaKey) && e.shiftKey && (e.key === 'S' || e.keyCode === 83)) {
                KeyPressAlert();
            }

            if (e.key === "Meta" && e.key === "Shift" && e.key === 'S') {
                e.preventDefault();
                KeyPressAlert();
            }   
            // eslint-disable-next-line
            key('escape', function () {
                activityType = "Escape";
                saveProctoringData(activityType);
                KeyPressAlert();
            });
            // eslint-disable-next-line
            key('ctrl+shift+j', function () {
                activityType = "View Console";
                saveProctoringData(activityType);
                CopyPasteAlert(activityType)
            });
        }

        const handlekeyUp = (e) => {
            if (e.keyCode === 44) {
                activityType = "Screenshot";
                saveProctoringData(activityType);
                KeyPressAlert();
            }
        }

        const handlePreventSelect = (e) => {
            e.preventDefault()
        }

        document.addEventListener('visibilitychange', handleVisibilityChange);
        window.addEventListener("pagehide", pageHideListener);
        window.addEventListener('keydown', handleKeyPress);
        window.addEventListener('keyup', handlekeyUp);
        document.addEventListener('selectstart', handlePreventSelect);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            document.removeEventListener('pagehide', pageHideListener);
            document.removeEventListener('keydown', handleKeyPress);
            document.removeEventListener('selectstart', handlePreventSelect);
        };
    }, []);



    useEffect(() => {
        getProctoringData();
        checkBatteryStatus();
        fetchQuiz();
        setMcqs();
        // eslint-disable-next-line
    }, []);

    const fetchQuiz = async () => {
        if (_slide !== '' && typeof _slide !== 'undefined') {
            const response = await fetch(`${API_BASE}get-submitted-quiz/${_slide}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + _token
                },
            });
            const data = await response.json();
            if (data.status) {
                setSubmitted(data.data.quiz);
            } else {
                setSubmitted('');
            }
        }
    } 
    //
    // const setMcqs = async () => {
    //     if (_slide !== '' && typeof _slide !== 'undefined') {
    //         var _aiquestionbank = [];
    //         let existing_questions = slide.material.questions;
    //         existing_questions.forEach(function (q) {

    //             //if(_aiquestionbank < 2){
    //             try {
    //                 let _current_queestion = q.question;
    //                 let _current_answers = q.answers;
    //                 let _current_answers_arr = [];
    //                 let _answer_option_1 = "";
    //                 let _answer_option_2 = "";
    //                 let _answer_option_3 = "";
    //                 let _answer_option_4 = "";
    //                 let _correct_answer = '1';
    //                 if (typeof (_current_answers[0]) != 'undefined') { _answer_option_1 = _current_answers[0].answer; _current_answers_arr.push(_answer_option_1); if (_current_answers[0].is_correct == 'Yes') { _correct_answer = '1'; } }
    //                 if (typeof (_current_answers[1]) != 'undefined') { _answer_option_2 = _current_answers[1].answer; _current_answers_arr.push(_answer_option_2); if (_current_answers[1].is_correct == 'Yes') { _correct_answer = '2'; } }
    //                 if (typeof (_current_answers[2]) != 'undefined') { _answer_option_3 = _current_answers[2].answer; _current_answers_arr.push(_answer_option_3); if (_current_answers[2].is_correct == 'Yes') { _correct_answer = '3'; } }
    //                 if (typeof (_current_answers[3]) != 'undefined') { _answer_option_4 = _current_answers[3].answer; _current_answers_arr.push(_answer_option_4); if (_current_answers[3].is_correct == 'Yes') { _correct_answer = '4'; } }

    //                 let _temp_question = {
    //                     "question": q.question,
    //                     "questionType": "text",
    //                     "answerSelectionType": "single",
    //                     "answers": _current_answers_arr,
    //                     "correctAnswer": _correct_answer,
    //                     "messageForCorrectAnswer": "Correct answer. Good job.",
    //                     "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
    //                     "point": "20"
    //                 };
    //                 _aiquestionbank.push(_temp_question);
    //             } catch (e) { }
    //             //}
    //         });
    //         console.info({
    //             "quizSynopsis": "Your Quiz is ready",
    //             "nrOfQuestions": existing_questions.length,
    //             "questions": _aiquestionbank,
    //             "appLocale": { "resultPageHeaderText": result_heading_title_text, "resultPagePoint": result_points_text }
    //         })
    //         setAiquiz({
    //             "quizSynopsis": "Your Quiz is ready",
    //             "nrOfQuestions": existing_questions.length,
    //             "questions": _aiquestionbank,
    //             "appLocale": { "resultPageHeaderText": result_heading_title_text, "resultPagePoint": result_points_text }
    //         });
    //     }
    // }

    const setMcqs = async () => {
        if (_slide !== '' && typeof _slide !== 'undefined') {
            var _aiquestionbank = [];
            let existing_questions = slide.material.questions;
            existing_questions.forEach(function (q) {

                //if(_aiquestionbank < 2){
                try {
                    let _current_queestion = q.question;
                    let _current_answers = q.answers;
                    let _current_answers_arr = [];
                    let _answer_option_1 = "";
                    let _answer_option_2 = "";
                    let _answer_option_3 = "";
                    let _answer_option_4 = "";
                    let _correct_answer = '1';
                    let _ansID_arr = []
                    if (typeof (_current_answers[0]) != 'undefined') {
                        _answer_option_1 = _current_answers[0].answer;
                        let _answerID_1 = _current_answers[0].uid;
                        _current_answers_arr.push(_answer_option_1);
                        _ansID_arr.push(_answerID_1);
                        if (_current_answers[0].is_correct == 'Yes') {
                            _correct_answer = '1';
                        }
                    }

                    if (typeof (_current_answers[1]) != 'undefined') {
                        _answer_option_2 = _current_answers[1].answer;
                        let _answerID_2 = _current_answers[1].uid;

                        _current_answers_arr.push(_answer_option_2);
                        _ansID_arr.push(_answerID_2);

                        if (_current_answers[1].is_correct == 'Yes') {
                            _correct_answer = '2';
                        }
                    }
                    if (typeof (_current_answers[2]) != 'undefined') {
                        _answer_option_3 = _current_answers[2].answer;
                        let _answerID_3 = _current_answers[2].uid;
                        _ansID_arr.push(_answerID_3);

                        _current_answers_arr.push(_answer_option_3);
                        if (_current_answers[2].is_correct == 'Yes') {
                            _correct_answer = '3';
                        }
                    }
                    if (typeof (_current_answers[3]) != 'undefined') {
                        _answer_option_4 = _current_answers[3].answer;
                        let _answerID_4 = _current_answers[3].uid;
                        _ansID_arr.push(_answerID_4);
                        _current_answers_arr.push(_answer_option_4);
                        if (_current_answers[3].is_correct == 'Yes') {
                            _correct_answer = '4';
                        }
                    }

                    let _temp_question = {
                        "question": q.question,
                        "questionType": "text",
                        "answerSelectionType": "single",
                        "answers": _current_answers_arr,
                        "correctAnswer": _correct_answer,
                        "messageForCorrectAnswer": "Correct answer. Good job.",
                        "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
                        "point": "20",
                        "qID": q.id,
                        "ansID": _ansID_arr
                    };
                    _aiquestionbank.push(_temp_question);
                } catch (e) { }
                //}
            });
            // console.info({
            //     "quizSynopsis": "Your Quiz is ready",
            //     "nrOfQuestions": existing_questions.length,
            //     "questions": _aiquestionbank,
            //     "appLocale": { "resultPageHeaderText": result_heading_title_text, "resultPagePoint": result_points_text }
            // })
            setAiquiz({
                "quizSynopsis": "Your Quiz is ready",
                "nrOfQuestions": existing_questions.length,
                "questions": _aiquestionbank,
                "appLocale": { "resultPageHeaderText": result_heading_title_text, "resultPagePoint": result_points_text }
            });
        }
    }

    const saveQuiz = async (form) => {
        setIsSaveAPICall(false);
        setProcessing(true);
        if (_slide !== '' && typeof _slide !== 'undefined') {
            const response = await fetch(`${API_BASE}submit-quiz/${_slide}`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${_token}`
                },
                body: JSON.stringify(form)
            });
            const data = await response.json();
            if (data.status) {
                fetchQuiz();
                setProcessing(false);
                try {
                    document.getElementById('smallScreenClick').click();
                } catch (e) {
                    console.log(e);
                }
            } else {
                setSubmitted('');
                setProcessing(false);
            }
        }
    }
    const retakeQuiz = async () => {
        if (_slide !== '' && typeof _slide !== 'undefined') {
            const response = await fetch(`${API_BASE}delete-submitted-quiz/${_slide}`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${_token}`
                },
            });
            const data = await response.json();
            if (data.status) {
                if (data?.data?.deleted) {
                    fetchQuiz();
                }
            }
        }
    }

    // const setQuizResult = (obj) => {
    //     setTimeout(() => {
    //         let _result_string = '';
    //         _result_string = 'Done';
    //         let _total_points = obj.totalPoints;
    //         let _correct_points = obj.correctPoints;
    //         let _percentage_f = (100 * _correct_points) / _total_points;
    //         let _percentage = _percentage_f.toFixed(2);

    //         let _message_text = 'You have scored ' + _percentage + '%. ';
    //         if (_percentage < 50) {
    //             _message_text += "Remember, there is always room for improvement. Keep practising, and you'll see progress soon!";
    //         } else if (_percentage > 50 && _percentage < 69) {
    //             _message_text += "Great effort on your score! Keep practising and you'll be ready for the next chapter in your career.";
    //         } else if (_percentage > 70) {
    //             _message_text += "Great Job! Your hard work has prepared you for the next chapter in your career.";
    //         } else {
    //             _message_text = 'You have completed the quiz.';
    //         }
    //         setAssesementResultText(_message_text);
    //         // YOUR LOGIC GOES HERE
    //     }, 2000);
    // }

    const setQuizResult = (obj) => {
        // var screenbtn = $("#smallScreenClick");
        // screenbtn.show();
        let _result_string = '';
        _result_string = 'Done';
        let _total_points = obj.totalPoints;
        let _correct_points = obj.correctPoints;
        let _percentage_f = (100 * _correct_points) / _total_points;
        let _percentage = _percentage_f.toFixed(2);

        let _message_text = 'You have scored ' + _percentage + '%. ';
        if (_percentage < 50) {
            _message_text += "Remember, there is always room for improvement. Keep practising, and you'll see progress soon!";
        } else if (_percentage > 50 && _percentage < 69) {
            _message_text += "Great effort on your score! Keep practising and you'll be ready for the next chapter in your career.";
        } else if (_percentage > 70) {
            _message_text += "Great Job! Your hard work has prepared you for the next chapter in your career.";
        } else {
            _message_text = 'You have completed the quiz.';
        }


        setAssesementResultText(_message_text);
        setResultPointsText(_correct_points);
        setResultHeadingTitleText(_message_text);

        var formData = {};
        // formData.slug = _course_slug;
        // formData.slide = _slide;
        // formData.total_points = _total_points;
        // formData.correct_points = _correct_points;
        // formData.numberOfQuestions = obj.numberOfQuestions;
        // formData.numberOfIncorrectAnswers = obj.numberOfIncorrectAnswers;
        // formData.numberOfCorrectAnswers = obj.numberOfCorrectAnswers;
        formData.question = userAnswers;
        // formData.answerID = obj.ansID;

        if (isSaveAPICall) {
            saveQuiz(formData);
        }
    }


    const CopyPasteAlert = (activityType) => {
        SwalBox.fire({
            title: 'Suspicious activity is observed',
            text: `${activityType} activity was detected, which is strictly prohibited during this examination.`,
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonClass: 'button_confirm_class',
            confirmButtonText: 'Okay',
            target: document.getElementById('sweetalertParent')
        }).then((result) => {
            if (result.isConfirmed) {
                SwalBox.close();
            } else {

            }
        });
    }

    const VisibilityAlert = () => {
        //let newNoofWarning = noOfWarning + 1;
        //setNoOfWarning(2);
        //console.log(noOfWarning, 'noOfWarning');
        SwalBox.fire({
            title: 'Suspicious activity is observed',
            text: 'You are not allowed to minimize this window or open a new tab or window during the exam',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonClass: 'button_confirm_class',
            confirmButtonText: 'Okay',
            target: document.getElementById('sweetalertParent')
        }).then((result) => {
            if (result.isConfirmed) {
                SwalBox.close();
            } else {

            }
        });
    }

    const KeyPressAlert = () => {
        SwalBox.fire({
            title: 'Suspicious activity is observed',
            text: 'You are not allowed to take screenshot or save page during the exam',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonClass: 'button_confirm_class',
            confirmButtonText: 'Okay',
            target: document.getElementById('sweetalertParent')
        }).then((result) => {
            if (result.isConfirmed) {
                SwalBox.close();
            } else {

            }
        });
    }

    const checkBatteryStatus = () => {
        navigator.getBattery().then(function (battery) {
            if (battery.level < 0.30) {
                SwalBox.fire({
                    title: 'Low Battery status',
                    text: `Low Battery alert Your Battery status is ${battery.level * 100}%`,
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonClass: 'button_confirm_class',
                    confirmButtonText: 'Okay',
                    target: document.getElementById('sweetalertParent')
                }).then((result) => {
                    if (result.isConfirmed) {
                        SwalBox.close();
                    } else {

                    }
                });
            }
            battery.addEventListener('levelchange', function () {
                if (this.level < 0.20) {
                    SwalBox.fire({
                        title: 'Low Battery status',
                        text: 'Low Battery alert',
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonClass: 'button_confirm_class',
                        confirmButtonText: 'Okay',
                        target: document.getElementById('sweetalertParent')
                    }).then((result) => {
                        if (result.isConfirmed) {
                            SwalBox.close();
                        } else {

                        }
                    });
                }
            });
        });
    }

    const saveProctoringData = async (activityType) => {
        const formData = new FormData();
        formData.append('slide_id', _slide);
        formData.append('activity_type', activityType);
        formData.append('attempt_id', initialAttempt);
        formData.append('course_slug', _slug);

        const response = await fetch(`${API_BASE}save-proctoring`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const data = await response.json();
        if (data.status) {
            Toaster.success(data.message);
            console.warn(data.data);
            setNoOfWarning(data.data.warning_id);
            setAttemptNo(data.data.attempt_id);

            console.info(data.data.attempt_id, "responce Attempt is");
            console.info(initialAttempt, "initial responce Attempt is");
            if (data.data.terminate === true) {
                // eslint-disable-next-line
                window.location.reload();
            }
        } else {
            Toaster.error(data.message);
        }
    }


    const handleAnswerSelected = (answer) => {
        setUserAnswers((prevAnswers) => ({
            ...prevAnswers,
            [answer.question.qID]: `${answer.question.ansID[answer.userAnswer - 1]}`,
        }));
    };
    const getProctoringData = async () => {
        const formData = new FormData();
        formData.append('slide_id', _slide);

        const response = await fetch(`${API_BASE}get-proctoring`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const data = await response.json();
        if (data.status) {
            initialAttempt = data.data.attempt_id;
        } else {
        }
    }
    return (

        // <>
        //     {
        //         submitted === '' && (slide.material.questions !== '' && slide.material.questions !== null)
        //         &&
        //         <>
        //             <form onSubmit={handleSubmit(saveQuiz)}>
        //                 <ul>
        //                     {
        //                         slide.material.questions.map((question, index) =>
        //                             <li key={index} className="mt-3">
        //                                 <strong>Question {index + 1}: {question.question}</strong><br />
        //                                 <ul className="pl-3">
        //                                     {
        //                                         question.answers.map((answer, indexx) =>
        //                                             <li key={indexx}><input {...register(`question.${question.id}`, { required: "required" })} type="radio" id={answer.answer} value={answer.uid} className="ek-lrs-assessment-responded" data-ek-lrs-title={slide.title} data-ek-lrs-url="" data-ek-lrs-answer={answer.answer} data-ek-lrs-question={question.question} /><label htmlFor={answer.answer}>Option {indexx + 1}: {answer.answer}</label></li>
        //                                         )
        //                                     }
        //                                 </ul>
        //                                 <span className="text-danger">
        //                                     {errors.question?.[question.id] && errors.question?.[question.id].type === "required" && 'Please select your answer.'}
        //                                     {errors.question?.[question.id] && errors.question?.[question.id].type !== "required" && errors.question?.[question.id].message}
        //                                 </span>
        //                             </li>
        //                         )
        //                     }
        //                 </ul>
        //                 <button type="submit" disabled={processing ? true : false} className="btn btn-circle btn-success ek-lrs-assessment-attempted" data-ek-lrs-url="" data-ek-lrs-title={slide.title}>{(processing === true) ? <i className="fa fa-refresh fa-spin"></i> : 'Submit'}</button>
        //             </form>
        //             <div id="sweetalertParent"></div>
        //         </>
        //     }
        //     {
        //         submitted !== '' && (slide.material.questions !== '' && slide.material.questions !== null)
        //         &&
        //         <>
        //             <hr />
        //             {
        //                 !certified
        //                 &&
        //                 <b onClick={retakeQuiz} className="text-center btn btn-circle btn-md btn-success btn-block p-3">You've submitted this quiz. Retake Quiz.</b>
        //             }
        //             <b className="text-center btn btn-circle btn-md btn-block bg-info p-1">Your score is {submitted.right_count}/{submitted.total_count}.</b>
        //             <ul>
        //                 {
        //                     slide.material.questions.map((question, index) =>
        //                         <li key={index} className="mt-3">
        //                             <strong>Question {index + 1}: {question.question}</strong><br />
        //                             <ul className="pl-3">
        //                                 {
        //                                     question.answers.map((answer, indexx) =>
        //                                         <>
        //                                             <li key={indexx} className="mt-2"><label className={`ml-2 ${answer.is_correct === 'Yes' && 'text-success'}`} htmlFor={answer.answer}>Option {indexx + 1}: {answer.answer}</label></li>
        //                                             {
        //                                                 submitted?.result.length > 0
        //                                                 &&
        //                                                 submitted.result.map((res) =>
        //                                                     <>
        //                                                         {
        //                                                             (answer.uid == res.option_id && res.is_correct == 1)
        //                                                             &&
        //                                                             <p className="px-2 py-1 badge badge-success text-white">Correct <span>{answer?.description ? `: ${answer.description}` : ''}</span></p>
        //                                                         }
        //                                                         {
        //                                                             (answer.uid == res.option_id && res.is_correct == 0)
        //                                                             &&
        //                                                             <p className="px-2 py-1 badge badge-danger text-white">Incorrect <span>{answer?.description ? `: ${answer.description}` : ''}</span></p>
        //                                                         }
        //                                                     </>
        //                                                 )
        //                                             }
        //                                         </>
        //                                     )
        //                                 }
        //                             </ul>
        //                             <span className="text-danger">
        //                                 {errors.question?.[question.id] && errors.question?.[question.id].type === "required" && 'Please select your answer.'}
        //                                 {errors.question?.[question.id] && errors.question?.[question.id].type !== "required" && errors.question?.[question.id].message}
        //                             </span>
        //                         </li>
        //                     )
        //                 }
        //             </ul>
        //         </>
        //     }



        //     {/**
        //     <section>
        //         <p className="assesement-result-text">{assesement_result_text}</p>
        //     {
        //     (aiquiz != false)
        //     &&
        //     <Quiz
        //     quiz={aiquiz}
        //     shuffle={true}
        //     showDefaultResult={true}
        //     onComplete={setQuizResult}

        //     />
        //     }
        //     </section>
        //     */}


        // </>

        <>
            <section>
                {/* <p className="assesement-result-text">{assesement_result_text}</p> */}
                {noOfWarning && noOfWarning > 0 &&
                    <div className="">
                        <h5 className="errorMessage">Warning :- {noOfWarning} / 3</h5>
                    </div>
                }
                {
                    (aiquiz != false)
                    &&
                    <>
                        <Quiz
                            quiz={aiquiz}
                            shuffle={true}
                            showDefaultResult={true}
                            onComplete={setQuizResult}
                            onQuestionSubmit={handleAnswerSelected}
                        // revealAnswerOnSubmit={true}
                        />
                    </>
                }
            </section>
        </>
    );

}

