import { useDropzone } from "react-dropzone";
import { useForm, Controller } from "react-hook-form";
import { API_BASE, IMAGE_EXT, IMAGE_TXT, DEFAULT_UPLOAD_LOGO_IMG } from "../../constants/global";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState, useRef } from "react";
import { SwalBox, Toaster } from "./../../entity/GlobalJS";
import authContext from "../../context/auth/authContext";
import SocialMediaLogin from "./SocialMediaLogin";
import ImageCropper from "../../personal_profile/Tabs/ImageCropper";
import ReCAPTCHA from 'react-google-recaptcha';
import { Helmet } from "react-helmet";


const CorporateRegister = () => {
    const navigate = useNavigate();
    const cntxAuth = useContext(authContext);
    const recaptchaRef = useRef();
    const [ip, setIp] = useState("");
    const [passwordType, setPasswordType] = useState("password");
    const [confirmPasswordType, setConfirmPasswordType] = useState("password");
    const [processing, setProcessing] = useState(false);
    const [upFileClass, setUpFileClass] = useState('');
    const [logo_file, setLogoFile] = useState('');
    const [logo_name, setLogoName] = useState('');
    const [logo_preview, setLogoPreview] = useState('');
    const [new_photo, setNewPhoto] = useState(null);
    const [cropbtnstatus, setCropbtnstatus] = useState('');
    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const [is_strong, setStrong] = useState({
        eight_char: false,
        have_numub: false,
        have_lower: false,
        have_upper: false,
        have_spslc: false,
        same_confp: false,
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        if (cntxAuth.isLogged) {
            navigate("/dashboard/home");
        }

        fetchIspIp()
        //eslint-disable-next-line
    }, []);

    const { register, handleSubmit, formState: { errors }, setValue, setError, watch, clearErrors, control } = useForm();
    async function fetchIspIp() {
        let response = await fetch("https://api.ipify.org/?format=json");
        response = await response.json();
        if (typeof response != 'undefined' && typeof response.ip != 'undefined') {
            setIp(response.ip);
        }
    }

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    const toggleConfirmPassword = () => {
        if (confirmPasswordType === "password") {
            setConfirmPasswordType("text")
            return;
        }
        setConfirmPasswordType("password")
    }

    function nameLengthValidator(file) {
        let img = new Image();
        let maxDimension = 512;
        img.src = window.URL.createObjectURL(file);
        setLogoPreview(img.src);
        img.onload = () => {
            if (img.width > maxDimension || img.height > maxDimension) {
                // setError('company_logo', { type: "focus", message: 'You can upload image with maximum 512x512 dimension.' }, { shouldFocus: true });
                // return false;
            }
        }
        return null
    }

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': ['.png', '.jpeg', '.jpg', '.ico', '.webp', '.svg', 'jfif']
        },
        maxFiles: 1,
        validator: nameLengthValidator,
        onDrop: (acceptFile, fileRejections) => {
            if (fileRejections.length == 0) {
                setLogoFile(acceptFile[0]);
                setLogoName(acceptFile[0].name);
                setUpFileClass('upfile_img_vdo');
                clearErrors('company_logo');
                moderateUploadedMedia(acceptFile[0]);
            } else {
                setLogoFile('');
                setLogoName('');
            }
        },
    });

    const removeFile = () => {
        setLogoFile('');
        setLogoName('');
        setUpFileClass('');
        clearErrors('company_logo');
    }

    async function onSubmit(data) {
        if (recaptchaValue === null) {
            setError('recaptcha', {
                type: 'manual',
                message: 'Please complete the reCAPTCHA verification.'
            });
            return;
        }
        data['ip'] = ip;
        if (logo_file === '') {
            setError('company_logo', { type: "focus", message: 'Please choose company logo.' }, { shouldFocus: true });
            return false;
        }
        clearErrors('company_logo');
        const formData = new FormData();
        formData.append('company_name', data.company_name);
        formData.append('company_website', data.company_website);
        formData.append('company_logo', new_photo ? new_photo : logo_file);
        formData.append('password', data.password);
        formData.append('cpassword', data.cpassword);
        formData.append('email', data.email);
        formData.append('ip', data.ip);
        formData.append('terms_condition', data.terms_condition);
        setProcessing(true);
        clearErrors('recaptcha');
        let response = await fetch(`${API_BASE}corporate-register`, {
            method: 'POST',
            headers: {

            },
            body: formData
        });

        response = await response.json();

        if (response.status) {
            setValue('company_name', '');
            setValue('company_website', '');
            setValue('company_logo', '');
            setValue('email', '');
            setValue('password', '');
            setValue('cpassword', '');
            setValue('is_tutor', '');

            setTimeout(() => {

                setProcessing(false);
                Toaster.success(response.message);
                navigate(`/register-steps/verify-email/${response.data.verifyuser}`);
            }, 2000);

        } else {
            setProcessing(false);
            Toaster.error(response.message);
        }
        setUpFileClass('');
    }

    const checkPassword = (e) => {
        const pass_str = document.getElementById('password-field').value.trim();
        const cpass_str = document.getElementById('cpassword-field').value.trim();

        if (pass_str.length > 0) {
            clearErrors('password');
        }

        if (cpass_str.length > 0) {
            clearErrors('cpassword');
        }

        const eight_char = (pass_str.length >= 8) ? true : false;
        const have_numub = (/\d/.test(pass_str)) ? true : false;
        const have_lower = (/[a-z]/.test(pass_str)) ? true : false;
        const have_upper = (/[A-Z]/.test(pass_str)) ? true : false;
        const have_spslc = (/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(pass_str)) ? true : false;
        const same_confp = (pass_str.length > 0 && cpass_str === pass_str) ? true : false;

        setStrong({
            eight_char: eight_char,
            have_numub: have_numub,
            have_lower: have_lower,
            have_upper: have_upper,
            have_spslc: have_spslc,
            same_confp: same_confp
        });

        if (eight_char && have_numub && have_lower && have_upper && have_spslc && same_confp) {
            document.getElementById('password-checker').classList.add("d-none");
        } else {
            document.getElementById('password-checker').classList.remove("d-none");
        }
    }
    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };
    const moderateUploadedMedia = async (logofile) => {
        var _token = '';
        setCropbtnstatus('disabled')
        const formData = new FormData();
        var fileField = logofile;
        const base64 = await convertBase64(fileField);
        formData.append("image", fileField);
        formData.append("base64", base64);
        formData.append("mediatype", fileField.type);
        const response = await fetch(API_BASE + 'validate-uploaded-image', {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + _token
            },
            body: formData
        });
        const data = await response.json();
        if (data.status) {
            var _is_valid = data.data;
            if (_is_valid != "") {
                SwalBox.fire({
                    title: 'Inappropriate image',
                    text: 'Your image has been removed as it contains inappropriate content (' + _is_valid + ') , please upload a valid image.',
                    icon: 'warning',
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ok'
                });
                setLogoFile('');
                setLogoName('');
                setUpFileClass('');
                clearErrors('company_logo');
                setTimeout(function () { removeFile(); }, 1500)
            } else {
                setCropbtnstatus('');
            }
        } else {
            setCropbtnstatus('');
        }
    }
    return (
        <>
            <Helmet>
                <title>{'EduKula - Sign-up and start hiring!'}</title>
            </Helmet>
            <section>
                <div className="custom-login-section new_login_design">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-5">
                                <div className="custom-login-box">
                                    <div className="login-form">
                                        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                                            <h2>Sign-up and <span>start hiring!</span></h2>
                                            <div className="form-group">
                                                <input {...register("company_name", {
                                                    required: "required",
                                                    pattern: {
                                                        value: /^[a-z0-9 ,.'-]+$/i,
                                                        message: "Invalid value"
                                                    },
                                                    minLength: {
                                                        value: 3,
                                                        message: "Please enter at least 3 characters"
                                                    },
                                                    maxLength: {
                                                        value: 80,
                                                        message: "Company name can not be more than 80 charaters."
                                                    }
                                                })} type="text" className="form-control" placeholder="Company name" />
                                                <span className="text-danger">
                                                    {errors.company_name && errors.company_name.type === "required" && 'Please enter Company name.'}
                                                    {errors.company_name && errors.company_name.type !== "required" && errors.company_name.message}
                                                </span>
                                            </div>
                                            <div className="form-group">
                                                <input {...register("company_website", {
                                                    required: "required",
                                                    pattern: {
                                                        value: /^((http|https?|ftp|smtp):\/\/)|(www.)|[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/,
                                                        message: "Please enter valid company URL.(Ex: www.example.com)"
                                                    },
                                                    minLength: {
                                                        value: 3,
                                                        message: "Please enter at least 3 characters"
                                                    },
                                                    maxLength: {
                                                        value: 80,
                                                        message: "Company website can not be more than 80 charaters."
                                                    }
                                                })} type="text" className="form-control" placeholder="Company website" />
                                                <span className="text-danger">
                                                    {errors.company_website && errors.company_website.type === "required" && 'Please enter Company website.'}
                                                    {errors.company_website && errors.company_website.type !== "required" && errors.company_website.message}
                                                </span>
                                            </div>
                                            <div className="form-group">
                                                {
                                                    logo_file !== ''
                                                    &&
                                                    <>
                                                        <div className="file-choosen">
                                                            <div onClick={(e) => { removeFile(); }} className="btnGrp">
                                                                <i className="fa fa-trash" aria-hidden="true"></i>
                                                            </div>
                                                            <div className="preview_img_box">
                                                                <img src={logo_preview} width="100%" alt="" />
                                                            </div>
                                                        </div>
                                                        <ImageCropper cropbtnstatus={cropbtnstatus} photo={logo_preview} setPhotoToShow={setLogoPreview} show_modal={logo_preview} setPhotoToUpload={setNewPhoto} src={'Register'} />
                                                    </>
                                                }

                                                {
                                                    logo_file === ''
                                                    &&
                                                    <>
                                                        <div {...getRootProps()} className={upFileClass} >
                                                            <img src={DEFAULT_UPLOAD_LOGO_IMG} className="cursor_pointer" width="100%" alt='preview' />
                                                            <input {...getInputProps()} />
                                                        </div>
                                                    </>
                                                }
                                                <p className="text-danger">
                                                    {errors.company_logo?.type === "required" && 'Please select company logo.'}
                                                    {errors.company_logo?.type !== "required" && errors.company_logo?.message}
                                                </p>
                                                <div className="clearfix"></div>
                                            </div>
                                            <div className="form-group">
                                                <input {...register("email", {
                                                    required: "required",
                                                    pattern: {
                                                        value: /\S+@\S+\.\S+/,
                                                        message: "Entered value does not match email format"
                                                    }
                                                })} type="email" className="form-control" placeholder="Email address" />
                                                <span className="text-danger">
                                                    {errors.email && errors.email.type === "required" && 'Please enter email address'}
                                                    {errors.email && errors.email.type !== "required" && errors.email.message}
                                                </span>
                                            </div>
                                            <div className="form-group">
                                                <input {...register("password", {
                                                    required: "required",
                                                    pattern: {
                                                        value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,50}$/,
                                                        message: "Password must contains at least a letter, number and a special character."
                                                    },
                                                    minLength: {
                                                        value: 8,
                                                        message: "Password can not be less than 8 characters."
                                                    },
                                                    maxLength: {
                                                        value: 50,
                                                        message: "Password can not be more than 50 characters."
                                                    }
                                                })} type={passwordType} id="password-field" className="form-control" placeholder="Password" onChange={checkPassword} />
                                                <span toggle="#password-field" className={`${passwordType === "password" ? "fa fa-eye " : "fa fa-eye-slash"} field-icon`} onClick={togglePassword}></span>
                                                <span className="text-danger">
                                                    {errors.password && errors.password.type === "required" && 'Please enter password.'}
                                                    {errors.password && errors.password.type !== "required" && errors.password.message}
                                                </span>
                                            </div>
                                            <div className="form-group">
                                                <input {...register("cpassword", {
                                                    required: "required",
                                                    pattern: {
                                                        value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,50}$/,
                                                        message: "Password must contains at least a letter, number and a special character."
                                                    },
                                                    minLength: {
                                                        value: 8,
                                                        message: "Confirm password can not be less than 8 characters."
                                                    },
                                                    maxLength: {
                                                        value: 50,
                                                        message: "Confirm password can not be more than 50 characters."
                                                    },
                                                    validate: (val: string) => {
                                                        if (watch('password') !== val) {
                                                            return 'Confirm password do not match.'
                                                        }
                                                    }
                                                })} type={confirmPasswordType} id="cpassword-field" className="form-control" placeholder="Confirm password" onChange={checkPassword} />
                                                <span toggle="#cpassword-field" className={`${confirmPasswordType === "password" ? "fa fa-eye " : "fa fa-eye-slash"} field-icon`} onClick={toggleConfirmPassword}></span>
                                                <span className="text-danger">
                                                    {errors.cpassword && errors.cpassword.type === "required" && 'Please enter confirm password.'}
                                                    {errors.cpassword && errors.cpassword.type !== "required" && errors.cpassword.message}
                                                </span>
                                            </div>
                                            <div id="password-checker" className="form-group d-none">
                                                <label className="w-100" htmlFor="">Password must contain:</label>
                                                <ul className="password_error">
                                                    <li>
                                                        {is_strong?.eight_char ? <i className="fa fa-check-circle text-success" aria-hidden="true"></i> : <i className="fa fa-times-circle text-danger" aria-hidden="true"></i>}
                                                        <label className="w-100">8 or more characters minimum</label>
                                                    </li>
                                                    <li>
                                                        {is_strong?.have_numub ? <i className="fa fa-check-circle text-success" aria-hidden="true"></i> : <i className="fa fa-times-circle text-danger" aria-hidden="true"></i>}
                                                        <label className="w-100">One or more than one characters with numbers</label>
                                                    </li>
                                                    <li>
                                                        {is_strong?.have_lower ? <i className="fa fa-check-circle text-success" aria-hidden="true"></i> : <i className="fa fa-times-circle text-danger" aria-hidden="true"></i>}
                                                        <label className="w-100">One or more lowercase characters</label>
                                                    </li>
                                                    <li>
                                                        {is_strong?.have_upper ? <i className="fa fa-check-circle text-success" aria-hidden="true"></i> : <i className="fa fa-times-circle text-danger" aria-hidden="true"></i>}
                                                        <label className="w-100">One or more capital characters</label>
                                                    </li>
                                                    <li>
                                                        {is_strong?.have_spslc ? <i className="fa fa-check-circle text-success" aria-hidden="true"></i> : <i className="fa fa-times-circle text-danger" aria-hidden="true"></i>}
                                                        <label className="w-100">One or more special characters</label>
                                                    </li>
                                                    <li>
                                                        {is_strong?.same_confp ? <i className="fa fa-check-circle text-success" aria-hidden="true"></i> : <i className="fa fa-times-circle text-danger" aria-hidden="true"></i>}
                                                        <label className="w-100">Match password with confirm password</label>
                                                    </li>
                                                </ul>
                                                {
                                                    (
                                                        (errors.password && errors.password.type !== "required")
                                                        ||
                                                        (errors.cpassword && errors.cpassword.type !== "required")
                                                    )
                                                        ?
                                                        <span className="text-danger">Password must follow the above rules.</span>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className="form-group">
                                                <Controller
                                                    name="recaptcha"
                                                    control={control}
                                                    render={({ onChange, onBlur }) => (
                                                        <ReCAPTCHA
                                                            ref={recaptchaRef}
                                                            sitekey="6Lc7XD4lAAAAAA5YHYwBCSEJA9Aj3sGE90EXLNml"
                                                            onChange={(value) => { setRecaptchaValue(value); clearErrors('recaptcha') }}
                                                            onExpired={() => { setRecaptchaValue(null) }}
                                                        />
                                                    )}
                                                />
                                                {errors.recaptcha && <p className='text-theme_red font-WorkSans_Regular'>
                                                    <span className="text-danger">{errors.recaptcha.message}</span></p>}
                                            </div>
                                            <div className="form-group small clearfix">
                                                <label htmlFor="terms_condition" className="register-links"> <p className="register-links">By clicking Agree &amp; Join, you agree to the EduKula <Link target="_blank" to="/pages/privacy-policy">Privacy Policy</Link> and <Link target="_blank" to="/pages/terms-of-use">Terms Of Service.</Link></p> </label>
                                            </div>
                                            <div className="clearfix"></div>
                                            <button disabled={processing} type="submit" className="btn btn-circle btn-success">
                                                {
                                                    processing ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : 'Agree & join now'
                                                }
                                            </button>
                                            <div className="login-footer">
                                                <div className="login-footer-text or_textline">
                                                    <p>Or</p>
                                                </div>
                                                <p className="sign-up-link"><Link to="/login">Already on EduKula? sign in</Link></p>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="custom-login-box">
                                    <div className="login-img-box regi-area">
                                        <img src="images/corporate_sign-up-img.png" alt="Login Image" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CorporateRegister;
