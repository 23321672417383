import React from 'react';
import { Outlet } from 'react-router-dom';

const RegiserLayout = () => {
    return (
        <>
            <section className="header-section">
                <div className="container">
                    <nav className="sticky-top text-center p-2">
                        <a className="navbar-brand"><img src={`images/logo.svg`} alt="Logo" /></a>
                    </nav>
                </div>
            </section>

            <Outlet />
        </>
    )
}

export default RegiserLayout