import { useForm } from "react-hook-form";
import { API_BASE } from "../constants/global";
import authContext from '../context/auth/authContext';
import { useEffect, useState, useContext } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import { CircleBoxSkeleton } from "../components/skeletons";
import { SwalBox } from "../entity/GlobalJS";
import { Helmet } from "react-helmet";


const Home2 = () => {
    const [homePage, setHomePageContent] = useState('');
    const [search, setSearch] = useState('');
    const [isLoader, setIsLoader] = useState(true);
    const navigate = useNavigate();
    const cntxAuth = useContext(authContext);

    const _token = localStorage.getItem('lmstoken');

    useEffect(() => {
        fetchHomePage();
        //eslint-disable-next-line
    }, []);

    const fetchHomePage = async () => {
        setIsLoader(true);
        const response = await fetch(API_BASE + 'pages/home', {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
        });

        const data = await response.json();
        if (data.status) {
            setIsLoader(false);
            var homeData = data.data;
            setHomePageContent(homeData);
        } else {
            setIsLoader(false);
        }
    }

    const homeSearch = () => {
        if (search != "") {
            navigate('/search/' + search);
        }
    }

    const RedirecttoLC = () => {
        if (_token == null) {
            SwalBox.fire({
                allowOutsideClick: false,
                title: 'Enrollment Alert',
                text: 'To Create learning circle, you must be logged in to the platform. If you do not have an account, don\'t worry! You can easily create one by clicking the "Sign Up" button below.',
                icon: 'info',
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: '#30314c',
                cancelButtonColor: '#30314c',
                confirmButtonText: 'Sign In',
                cancelButtonText: 'Sign Up'
            }).then((result) => {
                if (result.isConfirmed) {
                    localStorage.setItem('createLC', true);
                    navigate('/login');
                } else {
                    if (result.dismiss == 'cancel') {
                        localStorage.setItem('createLC', true);
                        navigate('/register');
                        return false;
                    } else {
                        SwalBox.close();
                    }
                }
            });
        } else {
            navigate('/dashboard/create-learning-circle')
        }
    }

    return (
        <>
            <Helmet>
                <title>{'EduKula - Homepage'}</title>
            </Helmet>
            {/* Banner */}
            <section className="custom-section m-0 new_home_banner">
                <div className="banner-section">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-7">
                                <div className="banner-info">
                                    <h2>Enhance your skills<span> & advance in your career</span></h2>
                                    <div className="banner-form">
                                        <input type="text" onChange={(e) => { setSearch(e.target.value); }} onKeyUp={(e) => { if (e.key === 'Enter') { homeSearch(); } }} placeholder="What would you like to learn ?" value={search} />
                                        <a onClick={(e) => { homeSearch() }} type="submit" className="btn btn-success"><i className="fas fa-search"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* top image */}
                <div className="content" dangerouslySetInnerHTML={{ __html: homePage?.logo_section }}></div>
            </section>
            <div className="clearfix"></div>

            {/* Corporate */}
            <section className="corporate_section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-6 position-relative">
                            <img src="images/images/corporates.png" alt="" />
                            <h3>Corporates</h3>
                        </div>
                        <div className="col-xl-6 col-lg-6">
                            <h2>Solutions for <span>corporates</span></h2>
                            <div className="solutionbox">
                                <img src="images/images/corpo_icon1.svg" alt="" />
                                <div>
                                    <h4>Corporate academia & youth connect</h4>
                                    <p className="homeSolution">Connects corporates with academia to create industry-relevant courses, and enables corporates to use them to train and hire talent.</p>
                                </div>
                            </div>
                            <div className="solutionbox">
                                <img src="images/images/corpo_icon2.svg" alt="" />
                                <div>
                                    <h4>Train your team</h4>
                                    <p className="homeSolution">Provide comprehensive training and ongoing support to empower employees, enhance their performance in current job roles, and facilitate career advancement.</p>
                                </div>
                            </div>
                            <div className="solutionbox">
                                <img src="images/images/corpo_icon3.svg" alt="" />
                                <div>
                                    <h4>Reporting & analytics</h4>
                                    <p className="homeSolution">Monitor the learning activities of your employees and obtain valuable insights that enable you to make informed decisions to achieve your organization's goals.</p>
                                </div>
                            </div>
                            {
                                cntxAuth.isLogged
                                    ?
                                    null
                                    :
                                    <Link to={"/corporate-register"} className="btn join_now">Join now</Link>
                            }
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </section>
            <div className="clearfix"></div>

            {/* Working Professionals */}
            <section className="corporate_section right_img_dgn white_bg">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-6 order-lg-2 position-relative">
                            <img src="images/images/work-proffesional.png" alt="" />
                            <h3>Working <br />Professionals</h3>
                        </div>
                        <div className="col-xl-6 col-lg-6">
                            <h2>Solutions for <span>working professionals</span></h2>
                            <div className="solutionbox">
                                <img src="images/images/working_icon1.svg" alt="" />
                                <div>
                                    <h4>Grow in your career</h4>
                                    <p className="homeSolution">Whether you're seeking to acquire a new skill or wish to enhance your existing skills through upskilling or reskilling, our platform can help you accelerate your career.</p>
                                </div>
                            </div>
                            <div className="solutionbox">
                                <img src="images/images/working_icon2.svg" alt="" />
                                <div>
                                    <h4>Collaborate with peers</h4>
                                    <p className="homeSolution">Gain deeper insights into your industry by creating or joining a learning circle and collaborating with like-minded individuals and industry peers.</p>
                                </div>
                            </div>
                            <div className="solutionbox">
                                <img src="images/images/working_icon3.svg" alt="" />
                                <div>
                                    <h4>Career advice</h4>
                                    <p className="homeSolution">Get personalized career advice based on your profile, helping you to identify the right learning opportunities to improve your skills and grow in your existing line of work.</p>
                                </div>
                            </div>
                            {
                                cntxAuth.isLogged
                                    ?
                                    null
                                    :
                                    <Link to={"/register"} className="btn join_now">Join now</Link>
                            }
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </section>
            <div className="clearfix"></div>

            {/* Students */}
            <section className="corporate_section student_title">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-6 position-relative">
                            <img src="images/images/student-freshers.png" alt="" />
                            <h3>Students <br /> & Freshers</h3>
                        </div>
                        <div className="col-xl-6 col-lg-6">
                            <h2>Solutions for <span>students & freshers</span></h2>
                            <div className="solutionbox">
                                <img src="images/images/student_icon1.svg" alt="" />
                                <div>
                                    <h4>Become job-ready</h4>
                                    <p className="homeSolution">Stay up-to-date with industry demands and gain practical experience by enhancing your skills and knowledge through top courses and trending learning circles.</p>
                                </div>
                            </div>
                            <div className="solutionbox">
                                <img src="images/images/student_icon2.svg" alt="" />
                                <div>
                                    <h4>Career advice</h4>
                                    <p className="homeSolution">Considering your profile details the system suggests the most suitable courses, learning circles, job vacancies and more to help you launch your career.</p>
                                </div>
                            </div>
                            <div className="solutionbox">
                                <img src="images/images/student_icon3.svg" alt="" />
                                <div>
                                    <h4>Get hired & earn rewards</h4>
                                    <p className="homeSolution">Study courses uploaded by corporates on EduKula, which are designed to find and hire new talent, and get rewarded monetarily for landing your dream job.</p>
                                </div>
                            </div>
                            {
                                cntxAuth.isLogged
                                    ?
                                    null
                                    :
                                    <Link to={"/register"} className="btn join_now">Join now</Link>
                            }
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </section>
            <div className="clearfix"></div>

            {/* Hiring Courses */}
            <section className="hiring_course">
                <div className="container">
                    <div className="explore-block">
                        <div className="title_with_btn">
                            <div>

                                <h2><span>Hiring</span> courses for job seekers</h2>
                                <p>Courses created by corporates looking to hire talent for a particular vacancy</p>
                            </div>
                            <Link to={"/courses"} className="btn">Explore more</Link>
                        </div>
                        <Tabs defaultActiveKey="0" className="nav nav-pills" id="pills-tab1" role="tablist">
                            {
                                (typeof homePage?.vacancy_courses_section?.data !== "undefined" && homePage?.vacancy_courses_section?.data?.length > 0)
                                &&
                                homePage?.vacancy_courses_section?.data.map((element, index) =>
                                    (element?.courses && index < 3)
                                    &&
                                    <Tab key={index} eventKey={index} title={element.title} className="nav-item">
                                        <div className="blog-card">
                                            <div className="row">
                                                {
                                                    element.courses.map((child_element, child_index) =>
                                                        <div className="col-md-6 col-lg-3" key={child_index}>
                                                            <Link to={`/course-details/${child_element?.slug}`} className="ek-lrs-course-initialized" data-ek-lrs-url={`/course-details/${child_element?.slug}`} data-ek-lrs-title={child_element?.title}>
                                                                <div className="blog-card-detail m-0">
                                                                    <div className="custom-card-img">
                                                                        <img src={child_element?.image} className="card-img-top" alt={child_element?.title} />
                                                                    </div>
                                                                    <div className="blog-card-inner">
                                                                        <div className="comp_photo">
                                                                            <img src={child_element.creator_image} alt="" />
                                                                        </div>
                                                                        <h5><a href={undefined}>{child_element?.title}</a><span>{child_element.creator_name}</span></h5>
                                                                        <ul className="course_tabs_sub mt-3 mb-2">
                                                                            {
                                                                                child_element?.sub_category?.title
                                                                                &&
                                                                                <li><a><i className="fas fa-list"></i><span>{child_element?.sub_category?.title}</span></a></li>
                                                                            }
                                                                            {
                                                                                <li><a><i className="fas fa-chair"></i><span>{child_element?.vacancy ? child_element?.vacancy[0].job_position : ''}</span></a></li>
                                                                            }
                                                                        </ul>
                                                                        <div className="clearfix"></div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </Tab>
                                )
                            }
                        </Tabs>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </section>
            <div className="clearfix"></div>

            {/* Top Courses */}
            {
                (typeof homePage?.our_courses_section?.data !== "undefined" && homePage?.our_courses_section?.data?.length > 0)
                &&
                <section className="hiring_course top_course">
                    <div className="container">
                        <div className="explore-block">
                            <div className="title_with_btn">
                                <div>
                                    {/* Commented and placed static due to word color css */}
                                    {/* <h2>{homePage?.our_courses_section?.title}</h2>
                                    <p>{homePage?.our_courses_section?.description}</p> */}

                                    <h2><span>Top</span> courses</h2>
                                    <p>Explore high-quality online courses on a wide array of topics and specializations.</p>
                                </div>
                                <Link to={"/courses"} className="btn">Explore more</Link>
                            </div>
                            <Tabs defaultActiveKey="0" className="nav nav-pills" id="pills-tab1" role="tablist">
                                {
                                    (typeof homePage?.our_courses_section?.data !== "undefined" && homePage?.our_courses_section?.data?.length > 0)
                                    &&
                                    homePage?.our_courses_section?.data.map((element, index) =>
                                        (element?.courses && index < 3)
                                        &&
                                        <Tab key={index} eventKey={index} title={element.title} className="nav-item">
                                            <div className="blog-card">
                                                <div className="row">
                                                    {
                                                        element.courses.map((child_element, child_index) =>
                                                            <div className="col-md-6 col-lg-3" key={child_index}>
                                                                <Link to={`/course-details/${child_element?.slug}`} className="ek-lrs-course-initialized" data-ek-lrs-url={`/course-details/${child_element?.slug}`} data-ek-lrs-title={child_element?.title}>
                                                                    <div className="blog-card-detail m-0">
                                                                        <div className="custom-card-img">
                                                                            <img src={child_element?.image} className="card-img-top" alt={child_element?.title} />
                                                                        </div>
                                                                        <div className="blog-card-inner">
                                                                            <div className="compny_logo">
                                                                                <img src="./images/course_logo_img.jpg" alt="" />
                                                                            </div>
                                                                            <h5>{child_element?.title}</h5>
                                                                            <p className="membr_line">
                                                                                {
                                                                                    child_element?.mode_of_learning
                                                                                    &&
                                                                                    <>
                                                                                        <span>{child_element?.mode_of_learning}</span>&nbsp;  &nbsp;
                                                                                    </>
                                                                                }

                                                                            </p>
                                                                            <ul className="course_tabs_sub mt-3 mb-2">
                                                                                {
                                                                                    child_element?.sub_category?.title
                                                                                    &&
                                                                                    <li><span><i className="fas fa-list"></i><span>{child_element?.sub_category?.title}</span></span></li>
                                                                                }
                                                                                {
                                                                                    <li><span><i className="fas fa-star"></i><span>{child_element?.rating !== "" ? child_element?.rating : 'No'} Rating</span></span></li>
                                                                                }
                                                                            </ul>
                                                                            <div className="clearfix"></div>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </Tab>
                                    )
                                }
                            </Tabs>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </section>
            }

            <div className="clearfix"></div>

            {/* power of peer to peer */}
            <section className="custom-section harnessing_section">
                <div className="image-with-text-section custom__bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 order-lg-2">
                                <div className="question-block">
                                    <img src="images/images/learning-circle.png" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="question-block text-white">
                                    <h2 className="text-white">Harnessing the power of <span>peer learning</span></h2>
                                    <p className="text-white homeSolution">Are you looking for a powerful platform to elevate your learning? A learning circle can be proved as your savior. It is a feature-rich study group which facilitates individuals in gathering and engaging with one another to gain advanced knowledge on various topics.
                                        <br />
                                        <Link to={'/explore-learning-circles'} >- Know more</Link>
                                    </p>
                                    <a onClick={(e) => { RedirecttoLC(); }} className="btn btn-info btn-circle">Create learning circle</a>
                                    <Link to={'/explore-learning-circles'} className="btn btn-light btn-circle">Join learning circle</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </section>
            <div className="clearfix"></div>

            {/* Top Learning Circles */}
            {
                (typeof homePage?.learning_circles_section?.data !== "undefined" && homePage?.learning_circles_section?.data?.length > 0)
                &&
                <section className="hiring_course top_learning_crcl">
                    <div className="container">
                        <div className="explore-block">
                            <div className="title_with_btn">
                                <div>
                                    {/* Commented and placed static due to word color css */}
                                    {/* <h1>{homePage?.learning_circles_section?.title}</h1>
                                    <p>{homePage?.learning_circles_section?.description}</p> */}
                                    <h2><span>Top</span> learning circles</h2>
                                    <p>Collaborative learning groups that connect you with industry peers and help you enhance your knowledge</p>
                                </div>
                                <Link to={'/explore-learning-circles'} className="btn">Explore more</Link>
                            </div>

                            <Tabs defaultActiveKey="0" className="nav nav-pills pb-5">
                                {
                                    (typeof homePage?.learning_circles_section?.data !== "undefined" && homePage?.learning_circles_section?.data?.length > 0)
                                    &&
                                    homePage?.learning_circles_section?.data.map((element, index) =>
                                        (element?.learning_circle && index < 3)
                                        &&
                                        <Tab key={index} eventKey={index} title={element.title}>
                                            <div className="blog-card">
                                                <div className="row">
                                                    {
                                                        element?.learning_circle
                                                        &&
                                                        element?.learning_circle.map((group, index) =>
                                                            <div key={index} className="col-md-6 col-lg-3">
                                                                <div className="blog-card-detail m-0">
                                                                    <div className="custom-card-img">
                                                                        <Link to={'/learning-circle-details/' + group.unique_id} className="ek-lrs-module-initialized" data-ek-lrs-url={`/learning-circle-details/${group.id}`} data-ek-lrs-title={group.title}>
                                                                            <img src={group.image} className="card-img-top" alt="Blog image" />
                                                                        </Link>
                                                                    </div>
                                                                    <div className="blog-card-inner">
                                                                        <p className="membr_line"><span>{group.membersCount}+ members</span> &nbsp; | &nbsp; <span>{group?.owner}</span></p>
                                                                        <h5>
                                                                            <Link to={'/learning-circle-details/' + group.unique_id} className="ek-lrs-module-initialized" data-ek-lrs-url={`/learning-circle-details/${group.id}`} data-ek-lrs-title={group.title}>
                                                                                {group.title}
                                                                            </Link>
                                                                        </h5>

                                                                        <ul className="course_tabs_sub mt-3 mb-2">
                                                                            <li><a><i className="fas fa-list"></i><span>{group.category}</span></a></li>
                                                                            {/* <li><Link to={`/profile-details/${group?.owner_slug}`}><i className="fas fa-user"></i><span>{group?.owner}</span></Link></li>
                                                                        <li><a><i className="fas fa-users"></i><span>{group.membersCount}+ Group members</span></a></li> */}
                                                                            {/* <li><a><i className="fas fa-language"></i><span>{group.language}</span></a></li> */}
                                                                            <li><a><i className="fas fa-star"></i><span>{group?.average_rating !== null ? group?.average_rating : 'No'} Rating</span></a></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </Tab>
                                    )
                                }
                            </Tabs>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </section>
            }
            <div className="clearfix"></div>

            {/* Educators */}
            <section className="corporate_section right_img_dgn tutor_sect white_bg">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-6 order-lg-2 position-relative">
                            <img src="images/images/tutors.png" alt="" />
                            <h3>Educators</h3>
                        </div>
                        <div className="col-xl-6 col-lg-6">
                            <h2>Solutions for <span>educators</span></h2>
                            <div className="solutionbox">
                                <img src="images/images/tutors_icon1.svg" alt="" />
                                <div>
                                    <h4>Publish courses</h4>
                                    <p className="homeSolution">You can create and publish courses on EduKula at no cost, enjoying complete control over your course material and no hidden charges.</p>
                                </div>
                            </div>
                            <div className="solutionbox">
                                <img src="images/images/tutors_icon2.svg" alt="" />
                                <div>
                                    <h4>Earn revenue</h4>
                                    <p className="homeSolution">Generate income by developing customized, industry-specific courses for corporate clients that align with their training requirements.</p>
                                </div>
                            </div>
                            <div className="solutionbox">
                                <img src="images/images/tutors_icon3.svg" alt="" />
                                <div>
                                    <h4>Track learner activity</h4>
                                    <p className="homeSolution">Our platform generates reports that track learner activity, providing you with valuable insights into how your courses are performing.</p>
                                </div>
                            </div>
                            {
                                cntxAuth.isLogged
                                    ?
                                    null
                                    :
                                    <Link to={"/register"} className="btn join_now">Join now</Link>
                            }
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </section>
            <div className="clearfix"></div>
        </>
    );
}

export default Home2;
