import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useDropzone } from "react-dropzone";
import { Link } from "react-router-dom";
import QuizModal from './QuizModal';
import { SwalBox, Toaster } from "../../../entity/GlobalJS";
import ReactTags from 'react-tag-autocomplete';
import { Controller, useForm } from "react-hook-form";
import { API_BASE, DEFAULT_UPLOAD_FILE_IMG_NEW, DEFAULT_UPLOAD_FILE_IMG_NEW2 } from "../../../constants/global";
import EduKulaAi from '../../../entity/EdukulaAi';
import { AgGridReact } from 'ag-grid-react';
import QuizData from './QuizDataAPI.json';
import { Helmet } from "react-helmet";

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

function QuizList() {
  const _token = localStorage.getItem('lmstoken');
  const [showQuizModal, setShowQuizModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [tags, setTags] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  // Add New Question Modal
  const [questype, setQuestype] = useState('text');
  const [queslevel, setQueslevel] = useState('Easy');
  const [questags, setQuestags] = useState('');
  const [anstype, setAnstype] = useState('text');
  const [selectedQuestionImage, setSelectedQuestionImage] = useState(null);
  const [answer1Image, setAnswer1Image] = useState(null);
  const [answer2Image, setAnswer2Image] = useState(null);
  const [answer3Image, setAnswer3Image] = useState(null);
  const [answer4Image, setAnswer4Image] = useState(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [quizID, setQuizID] = useState(null);
  const [selectedQuesVideo, setSelectedQuesVideo] = useState(null);
  const [FinalQuizData, setFinalQuizData] = useState([]);
  const [loading, setLoading] = useState(false);
  const _lmsuser = JSON.parse(localStorage.getItem('lmsuser'));
  const userID = _lmsuser['id'];
  const [reload, setReload] = useState(false);
  const [QuesEdit, setQuesEdit] = useState(false);
  const [selectedquestionIDarray, setSelectedquestionIDarray] = useState([]);
  const [trueansweris, setTrueansweris] = useState(null);

  const { register: register2, control: control2, handleSubmit: handleSubmit2, formState: { errors: errors2 }, clearErrors: clearErrors2, setError: setError2, reset: reset2, resetField: resetField2, unregister: unregister2, setValue: setValue2 } = useForm(
    {
      mode: "all",
    }
  );

  const { register, handleSubmit, formState: { errors }, watch, control, unregister } = useForm();
  const reactTags = useRef();
  const onDelete = useCallback((tagIndex) => {
    setTags(tags.filter((_, i) => i !== tagIndex));
  }, [tags])

  const onAddition = useCallback((newTag) => {
    setTags([...tags, newTag]);
  }, [tags])

  // let FinalQuizData = QuizData.data.slide.material.questions;
  const gridRef = useRef();

  const toggleLeftMenu = async () => {
    try {
      let sidebar = document.querySelector(".sidebar");
      sidebar.classList.add("close");
    } catch (e) { }
  }

  useEffect(() => {
    toggleLeftMenu();
    fetchQuestions();
  }, []);

  useEffect(() => {
    fetchQuestions();
  }, [reload]);

  const fetchSingleQuestion = async (questionIDis) => {
    const formData = new FormData();
    formData.append('question_id', questionIDis);

    const response = await fetch(`${API_BASE}get-quiz-bank-question`, {
      method: 'POST',
      headers: {
        "Authorization": `Bearer ${_token}`
      },
      body: formData
    });

    const data = await response.json();
    if (data.status) {

      console.log(data.data.questions[0].question);
      // setValue2('question',);
      setValue2('answer1_description', data.data.questions[0].option_a_description);
      setValue2('answer2_description', data.data.questions[0].option_b_description);
      setValue2('answer3_description', data.data.questions[0].option_c_description);
      setValue2('answer4_description', data.data.questions[0].option_d_description);

      console.log(data.data.questions[0].answer);
      if (data.data.questions[0].answer == "answer_a") {
        setValue2('true_answer', 1);
        setTrueansweris(1);
      } else if (data.data.questions[0].answer == "answer_b") {
        setValue2('true_answer', 2);
        setTrueansweris(2);
      } else if (data.data.questions[0].answer == "answer_c") {
        setValue2('true_answer', 3);
        setTrueansweris(3);
      } else if (data.data.questions[0].answer == "answer_d") {
        setValue2('true_answer', 4);
        setTrueansweris(4);
      }

      if (data.data.questions[0].question_type === "video") {
        setSelectedQuesVideo(data.data.questions[0].question);
        setSelectedQuestionImage(null);
        setValue2('question');
        setValue2('question_descriptionVideo', data.data.questions[0].question_description);
      } else if (data.data.questions[0].question_type === "image") {
        setSelectedQuestionImage(data.data.questions[0].question);
        setSelectedQuesVideo(null)
        setValue2('question');
        setValue2('question_descriptionImage', data.data.questions[0].question_description);
      } else if (data.data.questions[0].question_type === "text") {
        setValue2('question', data.data.questions[0].question);
        setSelectedQuesVideo(null);
        setSelectedQuestionImage(null);
      }

      if (data.data.questions[0].answer_type === "image") {
        setAnswer1Image(data.data.questions[0].option_a);
        setAnswer2Image(data.data.questions[0].option_b);
        setAnswer3Image(data.data.questions[0].option_c);
        setAnswer4Image(data.data.questions[0].option_d);
        setValue2('answer1');
        setValue2('answer2');
        setValue2('answer3');
        setValue2('answer4');

      } else if (data.data.questions[0].answer_type === "text") {
        setValue2('answer1', data.data.questions[0].option_a);
        setValue2('answer2', data.data.questions[0].option_b);
        setValue2('answer3', data.data.questions[0].option_c);
        setValue2('answer4', data.data.questions[0].option_d);
        setAnswer1Image(null);
        setAnswer2Image(null);
        setAnswer3Image(null);
        setAnswer4Image(null);
      }



      // setValue2('is_true',);
      // setSelectedQuestionImage();
      // setAnswer1Image();
      // setAnswer2Image();
      // setAnswer3Image();
      // setAnswer4Image();
      // selectedQuesVideo();
      setQuizID(data.data.questions[0].id);
      setAnstype(data.data.questions[0].answer_type);
      setQuestype(data.data.questions[0].question_type);

      setQueslevel(data.data.questions[0].difficulty_level);
      setQuestags(data.data.questions[0].metadata);
      document.getElementById('addnewquestionclick').click();


    } else {


    }
  }


  const fetchQuestions = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('user_id', userID);

    const response = await fetch(`${API_BASE}get-quiz-bank-questions`, {
      method: 'POST',
      headers: {
        "Authorization": `Bearer ${_token}`,
      },
      body: formData
    });

    const data = await response.json();

    if (data.status) {
      setFinalQuizData(data.data.questions);

    } else {
      setFinalQuizData([]);
    }
    setLoading(false);
  }

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [showButton, setShowButton] = useState(false);

  const isFirstColumn = (params) => {
    var displayedColumns = params.columnApi.getAllDisplayedColumns();
    var thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  };

  const defaultColDef = {
    checkboxSelection: true,
    resizable: true,
    editable: false,
    filter: true,
    sortable: true,
    floatingFilter: true,
    suppressNavigable: true,
    headerCheckboxSelection: isFirstColumn,
    checkboxSelection: isFirstColumn,
  }

  const Dele = (id) => {
    // setShowQuizModal(true);
    document.getElementById('modalTogglerUpdateEmployee').click();
  }

  const EditQuestionModal = (questionIDis) => {
    setQuesEdit(true);
    fetchSingleQuestion(questionIDis);
  };

  const columnDefs = [
    {
      headerName: "Sr.",
      valueGetter: "node.rowIndex + 1",
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      filter: false,
      sortable: false,
      floatingFilter: false,
      width: 100,
      minWidth: 100
    },

    //{
    //  headerName: 'QuestionID', field: 'id', width: '100', minWidth: 130
    //},

    {
      headerName: 'Question Type',
      field: 'question_type',
      width: 150,
      minWidth: 150

    },
    {
      headerName: 'Question',
      // field: 'question',
      flex: 3,
      cellRenderer: (params) => {
        if (params.data.question_type === "text") {
          return params.data.question
        } else if (params.data.question_type === "image" || params.data.question_type === "video") {
          return params.data.question_description
        }
      },
      filterValueGetter: (params) => {
        if (params.data.question_type === "text") {
          return params.data.question
        } else if (params.data.question_type === "image" || params.data.question_type === "video") {
          return params.data.question_description
        }
      }
    },

    {
      headerName: 'Difficulty Level',
      field: 'difficulty_level',
      width: 100,
      minWidth: 100
    },
    {
      headerName: 'Tags',
      field: 'metadata',
      width: 100,
      minWidth: 200
    },
    {
      headerName: 'Action Buttons',
      width: 150,
      pinned: 'right',
      filter: false,
      sortable: false,
      floatingFilter: false,
      resizable: false,
      cellRenderer: (params) => {
        return (
          <>
            <span style={{
              cursor: 'pointer',
              color: '#ef453f'
            }}>
              {/* <span onClick={() => { setViewModal(true); Dele(params.data.id) }} data-bs-toggle="tooltip" data-placement="bottom" title="View"><i className="fa-solid fa-eye"></i></span> */}
              <span onClick={() => { EditQuestionModal(params.data.id) }} style={{ marginLeft: '15px' }}><i className="fa-solid fa-pen-to-square"></i></span>
              {/* <span onClick={() => { ConfirmDeleteQuestion() }} style={{ marginLeft: '15px' }} data-bs-toggle="tooltip" data-placement="bottom" title="Delete"><i className="fa-solid fa-trash-can"></i></span> */}
            </span >
          </>
        );
      },
    },
  ];

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    const allColumnIds = [];
    params.columnApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    params.columnApi.autoSizeColumns(allColumnIds);
  };

  const extractKey = (array, key) => array.map(obj => obj[key]);

  const onSelectionChanged = useCallback(() => {
    var selectedRows = gridRef.current.api.getSelectedRows();
    if (selectedRows.length > 0) {
      const resultArray = extractKey(selectedRows, 'id');
      setShowButton(true);
      setSelectedquestionIDarray(resultArray);
    } else {
      setShowButton(false);
    }
  })

  const ConfirmDeleteQuestion = () => {
    SwalBox.fire({
      title: 'Are you sure?',
      text: `Are you sure you want to delete this question ?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteselectedQues(selectedquestionIDarray);
      }
    });
  }

  const deleteselectedQues = async (ids) => {
    SwalBox.close();
    SwalBox.fire({
      title: 'Please Wait...',
      text: 'Your questions are being deleted. Thank you for your patience.',
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        SwalBox.showLoading();
      }
    });
    const formData = new FormData();
    formData.append('question_ids', ids);
    const response = await fetch(`${API_BASE}delete-quiz-bank-questions`, {
      method: 'POST',
      headers: {
        "Authorization": `Bearer ${_token}`
      },
      body: formData
    });
    const data = await response.json();
    if (data.status) {
      setReload(!reload);
      SwalBox.close();
    } else {
      setReload(!reload);
      SwalBox.close();
    }
  }
  // className = 'GenVid GenQueBank'
  const handleCloseUpdateQuestion = () => {
    // reset2();
    setViewModal(false);
  }

  const AddMetaData = () => {
    document.getElementById('modalTogglerAddMetaData').click();
  }

  const handleCloseAddMetaData = () => {

  }


  const { getRootProps: getRoopProps2, getInputProps: getInputProps2 } = useDropzone({
    multiple: false,
    // maxFiles: 1,
    //  accept: 'image/jpeg, image/jpg, image/png',
    // validator: typeValidatorQM,
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      const validFormats = ['image/jpeg', 'image/jpg', 'image/png'];

      if (file && validFormats.includes(file.type)) {
        setSelectedQuestionImage(file);
        clearErrors2('quePhoto');
      } else {
        setError2('questionM', { type: "focus", message: ' Please upload a valid image file(jpg, jpeg, or png).' }, { shouldFocus: true });
      }
    }
  })

  const { getRootProps: getRootPropsV2, getInputProps: getInputPropsV2 } = useDropzone({
    multiple: false,
    // maxFiles: 1,
    //  accept: 'image/jpeg, image/jpg, image/png',
    // validator: typeValidatorQM,
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      const validFormats = ['video/mp4', 'video/mp3'];

      if (file && validFormats.includes(file.type)) {
        setSelectedQuesVideo(file);
        clearErrors2('quePhoto');
      } else {
        setError2('questionM', { type: "focus", message: ' Please upload a valid video file(jpg, jpeg, or png).' }, { shouldFocus: true });
      }
    }
  })

  const removeselectedQuestionImage = () => {
    setSelectedQuestionImage(null);
    setSelectedQuesVideo(null);
  }

  const { getRootProps: getRoopProps3, getInputProps: getInputProps3 } = useDropzone({
    multiple: false,
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      const validFormats = ['image/jpeg', 'image/jpg', 'image/png'];

      if (file && validFormats.includes(file.type)) {
        setAnswer1Image(file);
        clearErrors2('answer1Photo');
      } else {

      }
    }
  })

  const removeanswer1Image = () => {
    setAnswer1Image(null);
  }

  const { getRootProps: getRoopProps4, getInputProps: getInputProps4 } = useDropzone({
    multiple: false,
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      const validFormats = ['image/jpeg', 'image/jpg', 'image/png'];

      if (file && validFormats.includes(file.type)) {
        setAnswer2Image(file);
        clearErrors2('answer2Photo')
      } else {

      }
    }
  })

  const removeanswer2Image = () => {
    setAnswer2Image(null);
  }

  const { getRootProps: getRoopProps5, getInputProps: getInputProps5 } = useDropzone({
    multiple: false,
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      const validFormats = ['image/jpeg', 'image/jpg', 'image/png'];

      if (file && validFormats.includes(file.type)) {
        setAnswer3Image(file);
      } else {

      }
    }
  })

  const removeanswer3Image = () => {
    setAnswer3Image(null);
  }

  const { getRootProps: getRoopProps6, getInputProps: getInputProps6 } = useDropzone({
    multiple: false,
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      const validFormats = ['image/jpeg', 'image/jpg', 'image/png'];

      if (file && validFormats.includes(file.type)) {
        setAnswer4Image(file);
      } else {

      }
    }
  })

  const removeanswer4Image = () => {
    setAnswer4Image(null);
  }

  const handlechangequestiontype = () => {
    if (questype === 'image') {
      setSelectedQuesVideo(null)
      resetField2('question');
      unregister2('question');
      resetField2('question_descriptionVideo');
      unregister2('question_descriptionVideo');
    }
    if (questype === 'text') {
      setSelectedQuesVideo(null)
      setSelectedQuestionImage(null);
      resetField2('question_image');
      unregister2('question_image');
      resetField2('question_descriptionImage');
      unregister2('question_descriptionImage');
      resetField2('question_descriptionVideo');
      unregister2('question_descriptionVideo');
    }
    if (questype === 'video') {
      setSelectedQuestionImage(null);
      resetField2('question_descriptionImage');
      unregister2('question_descriptionImage');
    }
  }

  const handlechangeanswertype = () => {

    if (anstype === 'image') {
      resetField2('true_answer');
      resetField2('answer1');
      resetField2('answer2');
      resetField2('answer3');
      resetField2('answer4');
      unregister2('answer1');
      unregister2('answer2');
      unregister2('answer3');
      unregister2('answer4');
    }
    if (anstype === 'text') {
      resetField2('true_answer');
      resetField2('ans1image');
      resetField2('ans2image');
      resetField2('ans3image');
      resetField2('ans4image');
      setAnswer1Image(null);
      setAnswer2Image(null);
      setAnswer3Image(null);
      setAnswer4Image(null);
      unregister2('ans1image');
      unregister2('ans2image');
      unregister2('ans3image');
      unregister2('ans4image');
    }
  }

  const handleSendQuizWithImage = async (form) => {
    const formData = new FormData();
    if (questype === 'image' && selectedQuestionImage === null) {
      setError2('quePhoto', { type: 'custom', message: 'Please upload question' });
      return;
    }
    if (anstype === 'image' && answer1Image === null) {
      setError2('answer1Photo', { type: 'custom', message: 'Please upload answer' });
      return;
    }
    if (anstype === 'image' && answer2Image === null) {
      setError2('answer2Photo', { type: 'custom', message: 'Please upload answer' });
      return;
    }
    formData.append('question', form.question);
    formData.append('question_image', selectedQuestionImage);
    formData.append('answer1_image', answer1Image);
    formData.append('answer2_image', answer2Image);
    formData.append('answer3_image', answer3Image);
    formData.append('answer4_image', answer4Image);
    formData.append('answer_type', anstype);
    formData.append('question_type', questype);
    formData.append('question_level', queslevel);
    formData.append('question_tags', questags);
    formData.append('answer1', form.answer1);
    formData.append('answer1_description', form.answer1_description);
    formData.append('answer2', form.answer2);
    formData.append('answer2_description', form.answer2_description);
    formData.append('answer3', form.answer3);
    formData.append('answer3_description', form.answer3_description);
    formData.append('answer4', form.answer4);
    formData.append('answer4_description', form.answer4_description);
    formData.append('is_true', form.true_answer);
    formData.append('question_video', selectedQuesVideo);

    if (questype === 'image') {
      formData.append('question_description', form.question_descriptionImage);
    }

    if (questype === 'video') {
      formData.append('question_description', form.question_descriptionVideo);
    }

    if (QuesEdit === true) {
      formData.append('question_id', quizID);
    }


    reset2();
    setAnswer1Image(null);
    setAnswer2Image(null);
    setAnswer3Image(null);
    setAnswer4Image(null);
    setQuestype('text');
    setAnstype('text');

    var sURL = `${API_BASE}save-quiz-bank-question`;
    const response = await fetch(sURL, {
      method: 'POST',
      headers: {
        "Authorization": "Bearer " + _token
      },
      body: formData
    });


    const data = await response.json();
    if (data.status) {
      Toaster.success(data.message);
      // fetchSections(currentPage);
      // setQuiz(false);
      // setProcessing(false);
      reset2();
      setAnswer1Image(null);
      setAnswer2Image(null);
      setAnswer3Image(null);
      setAnswer4Image(null);
      setQuestype('text');
      setAnstype('text');
      document.getElementById('MaterialsTypeModalLongClose').click();
      setReload(!reload);
    } else {
      Toaster.error(data.message);
      // setProcessing(false);
      reset2();
      setAnswer1Image(null);
      setAnswer2Image(null);
      setAnswer3Image(null);
      setAnswer4Image(null);
      setQuestype('text');
      setAnstype('text');
    }
    setQuesEdit(false);
    setTrueansweris(null);
  }
  /*
  const fetchStudents = async (search = '') => {
  setProcessing(true);
  const response = await fetch(`${API_BASE}course-students/${props.course_id}?page=${page}&search=${search}`, {
  method: 'GET',
  headers: {
  "Content-Type": "application/json",
  "Accept": "application/json",
  "Authorization": "Bearer " + _token
  },
  });

  const data = await response.json();
  if (data.status) {
  setStudentEmp(data.data.students);
  } else {
  setStudentEmp([]);
  }

  setProcessing(false);
  }
  */

  return (
    <>
      <Helmet>
        <title>{'EduKula - Question list'}</title>
      </Helmet>
      <div className="col-lg-9 home-section">
        <div className="custom-user-profile-content custom-learning-circle-content chatPage vacancypage">
          <div className="ag-theme-alpine" style={{ height: '100%', width: '100%' }}>
            <div className="custom-learnig-circle-head">
              <h4>Quiz Bank - Question list</h4>
              <a id="MaterialsMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="btn btn-circle btn-info tour-vacancy-step-01" >Add Questions</a>
              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="MaterialsMenuButton" >
                <Link to={'/dashboard/tutor/addquiz'} className="dropdown-item quizbankDropdown" >Generate Questions</Link>
                <Link to={''} className="dropdown-item quizbankDropdown"
                  data-toggle="modal" data-target="#MaterialsTypeModalLong"
                >Add New Question</Link>
              </div>
            </div>
            {showButton &&
              <>
                <div className='QuizSelected'>
                  <button className='GenVid GenQueBank' onClick={() => { ConfirmDeleteQuestion() }}>
                    Delete Selected Questions
                  </button>
                  <button className='GenVid GenQueBank' onClick={() => { AddMetaData() }}>
                    Add tags to Selected Questions
                  </button>
                </div>
              </>
            }

            <AgGridReact
              onGridReady={onGridReady}
              columnDefs={columnDefs}
              // columnDefs={QuizData}
              ref={gridRef}
              defaultColDef={defaultColDef}
              rowData={FinalQuizData}
              domLayout="autoHeight"
              enableFilter={true}
              enableSorting={true}
              enableColResize={true}
              pagination={true}
              paginationPageSize={20}
              suppressRowClickSelection={true}
              rowSelection={'multiple'}
              onSelectionChanged={onSelectionChanged}
            />
          </div>
        </div>
      </div>
      {/* <QuizModal show={showQuizModal} onHide={() => setShowQuizModal(false)} /> */}


      <div className="materials_section">
        <div className="top_list">
          <div className="modal fade viewemployee" id="UpdateEmployeeModalLong" tabIndex="-1" role="dialog" aria-labelledby="UpdateEmployeeModalLongTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div className="modal-dialog modal-dialog-centered modal-ek-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 id="UpdateEmployeeModalLongTitle">{viewModal ? 'View' : 'Update '} Question </h4>
                  <button type="button" id="UploadModalLongClose2" className="close" data-dismiss="modal" aria-label="Close"
                    onClick={handleCloseUpdateQuestion}
                  ><span aria-hidden="true">&times;</span></button>
                </div>
                <div className="modal-body">
                  <div className="container">
                    <form key={2}
                    // onSubmit={handleSubmit2(SaveQuestion)}
                    >
                      <div className="form-group row">
                        <div className="col-md-12">
                          <label><b>Question</b></label>
                          <input type="text" autoComplete="none" name="first_name" id="first_name" className="form-control" autoFocus
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-md-6">
                          <label><b>answer 1</b></label>
                          <input type="text" autoComplete="none" name="first_name" id="first_name" placeholder="e.g. answer 1" className="form-control" autoFocus
                          // {...register2("first_name", {
                          //   required: "required",
                          // })} disabled={viewModal}
                          />
                          {/* <span className="text-danger">
{errors2.first_name && errors2.first_name.type === "required" && 'Please enter first name'}
</span> */}
                        </div>
                        <div className="col-md-6">
                          <label><b>answer 2</b></label>
                          <input type="text" autoComplete="none" name="last_name" id="last_name" placeholder="e.g. answer 2" className="form-control"
                          // {...register2("last_name", {
                          //   required: "required",
                          // })} disabled={viewModal}
                          />
                          {/* <span className="text-danger">
{errors2.last_name && errors2.last_name.type === "required" && 'Please enter last name'}
</span> */}
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-md-6">
                          <label><b>answer 3</b></label>
                          <input type="text" autoComplete="none" name="phone" id="phone" placeholder="e.g. answer 3" className="form-control"
                          // {...register2("phone", {
                          //   pattern: {
                          //     value: /^(\+\d{1,3}[- ]?)?\d{8,16}$/,
                          //     message: "Please enter minimum 8 and maximum 16 digit"
                          //   },
                          // })} disabled={viewModal}
                          />
                          {/* <span className="text-danger">
{errors2.phone && errors2.phone.type !== "required" && errors2.phone.message}
</span> */}
                        </div>
                        <div className="col-md-6">
                          <label><b>answer 4</b></label>
                          <input type="text" autoComplete="none" name="phone" id="phone" placeholder="e.g. answer 4" className="form-control"
                          // {...register2("phone", {
                          //   pattern: {
                          //     value: /^(\+\d{1,3}[- ]?)?\d{8,16}$/,
                          //     message: "Please enter minimum 8 and maximum 16 digit"
                          //   },
                          // })} disabled={viewModal}
                          />
                          {/* <span className="text-danger">
{errors2.phone && errors2.phone.type !== "required" && errors2.phone.message}
</span> */}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="adq tagdiv">
                            <label className='QBtags'>Add tags :- </label>
                            <Controller
                              control={control}
                              shouldUnregister={true}
                              name="tags"
                              rules={{ required: false, message: "Please enter keywords" }}
                              render={({ field: { onChange, onKeyUp, value, name, ref } }) => (
                                <ReactTags
                                  ref={reactTags}
                                  tags={tags}
                                  suggestions={suggestions}
                                  onDelete={onDelete}
                                  onAddition={onAddition}
                                  allowNew={true}
                                  placeholderText={tags.length <= 0 ? 'Please enter tags to this question' : ' '}
                                />
                              )}
                            />
                            {/* </div> */}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label><b>Difficulty levels</b></label>
                          <select name="corporate_emp_role" id="corporate_emp_role" className="form-control select2"
                          // {...register2("role_id")} disabled={viewModal}
                          >
                            <option value={0}>Easy</option>
                            <option value={1}>Moderate</option>
                            <option value={2}>Challenging </option>
                          </select>
                        </div>
                      </div>
                      {viewModal === false ? (
                        <button disabled={processing} type="submit" className="btn btn-circle btn-md submit-pink-btn">{processing ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : 'Update Question'}   </button>
                      ) : (<></>)
                      }
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a id="modalTogglerUpdateEmployee" data-toggle="modal" data-target="#UpdateEmployeeModalLong">&nbsp;</a>


      <div className="materials_section">
        <div className="top_list">
          <div className="modal fade viewemployee" id="AddMetaDataModal" tabIndex="-1" role="dialog" aria-labelledby="UpdateEmployeeModalLongTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div className="modal-dialog modal-dialog-centered modal-ek-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 id="UpdateEmployeeModalLongTitle">Add Metadata</h4>
                  <button type="button" id="UploadModalLongClose2" className="close" data-dismiss="modal" aria-label="Close"
                    onClick={handleCloseAddMetaData}
                  ><span aria-hidden="true">&times;</span></button>
                </div>
                <div className="modal-body">
                  <div className="container">
                    <form key={2}
                    // onSubmit={handleSubmit2(SaveQuestion)}
                    >

                      <div>
                        <div className="adq tagdiv">
                          <label className='QBtags'>Add tags :- </label>

                          <Controller
                            control={control}
                            shouldUnregister={true}
                            name="tags"
                            rules={{ required: false, message: "Please enter keywords" }}
                            render={({ field: { onChange, onKeyUp, value, name, ref } }) => (
                              <ReactTags
                                ref={reactTags}
                                tags={tags}
                                suggestions={suggestions}
                                onDelete={onDelete}
                                onAddition={onAddition}
                                allowNew={true}
                                placeholderText={tags.length <= 0 ? 'Please enter tags to question' : ' '}
                              />
                            )}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-md-12">
                          <label><b>Difficulty levels</b></label>
                          <select name="corporate_emp_role" id="corporate_emp_role" className="form-control select2"
                          // {...register2("role_id")} disabled={viewModal}
                          >
                            <option value={0}>Easy</option>
                            <option value={1}>Moderate</option>
                            <option value={2}>Challenging </option>
                          </select>
                        </div>
                      </div>



                      {viewModal === false ? (
                        <button disabled={processing} type="submit" className="btn btn-circle btn-md submit-pink-btn">{processing ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : 'Save'}   </button>
                      ) : (<></>)
                      }
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a id="modalTogglerAddMetaData" data-toggle="modal" data-target="#AddMetaDataModal">&nbsp;</a>


      {/* Add New Question Modal */}
      <div className="modal fade" id="MaterialsTypeModalLong" tabIndex="-1" role="dialog" aria-labelledby="MaterialsTypeModalLongTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-ek-lg" role="document">
          <div className="modal-content">
            <div className="modal-header"><h4 id="MaterialsTypeModalLongTitle">{QuesEdit ? `Edit ` : `Add `}Questions</h4>
              <button type="button" id="MaterialsTypeModalLongClose" className="close" data-dismiss="modal" aria-label="Close" onClick={() => {
                reset2(); setQuestype('text'); setAnstype('text'); setQuesEdit(false); setTrueansweris(null)
              }}><span aria-hidden="true">&times;</span></button>
            </div>
            <form key={2} onSubmit={handleSubmit2(handleSendQuizWithImage)}>

              <div className="modal-body">
                <label htmlFor="questype"><strong>Choose Question type :</strong></label>
                {/* eslint-disable-next-line */}
                <select name="questype" onChange={(e) => { setQuestype(e.target.value); handlechangequestiontype() }} id="quiztype" className="select-menu-q" value={questype}>

                  <option value="text">Question With text</option>
                  <option value="image">Question With image</option>
                  <option value="video">Question With video</option>
                </select>
                <div>
                  <label><strong>Question</strong></label>
                </div>
                {
                  questype === 'text' ? (<>
                    <input type="text" className="text-quiz-new" placeholder="Enter a Question"
                      {...register2("question", {
                        required: "required",
                      })}
                    />
                  </>) : (questype === 'image' ? (<>
                    {/* <input type='file' className="text-quiz-new" {...register2("question1", {
required: "required",
})} /> */}
                    <div className="upload_box_resume question-upload"  {...getRoopProps2()}>
                      <img src={DEFAULT_UPLOAD_FILE_IMG_NEW2} className="cursor_pointer" width="70%" style={{
                        height: '40px',
                        width: '50%'
                      }} alt='preview' />
                      <input style={{ display: 'none' }} {...getInputProps2()}
                      // {...register2("question_image", {
                      //     required: "required",
                      // })}
                      />
                    </div>

                    <label>Question Description</label>
                    <input type="text" className="text-quiz-new" placeholder="Question Description"
                      {...register2("question_descriptionImage")}
                    />

                    {selectedQuestionImage && (
                      <div>
                        <img src={QuesEdit ? selectedQuestionImage : URL.createObjectURL(selectedQuestionImage)} alt="Selected" style={{ maxWidth: '300px', maxHeight: '150px' }} />
                        <span onClick={(e) => { removeselectedQuestionImage(); }} className="btnGrp dlt_btn">
                          <i className="fa fa-trash mr-2" aria-hidden="true"></i>
                        </span>
                      </div>
                    )}
                  </>) : (
                    <>

                      <div className="upload_box_resume question-upload"  {...getRootPropsV2()}>
                        <img src={DEFAULT_UPLOAD_FILE_IMG_NEW2} className="cursor_pointer" width="70%" style={{
                          height: '40px',
                          width: '50%'
                        }} alt='preview' />
                        <input style={{ display: 'none' }} {...getInputPropsV2()}
                        // {...register2("question_image", {
                        //     required: "required",
                        // })}
                        />
                      </div>
                      <label>Question Description</label>
                      <input type="text" className="text-quiz-new" placeholder="Question Description"
                        {...register2("question_descriptionVideo")}
                      />
                      {selectedQuesVideo && (
                        <div>
                          <div className="video-preview">
                            <video controls style={{ maxWidth: '300px', maxHeight: '150px', marginTop: '10px' }}>
                              <source src={QuesEdit ? selectedQuesVideo : URL.createObjectURL(selectedQuesVideo)}
                              />
                              Your browser does not support the video tag.
                            </video>
                            <span onClick={(e) => { removeselectedQuestionImage(); }} className="btnGrp dlt_btn">
                              <i className="fa fa-trash mr-2" aria-hidden="true"></i>
                            </span>
                          </div>

                        </div>
                      )}

                    </>
                  )
                  )
                }
                <span className="text-danger">
                  {errors2.quePhoto && errors2.quePhoto.type === "custom" && 'Please upload question.'}
                </span>
                <span className="text-danger">
                  {errors2.question && errors2.question.type === "required" && 'Please enter question.'}
                </span>
                <div>
                  <label htmlFor="anstype">Choose Answer type :</label>
                  <select name="anstype" onChange={(e) => { setAnstype(e.target.value); handlechangeanswertype() }} id="anstype" className="select-menu-q" value={anstype}>

                    <option value="text">Answer With text</option>
                    <option value="image">Answer With image</option>
                  </select>
                </div>

                {
                  anstype === 'text' ? (<>
                    <span className="text-danger">
                      {errors2.true_answer && errors2.true_answer.type === "required" && 'Please Select true answer.'}
                    </span>
                    <div className="qmodecontainer" style={{ display: 'flex', gap: 10 }}>

                      <div style={{ width: '100%' }}>
                        <input type="radio" className="radio-quiz" name="is_correct" value={1}  {...register2("true_answer", {
                          required: "required",
                        })} onChange={(e) => { setTrueansweris(e.target.value) }} checked={trueansweris == 1} />
                        <label>answer 1</label>
                        <input type="text" className="text-quiz-new" placeholder="Add an Answer"
                          {...register2("answer1", {
                            required: "required",
                          })}
                        />
                        <span className="text-danger">
                          {errors2.answer1 && errors2.answer1.type === "required" && 'Please Write answer'}
                        </span>
                        <textarea className="text-quiz-textarea" placeholder="Explain why this is or isn't the best answer."  {...register2("answer1_description")} />
                      </div>
                      <div style={{ width: '100%' }}>
                        <input type="radio" className="radio-quiz" name="is_correct" value={2}
                          {...register2("true_answer", {
                            required: "required",
                          })} onChange={(e) => { setTrueansweris(e.target.value) }} checked={trueansweris == 2}
                        />
                        <label>answer 2</label>
                        <input type="text" className="text-quiz-new" placeholder="Add an Answer"
                          {...register2("answer2", {
                            required: "required",
                          })}
                        />
                        <span className="text-danger">
                          {errors2.answer2 && errors2.answer2.type === "required" && 'Please Write answer'}
                        </span>
                        <textarea className="text-quiz-textarea" placeholder="Explain why this is or isn't the best answer."
                          {...register2("answer2_description")}
                        />
                      </div>
                    </div>
                    <div className="qmodecontainer" style={{ display: 'flex', gap: 10 }}>
                      <div style={{ width: '100%' }}>
                        <input type="radio" className="radio-quiz" name="is_correct" value={3}
                          {...register2("true_answer", {
                            required: "required",
                          })} onChange={(e) => { setTrueansweris(e.target.value) }} checked={trueansweris == 3}
                        />
                        <label>answer 3</label>
                        <input type="text" className="text-quiz-new" placeholder="Add an Answer"
                          {...register2("answer3")}
                        />
                        <textarea className="text-quiz-textarea" placeholder="Explain why this is or isn't the best answer."
                          {...register2("answer3_description")}
                        />
                      </div>
                      <div style={{ width: '100%' }}>
                        <input type="radio" className="radio-quiz" name="is_correct" value={4}
                          {...register2("true_answer", {
                            required: "required",
                          })} onChange={(e) => { setTrueansweris(e.target.value) }} checked={trueansweris == 4}
                        />
                        <label>answer 4</label>
                        <input type="text" className="text-quiz-new" placeholder="Add an Answer"                                               {...register2("answer4")} />
                        <textarea className="text-quiz-textarea" placeholder="Explain why this is or isn't the best answer."
                          {...register2("answer4_description")} />
                      </div>
                    </div>
                  </>) : (<>
                    <span className="text-danger">
                      {errors2.true_answer && errors2.true_answer.type === "required" && 'Please Select true answer.'}
                    </span>
                    <div className="qmodecontainer" style={{ display: 'flex', gap: 10 }} >
                      <div style={{ width: '100%' }}>
                        <input type="radio" className="radio-quiz" name="is_true"
                          {...register2("true_answer", {
                            required: "required",
                          })}
                        />
                        <label>answer 1</label>
                        <div className="upload_box_resume question-upload"  {...getRoopProps3()}>
                          <img src={DEFAULT_UPLOAD_FILE_IMG_NEW2} className="cursor_pointer" width="70%" style={{
                            height: '40px',
                            width: '100%'
                          }} alt='preview' />
                          <input style={{ display: 'none' }} {...getInputProps3()}
                          // {...register2("ans1image", {
                          //     required: "required",
                          // })}
                          />
                        </div>
                        <span className="text-danger">
                          {errors2.answer1Photo && errors2.answer1Photo.type === "custom" && 'Please upload answer'}
                        </span>
                        {answer1Image
                          && (
                            <div>
                              <img src={QuesEdit ? answer1Image : URL.createObjectURL(answer1Image)} alt="Selected" style={{ maxWidth: '300px', maxHeight: '150px' }} />
                              <span onClick={(e) => { removeanswer1Image(); }} className="btnGrp dlt_btn">
                                <i className="fa fa-trash mr-2" aria-hidden="true"></i>
                              </span>
                            </div>

                          )}
                        <textarea className="text-quiz-textarea" placeholder="Explain why this is or isn't the best answer."
                          {...register2("answer1_description")}
                        />
                      </div>
                      <div style={{ width: '100%' }}>
                        <input type="radio" className="radio-quiz" name="is_true"
                          {...register2("true_answer", {
                            required: "required",
                          })}
                        />
                        <label>answer 2</label>
                        <div className="upload_box_resume question-upload"  {...getRoopProps4()}>
                          <img src={DEFAULT_UPLOAD_FILE_IMG_NEW2} className="cursor_pointer" width="70%" style={{
                            height: '40px',
                            width: '100%'
                          }} alt='preview' />
                          <input style={{ display: 'none' }} {...getInputProps4()}
                          // {...register2("ans2image", {
                          //     required: "required",
                          // })}
                          />
                        </div>
                        <span className="text-danger">
                          {errors2.answer2Photo && errors2.answer2Photo.type === "custom" && 'Please upload  answer'}
                        </span>
                        {answer2Image
                          && (
                            <div>
                              <img src={QuesEdit ? answer2Image : URL.createObjectURL(answer2Image)} alt="Selected" style={{ maxWidth: '300px', maxHeight: '150px' }} />
                              <span onClick={(e) => { removeanswer2Image(); }} className="btnGrp dlt_btn">
                                <i className="fa fa-trash mr-2" aria-hidden="true"></i>
                              </span>
                            </div>
                          )}
                        <textarea className="text-quiz-textarea" placeholder="Explain why this is or isn't the best answer."
                          {...register2("answer2_description")}
                        />
                      </div>
                    </div>
                    <div className="qmodecontainer" style={{ display: 'flex', gap: 10 }} >
                      <div style={{ width: '100%' }}>
                        <input type="radio" className="radio-quiz" name="is_true"
                          {...register2("true_answer", {
                            required: "required",
                          })}
                        />
                        <label>answer 3</label>
                        <div className="upload_box_resume question-upload"  {...getRoopProps5()}>
                          <img src={DEFAULT_UPLOAD_FILE_IMG_NEW2} className="cursor_pointer" width="70%" style={{
                            height: '40px',
                            width: '100%'
                          }} alt='preview' />
                          <input style={{ display: 'none' }} {...getInputProps5()}
                          // {...register2("ans3image")}
                          />
                        </div>
                        {answer3Image
                          && (
                            <div>
                              <img src={QuesEdit ? answer3Image : URL.createObjectURL(answer3Image)} alt="Selected" style={{ maxWidth: '300px', maxHeight: '150px' }} />
                              <span onClick={(e) => { removeanswer3Image(); }} className="btnGrp dlt_btn">
                                <i className="fa fa-trash mr-2" aria-hidden="true"></i>
                              </span>
                            </div>
                          )}
                        <textarea className="text-quiz-textarea" placeholder="Explain why this is or isn't the best answer."
                          {...register2("answer3_description")}
                        />
                      </div>
                      <div style={{ width: '100%' }}>
                        <input type="radio" className="radio-quiz" name="is_true"
                          {...register2("true_answer", {
                            required: "required",
                          })}
                        />
                        <label>answer 4</label>
                        <div className="upload_box_resume question-upload"  {...getRoopProps6()}>
                          <img src={DEFAULT_UPLOAD_FILE_IMG_NEW2} className="cursor_pointer" width="70%" style={{
                            height: '40px',
                            width: '100%'
                          }} alt='preview' />
                          <input style={{ display: 'none' }} {...getInputProps6()}
                          // {...register2("ans4image")}
                          />
                        </div>
                        {answer4Image
                          && (
                            <div>
                              <img src={QuesEdit ? answer4Image : URL.createObjectURL(answer4Image)} alt="Selected" style={{ maxWidth: '300px', maxHeight: '150px' }} />
                              <span onClick={(e) => { removeanswer4Image(); }} className="btnGrp dlt_btn">
                                <i className="fa fa-trash mr-2" aria-hidden="true"></i>
                              </span>
                            </div>
                          )}
                        <textarea className="text-quiz-textarea" placeholder="Explain why this is or isn't the best answer."
                          {...register2("answer4_description")}
                        />
                      </div>
                    </div>
                    {/* <button type="button" className="btn btn-circle btn-md submit-pink-btn" onClickCapture={() => {
handleSendQuizWithImage()
}}>Save Media</button> */}
                  </>)
                }


                <label htmlFor="questype"><strong>Difficulty Level</strong></label>
                {/* eslint-disable-next-line */}
                <select name="queslevel" onChange={(e) => { setQueslevel(e.target.value);  }} id="quizlevel" className="select-menu-q" value={queslevel}>

                <option value="Easy">Easy</option>
                <option value="Medium">Medium</option>
                <option value="Hard">Hard</option>
                </select>
                <br></br>
                <label htmlFor="questags"><strong>Meta Data</strong></label>
                {/* eslint-disable-next-line */}
                <input onChange={(e) => { setQuestags(e.target.value);  }} type="text" className="text-quiz-new" placeholder="Enter Meta Data" value={questags}

                />
              </div>

              <div className="modal-footer">
                <button type="submit" className="btn btn-circle btn-md submit-pink-btn">Save</button>

                <button type="button" className="btn btn-circle btn-md cancel-btn" data-dismiss="modal" onClick={() => {
                  reset2(); setQuestype('text'); setAnstype('text'); setSelectedQuestionImage(null); setQuesEdit(false); setTrueansweris(null)
                }} id="closeModelQuiz">Cancel</button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <a className="d-none" data-toggle="modal" data-target="#MaterialsTypeModalLong" id="addnewquestionclick"></a>
    </>
  )
}

export default QuizList;

