import { useForm } from "react-hook-form";
import { API_BASE } from "../../constants/global";
import { useContext, useEffect, useState } from "react";
import { Toaster, SwalBox } from "./../../entity/GlobalJS";
import { Link, useNavigate, useParams } from "react-router-dom";
import authContext from "../../context/auth/authContext";
import { Helmet } from "react-helmet";


const ResetPassword = () => {
    let { _token } = useParams();
    const navigate = useNavigate();
    const cntxAuth = useContext(authContext);
    const [is_strong, setStrong] = useState({
        eight_char: false,
        have_numub: false,
        have_lower: false,
        have_upper: false,
        have_spslc: false,
        same_confp: false,
    });

    useEffect(() => {
        if (cntxAuth.isLogged) {
            SwalBox.fire({
                allowOutsideClick: false,
                title: `Already login with another account`,
                text: `You are currently login with another account, first you need to log out from that account to reset password`,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#30314c',
                confirmButtonText: `Log out`,
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate('/logout', {
                        state: {
                            is_from: 'reset-pass',
                            resetPasstoken: _token
                        }
                    });
                }
            });
        }
        //eslint-disable-next-line
    }, []);

    const { register, handleSubmit, formState: { errors }, setValue, watch, clearErrors } = useForm();

    const onSubmit = async (data) => {
        let response = await fetch(`${API_BASE}reset-password-save`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(data)
        });

        response = await response.json();

        if (response.status) {
            setValue('password', '');
            setValue('cpassword', '');
            Toaster.success(response.message);

            setTimeout(() => {
                navigate('/login');
            }, 3000);
        } else if (response.message == "Server Error") {
            SwalBox.fire({
                title: "The reset password link is invalid",
                text: "It seems that the reset password link is not valid. Please try again.",
                icon: "error",
            });
            setTimeout(() => {
                SwalBox.close();
            }, 3000);
            navigate(`/login`);
        }
        else {
            Toaster.error(response.message);
        }
    }

    const checkPassword = (e) => {
        const pass_str = document.getElementById('password-field').value.trim();
        const cpass_str = document.getElementById('cpassword-field').value.trim();

        if (pass_str.length > 0) {
            clearErrors('password');
        }

        if (cpass_str.length > 0) {
            clearErrors('cpassword');
        }

        const eight_char = (pass_str.length >= 8) ? true : false;
        const have_numub = (/\d/.test(pass_str)) ? true : false;
        const have_lower = (/[a-z]/.test(pass_str)) ? true : false;
        const have_upper = (/[A-Z]/.test(pass_str)) ? true : false;
        const have_spslc = (/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(pass_str)) ? true : false;
        const same_confp = (pass_str.length > 0 && cpass_str === pass_str) ? true : false;

        setStrong({
            eight_char: eight_char,
            have_numub: have_numub,
            have_lower: have_lower,
            have_upper: have_upper,
            have_spslc: have_spslc,
            same_confp: same_confp
        });

        if (eight_char && have_numub && have_lower && have_upper && have_spslc && same_confp) {
            document.getElementById('password-checker').classList.add("d-none");
        } else {
            document.getElementById('password-checker').classList.remove("d-none");
        }
    }
    function togglePasswordVisibility() {
        const passwordField = document.getElementById("password-field");
        if (passwordField.type === "password") {
          passwordField.type = "text";
        } else {
          passwordField.type = "password";
        }
      }
      function toggleCPasswordVisibility() {
        const passwordField = document.getElementById("cpassword-field");
        if (passwordField.type === "password") {
          passwordField.type = "text";
        } else {
          passwordField.type = "password";
        }
      }
      
    return (
        <>
            <Helmet>
                <title>{'EduKula - Change Password'}</title>
            </Helmet>
            <section>
                <div className="custom-login-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="custom-login-box">
                                    <div className="login-form">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <h2>Change Password</h2>
                                            <input {...register("token", {
                                                required: "required"
                                            })} type="hidden" value={_token} />
                                            <div className="form-group">
                                                <input {...register("password", {
                                                    required: "required",
                                                    pattern: {
                                                        value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,50}$/,
                                                        message: "Password must contains at least a letter, number and a special character."
                                                    },
                                                    minLength: {
                                                        value: 8,
                                                        message: "Password can not be less than 8 characters."
                                                    },
                                                    maxLength: {
                                                        value: 50,
                                                        message: "Password can not be more than 50 characters."
                                                    }
                                                })}
                                                    type="password"
                                                    id="password-field"
                                                    className="form-control"
                                                    placeholder="Password"
                                                    onChange={checkPassword}
                                                    autoComplete="off"
                                                />
                                                <span onClick={togglePasswordVisibility} className="fa fa-fw fa-eye field-icon toggle-password"></span>
                                                <span className="text-danger">
                                                    {errors.password && errors.password.type === "required" && 'Please enter password.'}
                                                </span>
                                            </div>
                                            <div className="form-group">
                                                <input {...register("cpassword", {
                                                    required: "required",
                                                    pattern: {
                                                        value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,50}$/,
                                                        message: "Password must contains at least a letter, number and a special character."
                                                    },
                                                    minLength: {
                                                        value: 8,
                                                        message: "Password can not be less than 8 characters."
                                                    },
                                                    maxLength: {
                                                        value: 50,
                                                        message: "Confirm password can not be more than 50 characters."
                                                    },
                                                    validate: (val: string) => {
                                                        if (watch('password') !== val) {
                                                            return 'Confirm password do not match.'
                                                        }
                                                    }
                                                })}
                                                    type="password"
                                                    id="cpassword-field"
                                                    className="form-control"
                                                    placeholder="Confirm password"
                                                    onChange={checkPassword}
                                                    autoComplete="off"
                                                />
                                                <span onClick={toggleCPasswordVisibility} className="fa fa-fw fa-eye field-icon toggle-password"></span>
                                                <span className="text-danger">
                                                    {errors.cpassword && errors.cpassword.type === "required" && 'Please enter confirm password.'}
                                                </span>
                                            </div>
                                            <div id="password-checker" className="form-group d-none">
                                                <label className="w-100" htmlFor="">Password must contain:</label>
                                                <ul className="password_error">
                                                    <li>
                                                        {is_strong?.eight_char ? <i className="fa fa-check-circle text-success" aria-hidden="true"></i> : <i className="fa fa-times-circle text-danger" aria-hidden="true"></i>}
                                                        <label className="w-100">8 or more characters minimum</label>
                                                    </li>
                                                    <li>
                                                        {is_strong?.have_numub ? <i className="fa fa-check-circle text-success" aria-hidden="true"></i> : <i className="fa fa-times-circle text-danger" aria-hidden="true"></i>}
                                                        <label className="w-100">One or more than one characters with numbers</label>
                                                    </li>
                                                    <li>
                                                        {is_strong?.have_lower ? <i className="fa fa-check-circle text-success" aria-hidden="true"></i> : <i className="fa fa-times-circle text-danger" aria-hidden="true"></i>}
                                                        <label className="w-100">One or more lowercase characters</label>
                                                    </li>
                                                    <li>
                                                        {is_strong?.have_upper ? <i className="fa fa-check-circle text-success" aria-hidden="true"></i> : <i className="fa fa-times-circle text-danger" aria-hidden="true"></i>}
                                                        <label className="w-100">One or more capital characters</label>
                                                    </li>
                                                    <li>
                                                        {is_strong?.have_spslc ? <i className="fa fa-check-circle text-success" aria-hidden="true"></i> : <i className="fa fa-times-circle text-danger" aria-hidden="true"></i>}
                                                        <label className="w-100">One or more special characters</label>
                                                    </li>
                                                    <li>
                                                        {is_strong?.same_confp ? <i className="fa fa-check-circle text-success" aria-hidden="true"></i> : <i className="fa fa-times-circle text-danger" aria-hidden="true"></i>}
                                                        <label className="w-100">Match password with confirm password</label>
                                                    </li>
                                                </ul>
                                                {
                                                    (
                                                        (errors.password && errors.password.type !== "required")
                                                        ||
                                                        (errors.cpassword && errors.cpassword.type !== "required")
                                                    )
                                                        ?
                                                        <span className="text-danger">Password must follow the above rules.</span>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className="form-group small clearfix">
                                                <Link to="/login" className="forgot-link">Already have an account ?</Link>
                                            </div>
                                            <button type="submit" className="btn btn-success">Submit</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="custom-login-box">
                                    <div className="login-img-box">
                                        <img src="images/login-img.png" alt="Login" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ResetPassword;
