import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API_BASE } from "../../constants/global";

const LatestBlogs = () => {
    const [blogs, setBlogs] = useState('');

    useEffect(() => {
        fetchBlogs();
        //eslint-disable-next-line
    }, []);

    async function fetchBlogs() {
        const response = await fetch(API_BASE + 'get-latest-blogs', {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        });

        const data = await response.json();
        if (data.status) {
            setBlogs(data.data.blogs);
        }
    }

    return (
        <>
            <div className="community-activity-info community-news-desc">
                <div className="community-activity-block">
                    <h5>Latest Post</h5>
                    <ul className="community-resources">
                        {
                            blogs !== ''
                            &&
                            blogs.map((blog, index) =>
                                <li key={index}><Link to={"/blog/" + blog.url}>{blog.title}</Link></li>
                            )
                        }
                    </ul>
                </div>
            </div>
        </>
    );
}

export default LatestBlogs;