import { useState, useEffect } from 'react';
import { API_BASE } from "../../constants/global";
import { SwalBox, Toaster } from "../../entity/GlobalJS";
import { useCallbackPrompt } from "../../hooks/useCallbackPrompt.ts";
import { Helmet } from "react-helmet";


function NotificationPreferences() {
  const [isCourseMaterialNoti, setIsCourseMaterialNoti] = useState(false);
  const [isCourseMeetingNoti, setIsCourseMeetingNoti] = useState(false);
  const [isLClikesNoti, setIsLClikesNoti] = useState(false);
  const [isLCcomments, setIsLCcommentsNoti] = useState(false);
  const [notification, setNotification] = useState(false);
  const [isLCmaterial, setIsLCmaterialNoti] = useState(false);
  const [isLCmeeting, setIsLCmeetingNoti] = useState(false);

  const [isCourseQA, setIsCourseQA] = useState(false);
  const [isCourseO2OMeet, setIsCourseO2OMeet] = useState(false);
  const [isLCNewPost, setIsLCNewPost] = useState(false);
  const [isCourseReview, setIsCourseReview] = useState(false);

  const [showDialog, setShowDialog] = useState(false);
  const [showPrompt, confirmNavigation] = useCallbackPrompt(showDialog);


  const [isCourseQATutor, setIsCourseQATutor] = useState(false);
  const [isCourseO2OMeetTutor, setIsCourseO2OMeetTutor] = useState(false);

  const _token = localStorage.getItem('lmstoken');
  const user = JSON.parse(localStorage.getItem('lmsuser'));


  useEffect(() => {
    fetchNotification();
    // eslint-disable-next-line
  }, [notification]);

  useEffect(() => {
    if (showPrompt) {
      ConfirmSaveChanges();
    }
  }, [showPrompt]);

  const fetchNotification = async () => {
    let formData = {};
    const _lmsuser = JSON.parse(localStorage.getItem('lmsuser'));
    const _lms_learner_id = _lmsuser['id'];
    formData.user_id = _lms_learner_id;

    const response = await fetch(`${API_BASE}get-notification-preferences`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": "Bearer " + _token
      },
      body: JSON.stringify(formData)

    });

    const data = await response.json();
    if (data.status) {
      // setNotification('');
      if (data.data.course_material == "TRUE") { setIsCourseMaterialNoti(true); }
      if (data.data.course_meeting == "TRUE") { setIsCourseMeetingNoti(true); }
      if (data.data.learningcircle_like == "TRUE") { setIsLClikesNoti(true); }
      if (data.data.learningcircle_comment == "TRUE") { setIsLCcommentsNoti(true); }
      if (data.data.learningcircle_material == "TRUE") { setIsLCmaterialNoti(true); }
      if (data.data.learningcircle_meeting == "TRUE") { setIsLCmeetingNoti(true); }
      if (data.data.course_qa == "TRUE") { setIsCourseQA(true); }
      if (data.data.course_onemeeting == "TRUE") { setIsCourseO2OMeet(true); }
      if (data.data.learningcircle_newpost == "TRUE") { setIsLCNewPost(true); }
      if (data.data.course_review == "TRUE") { setIsCourseReview(true); }
      if (data.data.course_onemeeting_tutor == "TRUE") { setIsCourseO2OMeetTutor(true); }
      if (data.data.course_qa_tutor == "TRUE") { setIsCourseQATutor(true); }

    } else {

    }
  }

  const ConfirmSaveChanges = () => {
    SwalBox.fire({
      title: 'Changes won\'t be saved!',
      text: 'To save the changes made, click on \'Save\'; or Click on \'Cancel\' to discard the changes.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      // confirmButtonClass: 'button_confirm_class',
      cancelButtonColor: '#d33',
      // cancelButtonClass: 'button_cancel_class',
      confirmButtonText: 'Save'
    }).then((result) => {
      if (result.isConfirmed) {
        handleSavePreferences();
        confirmNavigation();
      } else {
        confirmNavigation();
      }
    });
  }

  const _lmsuser = JSON.parse(localStorage.getItem('lmsuser'));
  const userID = _lmsuser['id'];

  const ConfirmCourseMaterialNoti = (e) => {
    var featureEnable = e.target.checked;
    setIsCourseMaterialNoti(featureEnable);
    setShowDialog(true);
  }

  const ConfirmCourseMeetingNoti = (e) => {
    var featureEnable = e.target.checked;
    setIsCourseMeetingNoti(featureEnable);
    setShowDialog(true);
  }

  const ConfirmLClikesNoti = (e) => {
    var featureEnable = e.target.checked;
    setIsLClikesNoti(featureEnable);
    setShowDialog(true);
  }

  const ConfirmisLCcomments = (e) => {
    var featureEnable = e.target.checked;
    setIsLCcommentsNoti(featureEnable);
    setShowDialog(true);
  }

  const ConfirmLCmaterialNoti = (e) => {
    var featureEnable = e.target.checked;
    setIsLCmaterialNoti(featureEnable);
    setShowDialog(true);
  }

  const ConfirmLCmeetingNoti = (e) => {
    var featureEnable = e.target.checked;
    setIsLCmeetingNoti(featureEnable);
    setShowDialog(true);
  }

  const ConfirmCourseQA = (e) => {
    var featureEnable = e.target.checked;
    setIsCourseQA(featureEnable);
    setShowDialog(true);
  }

  const ConfirmCourseO2OMeet = (e) => {
    var featureEnable = e.target.checked;
    setIsCourseO2OMeet(featureEnable);
    setShowDialog(true);
  }

  const ConfirmLCNewPost = (e) => {
    var featureEnable = e.target.checked;
    setIsLCNewPost(featureEnable);
    setShowDialog(true);
  }

  const ConfirmCourseReview = (e) => {
    var featureEnable = e.target.checked;
    setIsCourseReview(featureEnable);
    setShowDialog(true);
  }

  const ConfirmCourseQATutor = (e) => {
    var featureEnable = e.target.checked;
    setIsCourseQATutor(featureEnable);
    setShowDialog(true);
  }

  const ConfirmCourseO2OMeetTutor = (e) => {
    var featureEnable = e.target.checked;
    setIsCourseO2OMeetTutor(featureEnable);
    setShowDialog(true);
  }



  const handleSavePreferences = async () => {
    setShowDialog(false);
    const formData = new FormData();
    formData.append('user_id', userID);
    formData.append('course_material', isCourseMaterialNoti);
    formData.append('course_meeting', isCourseMeetingNoti);
    formData.append('learningcircle_like', isLClikesNoti);
    formData.append('learningcircle_comment', isLCcomments);
    formData.append('learningcircle_material', isLCmaterial);
    formData.append('learningcircle_meeting', isLCmeeting);

    formData.append('course_qa', isCourseQA);
    formData.append('course_onemeeting', isCourseO2OMeet);
    formData.append('learningcircle_newpost', isLCNewPost);
    formData.append('course_review', isCourseReview);

    formData.append('course_qa_tutor', isCourseQATutor);
    formData.append('course_onemeeting_tutor', isCourseO2OMeetTutor);



    var sURL = `${API_BASE}save-notification-preferences`;
    const response = await fetch(sURL, {
      method: 'POST',
      headers: {
        "Authorization": "Bearer " + _token,

      },
      body: formData
    });

    const data = await response.json();
    if (data.status) {
      Toaster.success(data.message);
      setNotification(true);
    }
    else {

    }

  }
  return (
    <>
      <Helmet>
        <title>{'EduKula - Notification Preferences'}</title>
      </Helmet>
      <div className="tab-pane fade show active" id="v-pills-account" role="tabpanel" aria-labelledby="v-pills-account-tab">
        <div className="profile-dashboard-content">
          <h4>Notification Preferences</h4>
          <div className="profile-dashboard-box">
            <form>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group ai-settings custom-user-profile-content noti-prefer">
                    <p>
                      Here, you can choose what types of notifications you want to receive.
                    </p>

                    {user?.is_corporate !== 1 &&
                      <div>
                        <h5 className='learnpre'>Courses I learn</h5>

                        <div className='ml-5'>
                          <h6><i className="fa-solid fa-check"></i>&nbsp;&nbsp;&nbsp;
                            <span className="ek-res-ai-span"> Material notification</span>
                            <label className="switch switch-1-1">
                              <input type="checkbox" name="switch-1-1" id="switch-1-1"
                                checked={isCourseMaterialNoti ? 'checked' : ''}
                                onChange={(e) => ConfirmCourseMaterialNoti(e)}
                              />
                              <span className="slider round slider-1-1">
                              </span>
                            </label>
                            {isCourseMaterialNoti ? 'Enabled' : 'Disabled'}
                          </h6>

                          <h6><i className="fa-solid fa-check"></i>&nbsp;&nbsp;&nbsp;
                            <span className="ek-res-ai-span"> Meeting notification</span>
                            <label className="switch switch-1-1">
                              <input type="checkbox" name="switch-1-1" id="switch-1-1"
                                checked={isCourseMeetingNoti ? 'checked' : ''}
                                onChange={(e) => ConfirmCourseMeetingNoti(e)}
                              />
                              <span className="slider round slider-1-1">
                              </span>
                            </label>
                            {isCourseMeetingNoti ? 'Enabled' : 'Disabled'}
                          </h6>

                          <h6><i className="fa-solid fa-check"></i>&nbsp;&nbsp;&nbsp;
                            <span className="ek-res-ai-span"> Q&A notification</span>
                            <label className="switch switch-1-1">
                              <input type="checkbox" name="switch-1-1" id="switch-1-1"
                                checked={isCourseQA ? 'checked' : ''}
                                onChange={(e) => ConfirmCourseQA(e)}
                              />
                              <span className="slider round slider-1-1">
                              </span>
                            </label>
                            {isCourseQA ? 'Enabled' : 'Disabled'}
                          </h6>

                          <h6><i className="fa-solid fa-check"></i>&nbsp;&nbsp;&nbsp;
                            <span className="ek-res-ai-span"> One to One meeting notification</span>
                            <label className="switch switch-1-1">
                              <input type="checkbox" name="switch-1-1" id="switch-1-1"
                                checked={isCourseO2OMeet ? 'checked' : ''}
                                onChange={(e) => ConfirmCourseO2OMeet(e)}
                              />
                              <span className="slider round slider-1-1">
                              </span>
                            </label>
                            {isCourseO2OMeet ? 'Enabled' : 'Disabled'}
                          </h6>
                        </div>
                      </div>
                    }

                    {user?.is_tutor === 1
                      &&
                      <div>
                        <h5 className='learnpre'>Courses I teach</h5>
                        <div className='ml-5'>

                          <h6><i className="fa-solid fa-check"></i>&nbsp;&nbsp;&nbsp;
                            <span className="ek-res-ai-span"> Course Review notification</span>
                            <label className="switch switch-1-1">
                              <input type="checkbox" name="switch-1-1" id="switch-1-1"
                                checked={isCourseReview ? 'checked' : ''}
                                onChange={(e) => ConfirmCourseReview(e)}
                              />
                              <span className="slider round slider-1-1">
                              </span>
                            </label>
                            {isCourseReview ? 'Enabled' : 'Disabled'}
                          </h6>

                          <h6><i className="fa-solid fa-check"></i>&nbsp;&nbsp;&nbsp;
                            <span className="ek-res-ai-span"> Q&A notification</span>
                            <label className="switch switch-1-1">
                              <input type="checkbox" name="switch-1-1" id="switch-1-1"
                                checked={isCourseQATutor ? 'checked' : ''}
                                onChange={(e) => ConfirmCourseQATutor(e)}
                              />
                              <span className="slider round slider-1-1">
                              </span>
                            </label>
                            {isCourseQATutor ? 'Enabled' : 'Disabled'}
                          </h6>


                          <h6><i className="fa-solid fa-check"></i>&nbsp;&nbsp;&nbsp;
                            <span className="ek-res-ai-span"> One to One meeting notification</span>
                            <label className="switch switch-1-1">
                              <input type="checkbox" name="switch-1-1" id="switch-1-1"
                                checked={isCourseO2OMeetTutor ? 'checked' : ''}
                                onChange={(e) => ConfirmCourseO2OMeetTutor(e)}
                              />
                              <span className="slider round slider-1-1">
                              </span>
                            </label>
                            {isCourseO2OMeetTutor ? 'Enabled' : 'Disabled'}
                          </h6>
                        </div>
                      </div>
                    }

                    <h5 className='learnpre'>Learning circle</h5>

                    <div className='ml-5'>
                      <h6><i className="fa-solid fa-check"></i>&nbsp;&nbsp;&nbsp;
                        <span className="ek-res-ai-span"> Learning circle like notification</span>
                        <label className="switch switch-1-1">
                          <input type="checkbox" name="switch-1-1" id="switch-1-1"
                            checked={isLClikesNoti ? 'checked' : ''}
                            onChange={(e) => ConfirmLClikesNoti(e)}
                          />
                          <span className="slider round slider-1-1">
                          </span>
                        </label>
                        {isLClikesNoti ? 'Enabled' : 'Disabled'}
                      </h6>

                      <h6><i className="fa-solid fa-check"></i>&nbsp;&nbsp;&nbsp;
                        <span className="ek-res-ai-span"> Comment notification</span>
                        <label className="switch switch-1-1">
                          <input type="checkbox" name="switch-1-1" id="switch-1-1"
                            checked={isLCcomments ? 'checked' : ''}
                            onChange={(e) => ConfirmisLCcomments(e)}
                          />
                          <span className="slider round slider-1-1">
                          </span>
                        </label>
                        {isLCcomments ? 'Enabled' : 'Disabled'}
                      </h6>

                      <h6><i className="fa-solid fa-check"></i>&nbsp;&nbsp;&nbsp;
                        <span className="ek-res-ai-span"> Material notification</span>
                        <label className="switch switch-1-1">
                          <input type="checkbox" name="switch-1-1" id="switch-1-1"
                            checked={isLCmaterial ? 'checked' : ''}
                            onChange={(e) => ConfirmLCmaterialNoti(e)}
                          />
                          <span className="slider round slider-1-1">
                          </span>
                        </label>
                        {isLCmaterial ? 'Enabled' : 'Disabled'}
                      </h6>


                      <h6><i className="fa-solid fa-check"></i>&nbsp;&nbsp;&nbsp;
                        <span className="ek-res-ai-span"> Meeting notification</span>
                        <label className="switch switch-1-1">
                          <input type="checkbox" name="switch-1-1" id="switch-1-1"
                            checked={isLCmeeting ? 'checked' : ''}
                            onChange={(e) => ConfirmLCmeetingNoti(e)}
                          />
                          <span className="slider round slider-1-1">
                          </span>
                        </label>
                        {isLCmeeting ? 'Enabled' : 'Disabled'}
                      </h6>

                      <h6><i className="fa-solid fa-check"></i>&nbsp;&nbsp;&nbsp;
                        <span className="ek-res-ai-span"> New post notification</span>
                        <label className="switch switch-1-1">
                          <input type="checkbox" name="switch-1-1" id="switch-1-1"
                            checked={isLCNewPost ? 'checked' : ''}
                            onChange={(e) => ConfirmLCNewPost(e)}
                          />
                          <span className="slider round slider-1-1">
                          </span>
                        </label>
                        {isLCNewPost ? 'Enabled' : 'Disabled'}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <button className="btn btn-success btn-circle btn-md" onClick={handleSavePreferences} type='button'>Save</button>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default NotificationPreferences;
