import { useContext, useEffect, useState, useRef } from 'react';
import { API_BASE } from '../constants/global';
import authContext from '../context/auth/authContext';

const VerifiedBadge = (props) => {
    const cntxAuth = useContext(authContext);
    const [defaultekycstatus, setDefaulteKycStatus] = useState(false);
    const _refname      = props.refname;
    const _is_verified  = props._is_verified;
    useEffect(() => {
        if(_is_verified == ""){
            if (cntxAuth.isLogged === true) { checkDefaultKycStatus(); }
        }else if(_is_verified == '1' || _is_verified == 1 || _is_verified == true){
            setDefaulteKycStatus(true);
        }else if(_is_verified == '0' || _is_verified == 0 || _is_verified == false){
            setDefaulteKycStatus(false);
        }else{
            setDefaulteKycStatus(false);
        }
    }, []);
    const checkDefaultKycStatus = async () => {
        try {
            const _token    = localStorage.getItem('lmstoken');
            const response = await fetch(`${API_BASE}get-kyc-status`, {
                mode:'cors',
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${_token}`
                },
            });
            const data = await response.json();
            if (data.status) {
                if(data.kyc == true || data.kyc == 'true'){
                    setDefaulteKycStatus(true);
                }else{
                    setDefaulteKycStatus(false);
                    //setDefaulteKycStatus(true);
                }
            }
        }catch(err) {	}
    }
    return (
        <>
        {defaultekycstatus === true && (
        <span title="This is a verified account. Complete your eKYC to get verified on EduKula. Visit : Profile > eKYC" className={`${_refname}-ek-verification ek-verification-frame`}>
            &nbsp;<i className="fas fa-certificate ek-verification-certificate-icon"></i>
            <i className="fas fa-check ek-verification-check-icon"></i>&nbsp;
        </span>
        )}
        </>
    );
}

export default VerifiedBadge;