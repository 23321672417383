import QA from "../../user_dashboards/tutor/course/manage/QA";

const QAs = ({course}) => {
    return (
        <>
            <QA course_title={course.title} course_id={course.id} manage={false} />
        </>
    );
}

export default QAs;