import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const ChatUserListSkeleton = (props) => {
    const tempArray = [];
    for (let i = 0; i < props.count; i++) {
        tempArray[i] = i;
    }
    {
        return (
            <>
                <ul className="list">
                    {
                        tempArray.map((q, index) =>
                            <li className="clearfix" key={index}>
                                <a className="link">
                                    <div className="prof_pic">
                                        <Skeleton width={50} height={50}/>
                                    </div>
                                    <div className="about">
                                        <Skeleton width={150} />
                                        <div className="status">
                                            <Skeleton width={100} />
                                        </div>
                                    </div>
                                </a>
                            </li>
                        )
                    }
                </ul>
            </>
        );
    }
}

export default ChatUserListSkeleton;