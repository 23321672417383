import { Link, useNavigate } from "react-router-dom";

const PageNotFound = () => {
    const navigate = useNavigate();

    return (
        <>
            <section className="custom-section">
                <div className="teaching-about">
                    <div className="container">
                        <div className="teaching-about-info text-center">
                            <h1>Resource Not Found</h1>
                            <img src="/images/404_page.jpg" alt="404" />
                            <div className="text-center mt-3">
                                <button onClick={() => navigate(-2)} type="button" className="btn btn-success btn-sm ml-1">Go Back</button>
                                <Link className="btn btn-success btn-sm ml-2" to='/'>Home Page</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default PageNotFound;
