import { useEffect, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { API_BASE } from "../../constants/global";
import CustomPagination from "../../entity/CustomPagination";
import parse from 'html-react-parser';
import Skeleton from 'react-loading-skeleton'
import CircleBoxSkeleton from "../../components/skeletons/CircleBoxSkeleton";
import { Helmet } from "react-helmet";

const CircleIJoined = () => {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const currentPage = Number(searchParams.get('page')) || 0;
    const [reload, setReload] = useState(false);

    const _token = localStorage.getItem('lmstoken');
    const [groups, setGroups] = useState([]);
    const [total_count, setTotalCount] = useState(0);
    const [isLoader, setIsLoader] = useState(true);

    useEffect(() => {
        fetchGroups(currentPage);
        // eslint-disable-next-line 
    }, [location, reload]);

    const funReload = (page) => {
        setSearchParams({ ...searchParams, page: page });
        setReload(!reload);
    }

    const fetchGroups = async (page) => {
        setIsLoader(true);
        const response = await fetch(`${API_BASE}circles-i-have-joined?page=${page}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();
        setIsLoader(false);
        if (data.status) {
            setGroups(data.data.groups);
            setTotalCount(data.data.total_count);
        }
    }

    return (
        <>
            <Helmet>
                <title>{'EduKula - Joined Circle'}</title>
            </Helmet>
            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                {
                    isLoader ?
                        <>
                            <p><Skeleton count={1} /></p>
                        </>
                        :
                        typeof groups !== 'undefined' && groups.length > 0
                        &&
                        <p>Join any professional learning group created on our learning portal to get advice and exchange ideas with your peers on specific topics.</p>
                }
                <div className="blog-card">
                    <div className="row">
                        {
                            isLoader ?
                                <>
                                    <CircleBoxSkeleton count="4" />
                                </>
                                :
                                <>
                                    {
                                        typeof groups !== 'undefined' && groups.length > 0
                                        &&
                                        groups.map((group, index) =>
                                            <div key={index} className="col-md-6 col-lg-6">
                                                <div className="blog-card-detail">
                                                    <Link className="pos_link" to={`/view-learning-circle/${group.unique_id}/feeds`}></Link>
                                                    <div className="custom-card-img">
                                                        <img src={group.image} className="card-img-top" alt="Blog" />
                                                    </div>
                                                    <div className="blog-card-inner">
                                                        <h5>{group.title}</h5>
                                                        <ul>
                                                            <li><strong>Created by:</strong>{group?.owner}</li>
                                                            <li><strong>No. of People:</strong> {group?.member_count}</li>
                                                            <li className="text-right"><strong>{group.privacy}</strong></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        (typeof groups === 'undefined' || groups.length <= 0)
                                        &&
                                        <>
                                            <div className="col-12">
                                                <div className="empty_state">
                                                    <h3>No circles found!</h3>
                                                    <img alt="" src="images/no_courses.png" />
                                                    <p>
                                                        You Haven’t Joined Any Leaning Circles Yet.
                                                    </p>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </>
                        }

                    </div>
                </div>
                {
                    total_count > 4
                    &&
                    <div className="custom-pagination">
                        <nav aria-label="Page navigation example">
                            <CustomPagination pageCount={total_count} perPageRecord={4} handlePageClick={(data) => { funReload(data.selected) }} currentPage={currentPage} />
                        </nav>
                    </div>
                }
            </div>
        </>
    );
}

export default CircleIJoined;