import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { API_BASE, LRS_ENDPOINT } from "../../constants/global";
import { Toaster } from "../../entity/GlobalJS";
import { Helmet } from "react-helmet";


const AnalyticsIndex = () => {
    const user = JSON.parse(localStorage.getItem('lmsuser'));
    const _token = localStorage.getItem('lmstoken');
    const userData = JSON.parse(localStorage.getItem('lmsuser'));
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [submitting, setSubmitting] = useState(false);
    const [file, setFile] = useState('');
    const navigate = useNavigate();
    const [employees, setEmployees] = useState('');
    useEffect(() => {
        if(user?.is_corporate !== 1)
        {
            //navigate('/dashboard/home');
        }else{
            //fetchEmployees();
            //fetchCourse();
            //fetchCourses();
            //fetchCircle();
            //fetchCircles();
            //fetchStudent();
        }
    }, []);

    /* get employees data */
    const fetchEmployees = async (page = 0, search = "") => {
        const response = await fetch(`${API_BASE}employee/get?page=${page}&search=${search}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });
        const data = await response.json();
        if (data.status) {
            //console.info(data.data);
        } else {

        }
    }

    /* get course data */
    const fetchCourse = async (page = 0, search = "") => {
        const response = await fetch(`${API_BASE}reports/course/${page}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });
        const data = await response.json();
        if (data.status) {
            //console.info(data.data);
        } else {

        }
    }
    const fetchCourses = async (page = 0, search = "") => {
        const response = await fetch(`${API_BASE}get-tutor-published-course-list?page=0`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });
        const data = await response.json();
        if (data.status) {
            //console.info(data.data);
        } else {

        }
    }

    /* get learning circle data */
    const fetchCircle = async (page = 0, search = "") => {
        const response = await fetch(`${API_BASE}reports/circle/${page}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });
        const data = await response.json();
        if (data.status) {
            //console.info(data.data);
        } else {

        }
    }
    const fetchCircles = async (page = 0, search = "") => {
        const response = await fetch(`${API_BASE}circles-i-have-create`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });
        const data = await response.json();
        if (data.status) {
            //console.info(data.data);
        } else {

        }
    }

    const fetchStudent = async (page = 0, search = "") => {
        const response = await fetch(`${API_BASE}reports/student/${page}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });
        const data = await response.json();
        if (data.status) {
            //console.info(data.data);
        } else {

        }
    }

    return (
        <>
            <Helmet>
                <title>{'EduKula - Analytics'}</title>
            </Helmet>
            <div className="col-lg-9 home-section">
                <div className="custom-user-profile-content custom-learning-circle-content tutor_dashboard">
                    <div className="custom-learnig-circle-head">
                        <h4 className="generate-report">Analytics</h4>
                    </div>
                    
                    <div className="learning-circle-courses">
                        <section>
                            <div className="explore-section">
                                <div className="container">
                                    <div className="explore-block" style={{'border': 'none'}}>
                                        { 
                                        /*
                                        <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                            {
                                                user?.is_corporate !== 1
                                                &&
                                                <li className="nav-item">
                                                    <NavLink to={`/dashboard/reports/student-reports`} className={"nav-link "} id="pills-stReport-tab" data-toggle="pill" href="#pills-stReport" role="tab" aria-controls="pills-course" aria-selected="true">Student</NavLink>
                                                </li>
                                            }

                                            {
                                                user?.is_tutor === 1
                                                &&
                                                <li className="nav-item">
                                                    <NavLink to={`/dashboard/reports/tutor-reports`} className={"nav-link "} id="pills-tuReport-tab" data-toggle="pill" href="#pills-tuReport" role="tab" aria-controls="pills-home" aria-selected="true">Tutor</NavLink>
                                                </li>
                                            }
                                        </ul>
                                        <div className="tab-content" id="pills-tabContent">
                                            <Outlet />
                                        </div>
                                        */ 
                                        } 

                                        <p style={{'fontSize': '22px'}}>Course Analytics</p>
                                        <div className="generate-course-analytics"><svg></svg></div>
                                        
                                        
                                        
                                        <p style={{'fontSize': '22px'}}>Learning Circle Analytics</p>
                                        <div className="generate-circle-analytics"><svg></svg></div>
                                    
                                    
                                    
                                    
                                    
                                    
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AnalyticsIndex;