import { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { API_BASE } from "../../constants/global";
import { SwalBox, Toaster } from "../../entity/GlobalJS";
import { useCallbackPrompt } from "../../hooks/useCallbackPrompt.ts";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const Experience = () => {
    const user = JSON.parse(localStorage.getItem('lmsuser'));
    const navigate = useNavigate();
    const validationSchema = Yup.object().shape({
        experience: Yup.array().of(
            Yup.object().shape({
                title: Yup.string().required('Please enter title').min(3, 'Please enter at least 3 characters.').max(80, 'Title can not be more than 80 charaters.'),
                type: Yup.string().required('Please select employment type'),
                company: Yup.string().required('Please enter company name').min(3, 'Please enter at least 3 characters.').max(80, 'Company can not be more than 80 charaters.'),
                location: Yup.string().required('Please enter location').min(3, 'Please enter at least 3 characters.').max(80, 'Location can not be more than 80 charaters.'),
                industry: Yup.string().required('Please enter industry').min(2, 'Please enter at least 2 characters.').max(80, 'Industry can not be more than 80 charaters.'),
                start_date: Yup.string().nullable().required('Please select start date'),
                end_date: Yup.string().nullable().when("currently_working", {
                    is: 0,
                    then: Yup.string().required('Please select end date')
                }).when("currently_working", {
                    is: false,
                    then: Yup.string().required('Please select end date')
                }),
            })
        )
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    // functions to build form returned by useForm() and useFieldArray() hooks
    const { register, control, handleSubmit, formState, clearErrors, setValue } = useForm(formOptions);
    const { errors } = formState;
    const { fields, append, remove } = useFieldArray({ name: 'experience', control });

    const EXPERIENCE_INITIAL_STATE = { title: "", type: "", company: "", location: "", industry: "", start_date: "", end_date: "", description: "", experience_id: "", currently_working: false };
    const _token = localStorage.getItem('lmstoken');
    const [formValues, setFormValues] = useState({ "experience": [EXPERIENCE_INITIAL_STATE] });
    const [startYear, setStartYear] = useState([]);
    const [startMonth, setStartMonth] = useState([]);
    const [endYears, setEndYears] = useState([]);
    const [endMonths, setEndMonths] = useState([]);
    const [current_month, setCurrentMonth] = useState('');
    const [current_year, setCurrentYear] = useState('');

    const [showDialog, setShowDialog] = useState(false);
    const [showPrompt, confirmNavigation] = useCallbackPrompt(showDialog);

    const getYear = () => setCurrentYear(new Date().getFullYear());
    const getMonth = () => setCurrentMonth(new Date().getMonth());

    useEffect(() => {
        if (user?.is_corporate === 1) {
            navigate('/profile/general');
        }

        const fieldsLength = fields.length;
        for (let i = 0; i <= fieldsLength; i++) {
            remove(i);
        }

        getYear();
        getMonth();
        fetchExperience();
    }, []);

    useEffect(() => {
        if (showPrompt) {
            ConfirmSaveChanges();
        }
    }, [showPrompt]);

    const ConfirmSaveChanges = () => {
        SwalBox.fire({
            title: 'Changes won\'t be saved!',
            text: 'To save the changes made, click on \'Save\'; or Click on \'Cancel\' to discard the changes.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonClass: 'button_confirm_class',
            cancelButtonColor: '#d33',
            cancelButtonClass: 'button_cancel_class',
            confirmButtonText: 'Save'
        }).then((result) => {
            if (result.isConfirmed) {
                handleSubmit(saveExperience)();
                confirmNavigation();
            } else {
                confirmNavigation();
            }
        });
    }

    const testMonths = {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
        8: 'August',
        9: 'September',
        10: 'October',
        11: 'November',
        12: 'December',
    };

    const yearOptions = [];
    for (let index = 0; index <= 12; index++) {
        yearOptions[index] = { value: index, label: testMonths[index] };
    }

    var years = [];
    for (let index = 1950; index <= new Date().getFullYear(); index++) {
        years.push(index);
    }

    let setMonthSelection = (i, e) => {
        let startMonth = e.target.value;
        document.getElementById(`end_date_${i}`).setAttribute('min', startMonth)
    }

    let handleChange = (i, e) => {
        setShowDialog(true);
    }

    let handleChangeCurrentlyWorking = (i, e) => {
        fields[i].currently_working = e.target.checked;
    }

    let addFormFields = () => {
        append(EXPERIENCE_INITIAL_STATE);
    }

    let removeFormFields = (i) => {
        remove(i);
    }

    const fetchExperience = async () => {
        const response = await fetch(API_BASE + 'get-user-experience', {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            setFormValues({ "experience": data.data });
            if (data.data.length > 0) {
                for (let i = 0; i <= data.data.length; i++) {
                    remove(i);
                }
                data.data.map((ele, i) => {
                    remove(i);
                    append(ele);
                });


            }
            else {
                remove(0);
                append(EXPERIENCE_INITIAL_STATE);
            }
        } else {
            remove(0);
            append(EXPERIENCE_INITIAL_STATE);
            Toaster.error(data.message);
        }
    }

    const saveExperience = async (form) => {
        const response = await fetch(`${API_BASE}post-user-experience`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
            body: JSON.stringify(form)
        });

        const data = await response.json();
        if (data.status) {
            Toaster.success(data.message);
            fetchExperience();
            setShowDialog(false);
        } else {
            Toaster.error(data.message);
        }
    }

    const formattedDate = new Date().toISOString().split('T')[0];

    return (
        <>
            <Helmet>
                <title>{'EduKula - Experience'}</title>
            </Helmet>
            <div className="tab-pane fade show active" id="v-pills-experience" role="tabpanel" aria-labelledby="v-pills-experience-tab">
                <div className="profile-dashboard-content">
                    <h4>Experience</h4>
                    <div className="profile-dashboard-box">
                        <form onSubmit={handleSubmit(saveExperience)}>
                            {fields.map((element, index) => (
                                <div key={index}>
                                    <h6>Experience {index + 1}</h6>
                                    <div className="row">
                                        <input type="hidden" name={`experience[${index}]experience_id`} {...register(`experience.${index}.experience_id`)} id={`experience_id_${index}`} data-customcls="experience_id" onChange={(e) => { handleChange(index, e); }} />
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input name={`experience[${index}]title`} {...register(`experience.${index}.title`)} type="text" placeholder="Title" className={`form-control`} id={`title_${index}`} data-customcls="title" onChange={(e) => { clearErrors(`experience.${index}.title`); handleChange(index, e); }} />
                                                <div className="text-danger">{errors.experience?.[index]?.title?.message}</div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <select name={`experience[${index}]type`} {...register(`experience.${index}.type`)} className={`form-control`} id={`type_${index}`} data-customcls="type" onChange={(e) => { clearErrors(`experience.${index}.type`); handleChange(index, e); }}>
                                                    <option value=''>Employment type</option>
                                                    <option value='Full-time'>Full-time</option>
                                                    <option value='Part-Time'>Part-Time</option>
                                                    <option value='Internship'>Internship</option>
                                                    <option value='Trainee'>Trainee</option>
                                                    <option value='Self-Employed'>Self-Employed</option>
                                                    <option value='Freelance'>Freelance</option>
                                                </select>
                                                <div className="text-danger">{errors.experience?.[index]?.type?.message}</div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input name={`experience[${index}]company`} {...register(`experience.${index}.company`)} type="text" placeholder="Company name" className={`form-control`} id={`company_${index}`} data-customcls="company" onChange={(e) => { clearErrors(`experience.${index}.company`); handleChange(index, e); }} />
                                                <div className="text-danger">{errors.experience?.[index]?.company?.message}</div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input name={`experience[${index}]location`} {...register(`experience.${index}.location`)} type="text" placeholder="Location" className={`form-control`} id={`location_${index}`} data-customcls="location" onChange={(e) => { clearErrors(`experience.${index}.location`); handleChange(index, e); }} />
                                                <div className="text-danger">{errors.experience?.[index]?.location?.message}</div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input name={`experience[${index}]industry`} {...register(`experience.${index}.industry`)} type="text" placeholder="Industry" className={`form-control`} id={`industry_${index}`} data-customcls="industry" onChange={(e) => { clearErrors(`experience.${index}.industry`); handleChange(index, e); }} />
                                                <div className="text-danger">{errors.experience?.[index]?.industry?.message}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group current_working">
                                                <label htmlFor={`currently_working_${index}`}>I am currently working here</label>
                                                <input name={`experience[${index}]currently_working`} {...register(`experience.${index}.currently_working`)} type="checkbox" className={`form-control`} id={`currently_working_${index}`} data-customcls="currently_working" onChange={(e) => { clearErrors(`experience.${index}.currently_working`); handleChange(index, e); handleChangeCurrentlyWorking(index, e); }} />
                                                <div className="text-danger">{errors.experience?.[index]?.currently_working?.message}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mb-4 border-bottom">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="start_date">Start date </label>
                                                <input name={`experience[${index}]start_date`} {...register(`experience.${index}.start_date`)} type="date" min="1900-08-07"
                                                    max={`${formattedDate}`}
                                                    className={`form-control`} id={`start_date_${index}`} data-customcls="start_date" onChange={(e) => { setMonthSelection(index, e); clearErrors(`experience.${index}.start_date`); handleChange(index, e); }} />
                                                <div className="text-danger">{errors.experience?.[index]?.start_date?.message}</div>
                                            </div>
                                        </div>
                                        {
                                            fields?.[index]?.currently_working == false
                                            &&
                                            <>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label htmlFor="end_date">End date </label>
                                                        <input name={`experience[${index}]end_date`} {...register(`experience.${index}.end_date`)} type="date" min={fields?.[index]?.start_date}
                                                            max={`${formattedDate}`}
                                                            className={`form-control`} id={`end_date_${index}`} data-customcls="end_date" onChange={(e) => { clearErrors(`experience.${index}.end_date`); handleChange(index, e); }} />
                                                        <div className="text-danger">{errors.experience?.[index]?.end_date?.message}</div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <textarea name={`experience[${index}]description`} {...register(`experience.${index}.description`)} type="text" placeholder="Description" className={`form-control`} id={`description_${index}`} data-customcls="description" onChange={(e) => { clearErrors(`experience.${index}.description`); handleChange(index, e); }}></textarea>
                                                <div className="text-danger">{errors.experience?.[index]?.description?.message}</div>
                                            </div>
                                        </div>
                                        {

                                            index ?
                                                <div className="col-sm-12 mb-4">
                                                    <button type="button" className="btn m-0 btn-circle red_bg_btn float-right" onClick={() => removeFormFields(index)}><i className="fas fa-trash-alt"></i></button>
                                                </div>
                                                : null
                                        }
                                    </div>
                                </div>
                            ))}
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="add-new-education">
                                        <a className="link" onClick={() => addFormFields()}>+ Add New Experience</a>
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-success btn-circle btn-md">Save</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Experience;
