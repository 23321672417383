import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AnnouncementTab from "./AnnouncementTab";
import LearningCircleTab from "./LearningCircleTab";
import NewReleaseCourses from "./NewReleaseCourses";
import StudenTab from "./StudenTab";
import TrendingLearningCircle from "./TrendingLearningCircle";
import TutorTab from "./TutorTab";
import { stickyArea } from './scroll';
import Joyride from 'react-joyride';
import { SwalBox, Toaster } from "../../entity/GlobalJS";
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";

const DashboardHome = () => {
    const [active_tab, setActiveTab] = useState(1);
    const [tour_steps, setTourSteps] = useState([]);
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('lmsuser'));
    const no_of_time_login = localStorage.getItem('no_of_time_login');
    const has_auto_password = localStorage.getItem('has_auto_password');
    useEffect(() => {
        if (user?.is_corporate === 1) { setActiveTab(2) }
        stickyArea();
        if(no_of_time_login == 1 && has_auto_password == 'YES'){
            SwalBox.fire({
                allowOutsideClick: false,
                title: 'Change your password.',
                text: 'Your account has a default password. Would you like to set a password now ?.',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#30314c',
                cancelButtonColor: '#30314c',
                confirmButtonText: 'Yes, please.',
                cancelButtonText:'No, thank you.'
            }).then((result) => {
                if (result.isConfirmed) {
                    localStorage.removeItem('no_of_time_login');
                    localStorage.removeItem('has_auto_password');
                    navigate('/profile/security/');
                }else{
                    localStorage.removeItem('no_of_time_login');
                    localStorage.removeItem('has_auto_password');
                    showTour();
                }
            });
        } else {
            showTour();
        }
    }, []);
    const showTour = () => {
        let show_tour = Cookies.get("show_tour");  
        if(show_tour != 'NO'){
            SwalBox.fire({
                allowOutsideClick: false,
                title: 'Would you like to have a quick tour?',
                text: 'This tour will take you through all the menu items and introduce you with the features.',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#30314c',
                cancelButtonColor: '#30314c',
                confirmButtonText: 'Yes, please.',
                cancelButtonText:'No, thank you.'
            }).then((result) => {
                if (result.isConfirmed) {
                    startTour();
                }else{
                    Cookies.set('show_tour', 'NO');
                }
            });
        }
    }
    const startTour = () => {
        let steps= [];
        if (user?.is_corporate === 1) {
            steps= [
                {placementBeacon : 'right',disableBeacon : true,target: '.tour-cr-step-01',title : 'Profile',content: "Access your company's user dashboard, update your company's profile details, and log out using the dropdown menu in the top right corner.",},
                {placementBeacon : 'right',disableBeacon : true,target: '.tour-cr-step-02',title : 'Dock Menu',content: "Expand and collapse your menu on the left-hand side of the screen using the dock menu button.",},
                {placementBeacon : 'right',disableBeacon : true,target: '.tour-cr-step-03',title : 'Home',content: "Get important updates, invites, and announcements in a feed format for your company's courses and learning circles on EduKula.",},
                {placementBeacon : 'right',disableBeacon : true,target: '.tour-cr-step-04',title : 'Learning Circle',content: "Create a collaborative learning environment for your employees using EduKula's learning circle. Members can share videos, images, and other materials while learning and interacting with each other. Visit the Learning Circle section to view the circles you've joined or created.",},
                {placementBeacon : 'right',disableBeacon : true,target: '.tour-cr-step-05',title : 'Courses',content: "Access all the courses you've published on EduKula and drafts for the courses you're creating in the Courses section.",},
                {placementBeacon : 'right',disableBeacon : true,target: '.tour-cr-step-07',title : 'Vacancies',content: "Create Vacancies on EduKula and connect them with courses you have created on EduKula. Invite and hire talent based on their performance.",},
                {placementBeacon : 'right',disableBeacon : true,target: '.tour-cr-step-08',title : 'Employees',content: "Create or invite employees to the platform and manage the employee accounts added.",},
                {placementBeacon : 'right',disableBeacon : true,target: '.tour-cr-step-06',title : 'Reports',content: "Access detailed reports on your employee's user activity, as well as reports on the courses you have published on EduKula.",},
                {placementBeacon : 'right',disableBeacon : true,target: '.tour-cr-step-09',title : 'Notifications',content: "Stay updated on learning circles and your company's courses by checking the Notification section.",},
                {placementBeacon : 'right',disableBeacon : true,target: '.tour-cr-step-10',title : 'Chat',content: "Communicate with employees and members of the Learning Circles your company has joined or created.",},
            ];
        }else{
            steps= [
                {placementBeacon : 'right',disableBeacon : true,target: '.tour-lr-step-01',title : 'Profile',content: "Navigate to your user dashboard, update your profile details, and log out using the dropdown menu in the top right corner.",},
                {placementBeacon : 'right',disableBeacon : true,target: '.tour-lr-step-02',title : 'Dock Menu',content: "Use the dock menu button to expand and collapse your menu on the left-hand side of the screen.",},
                {placementBeacon : 'right',disableBeacon : true,target: '.tour-lr-step-03',title : 'Home',content: "Get important updates, invites, and announcements in a feed format for your courses and learning circles on EduKula.",},
                {placementBeacon : 'right',disableBeacon : true,target: '.tour-lr-step-04',title : 'Learning Circle',content: "Collaborate with like-minded learners in EduKula's learning circle. Share videos, images, and other materials while learning and interacting with each other. Visit the Learning Circle section to view the circles you've joined or created.",},
                {placementBeacon : 'right',disableBeacon : true,target: '.tour-lr-step-05',title : 'Student',content: "Find the general and hiring courses you've enrolled in through EduKula and the certificates for the ones you've completed in the Student section.",},
                {placementBeacon : 'right',disableBeacon : true,target: '.tour-lr-step-06',title : 'Tutor',content: "Access all the courses you've published on EduKula and drafts for the courses you're creating in the Tutor section.",},
                {placementBeacon : 'right',disableBeacon : true,target: '.tour-lr-step-07',title : 'Reports',content: "Access detailed reports on your user activity, as well as reports on the courses you are currently enrolled in or teaching.",},
                {placementBeacon : 'right',disableBeacon : true,target: '.tour-lr-step-08',title : 'Career Advice',content: "Grow in your career with personalized suggestions for courses, academic programs, and learning circles based on your user profile in the Career Advice section.",},
                {placementBeacon : 'right',disableBeacon : true,target: '.tour-lr-step-09',title : 'Self Assessment',content: "Boost your confidence and evaluate your knowledge with the Self-Assessment tool, which generates quizzes on learner-selected topics using AI.",},
                {placementBeacon : 'right',disableBeacon : true,target: '.tour-lr-step-10',title : 'Notifications',content: "Stay updated on learning circles and courses you're enrolled in or teaching by checking the Notification section.",},
                {placementBeacon : 'right',disableBeacon : true,target: '.tour-lr-step-11',title : 'Chat',content: "Communicate with course tutors and fellow members of the Learning Circles you have joined or created.",},
                {placementBeacon : 'right',disableBeacon : true,target: '.tour-lr-step-12',title : 'Explore Courses',content: "This is where you can search for and discover public courses to take on EduKula. You can filter by category, level, and more to find the perfect course for you.",},
                {placementBeacon : 'right',disableBeacon : true,target: '.tour-lr-step-13',title : 'Explore Learning Circles',content: "This is where you can discover and join learning circles on EduKula. You can search by category, rating, and more to find the right learning circle.",},
            ];
        }
        setTourSteps(steps);
        Cookies.set('show_tour', 'NO');
    }

    return (
        <>
            <Helmet>
                <title>{'EduKula - Dashboard'}</title>
            </Helmet>
        <div className="col-lg-9 home-section">
            <div className="row">
                <div className="col-lg-8">
                    <div className="custom-user-profile-content pt-0">
                        <div className="course-details-tab-block explore-block">
                            <ul className="nav nav-pills custom-section" id="pills-tab" role="tablist">
                                {
                                    user?.is_corporate !== 1
                                    &&
                                    <li className="nav-item">
                                        <a onClick={(e) => { setActiveTab(1); }} className={"nav-link " + (active_tab === 1 ? 'active' : '')} id="pills-student-tab" data-toggle="pill" href="#pill-student" role="tab" aria-controls="pill-student" aria-selected="true">Student</a>
                                    </li>
                                }
                                {
                                    user?.is_tutor === 1
                                    &&
                                    <>
                                        {
                                            user?.is_corporate === 1
                                                ?
                                                <li className="nav-item">
                                                    <a onClick={(e) => { setActiveTab(2); }} className={"nav-link " + (active_tab === 2 ? 'active' : '')} id="pills-tutor-tab" data-toggle="pill" href="#pills-tutor" role="tab" aria-controls="pills-tutor" aria-selected="false">Courses</a>
                                                </li>
                                                :
                                                <li className="nav-item">
                                                    <a onClick={(e) => { setActiveTab(2); }} className={"nav-link " + (active_tab === 2 ? 'active' : '')} id="pills-tutor-tab" data-toggle="pill" href="#pills-tutor" role="tab" aria-controls="pills-tutor" aria-selected="false">Tutor</a>
                                                </li>
                                        }
                                    </>
                                }
                                <li className="nav-item">
                                    <a onClick={(e) => { setActiveTab(3); }} className={"nav-link " + (active_tab === 3 ? 'active' : '')} id="pills-circle-tab" data-toggle="pill" href="#pills-circle" role="tab" aria-controls="pills-circle" aria-selected="false">Learning Circle</a>
                                </li>
                                <li className="nav-item">
                                    <a onClick={(e) => { setActiveTab(4); }} className={"nav-link " + (active_tab === 4 ? 'active' : '')} id="pills-announcements-tab" data-toggle="pill" href="#pills-announcements" role="tab" aria-controls="pills-announcements" aria-selected="false">Announcements</a>
                                </li>
                            </ul>
                            <div className="tab-content" id="pills-tabContent">
                                {
                                    (active_tab === 1)
                                    &&
                                    <StudenTab />
                                }

                                {
                                    (active_tab === 2 && user?.is_tutor === 1)
                                    &&
                                    <TutorTab />
                                }

                                {
                                    (active_tab === 3)
                                    &&
                                    <LearningCircleTab />
                                }

                                {
                                    (active_tab === 4)
                                    &&
                                    <AnnouncementTab />
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div data-sticky="true" data-top-gap="10" data-bottom-gap="10" className="custom-user-profile-content sticky-top pt-0">
                        {
                            user?.is_corporate !== 1
                            &&
                            <NewReleaseCourses />
                        }
                        <TrendingLearningCircle />
                    </div>
                </div>
        </div>
    </div>
    {
    tour_steps.length > 0 
    &&                 
    <Joyride
        steps={tour_steps}
        disableCloseOnEsc={true}
        disableOverlayClose={true}
        showSkipButton={true}
        continuous={true}
        hideCloseButton={true}

        styles={{options: {width: 500,zIndex: 5000,primaryColor: '#31314c',textColor: '#ef4a45',overlayColor: 'rgb(2 1 0 / 69%)',}}}
    />
    }   
    </>
    );
}

export default DashboardHome;
