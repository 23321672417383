const EduKulaAi =  {
    createCompletion: (params) => {
        //console.info(params);
        return new Promise((resolve, reject) => {
            /*
            //const apiKey    = '899605659fb646a1b05659637e7d3d9d';
            //const endpoint  = 'https://edukula-openai-v1.openai.azure.com/openai/deployments/edukula-gpt35/completions?api-version=2023-09-15-preview';            
            //const apiKey    = 'd3849dde98314aad92ddc2dd281ca675';
            //const endpoint  = 'https://edukula-openai-v2.openai.azure.com/openai/deployments/ek_custom_v1/chat/completions?api-version=2023-07-01-preview';
            */
            const apiKey      = '899605659fb646a1b05659637e7d3d9d';
            const endpoint    = 'https://edukula-openai-v1.openai.azure.com/openai/deployments/edukula-gpt35/chat/completions?api-version=2023-07-01-preview';
            const prompt_string = params.prompt;
            const requestBody = {
                "messages": [
                    {"role": "system","content": "You are an AI assistant that helps people find information."},
                    {"role": "user","content": prompt_string},
                ],
                 max_tokens: 4097-(prompt_string.length),temperature: 0.8,frequency_penalty: 0,presence_penalty: 0,top_p: 1,stop: null
            };
            fetch(endpoint, {
            method: 'POST',
            headers: {'Content-Type': 'application/json','api-key': apiKey},
            body: JSON.stringify(requestBody),
          }).then((response) => {
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              return response.json();
            }).then((data) => {
                let completion                  = {};
                completion.data                 = {};
                completion.status               = 200;
                completion.data.choices         = [];
                completion.data.choices[0]      = {};
                completion.data.choices[0].text = data.choices[0].message.content;
                resolve(completion);
            }).catch((error) => {
              reject(error);
            });
        });
    },
};
export default EduKulaAi;