import { useEffect } from "react";
import { useState } from "react";
import { Link, useOutletContext, useParams } from "react-router-dom";
import { API_BASE } from "../../constants/global";
import AboutCard from "./AboutCard";
import { Helmet } from "react-helmet";

const SentInvitation = () => {
    const { unique_id } = useParams();
    const [group] = useOutletContext();
    const _token = localStorage.getItem('lmstoken');
    const [sent_invitations, setSentInvitations] = useState('');
    const [page, setPage] = useState(1);
    const [all, setAll] = useState(false);

    useEffect(() => {
        setPage(1 + 1);
        fetchInvitations();
    }, []);

    const fetchInvitations = async () => {
        const response = await fetch(`${API_BASE}get-group-sent-invitations/${unique_id}?page=${page}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();
        if (data.status) {
            setSentInvitations(data.data.invitations);
            if (data.data.total === 1) {
                setAll(true);
            }
        } else {
            setSentInvitations('');
        }
    }

    const showMore = async () => {
        setPage(page + 1);
        fetchInvitations();
    }

    return (
        <>
        <Helmet>
        <title>{'EduKula - Learning Circle'}</title>
        </Helmet>
        <section className={`custom-section feed_page`}>
            <div className="profile_user_middle_section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="custom-user-profile-content custom-learning-circle-content post_box_top">
                                <div className="custom-learnig-circle-head">
                                    <div className="join-invite-section">
                                        <h4>Sent Invitations</h4>
                                        <div className="join-invite-listing">
                                            {
                                                sent_invitations !== ''
                                                &&
                                                sent_invitations.map((sent_invitation, index) =>
                                                    <div key={index} className="join-invite-list">
                                                        <div className="join-invite-user">
                                                            <div className="join-invite-avtar">
                                                                <img src={sent_invitation.to.profile} alt={sent_invitation.to.name} />
                                                            </div>
                                                            <div className="join-invite-content">
                                                                <p>Invitation sent to <Link to={`/profile-details/${sent_invitation.to.slug}`}>{sent_invitation.to.name} </Link> by <Link to={`/profile-details/${sent_invitation.from.slug}`}>{sent_invitation.from.name}</Link>.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            {
                                                (typeof sent_invitations === 'undefined' || sent_invitations.length <= 0)
                                                &&
                                                <><div className="text-center pt-4"><p>There were no invitations sent.</p></div></>
                                            }

                                            {
                                                all === false && sent_invitations !== ''
                                                &&
                                                <div className="join-invite-more">
                                                    <button onClick={(e) => { showMore(); }} className="show-more-btn">Show more</button>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <AboutCard group={group} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}

export default SentInvitation;