import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Toaster } from "../../../../entity/GlobalJS";
import Materials from "../manage/Materials";

const Step3 = (props) => {
    const navigate = useNavigate();
    const course_id = props.cid;
    const [count, setCount] = useState(0);
    const submitNext = (is_continue) => {
        if (count <= 0) {
            Toaster.error('Please add atleast one material.');
            return false;
        }

        if (is_continue) {
            props.setStep(4);
            navigate(`/dashboard/tutor/edit-course/${course_id}/4`);
        } else {
            navigate('/dashboard/tutor/draft-courses');
        }
    }

    return (
        <>
            <Materials course_id={course_id} is_edit={false} setCount={setCount} setStep={props.setStep} submitNext={submitNext} />
        </>
    );
}

export default Step3;